// :root {
//   --primaryColor: #025159;
//   --secondaryColor: #212529;
//   --greyColor:#ededed;
// }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}

#root {
  white-space: pre-line;
}

$primary-color: #025159;
$secondary-color: #212529;
$grey-color: #ededed;
$child-theme : #F2921D;
$yellow : #f2c94c;
$grey-2 : #f4f5f5;
$grey-3 : #d4d5d5;
$grey-4 : #76787d;
$black : #000000;
$black-1 : #424242;
$child-investment-bg : #f2e6da;
$parent-investment-bg : #daeae9;
$disabled-btn : var(--tertiaryGrayColor);
$cardShadow : 1px 1px 8px #dedede;
$cardRadius : 16px;
$loginCardRadius : 30px;

:root {
  --font-8: 8px;
  --font-9: 9px;
  --font-10: 10px;
  --font-11: 11px;
  --font-12: 12px;
  --font-13: 13px;
  --font-14: 14px;
  --font-15: 15px;
  --font-16: 16px;
  --font-17: 17px;
  --font-18: 18px;
  --font-19: 19px;
  --font-20: 20px;
  --font-21: 21px;
  --font-22: 22px;
  --font-23: 23px;
  --font-24: 24px;
  --font-25: 25px;
  --font-26: 26px;
  --font-27: 27px;
  --font-28: 28px;
  --font-29: 29px;
  --font-30: 30px;
  --font-31: 31px;
  --font-32: 32px;
  --font-33: 33px;
  --font-34: 34px;
  --font-35: 35px;
  --font-36: 36px;
  --font-37: 37px;
  --font-38: 38px;
  --font-39: 39px;
  --font-40: 40px;
  --font-41: 41px;
  --font-42: 42px;
  --font-43: 43px;
  --font-44: 44px;
  --font-45: 45px;
  --font-46: 46px;
  --font-47: 47px;
  --font-48: 48px;
  --font-49: 49px;
  --font-50: 50px;
  --font-56: 56px;
}

.small,
small {
  font-size: var(--font-13) !important;
  font-weight: 400;
}

.page-register {
  #finicityConnectIframe {
    top: 100px !important;
    min-height: 680px;

    @media (max-width:575px) {
      top: 0 !important;
      min-height: 790px;
    }
  }
}

.custom-switch {
  padding-left: 38px !important;

  label {
    &::before {
      left: -2.4rem !important;
      background-color: #adb5bd;
      width: 38px !important;
    }

    &::after {
      left: -2.4rem !important;
    }
  }
}

//2FA page css
.twoStep-auth-wrapper {
  width: 550px;
  max-width: 550px;
  margin: auto;

  .twoStep-auth-header {
    .back-arrow {
      margin-bottom: 20px;
    }

    .pg-tagLine {
      font-size: var(--font-16);
      line-height: 18px;
      margin-bottom: 7px;
      font-weight: 700;
    }

    .pg__title {
      font-weight: 700;
      font-size: var(--font-24);
      line-height: 27px;
      margin-bottom: 16px;
      color: var(--primaryColor);

      @media (max-width: 480px) {
        font-size: var(--font-21);
        line-height: 30px;
      }
    }

    .pg-desc {
      color: var(--tertiaryGrayColor);
      font-weight: 400;
      font-size: var(--font-14);
      line-height: 20px;
      letter-spacing: 0.01em;
      margin-bottom: 24px;
      max-width: 500px;
    }
  }

  .otp-input-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 113px;
    margin-top: 40px;

    input {
      margin-right: 10px;
      text-align: center;
      font-weight: 400;
      font-size: var(--font-35);
      width: 62px !important;
      height: 62px !important;
      border-radius: var(--borderRadiusM);
      background: #f4f5f5;
      border: 2px solid transparent;
      color: var(--primaryColor);

      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
        border: 2px solid var(--primaryColor);
      }

      &:last-child {
        margin-right: 0px;
      }

      @media (max-width: 575px) {
        width: 48px !important;
        height: 48px !important;
        min-width: 48px !important;
        min-height: 48px !important;
        margin-right: 10px !important;
        font-size: var(--font-30);
      }
    }
  }

  .resend-text {
    color: var(--tertiaryGrayColor);
    font-weight: 400;
    font-size: var(--font-14);
    text-align: center;
  }

  .error-msg {
    color: var(--errorColor);
    font-weight: 400;
    font-size: var(--font-14);
    text-align: center;
    margin-top: 30px;
  }

  &.parent {
    .twoStep-auth-header {
      .pg-tagLine {
        color: var(--tertiaryColor);
      }
    }

    .otp-input-wrapper {
      input {

        &:focus,
        &:active {
          box-shadow: none;
          outline: none;
          border: 2px solid var(--primaryColor);
        }
      }
    }
  }

  &.child {
    .twoStep-auth-header {
      .pg-tagLine {
        color: var(--primaryWardColor);
      }
    }

    .otp-input-wrapper {
      input {

        &:focus,
        &:active {
          box-shadow: none;
          outline: none;
          border: 2px solid var(--primaryWardColor);
        }
      }
    }
  }

  @media (max-width:575px) {
    width: 100%;
    max-width: 100%;
    padding: 30px 15px;
  }
}

body {
  overflow-y: scroll;
  margin: 0;
  font-family: 'GothamBook' !important;
  // font-family: 'gotham' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--secondaryColor);
  background: #fff;
  padding-top: 63px;

  &.register {
    @media (max-width:575px) {
      padding-bottom: 0 !important;
    }
  }

  &.investOnly {
    background-color: #fff !important;
  }

  // &.child-module.practice-mode {
  //   background-color: $child-investment-bg;
  // }

  // &.parent-module.practice-mode {
  //   background-color: $parent-investment-bg;
  // }

  &.page-no-header {
    padding-top: 0;
  }

  @media (max-width:767px) {
    padding-bottom: 52px;
  }

  @media (max-width: 575px) {
    padding-top: 0px;
  }
}

.modal-dialog {
  // @media (max-width:575px) {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
  // }
}

.grey-color {
  color: var(--tertiaryGrayColor);
  font-size: var(--font-12);
  margin-top: 4px;
}

.login-screen-container {
  // min-height: calc(100vh - 63px);
  margin-top: 51px;

  @media (max-width:575px){
    margin-top: 0;
  }

  .back-section{
    position: absolute;

    @media (max-width:575px){
      position: sticky;
      background: #fff;
      padding: 21px 0 10px;
      top: 0;
      z-index:1;
    }
  }

  .login-form {
    width: 440px;
    margin: 0px auto 0px auto;
    padding-bottom: 30px;
  
    .login-back-language {
      display: flex;
      justify-content: end;
      align-items: center;
  
      .loginLangSelect {
        a.nav-link {
          @media (max-width:575px) {
            padding: 0;
            line-height: 14px;
          }
        }
      }
  
      @media (max-width:575px) {
        align-items: flex-start;
        margin-top: 21px;
      }
    }
  
    &.login-wrapper {
      @media (max-width:575px) {
        padding-top: 22px;
  
        h1,
        .h1 {
          margin-bottom: 55px !important;
        }
  
        .form-group {
          margin-bottom: 72px !important;
        }
      }
    }
  
    .forgot-password {
      font-weight: 600;
    }
  
    h1,
    .h1 {
      color: var(--primaryGrayColor);
      margin-bottom: 40px;
      font-size: var(--font-28);
      line-height: 40px;
      color: var(--primaryColor);
  
      @media (max-width:480px) {
        text-align: left !important;
        font-size: var(--font-40);
        line-height: 56px;
        font-family: 'GothamXLight';
      }
    }
  
    .form-check {
      .form-check-label {
        .form-check-input {
          margin-top: 0.2rem;
        }
      }
    }
  
    .form-group {
      margin-bottom: 2rem !important;
  
      label {
        font-size: var(--font-13);
        margin-bottom: 0.3rem;
      }
  
      &:nth-child(2) {
        @media (max-width:480px) {
          margin-bottom: 2rem;
        }
      }
  
      @media (max-width: 480px) {
        margin-bottom: 50px !important;
  
        &.login-back-language {
          margin-bottom: 72px !important;
        }
  
        &.password-input {
          margin-bottom: 35px !important;
        }
  
        &.form-check {
          label.form-check-label {
            input {
              margin-top: 0.05rem;
            }
          }
        }
      }
    }
  
    @media (max-width:575px) {
      width:100%;
      .login-btn {
        width: 100%;
        margin-top: 0px;
        position: fixed;
        bottom: 0;
        left: 0px;
        padding: 10px 15px 30px;
      }
    }
  }

  .reset-pass-existing-user{
    margin-bottom: 0px;
    text-align: center;
    color: var(--secondaryGrayColor);
    font-size: var(--font-14);
    line-height: 24px;
    font-weight: 400;
    margin-top: 50px;

    @media (max-width:575px){
      left:50%;
      bottom: 15px;
      position: absolute;
      transform: translate(-50%, 50%);
    }
  }

  .alreadyAccount {
    margin-bottom: 0px;
    text-align: center;
    color: var(--secondaryGrayColor);
    font-size: var(--font-14);
    line-height: 24px;
    font-weight: 400;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 124px);

    a {
      color: var(--primaryColor);
      font-weight: 700;
    }

    @media (max-width:575px) {
      margin-bottom: -6px;
    }
  }
}

.landing-screen-tiles-wrapper {
  margin-top: 51px;
  // min-height: calc(100vh - 63px);
  // display: flex;
  // align-items: center;
  // justify-content: center;

  @media (max-width:575px) {
    margin-top: 21px;
  }

  .signupLanding {
    .back-section{
      margin-bottom: 161px;

      @media (max-width:575px){
        margin-bottom: 40px;
      }
    }
    .routeBack-flat {
      @media (max-width:767px) {
        max-width: 343px;
        margin: 0px auto 31px;
      }
    }
  }
}

.incomplete-spending-setting {
  width: 450px;
  margin: auto;

  @media (max-width:1199px) {
    width: 100%;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

.noscroll::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
  border-radius: var(--borderRadiusM);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor);
}

.clearfix {
  clear: both;
}

.customTooltip {
  color: #fff;
  background: #000;
  top: -33px;
  font-size: var(--font-13);
  padding: 3px;
  border-radius: var(--borderRadiusS);
  position: absolute;
  left: -20px;
  min-width: 100px;
  text-align: center;
  display: none;

  &::after {
    content: "▼";
    color: #000;
    position: absolute;
    bottom: -var(--font-13);
    left: calc(50% - 5px);
  }
}

.loginLangSelect {
  a.nav-link {
    text-transform: uppercase;
  }

  .dropdown-menu {
    min-width: auto;
    padding: 0px;

    a {
      font-size: var(--font-13);
      padding: 0.25rem 10px;
      min-width: 70px;

      img {
        margin-right: 5px;
      }

      &:active {
        background-color: transparent;
      }
    }
  }
}

#connect-container {
  width: 100%;

  iframe {
    width: 100%;
    // margin-left: calc(50% - 250px);
    // border: 1px solid #000;
    border-radius: var(--borderRadiusS);
    height: 720px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: #fff;
    }

    @media (max-width:575px) {
      width: 100%;
      margin-left: 0;
      position: fixed;
      border: none;
      min-height: 100vh;
      ;
    }
  }
}

.goBackInner {
  font-size: var(--font-18);
  color: var(--primaryColor);
  font-weight: 500;
  white-space: nowrap;
  float: left;
  display: flex;
  height: 36px;
  align-items: center;
  margin-top: -1px;

  img {
    width: 14px;
    margin-right: 6px;
  }
}

.modal {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: var(--tertiaryColor) !important;
  border-color: var(--tertiaryColor) !important;
}

.custom-control-input:checked~.custom-control-label::after {
  left: -2rem !important;
}

.custom-switch .custom-control-label::after {
  width: 20px !important;
  height: 20px !important;
  border-radius: 20px !important;
  box-shadow: 0px 0px 4px #adb5bd;
  top: 2.35px !important;
  background: #fff !important;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
  background-color: #adb5bd !important;
  border-color: #adb5bd !important;
}

.primary-text {
  color: var(--primaryColor);
}

.main-container {
  padding: 0px 40px;
  // background: #fff;
  min-height: calc(100vh - 64px);

  // &.child-module.practice-mode {
  //   background-color: $child-investment-bg;
  // }
}

.cursor_pointer {
  cursor: pointer;
}

.btn-266 {
  font-size: 22px !important;
  min-width: 266px !important;
  padding: 16px 20px !important;
}

.mobile-btn-266 {
  @media (max-width:575px) {
    font-size: 22px !important;
    min-width: 266px !important;
    padding: 16px 20px !important;
    width: 100%;
  }
}

.hide-575 {
  @media (max-width:575px) {
    display: none;
  }
}

select.custom-select {
  // font-weight: 700;
}

.mobile-view-tabs-listing {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0px;
  display: none;

  li {
    list-style: none;
    padding: 13px 0px;
    border-bottom: 1px solid #f4f5f5;
    font-size: var(--font-16);
    display: flex;
    align-items: center;
    color: var(--primaryColor);

    svg.menu-icon {
      width: 26px;
      margin-right: 5px;
    }

    span {
      margin-left: auto;

      img {
        width: 8px;
      }
    }
  }

  &.parent-setting {
    li {
      &:last-child {
        display: inherit !important;
        text-align: center;
        border: none;
        padding: 30px 0px;

        span {
          display: none;
        }
      }
    }
  }

  @media (max-width:575px) {
    display: block;
  }
}

.topLanguageChange {
  a.dropdown-toggle {
    padding: 0px;
    line-height: 27px;
    font-size: var(--font-14);
    height: 25px;
    vertical-align: top;
    text-transform: uppercase;
    color: var(--tertiaryGrayColor) !important;

    &::after {
      vertical-align: middle;
    }

    @media (max-width:575px) {
      height: 25px !important;
      line-height: 25px !important;
    }
  }

  .dropdown-menu {
    padding: 0px;
    min-width: auto;

    a {
      font-size: var(--font-13);
      padding: 0.25rem 10px;
      min-width: 70px;

      img {
        margin-right: 5px;
      }

      &:active {
        background-color: transparent;
      }
    }

    @media (max-width: 575px) {
      left: -40px;
    }
  }
}



.modal-heading {
  font-size: var(--font-20);
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  color: var(--primaryColor);

  @media (max-width:575px) {
    font-weight: 500;
    font-size: var(--font-20);
    line-height: 25px;
  }
}

.pos-relative {
  position: relative;
}

//BACK BUTTON GLOBAL CSS
.routeBack-flat {
  margin-bottom: 31px;

  span.back {
    font-size: var(--font-18);
    color: var(--primaryGrayColor);
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    align-items: center;

    img {
      margin-right: 6px;

      // @media (max-width:575px){
      //   width:20px;
      // }
    }
  }

  span.choreMis {
    position: absolute;
    right: 0;
    top: 1px;
  }

  @media (max-width:480px) {
    margin-bottom: 25px;

    span.back {
      font-size: var(--font-16);

      img {
        // width: 20px;
      }
    }

    span.choreMis {
      top: -2px;
    }
  }
}

.routeBack-abs {
  position: absolute;
  top: calc(50% - 29.5px);
  left: 0;

  span.back {
    font-size: var(--font-18);
    color: var(--primaryGrayColor);
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    align-items: center;

    img {
      margin-right: 6px;
      margin-top: 0px;

      @media (max-width:575px) {
        // margin-top:0;
        // width:20px;
      }
    }
  }

  @media (max-width:480px) {
    top: calc(50% - 12px);

    span.back {
      font-size: var(--font-16);

      img {
        // width: 20px;
      }
    }
  }
}

.routeBack-invest-abs {
  position: absolute;
  top: calc(50% - 11px);
  left: 0;

  span.back {
    font-size: var(--font-18);
    color: var(--primaryGrayColor);
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    align-items: center;

    img {
      margin-right: 6px;
      // width:20px;
    }
  }

  @media (max-width:1024px) {
    span.back {
      img {
        margin-top: 0 !important;
        width: auto !important;
      }
    }
  }

  @media (max-width:480px) {
    span.back {
      font-size: var(--font-16);

      img {
        // width: 20px !important;
      }
    }
  }

  @media (max-width: 414px) {
    top: calc(50% - 9.5px);
  }
}


.error-red {
  color: var(--errorColor);
  font-size: var(--font-12);
  padding-top: 2px;
  padding-top: 1px;
}

.disabledBtn {
  background-color: $disabled-btn !important;
  pointer-events: none;
  cursor: not-allowed;
}

.success-green {
  color: #76b118;
  font-size: var(--font-12);
  padding-top: 2px;
  padding-top: 1px;
}

.bottom-navigation-bar {
  position: fixed;
  width: 100%;
  bottom: 0px;
  display: none;
  z-index: 9;
  left: 0;
  background: #fff;

  .inner-bar {
    box-shadow: 0px 6px 12px #b8bfbfad;
    padding: 10px 22px;
    border-radius: var(--borderRadiusM);
    background: #fff;
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-bottom: 9px;
    overflow: hidden;

    ul {
      display: flex;
      padding: 0;
      column-gap: 20px;
      justify-content: space-between;
      margin: 0;
      align-items: center;

      li {
        list-style: none;
        width: 30px;
        text-align: center;
        position: relative;

        .badge {
          position: absolute;
          background-color: var(--errorColor);
          color: #fff;
          border-radius: 10px;
          top: -9px;
          font-size: 16px !important;
          text-align: center;
          left: 20px;
          padding: 5px 6px !important;
        }

        svg {
          width: 32px;
        }

        span.half-circle {
          display: none;
          width: 8px;
          height: 8px;
          background: var(--primaryColor);
          border-radius: 4px;
          position: absolute;
          bottom: -14px;
          left: calc(50% - 2px);
        }

        &.active {
          span.half-circle {
            display: inline-block;
          }

          svg {
            path {
              stroke: var(--primaryColor);
            }

            circle {
              stroke: var(--primaryColor);
            }

            rect {
              fill: var(--primaryColor);
            }

            &#myFamilyIcon {
              path {
                fill: none;
              }

              path#childHead {
                fill: var(--primaryColor);
              }

              path#lip {
                stroke: var(--primaryColor);
              }

              ellipse#eye {
                fill: var(--primaryColor);
              }

              path#stroke {
                stroke: var(--primaryColor);
              }
            }

            &#directPay {
              path {
                stroke: none;
                fill: var(--primaryColor);
              }

            }

            &#investmentReport {
              path {
                fill: var(--primaryColor);
                stroke: none;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width:767px) {
    display: initial;
  }
}

@font-face {
  font-family: "gotham";
  src: url('./fonts/new/Gotham-Book.woff') format('woff'),
    url('./fonts/new/Gotham-Book.woff2') format('woff2'),
    url('./fonts/new/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: GothamLight;
  src: url(./fonts/GothamBold.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: GothamMedium;
  src: url(./fonts/GothamMedium.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: GothamBook;
  src: url(./fonts/Gotham-Book.ttf) format("truetype");
  src: url(./fonts/Gotham-Book.woff) format("woff");
  src: url(./fonts/Gotham-Book.woff2) format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: GothamBold;
  src: url(./fonts/GothamBold.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: GothamThin;
  src: url(./fonts/Gotham-Thin.otf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: GothamXLight;
  src: url(./fonts/Gotham-XLight.otf) format("truetype");
  font-weight: normal;
}

// h1, h2, h3, h4, h5, h6, p, i, select, textarea, section, li {
//   font-family: 'GothamBook';
//   }

nav {
  z-index: 999;
}

.brand-logo {
  padding: 8px 0px 8px 0px;
  width: 250px;
}

.error-red {
  color: var(--errorColor);
  font-size: var(--font-12);
  padding-top: 2px;
  padding-top: 1px;
}

.text-error {
  color: var(--errorColor) !important;
}

.strikeout {
  text-decoration: line-through;
}

.react-datepicker-popper {
  .react-datepicker {
    .react-datepicker__month-container {
      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day--selected {
            background-color: var(--primaryColor);
          }

          .react-datepicker__day--keyboard-selected {
            background-color: transparent;
            color: #000;
          }

          .react-datepicker__day--outside-month {
            background-color: #f7f7f7;
            opacity: 0.3;
          }
        }
      }
    }
  }
}

.pointer-disable {
  pointer-events: none;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.flex {
  display: flex;
}

.space-bw {
  justify-content: space-between;
}

.row-reverse {
  flex-flow: row-reverse;
}

.direction-col {
  flex-direction: column;
}

.notFound-wrapper {
  text-align: center;

  .notFoundHeading {
    font-size: 120px;
    font-weight: 600;
  }

  .notFound-desc {
    font-size: var(--font-25);
  }
}

/*---COMMON CSS---*/
.react-datepicker-wrapper {
  display: block !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 1000s ease-in-out 0s;
  -webkit-text-fill-color: var(--primaryGrayColor) !important;
}

.inputWithIcon {
  position: relative;

  span.fa {
    position: absolute;
    right: 10px;
    top: 12px;
  }
}

.pointer {
  cursor: pointer;
}

.color-primary {
  color: var(--primaryColor);
}

.badge {
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 10px !important;
}

.badge-success {
  background-color: var(--primaryColor) !important;
}

.form-group {
  label {
    font-size: var(--font-14);
    margin-bottom: 5px;
    color: var(--secondaryGrayColor);
    font-weight: 500;
  }

  label.special {
    font-size: var(--font-14);
    line-height: 22px;
    margin-bottom: 10px;
    color: var(--primaryColor);
    font-weight: 700 !important;
  }
}

.section-block {

  h3,
  .h3 {
    background: #eaeaea;
    font-size: var(--font-13);
    padding: 7px 10px;
    font-weight: 400;
    color: #403f3f;
    border-radius: 5px;
  }

  h4,
  .h4 {
    font-size: var(--font-15);
  }
}

.linkBtn {
  font-size: var(--font-15);
  cursor: pointer;
  font-weight: 500;
}

.custom-table {
  table {
    thead {
      tr {
        th {
          font-weight: 500;
          border: none;
          padding: 9px 10px;
          font-size: var(--font-12);
          color: var(--primaryColor);
        }
      }
    }

    tbody {
      border-left: 1px solid #dee2e6;
      border-right: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;

      tr {
        td {
          background: #fff;
          font-size: var(--font-13);
          padding: 9px 10px;
        }
      }
    }
  }
}

.peers-single {
  padding: 15px;
  background: #fff;
  box-shadow: 0 1px 2px #00000026, 0 4px 15px #0000001a;
  border-radius: var(--borderRadiusS);
  margin-bottom: 15px;
}

select.form-control {
  font-size: var(--font-13);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #333;
  color: var(--primaryColor);

  &:focus {
    box-shadow: none;
  }
}

input.form-control {
  font-size: var(--font-16);
  line-height: 24px;
  background-color: transparent;
  padding: 6px 0px;
  height: 40px;
  border: none;
  border-bottom: 1px solid var(--primaryGrayColor);
  color: var(--primaryGrayColor);
  border-radius: 0px;

  &:focus {
    box-shadow: none;
    border-color: var(--primaryGrayColor);
  }
}

input.form-control::placeholder {
  font-size: var(--font-16);
  line-height: 24px;
  color: var(--tertiaryGrayColor);
}

textarea.form-control {
  font-size: var(--font-16);
  line-height: 24px;
  background-color: transparent;
  color: var(--primaryGrayColor);

  &:focus {
    box-shadow: none;
    border-color: var(--primaryGrayColor);
  }

  &::placeholder {
    font-size: var(--font-16);
    line-height: 24px;
    color: var(--tertiaryGrayColor);
  }
}

.navbar-custom-guest .nav-link {
  color: var(--secondaryColor) !important;
  padding: 0.5rem 2rem 0.5rem 0rem !important;

  .dropdown-toggle::after {
    vertical-align: 0.1em;
  }
}

.navbar-custom-guest .nav-item:last-child .nav-link {
  padding-right: 0px !important;
}

a {
  color: var(--primaryColor) !important;
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
  }
}

.mobDeskBtn {
  span.mobileIcn {
    display: none;
  }
}

button:disabled {
  background-color: $disabled-btn !important;
  pointer-events: none;
  cursor: not-allowed;
}

.btn-gradient {
  // background-image: linear-gradient(to right, #00D9BE, #01DCFA);
  background: var(--primaryColor);
  border: none;
  border-radius: var(--borderRadiusS);
  color: #fff !important;
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;
  min-width: 100px;

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    padding: 7px 15px;
    font-size: var(--font-13);
  }

  @media (max-width: 320px) {
    padding: 7px 15px !important;
  }
}

.btn-senior {
  // background-image: linear-gradient(to right, #00D9BE, #01DCFA);
  background: rgba(240, 243, 247, 1);
  border: none;
  border-radius: var(--borderRadiusS);
  color: var(--primaryGrayColor);
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.3px;
  cursor: pointer;
  min-width: 100px;

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    padding: 7px 15px;
    font-size: var(--font-13);
  }

  @media (max-width: 320px) {
    padding: 7px 15px !important;
  }
}

.btn-physicalCard-common {
  // background-image: linear-gradient(to right, #00D9BE, #01DCFA);
  background: var(--primaryColor);
  border: none;
  border-radius: var(--borderRadiusS);
  color: #fff !important;
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;
  min-width: 100px;

  &.created {
    background-color: transparent;
    color: var(--errorColor) !important;
    border: 1px solid var(--errorColor);
  }

  &.batched {
    opacity: 0.5;
    cursor: no-drop;
  }

  &.shipped {
    background-color: #fff;
    color: var(--primaryColor) !important;
    border: 1px solid var(--primaryColor);
  }

  &.returned {
    background-color: transparent;
    color: var(--primaryColor) !important;
  }

  &.canceled {
    background-color: var(--tertiaryGrayColor);
    color: #fff !important;
  }

  &.order_nobalance,
  .progress_nobalance {
    background-color: #dad3d3;
    color: #fff !important;
  }

  &.re-order {
    background-color: #d3d7da;
    color: #fff !important;
  }

  &.cancel {
    background-color: #fff;
    border: solid 1px #f14545;
    color: #f14545 !important;
  }

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    font-size: var(--font-13);
    padding: 7px 10px !important;
    width: 100px;
    justify-content: center;
  }
}

.btn-physicalCard-common.progress,
.btn-physicalCard-common.progress_nobalance {
  width: auto;
  height: auto;
  background: #dad3d3;
  border: none;
  border-radius: var(--borderRadiusS);
  color: #fff !important;
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;
  min-width: 100px;

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    font-size: var(--font-13);
    padding: 7px 10px !important;
    width: 100px;
    justify-content: center;
  }
}

.btn-delete-link {
  background: transparent;
  border: none;
  border-radius: var(--borderRadiusS);
  color: var(--errorColor) !important;
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;
  min-width: 100px;

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    padding: 7px 15px;
    font-size: var(--font-14);
  }

  @media (max-width: 320px) {
    padding: 7px 15px !important;
  }
}

.btn-choreCheck {
  background: var(--primaryColor);
  white-space: nowrap;
  border: none;
  border-radius: var(--borderRadiusS);
  color: #fff;
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;
  min-width: 100px;

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    padding: 7px 15px;
    font-size: var(--font-13);
  }

  @media (max-width: 320px) {
    padding: 7px 15px !important;
  }
}

.btn-gradient-outline {
  background: #fff;
  border: 1px solid var(--primaryColor);
  border-radius: var(--borderRadiusS);
  color: var(--primaryColor);
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;
  min-width: 100px;

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    padding: 7px 15px;
    font-size: var(--font-13);
  }

  @media (max-width: 320px) {
    padding: 7px 15px !important;
  }
}

.btn-gray {
  background: #f4f5f5;
  border: 1px solid transparent;
  border-radius: var(--borderRadiusS);
  color: var(--primaryColor);
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;
  min-width: 100px;

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    padding: 7px 15px;
    font-size: var(--font-13);
  }

  @media (max-width: 320px) {
    padding: 7px 15px !important;
  }
}

.btn-gray-outline {
  // background: #f4f5f5;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: var(--borderRadiusS);
  color: var(--tertiaryGrayColor);
  border: 1px solid var(--tertiaryGrayColor);
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;
  min-width: 100px;

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    padding: 7px 15px;
    font-size: var(--font-13);
  }

  @media (max-width: 320px) {
    padding: 7px 15px !important;
  }
}

.btn-gray-danger {
  background: #f4f5f5;
  border: 1px solid transparent;
  border-radius: var(--borderRadiusS);
  color: var(--errorColor);
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding: 10px 20px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    padding: 7px 15px;
    font-size: var(--font-13);
  }

  @media (max-width: 320px) {
    padding: 7px 15px !important;
  }
}

.btn-white {
  background: #fff;
  border: none;
  border-radius: var(--borderRadiusS);
  color: var(--tertiaryGrayColor);
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    padding: 7px 15px;
    font-size: var(--font-13);
  }

  @media (max-width: 320px) {
    padding: 7px 15px !important;
  }
}

.btn-secondary {
  background: var(--secondaryColor);
  border: none;
  border-radius: var(--borderRadiusS);
  color: #fff;
  box-shadow: none;
  padding: 10px 15px;
  font-size: var(--font-14);
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding: 10px 15px;
    font-size: var(--font-13);
    border-radius: var(--borderRadiusS);
  }

  @media (max-width: 480px) {
    padding: 7px 15px;
    font-size: var(--font-13);
  }

  @media (max-width: 320px) {
    padding: 7px 15px !important;
  }
}

.hide {
  display: none !important;
}

.loader-div {
  position: fixed;
  z-index: 9999999999999999;
  width: calc(100% - 506px);
  height: 100vh;
  top: 0;

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 270px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 0;
  }

  @media (max-width:575px) {
    height: calc(100vh - 150px);
    top: 75px;
  }

  &.center-loader {
    width: 100%;
    max-width: 100%;
    left: 0;
  }
}

.loader-div-post {
  position: fixed;
  z-index: 9999999999999999;
  width: calc(100% - 0px);
  height: 100vh;
  top: 0;

  @media (max-width:767px) {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    width: auto;

    .loader-post {
      transform: initial;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
    }
  }

  &.center-loader {
    width: 100%;
  }
}

@keyframes ldio-7cxkyhjer4s {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ldio-7cxkyhjer4s div {
  animation: ldio-7cxkyhjer4s 1s linear infinite;
  width: 80px;
  height: 80px;
  /* top: 60px; */
  /* left: 100px; */
  border-radius: 50%;
  box-shadow: 0 4px 0 0 var(--primaryColor);

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    box-shadow: 0 2px 0 0 var(--primaryColor);
  }

  /* transform-origin: 40px 41px;
  
}

.loader {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  left: 40%;
  position: relative;
  top: 30%;
  /* background: #f1f2f3; */
}

.loader-post {
  width: 80px;
  height: 80px;
  display: inline-block;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width:575px) {
    width: 40px;
    height: 40px;
  }
}

//No data found icon
.no-data-overlay-dashboard {
  position: fixed;
  top: 175px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 257px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-chores-childDetails {
  .no-data-found {
    top: 0;
    left: 0;
    transform: none;
    margin: 40px auto;
  }
}

.investment-settings-no-data {
  margin-top: 50px;

  .no-data-found {
    top: auto;
    left: auto;
    transform: initial;
    margin: auto;
  }
}

.no-data-stock-chart {
  text-align: center;

  .no-data-found {
    transform: none;
    left: auto;
    top: auto;
    width: 100%;
  }
}

.no-data-overlay-dashboard-chores {
  position: fixed;
  top: 214px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 214px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-dashboard-chores-noUser {
  position: fixed;
  top: 119px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 119px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-dashboard-chores-empty {
  position: fixed;
  top: 197px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 197px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-senior-notification {
  position: fixed;
  top: 117px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 117px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-dashboard-addBank {
  position: fixed;
  top: 270px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 270px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-timeline {
  position: fixed;
  top: 200px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 323px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-investment-timeline {
  position: fixed;
  top: 200px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 200px);

  &.filteredEmpyState {
    top: 265px;
    height: calc(100vh - 265px);
  }

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}


.no-data-overlay-schTransfer,
.no-data-overlay-receipts,
.no-data-overlay-statements {
  position: fixed;
  top: 117px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 142px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-wallet,
.no-data-overlay-savings,
.no-data-overlay-goals {
  position: fixed;
  top: 211px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 333px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-education {
  position: fixed;
  top: 183px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 278px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-stock-details {
  position: fixed;
  top: 183px;
  max-width: 800px;
  width: calc(100% - 324px);
  height: calc(100vh - 278px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-shopping {
  position: fixed;
  top: 174px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 336px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-retailer-search {
  margin-top: 30px;
  width: 100%;

  &.full {
    position: fixed;
    top: 269px;
    margin-top: 0;
    max-width: 800px;
    width: calc(100% - 506px);
    height: calc(100vh - 269px);

    @media (max-width:991px) {
      width: calc(100% - 320px);
    }

    @media (max-width:768px) {
      width: calc(100% - 230px);
    }

    @media (max-width:767px) {
      width: 100%;
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }
}

.no-data-overlay-charity-search {
  margin-top: 30px;
  width: 100%;

  &.full {
    position: fixed;
    top: 269px;
    margin-top: 0;
    max-width: 800px;
    width: calc(100% - 506px);
    height: calc(100vh - 269px);

    @media (max-width:991px) {
      width: calc(100% - 320px);
    }

    @media (max-width:768px) {
      width: calc(100% - 230px);
    }

    @media (max-width:767px) {
      width: 100%;
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }
}

.no-data-overlay-shopping-child {
  position: fixed;
  top: 180px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 267px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }

}

.no-data-overlay-shopping-child-search {
  position: fixed;
  top: 267px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 267px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
    top: 246px;
    height: calc(100vh - 248px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }

}

.no-data-overlay-shopping-charities {
  position: fixed;
  top: 262px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 431px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-family-children {
  position: fixed;
  top: 174px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 336px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-family-children-adult {
  position: fixed;
  top: 276px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 336px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-family-physicalcard {
  position: fixed;
  top: 261px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 336px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-childInvest-timeline {
  position: fixed;
  top: 274px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 274px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-childInvest-reports {
  position: fixed;
  top: 182px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 267px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-childInvest-watchlist {
  &.data-available {
    position: fixed;
    top: 197px;
    max-width: 800px;
    width: calc(100% - 506px);
    height: calc(100vh - 197px);

    @media (max-width:991px) {
      width: calc(100% - 320px);
    }

    @media (max-width:768px) {
      width: calc(100% - 230px);
    }

    @media (max-width:767px) {
      width: 100%;
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }

  &.no-data {
    position: fixed;
    top: 113px;
    max-width: 800px;
    width: calc(100% - 506px);
    height: calc(100vh - 113px);

    @media (max-width:991px) {
      width: calc(100% - 320px);
    }

    @media (max-width:768px) {
      width: calc(100% - 230px);
    }

    @media (max-width:767px) {
      width: 100%;
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
    }
  }
}

.no-data-found-peers-tab {
  position: fixed;
  top: 172px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 172px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-choresGoals {
  position: fixed;
  top: 180px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 267px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-overlay-childGoals {
  .no-data-found {
    width: 338px;
    top: auto;
    position: relative;
    left: auto;
    transform: translate(0, 0);
    margin: auto;

    .iconbox {
      margin: 0px auto 10px;
      text-align: center;
      background-color: #f4f6f5;
      color: #d3d7d8;
      width: 100px;
      height: 100px;
      font-size: 70px;
      border-radius: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 45px;
      }
    }

    .no-data-title {
      text-align: center;
      font-size: var(--font-14);
      line-height: 22px;
      margin-bottom: 5px;
      color: var(--secondaryGrayColor);
      font-weight: 600;
    }

    .no-data-subtitle {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--tertiaryGrayColor);
      text-align: center;
    }

    .no-data-subtitle-1 {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--tertiaryGrayColor);
      text-align: center;
    }
  }
}

.no-data-overlay-childGoalsTransaction {
  .no-data-found {
    width: 338px;
    top: auto;
    position: relative;
    left: auto;
    transform: translate(0, 0);
    margin: auto;

    .iconbox {
      margin: 0px auto 10px;
      text-align: center;
      background-color: #f4f6f5;
      color: #d3d7d8;
      width: 100px;
      height: 100px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 45px;
      }
    }

    .no-data-title {
      text-align: center;
      font-size: var(--font-14);
      line-height: 22px;
      margin-bottom: 5px;
      color: var(--secondaryGrayColor);
      font-weight: 600;
    }

    .no-data-subtitle {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--tertiaryGrayColor);
      text-align: center;
    }

    .no-data-subtitle-1 {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--tertiaryGrayColor);
      text-align: center;
    }
  }
}

.no-data-overlay-education-child {
  position: fixed;
  top: 117px;
  max-width: 800px;
  width: calc(100% - 506px);
  height: calc(100vh - 141px);

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.no-data-found {
  width: 338px;
  top: 50%;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);

  .iconbox {
    margin: 0px auto 10px;
    text-align: center;
    background-color: #f4f6f5;
    color: #d3d7d8;
    width: 100px;
    height: 100px;
    font-size: 70px;
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    img,
    svg {
      width: 45px !important;
      height:auto;
    }
  }

  .no-data-title {
    text-align: center;
    font-size: var(--font-14);
    line-height: 22px;
    margin-bottom: 5px;
    color: var(--secondaryGrayColor);
    font-weight: 600;
  }

  .no-data-subtitle {
    font-size: var(--font-14);
    line-height: 22px;
    color: var(--tertiaryGrayColor);
    text-align: center;

    @media (max-width:575px) {
      padding: 0px 30px;
    }
  }

  .no-data-subtitle-1 {
    font-size: var(--font-14);
    line-height: 22px;
    color: var(--tertiaryGrayColor);
    text-align: center;
  }

  @media (max-width:575px) {
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
  }
}

.ldio-7cxkyhjer4s {
  width: 100%;
  height: 100%;
  // position: relative;
  // transform: translateZ(0) scale(1);
  // backface-visibility: hidden;
  // transform-origin: 0 0;
  /* see note above */
}

.ldio-7cxkyhjer4s div {
  box-sizing: content-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*profileImage*/

.rounded-circle {
  width: 25px;
}

.navbar {
  padding: 0.1rem 1rem !important;
}

/*Top Header*/

.navbar-custom {
  background: #0b92a5;
}

.customBell {
  padding-top: 15px !important;
  color: #fea408 !important;
}

.navbar-custom-guest {
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: 1px 2px 10px #e8e8e8;
}

/*Global CSS*/

.customButton {
  background: #0b92a5;
  border: 1px solid #0b92a5;
}

.forgotPassword-success {
  .successMarkBox {
    width: 152px;
    height: 152px;
    padding: 16px;
    border-radius: 76px;
    margin: 0px auto 30px;

    .checkcover {
      width: 120px;
      height: 120px;
      border-radius: 60px;
      background: var(--primaryColor);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 56px;

        @media (max-width: 414px) {
          width: 36px;
        }
      }

      @media (max-width: 414px) {
        width: 90px;
        height: 90px;
        border-radius: 60px;
        background: var(--primaryColor);
        line-height: 90px;
      }
    }

    @media (max-width: 414px) {
      width: 112px;
      height: 112px;
      padding: 11px;
      border-radius: 56px;
    }
  }

  p {
    a {
      font-weight: 500;
    }
  }
}

//Investment Setup Success Modal
.investment-setup-success {
  .successMarkBox {
    width: 152px;
    height: 152px;
    padding: 16px;
    // background: #ceedea;
    border-radius: 76px;
    margin: 100px auto 0px;

    .checkcover {
      width: 120px;
      height: 120px;
      border-radius: 60px;
      background: var(--primaryColor);
      line-height: 120px;
      text-align: center;

      img {
        width: 56px;

        @media (max-width: 414px) {
          width: 36px;
        }
      }

      @media (max-width: 414px) {
        width: 90px;
        height: 90px;
        border-radius: 60px;
        background: var(--primaryColor);
        line-height: 90px;
      }
    }

    @media (max-width: 414px) {
      width: 112px;
      height: 112px;
      padding: 11px;
      border-radius: 56px;
    }
  }

  .successText {
    margin-top: 24px;
    text-align: center;

    h3,
    .h3 {
      font-weight: 600;
      font-size: var(--font-20);
      line-height: 23px;
      letter-spacing: 0.01em;
      color: var(--primaryColor);
    }
  }

  .bottom-fixed-buttons {
    @media (max-width:575px) {
      position: fixed;
      width: 100%;
      bottom: 0px;
      z-index: 9;
      left: 0px;
      padding: 10px 15px 30px;
    }
  }
}

//Investment Setup FAilure Modal
.investment-setup-failure {
  .failedMarkBox {
    width: 132px;
    height: 132px;
    padding: 16px;
    border-radius: 76px;
    margin: 0px auto 10px;
    margin-top: 90px;
    background-color: var(--gray5);

    .checkcover {
      width: 100px;
      height: 100px;
      border-radius: 60px;
      line-height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--secondaryGrayColor);

      img {
        width: 50px;
      }
    }
  }

  .successText {
    margin-top: 24px;
    text-align: center;

    h3,
    .h3 {
      font-weight: 600;
      font-size: var(--font-20);
      line-height: 23px;
      letter-spacing: 0.01em;
      color: var(--secondaryGrayColor);
    }
  }

  .bottom-fixed-buttons {
    @media (max-width:575px) {
      position: fixed;
      width: 100%;
      bottom: 0px;
      z-index: 9;
      left: 0px;
      padding: 10px 15px 30px;
    }
  }
}

//Physical Card Success Modal
.physical-card-success {
  .successMarkBox {
    width: 132px;
    height: 132px;
    padding: 16px;
    // background: #ceedea;
    border-radius: 76px;
    margin: 0px auto 30px;

    .checkcover {
      width: 100px;
      height: 100px;
      border-radius: 60px;
      background: var(--primaryColor);
      line-height: 100px;
      text-align: center;

      img {
        width: 56px;

        @media (max-width: 414px) {
          width: 36px;
        }
      }

      @media (max-width: 414px) {
        width: 90px;
        height: 90px;
        border-radius: 60px;
        background: var(--primaryColor);
        line-height: 90px;
      }
    }

    @media (max-width: 414px) {
      width: 112px;
      height: 112px;
      padding: 11px;
      border-radius: 56px;
    }
  }

  h3,
  .h3 {
    text-align: center;
    font-size: var(--font-21);
    font-weight: 600;
    color: var(--primaryColor);
  }

  p {
    text-align: center;
    margin-top: 10px;
    font-size: var(--font-14);
  }

  .linkBtn {
    font-size: var(--font-15);
    cursor: pointer;
    font-weight: 500;
  }
}

/*Login page*/
.landing-outer{
  max-width: 800px;
  margin:51px auto 0;

  @media (max-width:575px){
    margin:21px auto 0;
  }
}
.landing-selection-wrapper {
  width: 477px;
  margin: 50px auto 0px;
  text-align: center;

  .landingImg {
    margin-bottom: 32px;

    &.desktop {
      @media (max-width:480px) {
        display: none;
      }
    }

    &.mobile {
      display: none;
      margin-bottom: 70px;

      @media (max-width:480px) {
        display: inline-block;
        width: 100%;
        height: 373px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      @media (max-width:425px) {
        height: 333px;
      }

      @media (max-width:375px) {
        height: 293px;
      }
    }
  }

  h3,
  .h3 {
    font-weight: 300;
    font-size: var(--font-40);
    line-height: 56px;
    color: var(--primaryColor);
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    width: 343px;

    @media (max-width:480px) {
      width: 100%;
      padding: 0px 15px;
    }
  }

  p {
    font-size: var(--font-16);
    line-height: 24px;
    margin-bottom: 48px;
    width: 343px;
    margin-left: auto;
    margin-right: auto;
    color: var(--tertiaryGrayColor);
    font-weight: 400;

    @media (max-width:480px) {
      width: 100%;
      padding: 0px 15px;
    }
  }

  .btns-group {
    margin: 0px auto;
    padding-bottom: 50px;

    button.btn-gradient {
      font-size: var(--font-18);
      min-width: 266px;
      padding: var(--font-13) 20px;
    }

    button.btn-white {
      @media(max-width:480px) {
        font-weight: 600;
      }
    }

    @media (max-width:480px) {
      padding: 0px 15px 0px;

      button {
        width: 80%;
      }
    }
  }
}

.join-confirm-boxWrapper {
  width: 600px;
  margin: 200px auto;
  display: flex;
  justify-content: space-around;

  .join-confirm-box {
    padding: 20px;
    background: #fff;
    box-shadow: 0px 2px 7px #ececec;
    width: 45%;
    border-radius: var(--borderRadiusM);

    .icn-box {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      background: var(--tertiaryGrayColor);
      margin: 0px auto 20px auto;
    }

    .join-box-content {
      font-size: var(--font-18);
      text-align: center;
      padding: 0px 30px;
    }
  }
}

.feature-toggle-wrapper {
  .page-heading {
    padding-bottom: 25px;

    h1,
    .h1 {
      font-size: var(--font-25);
      line-height: 21px;
      color: var(--primaryColor);
      font-weight: 700;
      text-align: center;
      position: relative;
      margin-bottom: 0;

      .feature-info-icon {
        position: absolute;
        right: 0;
        top: 0;
      }

      .routeBack-abs {
        top: 0;

        @media (max-width:575px) {
          top: 8px;
        }
      }

      @media (max-width:1024px) {
        font-size: 0.938rem !important;
        line-height: 1rem;
        padding-bottom: 20px;
      }

      @media (max-width:575px) {
        font-size: var(--font-18) !important;
        line-height: 30px;
        padding: 0;
      }
    }

    @media (max-width:1024px) {
      padding-bottom: 0;
    }

    @media (max-width:575px) {
      padding-top: 14px;
      position: sticky;
      top: 0px;
      background-color: #fff;
      display: block;
      text-align: center;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .category-toggles-wrapper {
    .action-indicator {
      font-size: var(--font-12);
      color: var(--tertiaryGrayColor);
      margin-bottom: 10px;

      @media (max-width:575px) {
        display: none;
      }
    }

    .category-toggles-inner {
      display: flex;
      column-gap: 30px;
      flex-wrap: wrap;

      .feature-toggle-single {
        width: 343px;
        padding: 16px 16px;
        box-shadow: $cardShadow;
        border-radius: var(--borderRadiusL);
        margin-bottom: 20px;

        h4 {
          font-weight: 700;
          color: var(--primaryColor);
          line-height: 22px;
          min-height: var(--font-20);
          font-size: var(--font-17);
          overflow: hidden;
          display: -webkit-box;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          color: var(--primaryGrayColor);

          @media (max-width:575px) {
            font-weight: 500;
            color: var(--tertiaryGrayColor);
            font-size: var(--font-13);
            background-color: $grey-2;
            padding: 7px 12px;
            margin-bottom: 0px;
            margin-top: 0px;
            line-height: 1.2;
          }
        }

        .child-listing {
          margin-top: 13px;

          ul {
            padding: 0;
            margin: 0;
            display: flex;
            overflow: auto;

            li {
              list-style: none;
              margin-right: 10px;
              text-align: center;

              .child-icon {
                list-style: none;
                width: 42px;
                height: 42px;
                min-width: 42px;
                min-height: 42px;
                background: $grey-2;
                text-align: center;
                border-radius: 24px;
                overflow: hidden;
                box-sizing: border-box;
                cursor: pointer;

                &.active {
                  border: 2px solid var(--primaryColor);
                }

                @media (max-width:575px) {
                  width: 46px;
                  height: 46px;
                  min-width: 46px;
                  min-height: 46px;
                }
              }

              .username {
                color: var(--primaryColor);
                font-size: var(--font-10);
              }
            }

            &::-webkit-scrollbar {
              width: 6px;
              height: 4px;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
            }

            &::-webkit-scrollbar-thumb {
              background: var(--gray5);
              border-radius: var(--borderRadiusM);
            }

            &::-webkit-scrollbar-thumb:hover {
              background: var(--gray5);
            }
          }

          @media (max-width:575px) {
            margin-top: 0;
            padding: 15px 15px;
          }
        }

        @media (max-width:575px) {
          width: 100%;
          padding: 0;
          box-shadow: none;
          border-radius: 0;
          margin-bottom: 0px;
        }
      }

      @media (max-width:575px) {
        width: 100%;
      }
    }

    @media (max-width:575px) {
      margin: 0px auto;
    }


  }

  @media (max-width:575px) {
    margin-left: -15px;
    margin-right: -15px;
  }
}


/*---REGISTER PAGE---*/
.poBoxError {
  font-size: var(--font-12);
  color: var(--tertiaryGrayColor);
}

.signupLanding {
  // margin-top: 97px;
  // width: 716px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;

  .routeBack-flat {
    // min-height: 40px;
    display: flex;
    align-items: center;
  }

  .onboard-selection-title{
    text-align: center;
    font-weight: 600;
    // margin-bottom: 140px;
    // margin-top: 51px;

    @media (max-width:575px){
      margin-top:20px;
    }

    .sub-title{
      font-size: var(--font-18);
      text-align: center;
      font-weight: 700;
      color: var(--tertiaryColor);
      margin-bottom: .5rem;
      line-height: 1.2;

      @media (max-width:575px){
        font-size: var(--font-13);
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }
    .title{
      font-weight: 700;
      font-size: var(--font-22);
      color: var(--primaryColor);
      margin-bottom: 26px;
      line-height: 21px;
      margin-top: 125px;

      @media (max-width:575px){
        font-size: var(--font-22);
        line-height: 30px;
        text-align: left;
        margin-top:15px;
      }
    }
  }

  .signupLandingWrapper {
    display: flex;
    column-gap: 30px;
    margin: 0px auto 50px;
    justify-content: center;
    width:800px;

    &.registration {

      .signupLandingtile {
        width: 343px;
        height: 296px;
        padding: 21px 20px 32px 20px;
        cursor: pointer;
        border-radius: var(--borderRadiusXL);
        box-shadow: $cardShadow;
        position: relative;

        .create-account {
          width: 298px;
          height: 141px;
          margin: 0px auto 6px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 141px;
          }
        }

        .accept-invite {
          width: 298px;
          height: 141px;
          margin: 0px auto 6px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 141px;
          }
        }

        h3,
        .h3 {
          // font-size: var(--font-40);
          font-size: 1.9rem;
          line-height: 56px;
          color: var(--primaryColor);
          font-weight: 300;
          margin-bottom: 0px;
          text-align: center;
          font-family: 'GothamXLight';
          // font-size-adjust: 0.4;
        }

        p {
          font-size: var(--font-16);
          line-height: 24px;
          color: var(--tertiaryGrayColor);
          font-weight: 400;
          margin-bottom: 0px;
          margin-top: 4px;
        }

        .tile-background {
          // background-size: 353px 649px;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: -1;
          background-repeat: no-repeat;
        }

        @media (max-width:767px) {
          margin-bottom: 20px;
        }
      }
    }

    &.login {
      .tile-first {

        h3,
        .h3 {
          color: var(--primaryColor);
        }
      }

      .tile-second {

        h3,
        .h3 {
          color: var(--primaryWardColor);
        }
      }

      .tile-third {
        h3,.h3 {
          color: rgba(38, 51, 105, 1);
        }
      }

      .tile-first, .tile-second, .tile-third{
        width:100%;
      }

      .signupLandingtile {
        // width: 343px;
        height: 256px;
        padding: 21px 20px 32px 20px;
        cursor: pointer;
        position: relative;
        box-shadow: $cardShadow;
        border-radius: var(--borderRadiusXL);

        .im-adult {
          // width: 298px;
          height: 151px;
          margin: 0px auto 15px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .im-child {
          // width: 298px;
          height: 151px;
          margin: 0px auto 15px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h3,
        .h3 {
          font-weight: 400;
          // font-size: var(--font-40);
          font-size: 2em;
          line-height: 56px;

          margin-bottom: 0px;
          text-align: center;
          font-family: 'GothamXLight';
          // font-size-adjust: 0.4;

          // @media (max-width:640px){
          //   font-size: var(--font-30);
          // }
        }

        .tile-background {
          // background-size: 353px 599px;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: -1;
          background-repeat: no-repeat;
        }

        @media (max-width:767px) {
          margin-bottom: 20px;
        }
      }
    }

    @media (max-width:767px) {
      flex-wrap: wrap;
      width:100%;
    }

    // @media (max-width:575px){
    //   margin: 50px auto 0px;
    // }
    // @media (max-width:480px){
    //   flex-wrap: wrap;
    // }
  }

  .alreadyAccount {
    margin-bottom: 0px;
    text-align: center;
    color: var(--secondaryGrayColor);
    font-size: var(--font-14);
    line-height: 24px;
    font-weight: 400;
    position: absolute;
    bottom: 10px;
    left: calc(50% - 124px);

    a {
      color: var(--primaryColor);
      font-weight: 700;
    }

    @media (max-width:575px) {
      margin-bottom: -40px;
    }
  }

  @media (max-width:768px) {
    width: 100%;
  }

  @media (max-width:767px) {
    margin-top: 15px;
  }

  @media (max-width:575px) {
    margin-top: 0px;
    min-height: 700px;
    // height: calc(100vh - 90px);
    position: relative;
  }
}

.regoSignup {

  h1,
  .h1 {
    font-weight: 700;
    font-size: var(--font-22);
    color: var(--primaryColor);
    margin-bottom: 26px;
    line-height: 21px;

    @media (max-width:575px) {
      text-align: left !important;
      margin-bottom: 20px !important;
    }
  }

  h5,
  .h5 {
    font-size: var(--font-18);
    text-align: center;
    font-weight: 700;
    color: var(--tertiaryColor);
    margin-bottom: 15px;
    line-height: 1.2;

    @media (max-width:575px){
      font-size: var(--font-13);
      text-align: left !important;
      margin-bottom: 0.5rem;
      line-height: initial;
    }
  }

  &.pin_code,
  &.getting_started {
    margin: 51px auto;
    padding-bottom: 51px;

    .back-section {
      position:absolute;

      @media (max-width:575px) {
        position: relative;
        margin-right: 20px;
        top: auto;
      }
    }

    .pbar {
      display: flex;
      align-items: center;
      column-gap: 30px;
      min-height: 24px;
      position: relative;
      // margin: 0px auto 51px auto;

      .progressWrapper {
        display: none;
        width: 335px;
        position: absolute;
        left: calc(50% - 167px);
        margin-top: 0;

        .stepProgressBar {
          @media (max-width:575px) {
            height: 3px;
            border-radius: 3px;
          }
        }

        .stepBackground {
          @media (max-width:575px) {
            height: 3px;
          }
        }

        @media (max-width:575px) {
          width: 100%;
          position: relative;
          left: auto;
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 0;
        }
      }

      @media (max-width:575px) {
        position: sticky;
        top: 0px;
        background: #fff;
        z-index: 9;
        margin: 0px auto 0px auto;
        column-gap: 0;
      }

      @media (max-width:480px) {
        width: 100%;
      }
    }

    .confirmPhoneNumWrapper {
      width: 550px;

      .bottom-fixed-buttons {
        @media (max-width:575px) {
          position: fixed;
          width: 100%;
          bottom: 0px;
          background: #fff;
          z-index: 9;
          left: 0;
          padding: 10px 15px 30px !important;
        }
      }

      @media (max-width:575px) {
        margin-top: 20px !important;
      }
    }

    @media (max-width:575px) {
      width: 100%;
      margin: 0;
    }
  }



  .largeBtn {
    width: 343px;
  }

  .progressWrapper {
    position: relative;
    width: 475px;
    margin: auto;
    margin-top: 20px;

    .stepProgressBar {
      position: absolute;
      background: var(--primaryColor);
      height: 9px;
      min-width: 5%;
      border-radius: 7px;
    }

    .stepBackground {
      background-color: #f4f5f5;
      border-radius: 5px;
      width: 100%;
      height: 9px;
    }

    @media (max-width:480px) {
      width: 100%;
    }
  }

  .user-agreement {
    width: 670px;
    margin-left: calc(50% - 335px);
    margin-right: auto;

    &.u-agreement {

      h1,
      .h1 {
        @media (max-width:575px) {
          text-align: center !important;
          font-size: 15px !important;
        }
      }

      .userAgreement-box {
        p {
          font-size: var(--font-14);
          line-height: 21px;
        }
      }
    }

    .userAgreement-box {
      margin-bottom: 40px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--gray5);
        border-radius: var(--borderRadiusM);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--gray5);
      }

      p {
        font-size: var(--font-14);
        color: #505d5f;
        line-height: 21px;
        font-weight: 400;

        b {
          color: #404040;
        }

        // @media (max-width:575px){
        //   font-size: var(--font-13);
        //   line-height: 20px;
        // }
      }

      ul {
        padding: 0px;

        li {
          font-size: var(--font-14);
          color: #505d5f;
          line-height: 22px;
          font-weight: 400;
          list-style: none;
          margin-bottom: 10px;
        }
      }

      @media (max-width:575px) {
        margin-bottom: 60px;
      }
    }

    .acceptTerms {
      .custom-control {
        position: relative;

        input[type=checkbox] {
          top: calc(50% - 10px);
        }

        label {
          font-size: var(--font-12);
          line-height: 19px;
          color: #505d5f;
          padding-left: 20px;
          font-weight: 600;

          &::before {
            width: 20px;
            height: 20px;
            top: 0.05rem;
            left: -1.5rem;
            cursor: pointer;
            top: calc(50% - 10px);
          }

          &::after {
            width: 20px;
            height: 20px;
            background-size: 12px;
            top: 0.05rem;
            left: -1.5rem;
            cursor: pointer;
            top: calc(50% - 10px);
          }

          a {
            text-decoration: underline;
          }

          @media (max-width:575px) {
            font-size: var(--font-12);
            line-height: 21px;
          }
        }
      }
    }

    @media (max-width:640px) {
      width: 100%;
      margin-left: auto;
    }
  }

  .identityConfirmed-wrapper {
    .identity-content {
      margin: 30px auto 70px;
      max-width: 500px;

      p {
        font-size: var(--font-14);
      }
    }
  }

  .signup-createAccount {
    width: 400px;
    margin: auto;

    h1,
    .h1 {
      // margin-bottom: 20px;

      @media (max-width:575px) {
        margin-bottom: 16px;
      }
    }

    @media (max-width:575px) {
      width: 100%;
      margin-left: auto;
      padding-bottom: 30px;

      .form-group {
        margin-bottom: 42px !important;

        label {
          display: none;
        }

        &.min-margin {
          margin-bottom: 20px !important;
        }
      }
    }
  }

  .passwordRegMatch {
    ul {
      padding: 0px;

      li {
        list-style: none;
        font-size: var(--font-14);
        color: var(--primaryGrayColor);
        line-height: 16px;
        letter-spacing: 0.02em;
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0px;
        }

        span {
          width: 16px;
          height: 16px;
          background: transparent;
          display: inline-block;
          margin-right: 10px;
          border-radius: 14px;
          vertical-align: middle;
          border: 2px solid var(--gray5);
          margin-top: -2px;
        }

        img.MatchImg {
          margin-right: 10px;
          margin-top: -2px;
          width: 16px;
        }
      }
    }
  }

  .myProfileWrapper {
    position: relative;
    width: 400px;
    margin: auto;

    h1,
    .h1 {
      margin-bottom: 24px;
    }

    .grey-subtitle {
      font-weight: 500;
      font-size: var(--font-15);
      line-height: 20px;
      color: var(--tertiaryGrayColor);
      margin-bottom: 80px !important;

      @media (max-width:575px) {
        margin-bottom: 40px !important;
      }
    }

    .para-text {
      font-size: var(--font-12);
      color: #505D5F;
      font-weight: 600;

      a {
        color: #505D5F !important;
      }
    }

    .userIconDisplayBox {
      width: 96px;
      height: 96px;
      border-radius: 50px;
      margin: 0px auto 56px auto;
      background: #f4f5f5;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;

      img.openAvtarModal {
        position: absolute;
        bottom: 5px;
        cursor: pointer;
        right: 2px;
      }
    }

    button {
      margin-top: 0px !important;
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: #fff;
        z-index: 9;
        left: 0;
        padding: 10px 15px 30px !important;
      }
    }

    @media (max-width:575px) {
      width: 100% !important;
      margin-left: auto;
      padding-bottom: 30px;

      .form-group {
        margin-bottom: 42px !important;

        label {
          display: none;
        }
      }
    }
  }

  .confirmPhoneNumWrapper {
    width: 400px;
    margin: auto;

    h1,
    .h1 {
      margin-bottom: 24px;

      @media (max-width:575px){
        font-size: var(--font-22);
        line-height: 30px;
        text-align: left;
        margin-top: 15px;
      }
    }

    .grey-subtitle {
      margin-bottom: 74px !important;

      @media (max-width:575px) {
        margin-bottom: 32px !important;
      }
    }

    .confirmNum-InputsGroups {
      display: flex;
      padding: 0px;
      margin-bottom: 113px;
      margin-top: var(--font-13);

      input {
        margin-right: 32px;
        // text-align: center;
        // font-size: var(--font-24);
        // line-height: 36px;
        height: auto;
        font-weight: 500;
        color: #000;

        &:focus {
          border-color: var(--primaryColor);
        }

        &:focus-visible {
          border-color: var(--primaryColor);
        }

        &:last-child {
          margin-right: 0px;
        }

        // &::placeholder {
        //   font-size: var(--font-24);
        //   line-height: 36px;
        // }
      }

      @media (max-width:575px) {
        padding: 0;
      }
    }

    .box-inputs {
      display: flex;
      justify-content: center;
      margin-bottom: 113px;
      margin-top: 64px;

      input {
        margin-right: 10px;
        text-align: center;
        font-weight: 400;
        font-size: var(--font-35);
        width: 72px !important;
        height: 72px !important;
        border-radius: var(--borderRadiusM);
        background: #f4f5f5;
        border: 2px solid transparent;

        &:focus,
        &:active {
          border-color: var(--primaryColor);
          outline: none;
        }

        &:last-child {
          margin-right: 0px;
        }

        @media (max-width: 575px) {
          margin-right: 15px;
        }

        @media (max-width:480px) {
          width: 52px !important;
          height: 52px !important;
          min-width: 52px !important;
          min-height: 52px !important;
          font-size: var(--font-25);
        }

        @media (max-width:425px) {
          width: 46px !important;
          height: 46px !important;
          min-width: 46px !important;
          min-height: 46px !important;
          margin-right: 10px !important;
        }
      }
    }

    .resend-code {
      font-weight: 500;
      font-size: var(--font-15);
      line-height: 20px;
      color: var(--tertiaryGrayColor);

      @media (max-width:575px) {
        margin-bottom: 10px !important;
      }
    }

    @media (max-width:575px) {
      width: 100%;
      margin-left: auto;
    }
  }

  .confirmationCode {

    h1,
    .h1 {
      margin-bottom: 64px;
    }

    h3,
    .h3 {
      font-size: var(--font-17);
      font-weight: 400;
      letter-spacing: 0.4px;
      margin-bottom: 20px;
      color: #505d5f;
    }

    .form-group {
      position: relative;

      .error-red {
        position: absolute;
        top: 100%;
        width: 100%;
        margin-top: 4px;
      }
    }

    .resend-code {
      font-weight: 500;
      font-size: var(--font-16);
      line-height: 24px;
      color: var(--tertiaryGrayColor);
    }

    .verificationCodeInputs {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      input {
        margin-right: 10px;
        text-align: center;
        font-weight: 400;
        width: 72px;
        height: 72px;
        border-radius: var(--borderRadiusL);
        font-size: var(--font-35);
        background: #f4f5f5;
        border: 2px solid transparent;

        &:focus,
        &:active {
          border: 2px solid var(--primaryColor);
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .verifing-identity {
    width: 400px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


    h1,
    .h1 {
      text-align: center;

      @media (max-width:575px) {
        text-align: center !important;
        font-size: 18px !important;
        margin-bottom: 10px !important;
      }
    }

    h4.grey-subtitle {
      @media (max-width:575px) {
        text-align: center;
        margin-bottom: 0;
      }
    }

    @media (max-width:575px) {
      width: 100%;
      margin-left: auto;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .unverified-identity {
    width: 400px;
    margin: auto;

    .failedMarkBox {
      width: 132px;
      height: 132px;
      padding: 16px;
      border-radius: 76px;
      margin: 0px auto 10px;
      margin-top: 90px;
      background-color: var(--gray5);

      .checkcover {
        width: 100px;
        height: 100px;
        border-radius: 60px;
        line-height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #7d8687;

        img {
          width: 50px;
        }
      }
    }

    h3,
    .h3 {
      color: #7d8687;
      font-size: var(--font-18);
      text-align: center;
      font-weight: 700;
    }

    h4,
    .h4 {
      font-size: var(--font-15);
      color: var(--secondaryGrayColor);
      margin-bottom: 30px;
    }

    @media (max-width:575px) {
      width: 100%;
      margin-left: auto;
    }
  }

  .verifyIdentity {
    width: 400px;
    margin: auto;

    @media (max-width:575px) {
      width: 100%;
      margin-left: auto;

      .form-group {
        margin-bottom: 72px !important;

        label {
          display: none;
        }
      }
    }
  }

  .identity-confirmed {
    width: 400px;
    margin: auto;

    .para-text {
      p {
        font-size: var(--font-14);
        line-height: 21px;
      }

      @media (max-width:575px) {
        text-align: left !important;
      }
    }

    .accept-identity {
      .custom-control {
        position: relative;

        input[type=checkbox] {
          top: calc(50% - 10px);
        }

        label {
          font-size: var(--font-12);
          line-height: 19px;
          color: #505d5f;
          padding-left: 20px;
          font-weight: 600;

          &::before {
            width: 20px;
            height: 20px;
            top: 0.05rem;
            left: -1.5rem;
            cursor: pointer;
            top: calc(50% - 10px);
          }

          &::after {
            width: 20px;
            height: 20px;
            background-size: 12px;
            top: 0.05rem;
            left: -1.5rem !important;
            cursor: pointer;
            top: calc(50% - 10px);
          }

          a {
            text-decoration: underline;
          }

          @media (max-width:575px) {
            font-size: var(--font-12);
            line-height: 21px;
          }
        }
      }
    }

    .successMarkBox {
      width: 132px;
      height: 132px;
      padding: 16px;
      // background: #ceedea;
      border-radius: 76px;
      margin: 0px auto 90px;

      .checkcover {
        width: 100px;
        height: 100px;
        border-radius: 60px;
        background: var(--primaryColor);
        line-height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 50px;
        }
      }

      @media (max-width:575px) {
        width: 132px;
        height: 132px;
        padding: 16px;
        background: #ceedea;
        border-radius: 76px;
        margin: 0px auto 0px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .identity-confirmed-content {
      margin-bottom: 50px;

      p {
        font-size: var(--font-15);
        color: #404040;
        text-align: center;

        @media (max-width:575px) {
          text-align: left;
        }
      }
    }

    @media (max-width:768px) {
      padding: 0px 100px;
    }

    @media (max-width:575px) {
      padding: 0px 0px;
      width: 100%;
      margin-left: auto;
    }
  }

  .connect-bank {
    width: 670px;
    margin: auto;
    margin-right: auto;

    h1,
    .h1 {
      @media (max-width:575px) {
        text-align: center !important;
        font-size: 20px !important;
      }
    }

    .signup-add-fund-wrapper {
      .successMarkBox {
        width: 132px;
        height: 132px;
        padding: 16px;
        border-radius: 76px;
        margin: 0px auto 10px;
        margin-top: 90px;

        .checkcover {
          width: 100px;
          height: 100px;
          border-radius: 60px;
          line-height: 110px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 50px;
          }
        }

        &.partial,
        &.success {
          background: #ceedea;

          .checkcover {
            background: var(--primaryColor);
          }
        }

        &.failed {
          background: var(--gray5);

          .checkcover {
            background: #7d8687;
          }
        }

        @media (max-width:575px) {
          margin-top: 160px;
        }
      }

      .message-box {
        .msg-title {
          font-size: var(--font-18);
          text-align: center;
          font-weight: 700;

          &.partial,
          &.success {
            color: var(--primaryColor);
          }

          &.failed {
            color: #7d8687;
          }
        }

        p {
          font-size: var(--font-15);
          color: var(--secondaryGrayColor);
        }
      }

      .banks-list {
        margin-bottom: 60px;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        column-gap: 40px;
        flex-wrap: wrap;

        .bank-detail {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          width: 50%;

          .bank-icon {
            width: 30px;
            min-width: 30px;
            margin-right: 10px;
          }

          .bank-name {
            color: var(--primaryColor);
            line-height: 20px;
            min-height: 20px;
            padding-right: 15px;
            font-size: var(--font-15);
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }

          .status {
            margin-left: auto;
            text-align: right;

            label {
              margin-bottom: 0;
              font-size: var(--font-12);
              white-space: nowrap;

              &.verified {
                color: var(--secondaryGrayColor);
              }

              &.unverified {
                color: var(--errorColor);
              }
            }

            span {
              display: block;
              font-size: var(--font-12);
              color: var(--secondaryGrayColor);
              white-space: nowrap;
            }
          }

          @media (max-width:767px) {
            margin-bottom: 25px;
          }

          @media (max-width:575px) {
            width: 100%;
          }
        }
      }

      .nobank-flow {
        .screen-1 {

          h3,
          .h3 {
            font-size: var(--font-18);
            font-weight: 700;
            padding-bottom: 20px;
            color: var(--primaryColor);
            margin-bottom: 0;
          }

          p {
            font-size: var(--font-15);
            margin-bottom: 25px;

            // @media (max-width:575px){
            //   font-size:var(--font-13);
            //   line-height: 20px;
            // }
          }

          ul {
            padding: 0;

            li {
              list-style: none;

              // @media (max-width:575px){
              //   font-size:var(--font-13);
              //   line-height: 20px;
              // }
            }
          }

          .btn-next {
            margin-top: 50px;

            // button.next {
            //   font-size: var(--font-18);
            //   min-width: 266px;
            //   padding: var(--font-13) 20px;
            // }
          }
        }

        .screen-2 {

          h3,
          .h3 {
            font-size: var(--font-18);
            font-weight: 700;
            padding-bottom: 20px;
            color: var(--primaryColor);
            margin-bottom: 0;
          }

          p {
            font-size: var(--font-15);
          }

          .popular-banks-list {
            margin-top: 20px;
            margin-bottom: 25px;

            li {
              list-style: none;
              padding: 9px 0px 7px;
              border-bottom: 1px solid #f4f5f5;
              cursor: pointer;

              a {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .bank-name {
                  font-size: var(--font-15);
                  color: var(--primaryColor);
                }

                .angle-right {
                  img {
                    width: 8px;
                  }
                }
              }
            }
          }

          .btn-next {
            margin-top: 50px;

            button.next {
              font-size: var(--font-18);
              min-width: 266px;
              padding: var(--font-13) 20px;
            }
          }
        }

        @media (max-width:575px) {
          padding-bottom: 30px;
        }
      }
    }

    @media (max-width:768px) {
      width: 100%;
      margin-left: auto;
    }
  }

  .fundAccount1 {
    .logo-icn {
      width: 120px;
      height: 120px;
      background: #ececec;
      border-radius: 120px;
      margin: 0px auto 20px auto;
    }

    .fundText {
      font-size: var(--font-17);
      font-weight: 400;
      letter-spacing: 0.4px;
      margin-bottom: 20px;
      color: #505d5f;
      padding: 0px 70px;
    }

    .fund-btnSet {
      margin-top: 70px;
    }
  }

  .fundAccount2 {

    h3,
    .h3 {
      font-size: var(--font-17);
      font-weight: 400;
      letter-spacing: 0.4px;
      margin-bottom: 20px;
      color: #505d5f;
      padding: 0px 70px;
    }

    .fundAccount-card {
      padding: 15px;
      background-color: #fff;
      box-shadow: 0px 2px 7px #ececec;
      margin-bottom: 15px;
      border-radius: var(--borderRadiusM);
      cursor: pointer;

      h4,
      .h4 {
        color: var(--primaryColor);
        font-size: var(--font-18);
        font-weight: 500;
        margin-bottom: 5px;
      }

      p {
        font-size: var(--font-13);
        margin-bottom: 0px;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .fund-btnSet {
      margin-top: 70px;
    }
  }

  .welcome-screen {
    .identity-confirmed {

      h1,
      .h1 {
        text-align: center !important;
      }
    }

    .para-box {
      padding-top: 180px;
    }
  }

  .genderGrp {
    button {
      background-color: #f4f5f5;
      color: var(--secondaryGrayColor);
      font-size: var(--font-12);
      line-height: 16px;
      border: none;
      padding: 16px 30px;
      margin-right: 15px;
      font-weight: 500;
      border-radius: var(--borderRadiusS);

      &.active {
        background-color: var(--primaryColor);
        color: #fff;
      }

      @media (max-width:575px) {
        padding: 10px 30px;
      }
    }
  }

}

.regoSignup {
  .form-group {
    margin-bottom: 2rem !important;

    &.states-list {
      select {
        border-radius: 0;
        font-size: var(--font-16);
        line-height: 24px;
        background-color: transparent;
        padding: 6px 0px;
        height: 40px;
        border: none;
        border-bottom: 1px solid var(--primaryGrayColor);
        color: var(--primaryGrayColor);
        border-radius: 0px;

        &:disabled {
          opacity: 0.7;
        }
      }
    }
  }
}

.regoSignup .form-group label {
  font-size: var(--font-13);
  margin-bottom: 0.3rem;
}

.googlePlaces-input {
  .css-2b097c-container {
    font-size: var(--font-13);

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer {
      display: none;
    }

    .css-1pahdxg-control {
      background-color: #f7f9f9;
      border: 1px solid #cecece;
      height: 40px;

      &:focus,
      &:hover,
      &:active {
        border: 1px solid #80bdff !important;
        box-shadow: none !important;
      }
    }

    .css-yk16xz-control {
      background-color: #f7f9f9;
      border: 1px solid #cecece;
      height: 40px;
    }
  }
}

.register-wrapper{
  max-width: 800px;
  margin: 51px auto 0;

  @media (max-width:575px){
    margin:0 auto;
  }

  .back-section {
    position: absolute;

    @media (max-width:575px) {
      position: sticky;
      top: 0;
      padding:21px 15px 10px;
      background-color: #fff;
    }
  }

  .signUpStepForm-wrapper {
    width: 670px;
    max-width: 670px;
    margin: 0px auto 51px;
    padding-bottom: 51px;
  
    .stepNumbers-list {
      margin: 0px auto 51px auto;
      position: relative;
      align-items: center;
  
      img {
        margin-bottom: 14px;
      }
  
      .signupLangChange {
        display: inline-block;
  
        li {
          list-style: none;
  
          a.nav-link {
            padding: 0;
            text-transform: uppercase;
  
            @media (max-width: 480px) {
              padding: 0px;
            }
          }
  
          .dropdown-menu {
            min-width: auto;
            padding: 0;
  
            a {
              font-size: var(--font-14);
  
              &:active {
                background-color: transparent;
              }
            }
          }
        }
      }
  
      .progressWrapper {
        position: relative;
        width: 335px;
        margin: auto;
  
        .stepProgressBar {
          position: absolute;
          background: var(--primaryColor);
          height: 9px;
          min-width: 5%;
          border-radius: 7px;
  
          @media (max-width:575px) {
            height: 3px;
            border-radius: 3px;
          }
        }
  
        .stepBackground {
          background-color: #f4f5f5;
          border-radius: 5px;
          width: 100%;
          height: 9px;
  
          @media (max-width:575px) {
            height: 3px;
          }
        }
  
        @media (max-width:575px) {
          border-radius: 4px;
          overflow: hidden;
        }
      }
    }
  
    &.register-flow {
      .grey-subtitle {
        font-size: var(--font-14);
        line-height: 20px;
        color: var(--tertiaryGrayColor);
        text-align: center;
        margin-bottom: 64px;
  
        span {
          font-weight: 700;
        }
  
        @media (max-width:575px) {
          text-align: left;
        }
      }
  
      .stepNumbers-list {
        display: flex;
        align-items: center;
        column-gap: 30px;
        justify-content: center;
        min-height: 24px;
        position: relative;
  
        .progress-section {
          width: 335px;
          position: absolute;
          left: calc(50% - 167px);
  
          @media (max-width:575px) {
            width: 100%;
            position: relative;
            left: auto;
          }
        }
  
        .language-section {
          position: absolute;
          right: 0;
          top: 1px;
  
          @media (max-width:575px) {
            position: relative;
            top: auto;
            margin-left: 20px;
          }
        }
  
        @media (max-width:575px) {
          position: sticky;
          top: 0px;
          background: #fff;
          z-index: 9;
          padding-top: 21px;
          padding-bottom: 15px;
          margin: 0px auto 0px auto;
          column-gap: 0;
          min-height: 54px;
          justify-content: flex-start;
        }
      }
  
      .signup-heading {
        font-size: var(--font-18);
        text-align: center;
        font-weight: 700;
        color: var(--tertiaryColor);
        margin-bottom: 15px;
  
        @media (max-width:575px) {
          font-size: var(--font-13);
          text-align: left;
        }
      }
    }
  
    .bottom-fixed-buttons {
  
      @media (max-width:575px) {
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: #fff;
        z-index: 9;
        left: 0;
        padding: 10px 15px 30px;
      }
    }
  
    @media (max-width: 640px) {
      width: 100%;
      max-width: 100%;
    }
  
    @media (max-width: 575px) {
      margin: 20px auto 0;
      padding: 0px 15px 0px !important;
    }
  }
}



.notFound {
  margin-top: 20px;
  background: #fff;
  padding: 10px 40px;
  border: 2px dashed #cecece;
  font-size: var(--font-30);
  color: #cecece;
}

.notFound:hover {
  background: #000;
  color: #fff;
}

/*----FORGOT PASSWORD SCREEN CSS---*/
.forgotPassword {
  width: 343px;
  margin: 112px auto 100px auto;

  h1,
  .h1 {
    font-size: var(--font-28);
    line-height: 40px;
    color: var(--primaryColor);
    font-weight: 700;
    text-align: center;

    @media (max-width:575px) {
      font-size: var(--font-40);
      line-height: 56px;
      font-family: 'GothamXLight';
      margin-bottom: 16px;
      margin-top: 52px;

      @media (max-width:575px) {
        text-align: left !important;
      }
    }
  }

  .sub-title {
    margin-bottom: 58px;
    text-align: center;

    @media (max-width:575px) {
      text-align: left !important;
    }
  }

  .form-group {
    margin-bottom: 80px;
  }

  .bottom-fixed-button {
    @media (max-width:575px) {
      position: fixed;
      width: 100%;
      bottom: 0px;
      background: #fff;
      z-index: 9;
      left: 0;
      padding: 10px 15px 30px !important;
    }
  }

  @media (max-width:767px) {
    margin: 15px auto 0px;
  }

  @media (max-width:575px) {
    width: 100%;
  }
}

/*-----DASHBOARD CSS-------*/
.dashboard-wrapper {
  padding: 30px 0px;
  position: relative;
  min-height: calc(100vh - 64px);

  &.sidebar {
    display: flex;
  }

  // &.child-module.practice-mode {
  //   background-color: $child-investment-bg;
  // }

  .toggleSidebar {
    display: none;
    position: fixed;

    img {
      width: 36px;
    }

    // @media (max-width:575px) {
    //   display: none !important;
    // }
  }

  .parent-left-sidebar {
    min-width: 243px;
    width: 244px;
    // padding-top: 3px;
    position: fixed;
    left: 60px;
    transition: all 0.5s;

    ul {
      padding: 0px;

      li {
        list-style: none;
        font-size: var(--font-13);
        margin-bottom: 28px;
        font-weight: 400;
        color: var(--tertiaryGrayColor);
        letter-spacing: 0.4px;
        cursor: pointer;

        a {
          text-decoration: none;
          color: var(--tertiaryGrayColor) !important;

          span.menu-icon {
            width: 40px;
            display: inline-block;

            img {
              width: 25px;
            }
          }

          span {
            line-height: 11px;
            display: inline-block;
            vertical-align: middle;
          }

          svg {
            width: 30px;
          }
        }

        &.active {
          a {
            color: var(--primaryColor) !important;
          }

          svg {
            path {
              stroke: var(--primaryColor);
            }

            circle {
              stroke: var(--primaryColor);
            }

            rect {
              fill: var(--primaryColor);
            }

            &#myFamilyIcon {
              path {
                fill: none;
              }

              path#childHead {
                fill: var(--primaryColor);
              }

              path#lip {
                stroke: var(--primaryColor);
              }

              ellipse#eye {
                fill: var(--primaryColor);
              }

              path#stroke {
                stroke: var(--primaryColor);
              }
            }

            &#seniorDashboardIcon{
              path{
                stroke:none;
                fill: var(--primaryColor);
              }
            }

            &#caregiverIcon{
              mask{
                display: none;
              }
              path.middle{
                stroke: none;
                fill: var(--primaryColor);
                stroke-width: 1;
              }
            }

            &#investmentReport {
              path {
                fill: var(--primaryColor);
                stroke: none;
              }
            }

            &#editProfile {
              path {
                fill: var(--primaryColor);
                stroke: none;
              }
            }

            &#insightIcon, &#piggyIcon, &#protectionIcon{
              path{
                fill: var(--primaryColor);
                stroke: none;
              }
            }
          }
        }
        
      }
    }

    @media screen and (max-width: 10000px) and (min-width: 1200px) {
      left: calc(50% - 550px);
    }

    @media (max-width:1024px){
      left:40px;
    }

    @media (max-width:768px) {
      left: 15px;
      width: 200px;
      min-width: 200px;
    }

    @media (max-width:767px) {
      left: -240px;
      display: none;

      &.active {
        left: 0px;
        background: #fff;
        box-shadow: 2px 8px 8px #d1dede69;
        z-index: 9;
        height: 100%;
        padding-left: 20px;
        top: 54px;
        padding-top: 30px;
      }
    }
  }

  .dashboard-right-article {
    // width: 100%;
    width: calc(100% - 300px);
    padding: 0px 0px;
    margin-left: 244px;
    position: relative;

    .tenant-menubar {
      margin-bottom: 12px;
      position: sticky;
      top: -1px;
      background: #fff;
      z-index: 9;
      padding: 10px 0px 20px;

      ul {
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          list-style: none;
          font-size: var(--font-14);
          font-weight: 400;
          letter-spacing: 0.4px;
          cursor: pointer;
          padding: 0 15px;

          a {
            color: var(--tertiaryGrayColor) !important;
            position: relative;
            padding: 10px 0px;
          }

          &.active {
            a {
              color: var(--primaryColor) !important;

              &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background: var(--primaryColor);
                left: 0;
                bottom: 0;
              }
            }
          }

          &:first-child {
            padding-left: 0;
          }
        }
      }

      &.investment {
        // background-color: #fff !important;
        background-color: transparent;
      }

      @media (max-width:767px) {
        display: none;
      }
    }

    &.no-sidebar {
      width: 100%;
      margin-left: 0;
      max-width: 800px;

      .loader-div {
        width: 100%;
        max-width: 100%;
        left: 0;
      }

      .no-data-overlay-education,
      .no-data-overlay-wallet,
      .no-data-overlay-savings,
      .no-data-overlay-goals,
      .no-data-overlay-schTransfer,
      .no-data-overlay-receipts,
      .no-data-overlay-statements,
      .no-data-overlay-investment-timeline,
      .no-data-overlay-timeline,
      .no-data-overlay-dashboard-addBank,
      .no-data-overlay-dashboard-chores-empty,
      .no-data-overlay-dashboard-chores-noUser,
      .no-data-overlay-dashboard-chores,
      .no-data-overlay-dashboard,
      .no-data-overlay-shopping,
      .no-data-overlay-retailer-search,
      .no-data-overlay-charity-search,
      .no-data-overlay-shopping-child,
      .no-data-overlay-shopping-child-search,
      .no-data-overlay-shopping-charities,
      .no-data-overlay-family-children,
      .no-data-overlay-family-children-adult,
      .no-data-overlay-family-physicalcard,
      .no-data-overlay-childInvest-timeline,
      .no-data-overlay-childInvest-reports,
      .no-data-overlay-childInvest-watchlist,
      .no-data-found-peers-tab,
      .no-data-overlay-choresGoals,
      .no-data-overlay-education-child {
        width: 100%;
        max-width: 100%;
        left: 0;
        right: 0;
        transform: none;
      }
    }

    .dashboard-heading {
      font-size: var(--font-20);
      font-weight: 100;
      // padding-bottom: 25px;
      color: var(--primaryColor);
      font-weight: 700;
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      padding-bottom: 36px;
      line-height: 21px;

      .heading-right-icon-list {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          list-style: none;
          position: relative;
          margin-left: 20px;

          svg {
            height: 21px;
            display: flex;
            fill: var(--tertiaryGrayColor);
          }

          img {
            height: 21px;
            max-height: 21px;
            vertical-align: top;

            @media (max-width:575px) {
              height: 23px;
              max-height: 23px;
            }
          }

          &.superpower-icon {
            .dot {
              background-color: var(--errorColor);
              border-radius: 8px;
              color: #fff;
              display: inline-block;
              font-size: var(--font-10);
              height: 14px;
              line-height: 14px;
              position: absolute;
              right: -5px;
              text-align: center;
              top: -4px;
              width: 14px;
            }
          }

          &.language-changer {
            .dropdown-toggle {
              font-size: var(--font-15);
              line-height: 12px;
              font-weight: 500;

              @media (max-width:575px) {
                line-height: 22px;
              }
            }

            .dropdown-menu {
              li {
                font-weight: 500;
                font-size: var(--font-13);
                margin-left: 0;
                padding: 3px 12px;
                cursor: pointer;

                img {
                  margin-right: 8px;
                }
              }

              @media (max-width:1200px) {
                left: -50px !important;
                top: 5px !important;
              }
            }
          }

          @media (max-width:575px) {
            margin-left: 17px;
          }
        }

        @media (max-width:575px) {
          align-items: initial;
          margin-top: 0px;
        }
      }

      &.none {
        display: none;
      }

      @media (max-width:575px) {
        font-size: 18px !important;
        padding-top: 14px;
        line-height: 30px;
      }
    }

    &.page-parent-featureToggle {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-inviteUser {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-child-add {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-adult-add {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-senior-add {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-chore-common-add {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-setting-dashboard {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-chore-type-choreId {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-chore-type {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-child-detail {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-child-detail-childId {
      .dashboard-heading {
        display: none;
      }
    }

    &.page-parent-child-setting {
      .dashboard-heading {
        display: none;
      }
    }

    .dashboard-family-tabs {

      .my-family-header-group {
        margin-top: -59px;
        // margin-top: -52px;

        .banno-page-title {
          color: var(--primaryColor);
          font-weight: 700;
          display: flex;
          justify-content: center;
          margin-bottom: 0;
          padding-bottom: 5px;
          line-height: 21px;

          @media (max-width:575px) {
            font-size: 18px !important;
            position: absolute;
            left: calc(50% - 37px);
            padding: 0;
            line-height: 21px;
          }
        }

        .mobile-parent-setting-icon {
          display: none;

          img {
            height: 23px;
            margin-left: 0;

            @media (max-width:767px) {
              margin-top: -4px;
            }
          }

          @media (max-width:767px) {
            display: inline-block;
            position: absolute;
            left: 0px;
          }

          @media (max-width:575px) {
            left: 15px;
          }
        }

        .pgHeading {
          height: 51px;
          display: none;

          @media (max-width:767px) {
            display: inline-block;
          }

          @media (max-width:575px) {
            height: 30px;
            padding-bottom: 0;
            display: inline-block;
          }
        }

        @media (max-width:1024px) {
          .pgHeading {
            margin-top: 0px;
          }
        }

        @media (max-width:575px) {
          margin-top: 0px;
          padding-top: 21px;
          position: sticky;
          top: 0;
          z-index: 99;
          background: #fff;
          margin-left: -15px;
          padding-left: 15px;
          margin-right: -15px;
          padding-right: 15px;
          padding-bottom: 10px;
        }
      }

      .pgHeading {
        font-size: var(--font-20);
        padding-bottom: 25px;
        color: var(--primaryColor);
        font-weight: 700;
        // margin-top: -61px;
        margin-bottom: 0 !important;
        line-height: 21px;

        a {
          position: relative;

          .dot {
            width: 18px;
            height: 18px;
            background-color: var(--errorColor);
            border-radius: var(--borderRadiusS);
            display: inline-block;
            position: absolute;
            right: -7px;
            top: -4px;
            font-size: var(--font-11);
            color: #fff;
            text-align: center;
            line-height: 18px;
          }

          img {
            // width: 26px;
            // margin-top: -7px;
            cursor: pointer;
            // margin-left: 9px;

            @media (max-width:575px) {
              margin-top: 0px;
            }
          }
        }

        @media (max-width:1024px) {
          padding-bottom: 31px;
        }

        @media (max-width:575px) {
          text-align: center;
          margin-top: 0px;
          font-size: var(--font-18);

          img {
            margin-top: -1px;
          }
        }
      }

      .mobile-tabs {
        .tab-pane:not(.mobile-active) {
          @media (max-width:575px) {
            display: none;
          }
        }
      }

      .tiles-cards-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;

        .dashboard-children-card {
          width: 343px;
          margin-bottom: 20px;

          @media (max-width:1024px) {
            width: 330px;
          }

          @media (max-width:992px) {
            width: calc(50% - 15px);
          }

          @media (max-width:820px) {
            width: 100%;
          }

          @media (max-width:480px) {
            margin-bottom: 0px;
          }
        }

        .dashboard-chore-card {
          width: 343px;
          margin-bottom: 20px;

          @media (max-width:1024px) {
            width: 330px;
          }

          @media (max-width:992px) {
            width: calc(50% - 15px);
          }
          
          @media (max-width:820px) {
            width: 100%;
          }

          @media (max-width:480px) {
            margin-bottom: 0px;
          }
        }
      }

      .nav-tabs {
        border: none;
        align-items: center;
        overflow: auto;
        // height: 34px;

        &.child-settings {
          @media (max-width:575px) {
            display: none;
          }
        }


        li {
          margin-right: 20px;
          white-space: nowrap;
          margin-bottom: 6px;

          a.nav-link {
            border: none;
            font-size: var(--font-15);
            line-height: var(--font-20);
            padding: 0px 0px 3px 0px;
            color: var(--tertiaryGrayColor) !important;
            background-color: transparent;
            position: relative;

            &.active {
              color: var(--primaryColor) !important;
            }

            span {
              font-weight: 200;
            }

            &.active::after {
              content: "";
              position: absolute;
              width: 48px;
              background: var(--primaryColor);
              height: 2px;
              bottom: 0px;
              left: 0;
              border-radius: 2px;

              @media (max-width: 575px) {
                bottom: -10px;
              }
            }

            @media (max-width: 575px) {
              font-size: var(--font-27);
              line-height: 24px;
              font-family: 'GothamXLight';
            }
          }

          &.parent-inTabs {
            margin-right: 0px;

            @media (max-width: 575px) {
              display: none;
            }
          }

          &.shopping-inTabs {
            a {
              font-size: var(--font-18);
              color: var(--primaryColor) !important;
              line-height: 30px;
              font-weight: 500;
              padding: 0px;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 0px !important;
          height: 0px;
        }

        &.myFamily-navtabs {
          align-items: flex-start;

          @media (max-width:575px) {
            padding-top: 20px;
            margin-top: 0;
          }
        }
      }

      .tab-content {
        margin-top: 31px;

        .chore-sticky-row {
          @media (max-width:575px) {
            position: sticky;
            top: 125px;
            background: #fff;
            z-index: 9;
            margin-bottom: 5px;
          }
        }

        .dashboard-chores-card {
          .dashboard-tile-container {
            .dashboard-tile-icon {
              @media (max-width:575px) {
                width: 30px;
                min-width: 30px;
                height: 30px;
                min-height: 30px;
                line-height: 30px;
              }
            }
          }
        }

        .search-chore {
          margin-bottom: 28px;
          border-radius: var(--borderRadiusM);
          position: relative;
          display: flex;
          align-items: start;
          justify-content: space-between;
          column-gap: 20px;

          input {
            background-color: #f4f6f5;
            border: none;
            padding: 16px 16px 16px 48px;
            height: auto;
            border-radius: var(--borderRadiusM);
            border: 1px solid transparent;
            transition: all 0.7s;
            width: 343px;

            &:focus {
              border: 1px solid var(--primaryColor);
            }

            @media (max-width:1024px) {
              width: 330px;
            }

            @media (max-width:992px) {
              width: calc(50% - 15px);
            }

            @media (max-width:575px) {
              width: 100%;
            }
          }

          img.searchIcn {
            position: absolute;
            top: 21px;
            left: 16px;
          }

          .mobile-chore-btn {
            display: none;
            background-color: $child-theme;
          }

          .desktop-chore-btn {
            white-space: nowrap;
          }

          .closeFilter {
            top: 24px;
            width: 12px;
            position: absolute;
            left: calc(46% - 32px);
            cursor: pointer;

            @media (max-width:1024px) {
              left: calc(50% - 42px);
            }

          }

          @media(max-width:575px) {
            border-radius: var(--borderRadiusS);
            margin-bottom: 20px;
            column-gap: 40px;

            .mobile-chore-btn {
              display: block;
              padding: 10px 12px;
              border-radius: var(--borderRadiusM);
              min-width: auto;

              img {
                width: 19px;
              }
            }

            .desktop-chore-btn {
              display: none;
            }

            input {
              height: 60px;
              border: none;
              border-radius: var(--borderRadiusL);
            }

            img.searchIcn {
              top: 22px;
              width: 18px;
            }

            img.closeFilter {
              right: 100px;
              left: auto;
              top: 25px;
            }
          }
        }

        .bank-connection-expired {
          margin-bottom: 20px;
          position: relative;

          .icon-title {
            padding: 10px 30px;
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 0px;
            border-radius: var(--borderRadiusL);
            background: var(--primaryColor);
            color: #fff;

            .icon {
              padding-right: 16px;

              img {
                width: 25px;
              }
            }

            .title-desc {
              width: 100%;

              h3,
              .h3 {
                font-size: var(--font-14);
                position: relative;
                font-weight: 600;
                text-align: center;
                margin-bottom: 10px;
                padding-top: 0px;

                span.closeIcon {
                  position: absolute;
                  right: -20px;
                  top: -2px;

                  svg {
                    cursor: pointer;
                    width: 24px;

                    @media (max-width:575px) {
                      width: 22px;
                    }
                  }

                  @media (max-width:425px) {
                    right: -10px;
                  }
                }

                span.expandable {
                  position: absolute;
                  left: -20px;
                  top: -2px;
                  width: 24px;
                  height: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: var(--borderRadiusM);

                  &:not(.collapsed) {
                    .collapse {
                      display: none;
                    }
                  }

                  &.collapsed {
                    .collapse {
                      display: initial;
                    }

                    .expand {
                      display: none;
                    }
                  }

                  svg {
                    cursor: pointer;
                    width: var(--font-13);
                    height: 7px;

                    @media (max-width:1024px) {
                      height: 6.5px;
                    }
                  }

                  @media (max-width:1024px) {
                    left: -10px;
                    width: 18px;
                    height: 18px;
                  }

                  @media (max-width:425px) {
                    left: -10px;
                  }
                }
              }

              p {
                font-size: var(--font-14);
                margin: 0;
                line-height: 19px;
                text-align: center;

                @media (max-width:575px) {
                  line-height: 16px;
                  font-size: var(--font-12);
                }
              }
            }

            .before {}

            @media (max-width:425px) {
              padding: 20px 20px;
            }
          }

          .connect {
            text-align: center;
            font-weight: 500;
            padding: 2px 16px 5px 16px;
            font-size: var(--font-14);

            span {
              cursor: pointer;
              color: #fff;
            }
          }

          @media (max-width:480px) {
            margin-bottom: 15px;
          }
        }

        .timeFrames {
          margin-bottom: 20px;

          select {
            width: 130px;
            background-color: #f4f5f5;
            border: none;
            margin-right: 10px;
            font-size: var(--font-16);
            line-height: var(--font-19);
            font-weight: 500;
            height: auto;
            padding: 10px 12px;
            color: var(--primaryGrayColor);
            border-radius: var(--borderRadiusM);

            &:focus {
              box-shadow: none;
            }
          }

          .week-display {
            font-size: var(--font-13);
            font-weight: 600;
            height: 34px;
            line-height: 34px;
          }
        }

        @media (max-width:575px) {
          padding-top: 19px;
          margin-top: 0;
        }
      }
    }

    .dashboard-children-card {
      .pending {
        .dashboard-tile-container {
          .dashboard-tile-content {
            @media (max-width:767px) {
              display: flex;
              align-items: center;
            }

            .invitePending1 {
              @media (max-width:767px) {
                margin-left: auto;
                margin-bottom: 0 !important;
              }
            }
          }
        }

        .tile-background {
          @media (max-width:767px) {
            height: 95px;
          }

          @media (max-width:575px) {
            height: 87px;
          }
        }
      }

      .tile-background {
        width: 100%;
        height: 138px;
      }
    }

    .dashboard-chores-card {
      .tile-background {
        width: 100%;
        height: 130px;
      }
    }

    .dashboard-tile {
      position: relative;

      .dashboard-tile-container {
        display: flex;
        padding: 16px 16px;
        box-shadow: $cardShadow;
        border-radius: var(--borderRadiusL);
        background-color: #fff;

        .dashboard-tile-icon {
          margin-right: 18px;
          width: 38px;
          min-width: 38px;
          height: 38px;
          min-height: 38px;
          background: #f4f5f5;
          border-radius: 24px;
          overflow: hidden;
          line-height: 38px;

          @media (max-width:480px) {
            margin-right: 16px !important;
          }
        }

        .dashboard-tile-content {
          width: 100%;

          .tile-childName.chore {
            font-weight: 700;
            color: #424242;
            line-height: 22px;
            min-height: var(--font-18);
            display: flex;
            column-gap: 10px;
            justify-content: space-between;
            font-size: var(--font-16);

            .headingCont {
              overflow: hidden;
              display: -webkit-box;
              line-clamp: 1;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              color: #424242;
            }

            span {
              float: right;
              white-space: nowrap;
            }
          }

          .tile-childName.children {
            font-weight: 700;
            color: var(--primaryColor);
            line-height: 22px;
            min-height: var(--font-20);
            display: flex;
            justify-content: space-between;
            font-size: var(--font-16);
            position: relative;

            .headingCont {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              color: var(--primaryGrayColor);
            }

            .fix-spending-setting {
              font-size: var(--font-12);
              line-height: 14px;
              position: absolute;
              right: 0px;
              max-width: 100px;
              font-weight: 500;
            }

            @media (max-width:768px) {
              font-size: var(--font-17);
            }
          }

          .tile-spent.children {
            color: var(--tertiaryGrayColor);
            font-size: var(--font-12);
            line-height: 18px;
            margin-bottom: 8px;
            min-height: 18px;
            max-height: 18px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            .dotValue {
              display: inline-block;
              position: relative;
              padding: 0px 17px 0px 14px;

              &::before {
                content: '';
                position: absolute;
                left: 5px;
                width: 4px;
                height: 4px;
                background: var(--gray5);
                border-radius: 3px;
                top: 9px;
              }

              &::after {
                content: '';
                position: absolute;
                right: 6px;
                width: 4px;
                height: 4px;
                background: var(--gray5);
                border-radius: 3px;
                top: 9px;
              }

              &.noFrequency {
                &::after {
                  display: none !important;
                }
              }
            }

            @media (max-width: 480px) {
              font-size: var(--font-13);
            }
          }

          .tile-spent.invitePending {
            color: #000;
          }

          .tile-spent.chore {
            color: var(--tertiaryGrayColor);
            font-size: var(--font-12);
            line-height: 18px;
            margin-bottom: 5px;
            min-height: 18px;
            max-height: 18px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            @media (max-width:575px) {
              margin-bottom: 15px;
            }
          }

          .tile-bottom {
            display: flex;
            justify-content: space-between;
            min-height: 40px;
            align-items: center;

            .choresList {
              display: flex;

              .child-single {
                position: relative;
                margin-right: 8px;

                img {
                  width: 32px;
                }

                .status {
                  position: absolute;
                  bottom: -1px;
                  right: 6px;
                  width: 16px;
                  height: 16px;

                  img {
                    width: 16px;
                    margin-right: 0 !important;
                    vertical-align: top;
                  }

                  &.status-rejected {
                    border-radius: var(--borderRadiusM);
                    border: 2px solid $yellow;
                    background-color: #fff;
                    display: flex;
                    text-align: center;
                    padding-left: 2px;

                    img {
                      width: 9px;
                    }
                  }

                  &.status-awaitingConfirmation {
                    background-color: $yellow;
                    display: flex;
                    border-radius: var(--borderRadiusM);
                    align-items: center;
                    justify-content: center;

                    img {
                      width: 9px;
                    }
                  }

                  &.status-stolen {
                    background-color: $grey-3;
                    display: flex;
                    border-radius: var(--borderRadiusM);
                    align-items: center;
                    justify-content: center;

                    img {
                      width: 9px;
                    }
                  }
                }
              }

            }

            .created-by-name {
              font-size: var(--font-12);
              color: var(--tertiaryGrayColor);
              text-align: center;
              line-height: var(--font-15);
            }

            .tile-totalAlloted {
              font-weight: 700;
              color: var(--secondaryGrayColor);
              font-size: var(--font-14);
              min-height: 22px;

              @media (max-width: 480px) {
                font-size: var(--font-16);
              }
            }

            .tile-chart {
              font-weight: 500;
              color: var(--secondaryGrayColor);
              font-size: var(--font-14);
              display: flex;
              align-items: center;

              svg {
                width: 40px;
              }

              img {
                width: 130px;
              }

              canvas {
                margin-right: 16px;
                width: 40px !important;
                height: 40px !important;

                @media (max-width:480px) {
                  margin-right: 35px !important;
                }
              }

              .pieChart-customLegends {
                .singleLegend {
                  display: flex;
                  align-items: center;
                  margin-bottom: 6px;

                  .icn {
                    margin-right: 4px;
                    display: inline-block;
                    line-height: 10px;

                    img {
                      width: 12px;
                      margin-top: -4px;
                    }
                  }

                  .prgBar {
                    position: relative;
                    border-radius: 2px;
                    overflow: hidden;
                    margin-right: 0px;
                    width: 60px;

                    .prgBarBase {
                      height: 4px;
                      opacity: 0.1;
                    }

                    .prgBarData {
                      // width: 40px;
                      position: absolute;
                      left: 0;
                      height: 4px;
                      z-index: 9;
                      top: 0;
                    }

                    @media (max-width:480px) {
                      width: 63px !important;
                    }
                  }

                  .upDown {
                    line-height: 10px;

                    img {
                      width: 8px;
                      margin-top: -2px;
                      margin-left: 6px;

                      &.down {
                        transform: rotate(180deg);
                      }
                    }
                  }

                  &:nth-child(1) {
                    .prgBar {
                      .prgBarBase {
                        background-color: #f27405;
                      }

                      .prgBarData {
                        background-color: #f27405;
                      }
                    }
                  }

                  &:nth-child(2) {
                    .prgBar {
                      .prgBarBase {
                        background-color: var(--primaryColor);
                      }

                      .prgBarData {
                        background-color: var(--primaryColor);
                      }
                    }
                  }



                  &:nth-child(3) {
                    .prgBar {
                      .prgBarBase {
                        background-color: var(--tertiaryColor)
                      }

                      .prgBarData {
                        background-color: var(--tertiaryColor);
                      }
                    }
                  }

                  &:last-child {
                    margin-bottom: 0px;
                  }
                }
              }
            }

            @media (max-width: 480px) {
              align-items: center;
              min-height: auto;
            }
          }
        }

        @media (max-width:375px) {
          padding: 16px 16px 11px;
        }
      }

      @media (max-width: 480px) {
        margin-bottom: 15px;
        // padding: 15px 20px 21px;
        // border-radius: 33px;
        // margin-bottom: 10px;
        // padding: 20px 30px 30px;
      }
    }

    @media (max-width:1200px) {
      width: calc(100% - 244px);
    }

    @media (max-width:575px) {
      &.page-parent-profile {
        .dashboard-heading {
          padding-top: 14px;
          position: sticky;
          top: 0px;
          background-color: #fff;
          display: block;
          text-align: center;
          width: 100%;
          padding-bottom: 20px;
          z-index: 9;
        }
      }

      &.page-parent-timeline {
        .dashboard-heading {
          padding-top: 14px;
          position: sticky;
          top: 0px;
          background-color: #fff;
          display: block;
          text-align: center;
          width: 100%;
          padding-bottom: 0px;
          z-index: 9;
        }
      }

      &.page-parent-changePassword {
        .dashboard-heading {
          padding-top: 14px;
          position: sticky;
          top: 0px;
          background-color: #fff;
          // display: block;
          text-align: center;
          width: 100%;
          padding-bottom: 0px;
        }
      }

      &.page-parent-digital-wallet,
      &.page-parent-digital-saving,
      &.page-parent-digital-goals {
        .dashboard-heading {
          display: block;
          text-align: center;
          position: sticky;
          top: 0;
          background-color: #fff;
        }
      }

      &.page-parent-dashboard {
        .dashboard-heading {
          padding-top: 0px;
          position: fixed;
          right: 15px;
          z-index: 100;
          top: 22px;
        }
      }

      &.page-parent-education {
        .dashboard-heading {
          padding-bottom: 10px;
          text-align: center;
          display: block;
          position: sticky;
          top: 0px;
          background-color: #fff;
          z-index: 9;
        }
      }

      &.page-parent-scheduledTransfers,
      &.page-parent-receipts,
      &.page-parent-statement {
        .dashboard-heading {
          padding-bottom: 10px;
          text-align: center;
          display: block;
          position: sticky;
          top: 0px;
          background-color: #fff;
          z-index: 9;
        }
      }

      &.page-parent-receipts {
        .dashboard-heading {
          margin-left: -15px;
          margin-right: -15px;
        }
      }

      &.page-parent-wallet {
        .dashboard-heading {
          display: block;
          text-align: center;
          // padding-bottom: 10px;
          position: sticky;
          top: 0px;
          background-color: #fff;
          z-index: 9;
          margin-left: -15px;
          padding-left: 15px;
          margin-right: -15px;
          padding-right: 15px;
        }

        .recentTran-wrapper {
          .singlePendingTransaction {
            .noChild-addNewOne {

              @media (max-width:575px) {
                display: none;
              }
            }
          }
        }
      }

      &.page-parent-shopping {
        .dashboard-heading {
          display: none;
        }
      }

      &.page-parent-chore-type {
        .dashboard-heading {
          display: none;
        }
      }

      &.page-parent-education-detail-contentId {
        .dashboard-heading {
          display: none;
        }
      }

      &.page-parent-superpowers {
        .dashboard-heading {
          padding-bottom: 0px;
          display: block;
          text-align: center;
          position: sticky;
          top: 0;
          background: #fff;
          z-index: 9;
        }
      }
    }
  }

  @media (max-width:1024px) {
    padding: 20px 0px;
  }

  @media (max-width:575px) {
    padding: 0px;
  }
}


.page-parent-child-setting {
  .dashboard-family-tabs {
    .title {
      @media (max-width:1024px) {
        transform: initial;
      }
    }
  }
}

// WALLET/SAVINGS/GOAL PAGE CSS
.page-parent-digital-wallet,
.page-parent-digital-saving,
.page-parent-digital-goals {

  .dashboard-heading {
    display: block !important;
    text-align: center;
  }

  .digital-wallet-goals {
    position: relative;

    .goals-wrapper {
      margin-bottom: 60px;
    }

    .routeBack-abs {
      top: -57px;

      @media (max-width:575px) {
        position: fixed;
        left: 15px;
        top: 20px;
        z-index: 9;
      }
    }
  }

  .digital-wallet-wallet {
    position: relative;

    .routeBack-abs {
      top: -57px;

      @media (max-width:575px) {
        position: fixed;
        left: 15px;
        top: 20px;
        z-index: 9;

        img {
          margin-top: 0px;
        }
      }
    }

    .wallet-amount {
      text-align: center;
      padding-bottom: 30px;

      .amount {
        font-size: var(--font-40);
        line-height: 36px;
        letter-spacing: -0.03em;
        color: #424242;
        font-weight: 100;
        margin-bottom: 20px;

        @media (max-width:575px) {
          font-size: var(--font-35);
        }
      }

      .total-balance {
        font-size: var(--font-13);
        line-height: 16px;
        letter-spacing: 0.01em;
        cursor: pointer;
        color: var(--tertiaryGrayColor);
      }

      @media (max-width:575px) {
        position: sticky;
        top: 69px;
        background-color: #fff;
        z-index: 9;
      }
    }
  }

  .transaction-heading {
    font-size: var(--font-16);
    padding-bottom: 10px;
    margin-bottom: 0;
    color: var(--primaryColor);
    font-weight: 600;

    @media (max-width:575px) {
      font-size: var(--font-15);
      position: sticky;
      top: 171px;
      z-index: 9;
      background-color: #fff;
    }
  }

  .month-wrapper {
    .month-name {
      color: var(--tertiaryGrayColor);
      font-size: var(--font-13);
      background-color: #f4f5f5;
      padding: 7px 7px;
      margin-bottom: 0px;
      margin-top: 0px;
      line-height: 1.2;
    }

    .transaction-listing {
      .table {
        margin: 0px;

        tbody {
          tr {
            border-bottom: 1px solid #f4f5f5;

            td {
              border: none;
              padding: 16px 0px;
              vertical-align: middle;
              font-size: var(--font-14);
              font-weight: 300;

              h5,
              .h5 {
                font-size: var(--font-16);
                margin-bottom: 0px;
                font-weight: 300;
                line-height: 24px;

                @media (max-width: 575px) {
                  font-size: var(--font-14);
                  line-height: 20px;
                }
              }

              p {
                font-size: var(--font-12);
                margin-bottom: 0px;
                color: var(--tertiaryGrayColor);
                line-height: 16px;
                letter-spacing: 0.01em;
              }

              .avtar {
                width: 48px;
                height: 48px;
                min-width: 48px;
                min-height: 48px;
                background-color: #f4f5f5;
                border-radius: 24px;
                overflow: hidden;

                @media (max-width:575px) {
                  width: 42px;
                  height: 42px;
                  min-width: 42px;
                  min-height: 42px;
                }
              }

              &:last-child {
                font-size: var(--font-16);
                line-height: 24px;
                color: var(--primaryColor);
                font-weight: normal;

                @media (max-width: 575px) {
                  font-size: var(--font-15);
                  line-height: 20px;
                }
              }

              &:nth-child(2) {
                font-weight: normal;
                padding-left: 14px;
                padding-right: 80px;
              }

              &.negative {
                color: var(--errorColor);
              }

              &.positive {
                color: var(--primaryColor);
              }

              @media (max-width: 575px) {
                padding: 10px 0px;
                vertical-align: top;
              }
            }
          }
        }
      }
    }
  }
}

.page-digital-goals {
  .goals-wrapper {
    width: 600px;
    margin: 30px auto 0px;
    display: block;

    .goals-progress-single {
      margin-bottom: 24px;
      display: flex;

      .goal-avatar {
        min-width: 64px;
        max-width: 64px;
        background: var(--tertiaryGrayColor);
        min-height: 64px;
        max-height: 64px;
        background-position: center;
        background-size: cover;
        border-radius: 32px;
        background-size: 35px;
        background-repeat: no-repeat;

        @media (max-width: 575px) {
          background-size: 25px;
          min-width: 40px;
          max-width: 40px;
          min-height: 40px;
          max-height: 40px;
        }

        @media (max-width: 480px) {
          background-size: 20px;
        }
      }

      .goal-progress-bar {
        padding-left: 16px;
        width: 100%;

        h5,
        .h5 {
          font-size: var(--font-14);
          line-height: 22px;
          color: #000;
          margin-bottom: 6px;
          font-weight: 600;
          min-height: 22px;

          span {
            font-size: var(--font-16);
            line-height: 24px;
            font-weight: normal;
            color: #000;
          }
        }

        .progress {
          height: 8px;
          background-color: #f6f7f7;
          margin-bottom: 5px;

          .progress-bar {
            background: var(--primaryColor);
            height: 8px;
            border-radius: 4px 0px 0px 4px;
          }
        }

        .progress-value {
          font-size: var(--font-14);
          font-weight: 500;
          line-height: 16px;
          color: #000;
          max-width: 100%;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    @media (max-width:640px) {
      width: 100%;
    }
  }
}

/*------CHILD DETAIL CSS-------*/
.child-detail-wrapper {
  .child-detail-breadcrumb {
    padding-bottom: 36px;
    align-items: center;
    display: flex;
    text-align: center;

    .routeBack-abs {
      top: 0px;
    }

    .left-arrrow {
      font-size: var(--font-18);
      line-height: 22px;
      font-weight: 500;
      color: var(--primaryGrayColor);

      span {
        img {
          margin-top: -3px;
        }
      }
    }

    .center-avtar {
      font-size: var(--font-20);
      line-height: 21px;
      align-items: center;
      color: var(--primaryColor);
      font-weight: 700;
      display: flex;

      &.dropdown {
        .dropdown-toggle {
          display: flex;
          align-items: center;

          .dropdown-angle {
            display: flex;

            img {
              transition: all 0.5s;
              transform: rotate(90deg);
            }
          }

          &::after {
            display: none;
          }
        }

        &.show {
          .dropdown-toggle {
            .dropdown-angle {
              img {
                transform: rotate(270deg);
              }
            }
          }
        }
      }

      ul {
        background-color: #f4f5f5;

        li {
          padding: 5px 10px;
          font-weight: 500;
          font-size: var(--font-14);
        }
      }

      .editUserAvatar {
        .userIconDisplayBox {
          width: 21px;
          height: 21px;
          border-radius: 24px;
          background-color: #f4f5f5;
          margin: 0px 8px 0px auto;

          @media (max-width:575px) {
            width: 32px;
            height: 32px;
          }
        }
      }

      .child-bread-avtar {
        width: 60px;
        height: 60px;
        border-radius: 40px;
        background: #f4f5f5;
        line-height: 55px;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;

        img {
          margin-right: 10px;
          padding: 5px;
        }
      }

      @media (max-width:575px) {
        font-size: 20px !important;
        font-weight: 600;
        color: var(--primaryColor);
      }
    }

    .rightBtns {
      position: absolute;
      right: 0;
      top: 0;

      a.toChildSetting {
        img {
          display: none;
          height: 25px;
        }
      }

      @media (max-width:575px) {
        right: 15px;
        top: 19px;
      }

      @media (max-width:480px) {
        a.toChildSetting {
          button {
            display: none;
          }

          img {
            display: initial;
          }
        }
      }
    }

    @media (max-width:575px) {
      position: sticky;
      top: 0;
      background: #fff;
      padding-top: 11px;
      margin-left: -15px;
      padding-left: 15px;
      margin-right: -15px;
      padding-right: 15px;
      z-index: 9;
      padding-bottom: 10px !important;

      .routeBack-abs {
        left: 15px;
        top: 20px;
      }
    }
  }

  .digital-wallet-childDtl {
    padding: 24px;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
    border-radius: var(--borderRadiusM);

    h3,
    .h3 {
      font-size: var(--font-24);
      line-height: 36px;
      font-weight: 600;
      color: var(--primaryGrayColor);
      margin-bottom: 16px;
    }

    .wallet-amount {
      text-align: center;
      margin-bottom: 30px;

      .total-amount {
        font-size: var(--font-40);
        line-height: 56px;
        color: var(--primaryGrayColor);
        font-weight: 100;
      }

      .allowance-schedule {
        font-size: var(--font-12);
        line-height: 16px;
        color: var(--tertiaryGrayColor);
        cursor: pointer;
        letter-spacing: 0.02em;
      }
    }

    .allowance-top {
      text-align: center;
      margin-bottom: 20px;

      span {
        line-height: 36px;
        cursor: pointer;
        color: var(--tertiaryGrayColor);
        font-size: var(--font-16);

        &.allowanceAmtField {
          position: relative;
          padding-right: 22px;
          padding-left: 10px;

          &::after {
            content: '';
            position: absolute;
            right: 6px;
            width: 5px;
            height: 5px;
            background: var(--tertiaryGrayColor);
            border-radius: 3px;
            top: calc(50% - 3px);
          }

          @media (max-width: 480px) {
            padding-right: 18px;
          }
        }

        &.allowanceFrequency {
          position: relative;
          padding-right: 24px;

          &::after {
            content: '';
            position: absolute;
            right: 9px;
            width: 5px;
            height: 5px;
            background: var(--tertiaryGrayColor);
            border-radius: 3px;
            top: calc(50% - 3px);

            @media (max-width:575px) {
              display: none;
            }
          }

          @media (max-width:575px) {
            padding-right: 0;
          }
        }

        &.allowance-label {
          color: var(--primaryColor);
          font-weight: 600;
        }

        &.allowanceWeekMonthDays {
          @media (max-width:575px) {
            display: block;
            padding-top: 3px;
          }
        }

        @media (max-width: 991px) {
          font-size: var(--font-14);
        }

        @media (max-width: 768px) {
          font-size: var(--font-15);
          line-height: 19px;
        }

        @media (max-width: 480px) {
          font-size: var(--font-15);
          width: 100%;
        }
      }
    }

    .wallet-strip-container {
      display: flex;
      column-gap: 20px;
      row-gap: 15px;
      flex-wrap: wrap;

      .wallet-saving-stripe {
        width: calc(50% - 10px);

        button {
          border: none;
          background: transparent;
          width: 100%;
          padding: 0;

          .wallet-saving-stripe-single {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: 21px 16px;
            background: #fafafa;
            border-radius: var(--borderRadiusM);

            span.label {
              vertical-align: middle;
              color: var(--primaryColor);
              font-size: var(--font-14);
              line-height: 22px;
              font-weight: 700;
              white-space: nowrap;

              @media (max-width:575px) {
                font-size: var(--font-15);
              }
            }

            .amount {
              color: var(--primaryColor);
              font-size: var(--font-14);
              line-height: 22px;
              font-weight: 700;
              width: 100%;
              text-align: right;
              margin-top: 1px;

              @media (max-width:575px) {
                font-size: var(--font-15);
              }
            }

            .right-angle {
              margin-left: 15px;

              img {
                margin-right: 0;
              }

              @media (max-width:575px) {
                display: initial;
              }
            }

            img {
              margin-right: 10px;
            }

            @media (max-width:767px) {
              box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.27);
              padding: 16px 16px;
              border-radius: var(--borderRadiusS);
              width: 100%;
              background-color: #fff;
            }

          }

          @media (max-width:575px) {
            padding: 0;
          }
        }

        @media (max-width:575px) {
          width: 100%;
        }
      }

      @media (max-width:575px) {
        row-gap: 20px;
      }
    }


    .addAllowanceBtn {
      padding: 34px 0px 0px;
      font-size: var(--font-14);
      line-height: 22px;
      text-align: center;
      cursor: pointer;
      color: #000000;

      p {
        margin-bottom: 0px;
      }
    }

    @media (max-width:767px) {
      padding: 24px 0px 0px !important;
      box-shadow: none;

      h3,
      .h3 {
        display: none;
      }
    }
  }

  .displayAllowance {
    padding: 60px 0px 30px;
    text-align: center;
    position: relative;

    .chartFrame {
      position: absolute;

      select {
        width: 150px;
        min-width: 150px;
        background-color: #f4f5f5;
        border: none;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);
        border-radius: var(--borderRadiusM);

        &:last-child {
          margin-right: 0px;
        }

        &:focus {
          box-shadow: none;
        }

        @media (max-width: 480px) {
          width: auto;
          min-width: 120px;
          font-size: var(--font-14);
          height: 32px;
        }
      }

      // @media (max-width: 991px) {
      //   margin-right: 20px;
      //   position: relative;
      // }

      @media (max-width:575px) {
        display: none;
      }

    }

    span {
      line-height: 36px;
      cursor: pointer;
      color: var(--tertiaryGrayColor);
      font-size: var(--font-16);

      &.allowanceAmtField {
        position: relative;
        padding-right: 22px;
        padding-left: 10px;

        &::after {
          content: '';
          position: absolute;
          right: 6px;
          width: 5px;
          height: 5px;
          background: var(--tertiaryGrayColor);
          border-radius: 3px;
          top: calc(50% - 3px);
        }

        @media (max-width: 480px) {
          padding-right: 18px;
        }
      }

      &.allowanceFrequency {
        position: relative;
        padding-right: 24px;

        &::after {
          content: '';
          position: absolute;
          right: 9px;
          width: 5px;
          height: 5px;
          background: var(--tertiaryGrayColor);
          border-radius: 3px;
          top: calc(50% - 3px);

          @media (max-width:575px) {
            display: none;
          }
        }

        @media (max-width:575px) {
          padding-right: 0;
        }
      }

      &.allowance-label {
        color: var(--primaryColor);
        font-weight: 600;
      }

      &.allowanceWeekMonthDays {
        @media (max-width:575px) {
          display: block;
          padding-top: 3px;
        }
      }

      @media (max-width: 991px) {
        font-size: var(--font-14);
      }

      @media (max-width: 768px) {
        font-size: var(--font-15);
        line-height: 19px;
      }

      @media (max-width: 480px) {
        font-size: var(--font-15);
        width: 100%;
      }
    }

    p {
      font-size: var(--font-16);
      line-height: 24px;
      font-weight: 600;
      cursor: pointer;

      span {
        font-weight: 300;
        color: var(--tertiaryGrayColor);
        font-size: var(--font-14);
      }
    }

    // @media (max-width: 991px) {
    //   display: flex;
    //   align-items: center;
    // }

    @media (max-width: 575px) {
      flex-wrap: wrap;
      justify-content: center;
      padding: 20px 0px 30px !important;
    }
  }

  .displayChart {
    margin-bottom: 50px;

    .chartGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    .pieChart {
      width: 279px;
      height: 279px;
      position: relative;

      .chartCenter {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        p {
          text-align: center;
          color: var(--secondaryGrayColor);
          font-size: var(--font-20);
          line-height: 26px;
          margin-bottom: 3px;

          @media (max-width:575px) {
            font-family: 'GothamXLight';
          }
        }

        h3,
        .h3 {
          font-size: var(--font-22);
          font-weight: 700;
          margin-bottom: 0;
        }

        h4.selected-timeframe {
          // display: none;
          font-size: var(--font-13);
          margin-top: 4px;
          margin-bottom: 0;
          color: var(--tertiaryGrayColor);

          @media (max-width:575px) {
            display: inline-block;
          }
        }
      }

      @media (max-width:480px) {
        width: 279px !important;
        height: 279px !important;
      }
    }

    .mobile-chart-frame-select {
      display: none;

      .chartFrame {

        select {
          width: 150px;
          min-width: 150px;
          background-color: #f4f5f5;
          border: none;
          font-size: var(--font-16);
          line-height: var(--font-19);
          font-weight: 600;
          height: var(--font-36);
          color: var(--primaryGrayColor);

          &:last-child {
            margin-right: 0px;
          }

          &:focus {
            box-shadow: none;
          }

          @media (max-width:575px) {
            font-weight: 600;
            border-radius: var(--borderRadiusM);
            height: 42px;
          }
        }
      }

      @media (max-width:575px) {
        display: block;
        width: 100%;
        margin-top: 40px;
        text-align: center;
      }
    }

    .chartLegends {
      width: 100%;
      text-align: center;
      margin-top: 40px;

      ul {
        margin: 0px;
        padding: 0px;

        li {
          list-style: none;
          display: inline-block;
          text-align: center;
          min-width: 80px;
          padding-right: 15px;

          .legImg {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 480px) {
              width: 48px;
              height: 48px;
              border-radius: 48px;
              line-height: 45px;
            }
          }

          img {
            width: 22px;
          }

          p {
            color: var(--primaryColor);
            margin-top: 10px;
            font-size: var(--font-14);
            font-weight: 600;

            @media (max-width: 480px) {
              margin-top: 3px;
              font-size: var(--font-12);
              margin-bottom: 0px;
            }
          }

          &:first-child {
            .legImg {
              background-color: #f27405;
            }
          }

          &:nth-child(2) {
            .legImg {
              background-color: var(--primaryColor);
            }
          }

          &:nth-child(3) {
            .legImg {
              background-color: #f24405;
            }
          }

          &:nth-child(4) {
            .legImg {
              background-color: var(--tertiaryColor);
            }
          }

          &:nth-child(5) {
            .legImg {
              background-color: var(--secondaryGrayColor);
            }
          }

          &:nth-child(6) {
            .legImg {
              background-color: var(--gray5);
            }
          }

          &:nth-child(7) {
            .legImg {
              background-color: #F47C52;
            }
          }

          @media (max-width: 480px) {
            min-width: 60px;
            margin-bottom: 20px;
            width: 33%;

            img {
              width: 24px;
            }
          }
        }
      }

      @media (max-width:575px) {
        margin-top: 30px;
      }
    }

    @media (max-width:575px) {
      margin-bottom: 20px;
    }
  }

  .financial-targets {
    margin-bottom: 42px;

    h3,
    .h3 {
      font-size: var(--font-20);
      line-height: 22px;
      color: var(--primaryColor);
      font-weight: 600;

      @media (max-width:575px) {
        font-size: var(--font-16);
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 23px;
      }
    }

    .financial-strip-wrapper {
      display: flex;
      column-gap: 10px;
      margin-top: 30px;

      .financial-stripe {
        margin-bottom: 16px;
        width: 100%;

        label {
          font-weight: 700;
          font-size: var(--font-12);
          line-height: 16px;
          letter-spacing: 0.01em;
          color: var(--primaryColor);
          margin-bottom: 4px;

          @media (max-width:575px) {
            font-size: var(--font-12);
          }
        }

        .bar-group {
          position: relative;
          width: calc(100% - 30px);

          .baseBar {
            background: #F4F5F5;
            border-radius: 4px;
            width: 100%;
            height: 8px;
          }

          .dynamicBar {
            width: 20%;
            height: 8px;
            border-radius: 4px;
            position: absolute;
            top: 0;
            max-width: 100%;
            transition: all 1s;
          }

          .growth-indicator {
            position: absolute;
            right: -30px;
            top: 0;
            height: 8px;
            width: 20px;
            display: flex;
            align-items: center;

            img {
              vertical-align: top;
            }
          }
        }

        &:nth-child(2) {
          .bar-group {
            .dynamicBar {
              background: #F27405;
            }
          }
        }

        &:nth-child(4) {
          margin-bottom: 0px;

          .bar-group {
            .dynamicBar {
              background: var(--primaryColor);
            }
          }
        }

        &:nth-child(3) {

          .bar-group {
            .dynamicBar {
              background: #F24405;
            }
          }
        }

      }

      @media (max-width:767px) {
        flex-wrap: wrap;
        margin-top: 15px;
      }
    }
  }

  .chores-blank-wrapper {
    padding: 25px 20px 70px 20px;
    box-shadow: 0 1px 2px #00000026, 0 4px 15px #0000001a;
    border-radius: var(--borderRadiusL);
    margin-bottom: 50px;

    h3,
    .h3 {
      font-size: var(--font-20);
      line-height: 22px;
      color: var(--primaryColor);
      font-weight: 600;

      .right-angle {
        display: none;

        @media (max-width:575px) {
          display: inline-block;
          margin-left: auto;

          img {
            width: 8px;
          }
        }
      }

      @media (max-width: 575px) {
        font-size: 16px !important;
        font-weight: 700;
        margin-bottom: 5px;
        line-height: 23px;
        display: flex;
        align-items: center;
      }
    }

    .add-chore {
      text-align: center;
      margin-top: 30px;

      .chore-avtar {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        background: #f4f6f5;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px auto 10px auto;

        @media (max-width:575px) {
          width: 70px;
          height: 70px;

          img {
            width: 30px;
          }
        }
      }

      .description {
        text-align: center;
        font-size: var(--font-14);
        line-height: 22px;
        margin-bottom: 5px;
        color: var(--secondaryGrayColor);
        font-weight: 600;
      }

      @media (max-width:575px) {
        margin-top: 0px;
      }
    }

    .noChild-addNewOne {
      width: 238px;
      margin: 38px auto;
      text-align: center;

      img {
        width: 100px;
        margin-bottom: 10px;

        @media (max-width:575px) {
          width: 70px;
        }
      }

      // .thumbIcon {
      //   margin: 0px auto 34px;
      // }

      .noChild-title {
        text-align: center;
        font-size: var(--font-14);
        line-height: 22px;
        margin-bottom: 5px;
        color: var(--secondaryGrayColor);
        font-weight: 600;
      }

      .noChild-subtitle {
        font-size: var(--font-14);
        line-height: 22px;
        color: var(--tertiaryGrayColor);
        text-align: center;
      }

      @media (max-width:575px) {
        margin: 0px auto;
      }
    }

    @media (max-width:575px) {
      padding: 15px;
    }
  }

  .chores-wrapper {
    margin-bottom: 50px;
    padding: 24px;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
    border-radius: var(--borderRadiusM);

    .chores-header-container {
      margin-bottom: 32px;

      h3,
      .h3 {
        font-size: var(--font-20);
        line-height: 22px;
        font-weight: 600;
        color: var(--primaryColor);
        margin-bottom: 16px;

        .right-angle {
          display: none;

          @media (max-width:575px) {
            display: inline-block;
            margin-left: auto;

            img {
              width: 8px;
            }
          }
        }

        @media (max-width: 575px) {
          font-size: 16px !important;
          font-weight: 700;
          margin-bottom: 5px;
          line-height: 23px;
          display: flex;
          align-items: center;
        }
      }

      select {
        min-width: 100px;
        width: auto;
        background-color: #f4f5f5;
        border: none;
        margin-left: 24px;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);
        border-radius: var(--borderRadiusM);

        &:last-child {
          margin-right: 0px;
        }

        &:focus {
          box-shadow: none;
        }

        @media (max-width:575px) {
          display: none;
        }
      }

      a.addChoreBtn {
        font-size: var(--font-18);
        line-height: 40px;
        display: inline-block;
      }

      @media (max-width:575px) {
        margin-bottom: 0;
      }
    }

    .chores-today-records {
      .chore-row {
        margin-bottom: 40px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        cursor: pointer;

        .icon-box {
          width: 35px;
          min-width: 35px;
          margin-right: 16px;

          .s-box {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            margin-right: 10px;
            border-radius: 6px;

            &.completed {
              background-color: var(--primaryColor);

              img {
                width: 18px;
              }
            }

            &.bordered {
              border: 2px solid var(--primaryColor);
            }

            &.fill {
              border: 2px solid var(--primaryColor);
            }

            &.awaiting-approval {
              background-color: $yellow;
              border-color: $yellow;

              img {
                width: 18px;
              }
            }

            &.incomplete {
              border: 2px solid $yellow;
            }

            &.stolen {
              background-color: red;
              color: white;
            }

            &.f2f-notavailable {
              background-color: var(--tertiaryGrayColor);
              border: 2px solid #a8aeaf;

              img {
                width: 18px;
              }
            }
          }
        }

        .content-box {

          h3,
          .h3 {
            font-weight: 700;
            color: $black-1;
            line-height: 22px;
            min-height: 20px;
            display: flex;
            justify-content: space-between;
            font-size: var(--font-16);
            margin-bottom: 3px;
          }

          p {
            color: var(--tertiaryGrayColor);
            font-size: var(--font-12);
            line-height: 15px;
            margin-bottom: 0px;
            min-height: 15px;
            max-height: 15px;

            .status-text {
              font-weight: 600;
              display: inline-block;
              padding-left: 17px;
              position: relative;

              &::before {
                content: '';
                position: absolute;
                left: 3px;
                width: 4px;
                height: 4px;
                background: var(--tertiaryGrayColor);
                border-radius: 3px;
                top: 6px;
              }
            }
          }

          .days-listing {
            margin-top: 5px;
            display: flex;
            align-items: center;

            li {
              list-style: none;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              min-width: 24px;
              height: 24px;
              min-height: 24px;
              background: #f4f5f5;
              text-align: center;
              line-height: 24px;
              cursor: pointer;
              color: #000000;
              border-radius: var(--borderRadiusS);
              font-size: var(--font-12);
              margin-right: 5px;

              &.fill {
                background-color: var(--primaryWardColor);
                color: #fff;
              }

              &.bordered {
                border: 2px solid var(--primaryWardColor);
                color: var(--primaryWardColor);
              }

              &.pending {
                background-color: $yellow;
              }

              &.stolen {
                background-color: red;
                color: white;
              }

            }
          }

        }

        .amount-box {
          font-size: var(--font-16);
          line-height: 24px;
          color: var(--tertiaryGrayColor);
          font-weight: normal;
          margin-left: auto;
          padding-left: 30px;
        }
      }
    }

    .chores-single-data {
      margin-bottom: 24px;

      table {
        margin: 0px;

        tr {
          td {
            border: none !important;
            padding: 0px !important;
            font-size: var(--font-13);
            vertical-align: middle;

            input[type="checkbox"] {
              width: 25px;
              height: 25px;
              outline: 1px auto #f27405;
            }

            h5,
            .h5 {
              font-size: var(--font-16);
              margin-bottom: 0px;
              font-weight: 700;
              line-height: 22px;

              @media (max-width: 480px) {
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                font-size: var(--font-15);
                font-weight: 500;
              }
            }

            p {
              font-size: var(--font-12);
              line-height: 15px;
              color: var(--gray5);
              margin-bottom: 0px;
              position: relative;

              span:nth-child(1) {
                position: relative;
                padding-right: 15px;

                &::after {
                  content: "*";
                  position: absolute;
                  right: 4px;
                  border-radius: 10px;
                  top: 4px;

                  @media (max-width: 480px) {
                    top: 2px;
                  }
                }
              }
            }

            .week-days {
              display: flex;
              margin-top: 5px;

              li {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                min-width: 24px;
                height: 24px;
                min-height: 24px;
                background: $grey-2 ;
                text-align: center;
                line-height: 24px;
                cursor: pointer;
                color: #000000;
                border-radius: var(--borderRadiusS);
                font-size: var(--font-12);
                margin-right: 5px;

                &.rejected {
                  border: 2px solid var(--errorColor);
                }

                &.stolen {
                  border: 2px solid var(--errorColor);
                  background-color: var(--errorColor);
                  color: #fff;
                }

                &.outline {
                  border: 2px solid var(--primaryColor);
                }

                &.pending {
                  border: 2px solid $yellow;
                }

                &.fill {
                  background-color: var(--primaryColor);
                  color: #fff;
                }
              }
            }

            &:nth-child(2) {
              padding-left: 16px !important;
            }

            &:nth-child(3) {
              color: var(--tertiaryGrayColor);
              font-size: var(--font-16);
              line-height: 24px;
            }

            &:last-child {
              img {
                vertical-align: baseline;
                width: 21px;
              }
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    @media (max-width: 480px) {
      margin-bottom: 20px;
    }
  }

  .goals-blank-wrapper {
    padding: 25px 20px 70px 20px;
    box-shadow: 0 1px 2px #00000026, 0 4px 15px #0000001a;
    border-radius: var(--borderRadiusL);
    margin-bottom: 50px;

    h3,
    .h3 {
      font-size: var(--font-20);
      line-height: 22px;
      color: var(--primaryColor);
      font-weight: 600;

      .right-angle {
        display: none;

        @media (max-width:575px) {
          display: inline-block;
          margin-left: auto;

          img {
            width: 8px;
          }
        }
      }

      @media (max-width: 575px) {
        font-size: 16px !important;
        font-weight: 700;
        margin-bottom: 5px;
        line-height: 23px;
        display: flex;
        align-items: center;
      }
    }

    .add-goal {
      text-align: center;
      margin-top: 30px;

      .goal-avtar {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        background: #f4f6f5;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px auto 10px auto;

        @media (max-width:575px) {
          width: 70px;
          height: 70px;
        }
      }

      .description {
        text-align: center;
        font-size: var(--font-14);
        line-height: 22px;
        margin-bottom: 5px;
        color: var(--secondaryGrayColor);
        font-weight: 600;
      }

      @media (max-width:575px) {
        margin-top: 0px;
      }
    }
  }

  .goals-wrapper {
    padding: 24px;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
    border-radius: var(--borderRadiusM);

    h3,
    .h3 {
      font-size: var(--font-20);
      line-height: 22px;
      font-weight: 600;
      color: var(--primaryColor);
      margin-bottom: 24px;

      .right-angle {
        display: none;

        @media (max-width:575px) {
          display: inline-block;
          margin-left: auto;

          img {
            width: 8px;
          }
        }
      }

      @media (max-width: 575px) {
        font-size: 16px !important;
        font-weight: 700;
        margin-bottom: 5px;
        line-height: 23px;
        display: flex;
        align-items: center;
      }
    }

    .goals-progress-container {
      .goals-progress-single {
        margin-bottom: 24px;
        display: flex;

        .goal-avatar {
          min-width: 58px;
          max-width: 58px;
          background: $grey-2;
          min-height: 58px;
          max-height: 58px;
          background-position: center;
          background-size: cover;
          border-radius: 32px;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 23px;

            @media (max-width:575px) {
              width: 20px;
            }
          }

          @media (max-width: 575px) {
            background-size: 25px;
          }

          @media (max-width: 480px) {
            background-size: 20px;
          }
        }

        .goal-progress-bar {
          padding-left: 16px;
          width: 100%;

          h5,
          .h5 {
            font-size: var(--font-14);
            line-height: 22px;
            color: #000;
            margin-bottom: 6px;
            font-weight: 600;
            min-height: 22px;

            span {
              font-size: var(--font-16);
              line-height: 24px;
              font-weight: normal;
              color: #000;
            }

            @media (max-width:480px) {
              min-height: 17px;
            }
          }

          .progress {
            height: 8px;
            background-color: #f6f7f7;
            margin-bottom: 5px;

            .progress-bar {
              background: var(--primaryColor);
              height: 8px;
              border-radius: 4px 0px 0px 4px;
            }
          }

          .progress-value {
            font-size: var(--font-14);
            font-weight: 500;
            line-height: 16px;
            color: #000;
            max-width: 100%;
          }
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.myCal {
  margin-bottom: 50px;

  .rbc-calendar {
    .rbc-toolbar {
      position: relative;

      .rbc-toolbar-label {
        font-size: var(--font-24);
        line-height: 36px;
        color: #000000;
        font-weight: 500;
      }

      .rbc-btn-group:nth-child(1) {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;

        button:first-child {
          display: none;
        }

        button:nth-child(2) {
          float: left;
        }

        button:nth-child(3) {
          float: right;
        }

        button {
          padding-left: 0;
          border: none;

          &:hover {
            background-color: transparent;
          }
        }
      }

      .rbc-btn-group:nth-child(3) {
        display: none;
      }
    }

    .rbc-month-view {
      border: none !important;

      .rbc-month-header {
        .rbc-header {
          font-size: var(--font-24);
          text-align: center;
          line-height: 36px;
          color: #222730;
          font-weight: 500;
          border: none;
          padding: 14px;
        }
      }

      .rbc-month-row+.rbc-month-row {
        border-top: 7px solid #fff;
      }

      .rbc-month-row {
        overflow: initial;

        .rbc-row-bg {
          .rbc-off-range-bg {
            background-color: #fcfcfc !important;
          }

          .rbc-day-bg {
            background-color: #f4f5f5;
          }

          .rbc-day-bg+.rbc-day-bg {
            border-left: 7px solid #fff;
          }
        }

        .rbc-row-content {
          min-height: 107px;

          .rbc-row {
            .rbc-row-segment {
              padding: 0 6px 1px;

              .rbc-event {
                background-color: #f24405;
                font-size: var(--font-11);
                line-height: 15px;
                letter-spacing: -0.02em;
                font-weight: 500;

                &:focus {
                  outline: none;
                }

                &.rbc-selected {
                  background-color: #ce3a05;
                }
              }
            }

            .rbc-date-cell {
              text-align: left;
              font-size: var(--font-18);
              line-height: 32px;
              padding: 4px 12px;

              a {
                color: var(--primaryGrayColor);
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.rbc-overlay {
  .rbc-event {
    background-color: #f24405;
    font-size: var(--font-11);
    line-height: 15px;
    letter-spacing: -0.02em;
    font-weight: 500;

    &:focus {
      outline: none;
    }

    &.rbc-selected {
      background-color: #ce3a05;
    }
  }
}

/*---RECEIPT DETAIL UI DESIGN---*/
.receiptDetail {
  padding: 50px 0px;
  background: #e4e5eb;

  .headerSec {
    text-align: center;
    padding: 40px 0px;
    background-color: #fff;

    h2,
    .h2 {
      color: var(--primaryColor);
      font-size: var(--font-28);
      font-weight: 700;
      margin-bottom: 15px;
    }

    h3,
    .h3 {
      color: var(--secondaryGrayColor);
      margin: 0px;

      @media (max-width:575px) {
        font-size: var(--font-23);
        font-weight: 600;
      }
    }
  }

  .bodySec {
    padding: 40px 10px;
    background: #f4f4f3;

    h3,
    .h3 {
      font-size: var(--font-15);
      line-height: 22px;

      @media (max-width:575px) {
        font-weight: 600;
        margin-bottom: var(--font-13);
      }
    }

    .transDtl {
      p {
        font-size: var(--font-13);
        line-height: 16px;
        margin-bottom: 5px;
        font-weight: 400;
      }
    }

    @media (max-width:575px) {
      padding: 40px 30px;
    }
  }

  .footer {
    padding: 0px 40px;

    p {
      font-size: var(--font-13);
      line-height: 22px;
      font-weight: 400;

      @media (max-width:575px) {
        font-size: var(--font-12);
        line-height: 17px;
      }

    }

    @media (max-width:575px) {
      padding: 0;
    }
  }

  @media (max-width:575px) {
    padding: 10px 0px 0px !important;
    margin-left: -15px;
    margin-right: -15px;
  }
}

/*-----ADD CHILD CSS----*/
.myProfileWrapper {
  button {
    margin-top: 100px;
  }
}

.connectQRWrapper {
  p {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: var(--font-16);
  }

  .displayPin {
    color: var(--primaryColor);
    margin-top: 20px;
    font-size: var(--font-14);
    font-weight: 600;
  }

  .displayQR {
    color: var(--primaryColor);
    margin-top: 20px;
    font-size: var(--font-14);
    font-weight: 600;
  }

  button {
    margin-top: 60px;
  }
}

.pinsGroup {
  display: flex;
  justify-content: center;

  input {
    width: 40px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 40px;
  }
}

.addNewRetailer {
  padding: 40px 30px;

  h1,
  .h1 {
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 30px;

    &.states-list {
      select {
        border-radius: 0;
        font-size: var(--font-16);
        line-height: 24px;
        background-color: transparent;
        padding: 6px 0px;
        height: 40px;
        border: none;
        border-bottom: 1px solid var(--primaryGrayColor);
        color: var(--primaryGrayColor);

        &:disabled {
          opacity: 0.7;
        }
      }
    }
  }
}

/*-- APPROVE CHORE REQUEST--*/
.approveChoreModal {
  padding: 40px 30px;

  h1,
  .h1 {
    margin-bottom: 30px;
  }

  .userAvatar {
    text-align: center;
    margin-bottom: 30px;

    img {
      width: 70px;
      margin: auto;
    }
  }

  .requestMsg {
    text-align: center;
    // font-size: var(--font-13);
    margin-bottom: 50px;

    span {
      font-weight: 700;
      color: #000;
    }
  }

  .retailerText {
    .link-text {
      font-size: var(--font-14);
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }

    a {
      text-decoration: underline !important;
      font-size: var(--font-12);
      color: #212529 !important;
      text-align: center;
      display: block;
      cursor: pointer;

      &:hover {
        text-decoration: underline !important;
      }
    }

    .btn-gradient {
      @media (max-width:575px) {
        background-color: transparent;
        padding: 0 !important;
        color: var(--secondaryGrayColor) !important;
        text-decoration: underline;
      }
    }
  }
}

/*------ADD CHORE CSS START---*/
.add-chore-wrapper {
  max-width: 700px;
  margin: auto;

  h1,
  .h1 {
    font-size: var(--font-35);
    font-weight: 300;
    text-align: center;
  }

  .enterChore {
    width: 300px;
    margin: 30px auto 50px auto;
  }

  .choresSuggestionsBlock {
    margin-bottom: 30px;

    h3,
    .h3 {
      font-size: var(--font-15);
      color: var(--primaryColor);
      margin-bottom: 15px;
    }

    .choresSuggestionsBlock-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .choresSuggestionsBlock-single {
        cursor: pointer;

        .icn-box {
          .icn {
            background-color: #f27405;
            display: inline-block;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 30px;
            border-radius: 20px;
            margin-right: 10px;
            opacity: 0.5;

            img {}
          }

          span {
            font-size: var(--font-13);
            font-weight: 600;
          }
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.add-chore-wrapper1 {
  padding-bottom: 50px;
  // margin-top: -62px;


  h1,
  .h1 {
    font-size: var(--font-20);
    font-weight: 100;
    padding-bottom: 25px;
    color: var(--primaryColor);
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
    position: relative;
    line-height: 21px;

    .routeBack-abs {
      top: 0px;

      img {
        margin-top: 0px;
      }

      @media (max-width:575px) {
        top: 22px;
      }
    }

    @media (max-width:768px) {
      font-size: 20px !important;
    }

    @media (max-width:575px) {
      font-size: 18px !important;
      position: sticky;
      top: 0px;
      background: #fff;
      z-index: 9;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 0 !important;
      line-height: 30px;
    }
  }

  &.level-2 {
    .routeBack-abs {
      top: 0px;

      @media (max-width:575px) {
        position: fixed;
        top: 22px;
        z-index: 99;
        left: 15px;
        // width: calc(100% - 30px);

        img {
          margin-top: 0;
        }

        .choreMis {
          img {
            vertical-align: top;
          }
        }
      }
    }

    .form-data {
      @media (max-width:575px) {
        margin-bottom: 80px;
      }
    }

    .footerBtns {
      @media (max-width:575px) {
        position: fixed;
        padding: 10px 15px 30px;
        bottom: 0;
        background-color: #fff;
        z-index: 9;
        width: 100%;
        left: 0px;

        .form-data {
          margin-top: 0 !important;
          margin-bottom: 0;
        }
      }
    }

    @media (max-width:575px) {
      .title {
        position: initial;
      }
    }
  }

  &.edit-chore-screen {

    .form-data {
      margin-top: 27px;

      @media (max-width:767px) {
        margin-top: 22px;
      }
    }

    @media (max-width:575px) {
      padding-bottom: 50px;

      .child-list {
        margin-bottom: 0px;

        h3,
        .h3 {
          display: none;
        }

        .child-listting-edit {
          li {
            .child-content {
              width: 100%;
              height: auto;
              display: flex;
              column-gap: 15px;
              align-items: center;
              margin-bottom: 8px;
            }
          }
        }
      }

      .description {
        margin: 10px auto 16px auto;

        label {
          font-size: var(--font-16);
        }

        input {
          font-size: var(--font-13);
        }
      }

      .rewards {
        .form-group {
          display: flex;
          justify-content: space-between;

          label {
            font-size: var(--font-16);
            margin-bottom: 0;
            font-weight: 600;
          }

          .dollar {
            font-size: var(--font-15);
            top: 1px;
          }

          input {
            font-size: 15px !important;
            border: none;
            padding: 0;
            height: auto;
            text-align: right !important;

            &::placeholder {
              font-size: var(--font-15);
            }
          }
        }

      }

      .requireApproval {
        margin-bottom: 25px !important;

        h3,
        .h3 {
          font-size: var(--font-16);
          font-weight: 600;
        }
      }

      .firstToFinish {

        h3,
        .h3 {
          font-size: var(--font-16);
          font-weight: 600;
        }
      }

      .edit-chore-fixed-buttons {
        position: fixed;
        padding: 10px 15px 30px;
        bottom: 0;
        background-color: #fff;
        z-index: 9;
        width: 100%;
        left: 0px;

        .text-center {
          height: 17px;
        }
      }
    }

    .firstToFinish,
    .requireApproval {

      h3,
      .h3 {
        img {
          display: none;
        }
      }
    }
  }

  .title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .routeBack-flat {
      margin-top: -2px;

      @media (max-width:575px) {
        margin-top: 4px;
      }
    }

    .chore-title {
      font-size: var(--font-20);
      line-height: 21px;
      color: var(--primaryColor);
      font-weight: 700;
      display: flex;
      align-items: center;

      img {
        margin-right: 15px;
      }

      img.choreAvatar {
        width: 21px;
        vertical-align: top;

      }

      .created-by-name {
        font-size: var(--font-12);
        color: var(--tertiaryGrayColor);
        text-align: center;
        font-weight: 500;
      }

      .dropdown-toggle {
        display: flex;
        align-items: center;

        .dropdown-angle {
          transform: rotate(90deg);
          transition: all .5s;

          img {
            margin-right: 0;
          }
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        background-color: #f4f5f5;
        max-height: 247px;
        overflow: auto;
        right: 0;
        left: auto !important;

        li {
          font-size: var(--font-14);
          padding: 5px 10px;
          font-weight: 500;
          white-space: nowrap;
        }
      }


      @media (max-width:575px) {
        font-size: 18px !important;
        line-height: 20px;
      }
    }

    span.choreMis {
      img {
        width: 21px;
        margin-right: 0;
      }

      @media (max-width:575px) {
        line-height: initial;
      }
    }

    @media (max-width:768px) {
      font-size: var(--font-20);
      font-weight: 500;

      .chore-title {
        font-size: var(--font-20);
        display: flex;
        align-items: center;

        img.choreAvatar {
          margin-right: 8px;
        }
      }
    }

    @media (max-width:575px) {
      padding-top: 11px;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 9;
      padding-bottom: 10px;
    }
  }

  .form-group {
    margin-bottom: 56px;
  }

  .title {
    width: 600px;
    margin: auto;

    label {
      font-size: var(--font-16);
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 16px;
      letter-spacing: -0.02em;
      color: var(--primaryColor);

      @media (max-width:575px) {
        margin-bottom: 0;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
    }

    @media (max-width:575px) {
      position: sticky;
      top: 60px;
      background: #fff;
      z-index: 9;
      padding-bottom: 30px;

      .form-group {
        margin-bottom: 0;

        input {
          padding: 0;
          height: 36px;
          font-size: var(--font-14);
        }
      }
    }
  }

  input[disabled] {
    background-color: transparent !important;
    // border-bottom: none;
    border-color: $grey-3;
    color: var(--tertiaryGrayColor);
  }

  .choreSuggestions {
    width: 600px;
    margin: auto;

    label {
      font-size: var(--font-14);
      line-height: 22px;
      font-weight: 600;
      margin-bottom: 24px;
      color: var(--primaryColor);

      @media (max-width:575px) {
        margin-bottom: 15px;
      }
    }

    .suggestionsList {
      display: flex;
      flex-wrap: wrap;

      .singleSuggestion {
        width: 50%;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 16px;

        .choreIcn {
          width: 32px;
          height: 32px;
          border-radius: 16px;
          margin-right: 16px;

          &.active {
            border: 2px solid var(--primaryColor);
          }

          @media (max-width:575px) {
            width: 48px;
            height: 48px;
          }
        }

        .choreName {
          font-size: var(--font-14);
          line-height: 22px;

          @media (max-width:575px) {
            font-size: var(--font-15);
          }
        }
      }
    }

    @media (max-width:1024px) {
      width: 100%;
    }
  }

  .description {
    margin: 60px auto 40px auto;
    font-size: var(--font-14);
    width: 600px;

    label {
      color: #4c5562;
      font-size: var(--font-16);
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 16px;
      letter-spacing: -0.02em;

      &.active {
        color: var(--primaryColor);
      }
    }

    select {
      font-size: var(--font-16);
      line-height: 24px;
      background-color: transparent;
      padding: 6px 0px;
      height: 40px;
      border: none;
      border-bottom: 1px solid var(--primaryGrayColor);
      color: var(--primaryGrayColor);
      border-radius: 0px;
    }

    @media (max-width: 1024px) {
      width: 100%;
    }

    @media (max-width:575px) {
      margin: 10px auto 30px auto !important;

      label {
        margin-bottom: 0;
        color: var(--primaryColor);
      }

      input {
        padding: 0;
        height: 36px;
        font-size: var(--font-14);

        &::placeholder {
          font-size: var(--font-14);
        }
      }
    }
  }

  .viewChildUpdation {
    .singleChildAuth {
      margin-bottom: 30px;

      label {
        font-size: var(--font-14);
        margin-bottom: 5px;
        color: var(--secondaryGrayColor);
        font-weight: 500;

        img {
          width: 50px;
          margin-right: 7px;
        }
      }

      .weeksName {
        margin-top: 5px;

        ul {
          padding: 0px;
          margin: 0px;
          display: flex;

          li {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 50px;
            position: relative;
            height: 50px;
            background: $grey-2;
            text-align: center;
            cursor: pointer;
            margin-right: 20px;
            color: var(--primaryColor);
            font-weight: 600;
            border-radius: var(--borderRadiusS);
            padding: 3px 0px;

            span.status-icon-wrap {
              width: 100%;

              img {
                display: block;
                margin: auto;
              }
            }

            &:hover {
              .customTooltip {
                display: initial;
              }
            }

            &.completionStatus-0 {
              border-color: var(--primaryColor);
            }

            &.accepted {
              background-color: var(--primaryColor);
              color: #fff;
            }

            &.stolen {
              background-color: #f24405;
              color: #fff;

              img {
                width: var(--font-13);
                margin-top: 2px;

                @media (max-width: 480px) {
                  width: 10px;
                  margin-top: 0px;
                }

                @media (max-width: 320px) {
                  width: 8px;
                }
              }
            }

            &.pending-approval {
              border-color: #f2c94c;

              img {
                width: var(--font-13);
                margin-top: 2px;
              }
            }

            &.rejected {
              background-color: var(--errorColor);
              color: #fff;
            }

            &.waiting {
              background-color: #f2c94c;
              color: #fff;

              img {
                width: var(--font-13);
                margin-top: 2px;
              }
            }

            &.pending {
              background-color: #f2c94c;
              color: #000;

              img {
                width: var(--font-13);
                margin-top: 2px;

                @media (max-width: 480px) {
                  width: 10px;
                  margin-top: 0px;
                }

                @media (max-width: 320px) {
                  width: 8px;
                }
              }
            }

            &:last-child {
              margin-right: 0px;
            }

            @media (max-width: 480px) {
              width: 40px;
              height: 40px;
              margin-right: 5px;
              font-size: var(--font-13);

              img {
                width: 10px !important;
              }
            }

            @media (max-width: 375px) {
              width: 35px;
              height: 35px;
              margin-right: 5px;
              font-size: var(--font-12);
            }

            @media (max-width: 320px) {
              width: 26px;
              height: 26px;
              font-size: var(--font-10);
              padding: 0px;

              img {
                width: 8px;
              }
            }
          }
        }
      }

      @media (max-width: 480px) {
        margin-bottom: 15px;
      }
    }
  }

  .child-list {
    margin-bottom: 31px;
    width: 600px;
    margin-left: auto;
    margin-right: auto;

    h3,
    .h3 {
      font-size: var(--font-16);
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 16px;
      letter-spacing: -0.02em;
      color: #4c5562;

      &.active {
        color: var(--primaryColor);
      }

      @media (max-width:575px) {
        margin-bottom: 10px;
      }
    }

    .child-listing {
      ul {
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-wrap: wrap;

        li {
          list-style: none;
          display: block;
          border-radius: 100%;
          border: 1px solid transparent;
          margin-right: 15px;

          .child-content {
            text-align: center;
            cursor: pointer;
            display: inline-block;
            max-width: 64px;

            .img-title {
              width: 54px;
              min-width: 54px;
              height: 54px;
              min-height: 54px;
              line-height: 45px;
              background: #f4f5f5;
              overflow: hidden;
              border-radius: 32px;

              &.active {
                border: 2px solid var(--primaryColor);
              }

              @media (max-width: 575px) {
                width: 42px;
                min-width: 42px;
                height: 42px;
                min-height: 42px;
                line-height: initial;
              }
            }

            p {
              font-size: var(--font-11);
              line-height: 16px;
              color: #000;
              margin-top: 3px;
              letter-spacing: 0.01em;
              text-align: center;
              margin-bottom: 0px;
              word-wrap: break-word;
            }
          }
        }

        &.child-listting-edit {
          // justify-content: space-between;
        }
      }
    }

    @media (max-width:1024px) {
      width: 100%;
    }

    @media (max-width:575px) {
      margin-bottom: 0;
    }
  }

  .rewards {
    margin-bottom: 31px;
    font-size: var(--font-14);
    width: 600px;
    margin-left: auto;
    margin-right: auto;

    label {
      font-size: var(--font-16);
      font-weight: 600;
      margin-bottom: 15px;
      color: #4c5562;
      line-height: 24px;
      letter-spacing: -0.02em;

      &.active {
        color: var(--primaryColor);
      }

      @media (max-width:575px) {
        color: var(--primaryColor);
      }
    }

    .dollarInput {
      position: relative;

      input {
        text-align: left !important;
        font-size: var(--font-24);

        @media (max-width:575px) {
          font-size: 22px !important;
          padding: 0;
        }
      }

      input::placeholder {
        color: var(--tertiaryGrayColor);
        font-size: var(--font-26);
        line-height: 24px;

        @media (max-width:575px) {
          font-size: 22px !important;
        }
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
    }

    // @media (max-width:575px) {
    //   .form-group {
    //     display: flex;
    //     justify-content: space-between;
    //   }
    // }
  }

  .schedule {
    margin-bottom: 31px;
    width: 600px;
    margin-left: auto;
    margin-right: auto;

    h3,
    .h3 {
      font-size: var(--font-16);
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 15px;
      color: var(--primaryColor);

      select {
        width: 150px;
        border: none;
        font-size: var(--font-13);
        border-bottom: 1px solid #333;
        border-radius: 0px;
        padding: 0px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .once {
      margin-top: 30px;

      .react-datepicker {
        width: 100%;
        border: none;

        .react-datepicker__navigation--previous {
          right: 50px;
          left: auto;
        }

        .react-datepicker__month-container {
          width: 100%;

          .react-datepicker__header {
            background-color: #fff;
            border: none;

            .react-datepicker__current-month {
              margin-bottom: 20px;
            }

            .react-datepicker__day-names {
              display: flex;
              justify-content: space-around;

              .react-datepicker__day-name {
                font-weight: 300;
                font-size: var(--font-12);
                color: var(--tertiaryGrayColor);
                letter-spacing: 0.1em;
              }
            }
          }

          .react-datepicker__month {
            background-color: #fff;
            margin: 0px;

            .react-datepicker__week {
              display: flex;
              justify-content: space-around;
              font-size: var(--font-16);

              .react-datepicker__day {
                margin-bottom: 10px;
                font-family: 'GothamBook';
              }

              .react-datepicker__day--today {
                font-weight: normal;
              }

              .react-datepicker__day--outside-month {
                background-color: #f7f7f7;
                opacity: 0.3;
              }

              .react-datepicker__day--keyboard-selected {
                background-color: transparent;
                color: #000;
              }

              .react-datepicker__day--selected {
                background-color: var(--primaryColor);
                border-radius: 100%;
                color: #fff;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .weeksName {
      margin-top: 30px;

      ul {
        padding: 0px;
        margin: 0px;
        display: flex;

        li {
          list-style: none;
          display: inline-block;
          width: 50px;
          min-width: 50px;
          height: 50px;
          min-height: 50px;
          background: #f4f5f5;
          text-align: center;
          line-height: 50px;
          cursor: pointer;
          margin-right: 20px;
          color: var(--primaryColor);
          font-weight: 600;
          border-radius: var(--borderRadiusS);

          &.active {
            background-color: var(--primaryColor);
            color: #fff;
          }

          &:last-child {
            margin-right: 0px;
          }

          @media (max-width: 480px) {
            width: 35px;
            min-width: 35px;
            height: 35px;
            min-height: 35px;
            line-height: 35px;
            font-size: var(--font-13);
            margin-right: 10px;
          }

          @media (max-width: 320px) {
            width: 30px;
            min-width: 30px;
            height: 30px;
            min-height: 30px;
            line-height: 30px;
            font-size: var(--font-13);
            margin-right: 5px;
          }
        }
      }
    }

    // .monthlySelection {
    //   margin-top: 30px;
    //   display: flex;
    //   text-align: right;
    //   align-items: center;

    //   span {
    //     font-weight: 500;
    //     font-size: var(--font-13);
    //     line-height: 30px;
    //     margin-right: 20px;

    //     &:first-child {
    //       display: none;
    //     }
    //   }

    //   select {
    //     width: 180px;
    //     background-color: #f4f5f5;
    //     border: none;
    //     margin-right: 10px;
    //     font-size: var(--font-16);
    //     line-height: var(--font-19);
    //     font-weight: 600;
    //     height: var(--font-36);
    //     color: var(--primaryGrayColor);

    //     &:last-child {
    //       margin-right: 0px;
    //     }

    //     &:focus {
    //       box-shadow: none;
    //     }

    //     @media (max-width: 575px) {
    //       background: transparent;
    //       border-bottom: 1px solid var(--primaryColor);
    //       border-radius: 0;
    //       font-size: var(--font-13);
    //       height: 32px;

    //       &:focus {
    //         outline: none;
    //       }
    //     }
    //   }
    // }

    .monthlySelection {
      display: flex;
      column-gap: 20px;

      label {
        font-weight: 700;
      }

      select {
        width: 100%;
        background-color: $grey-2;
        border: none;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);

        &:last-child {
          margin-right: 0px;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .recurrence-selection {
      margin-top: 45px;
      margin-bottom: 25px;

      .form-group {
        margin-bottom: 25px;
      }

      label {
        font-weight: 700;
      }

      select {
        width: 100%;
        background-color: $grey-2;
        border: none;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);

        &:focus {
          box-shadow: none;
        }
      }
    }

    .recurrence-dayofMonth {
      .form-group {
        margin-bottom: 0;
      }

      select {
        width: 100%;
        background-color: $grey-2;
        border: none;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);

        &:focus {
          box-shadow: none;
        }
      }
    }

    @media (max-width:768px) {
      width: 100%;
    }

    @media (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  .time {
    margin-bottom: 31px;
    width: 600px;
    margin-left: auto;
    margin-right: auto;

    h3,
    .h3 {
      font-size: var(--font-16);
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #4c5562;
      // &.inline-block{
      //   display: inline-block;
      //   margin-right:16px;
      // }

      .custom-switch {
        padding-left: 1.75rem;

        label {
          &::before {
            left: -1.75rem;
          }

          &::after {
            left: calc(-1.75rem + 2px);
          }
        }
      }

      &.active {
        color: var(--primaryColor);
      }

      @media (max-width:575px) {
        margin-bottom: 5px;
        font-weight: 600;
      }
    }

    ul {
      padding: 0px;
      margin-bottom: 0px;
      display: inline-block;

      li {
        display: inline-block;
        list-style: none;
        cursor: pointer;
        background-color: transparent;
        color: var(--tertiaryGrayColor);
        font-size: var(--font-16);
        line-height: 24px;
        border: none;
        padding: 8px 17px;
        margin-right: 5px;
        font-weight: 400;
        border-radius: var(--borderRadiusS);

        &.active {
          background: var(--primaryColor);
          color: #fdfcfb;
        }

        @media (max-width: 480px) {
          font-size: 14px !important;
          line-height: 16px;
          padding: 12px 19px !important;
        }

        @media (max-width: 375px) {
          font-size: var(--font-13) !important;
        }

        @media (max-width: 320px) {
          font-size: 12px !important;
          padding: 6px 7px !important;
        }
      }
    }

    p {
      font-size: var(--font-13);
      color: var(--tertiaryGrayColor);
      margin-top: 20px;
    }

    @media (max-width:1024px) {
      width: 100%;
    }

    @media (max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  &.viewMode {
    .form-group {
      margin-bottom: 30px;
    }

    .description {
      margin: 0px auto 30px auto;
    }

    .rewards {
      margin-bottom: 30px;
    }

    .time {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 0px;
  }

  .footerBtns {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .cancel-button {
      position: absolute;
    }

    @media (max-width:768px) {
      width: 100%;
    }
  }
}

/*------ADD CHORE CSS END---*/

//SERVER ERROR PAGE CSS
.server-error-wrapper {
  .error-sec-wrapper {
    width: 375px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0px 15px;

    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      background: $grey-3;
      border-radius: 76px;
      margin: 0px auto;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: $grey-4;
        line-height: 120px;
        text-align: center;

        img {
          width: 60px !important;

          @media (max-width: 414px) {
            width: 45px !important;
          }
        }

        @media (max-width: 414px) {
          width: 90px;
          height: 90px;
          border-radius: 60px;
          line-height: 90px;
        }
      }

      @media (max-width: 414px) {
        width: 112px;
        height: 112px;
        padding: 11px;
        border-radius: 56px;
      }
    }

    .successText {
      margin-top: 24px;
      text-align: center;

      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.01em;
        color: #1e333d
      }

      p {
        color: $grey-4;
        font-size: 14px;
      }
    }
  }
}

/*------SHOP PAGE CSS START---*/
.page-parent-shopping {
  .dashboard-heading {
    display: none !important;

    .heading-right-icon-list {
      display: none !important;
    }
  }
}

.shop-wrapper,
.child-shop-wrapper {
  .banno-page-title {
    font-size: var(--font-20);
    color: var(--primaryColor);
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    padding-bottom: 5px;
    line-height: 21px;

    @media (max-width:575px) {
      position: absolute;
      left: calc(50% - 37px);
      padding: 0;
      line-height: 21px;
    }
  }

  .noShopData {
    width: 400px;
    margin: 70px auto;

    .thumbIcon {
      width: 164px;
      height: 164px;
      background: #ddd;
      border-radius: 100px;
      margin: 0px auto 83px;
    }

    .noChild-title {
      padding: 0px 50px;
      text-align: center;
      font-size: var(--font-18);
      margin-bottom: 30px;
    }
  }

  .shop-dash-merchants {
    .search-box-row {
      width: 343px;

      @media (max-width:1024px) {
        width: 330px;
      }

      @media (max-width:991px) {
        width: calc(50% - 15px);
      }

      @media (max-width:768px) {
        width: 100%;
      }

      @media (max-width:575px) {
        position: sticky;
        top: 71px;
        background: #fff;
        z-index: 9;
        padding-top: 14px;
      }
    }

    .searchMerchant {
      margin-bottom: 28px;
      display: flex;
      align-items: center;
      column-gap: 10px;

      .inner-search {
        position: relative;
        border-radius: var(--borderRadiusM);
        width: 100%;

        input {
          border: none;
          padding: 16px 80px 16px 48px;
          height: auto;
          border-radius: var(--borderRadiusM);
          background-color: #f4f5f5;
          border: 1px solid transparent;
          transition: all 0.7s;

          &:focus {
            border: 1px solid var(--primaryColor);
          }

          @media (max-width:575px) {
            border-radius: var(--borderRadiusL);
          }
        }

        img.searchIcn {
          position: absolute;
          top: 21px;
          left: 16px;
        }

        .infoIcn {
          position: absolute;
          top: 18px;
          cursor: pointer;
          right: 16px;
          display: flex;
        }

        .filterIcon {
          position: absolute;
          top: 17px;
          cursor: pointer;
          right: 56px;
          width: 21px;
        }
      }

      .closeFilter {
        cursor: pointer;
        font-weight: 500;
        font-size: var(--font-14);
        color: var(--primaryColor);
        transition: all 0.5s;
      }

      @media (max-width:575px) {
        margin-bottom: 25px;
      }
    }

    .approvedRetailers {

      h3,
      .h3 {
        font-size: var(--font-12);
        line-height: 16px;
        letter-spacing: 0.01em;
        color: var(--tertiaryGrayColor);
        margin-bottom: 16px;

        @media (max-width:575px) {
          font-size: var(--font-16);
          line-height: 22px;
          margin-bottom: 20px;
        }
      }
    }

    .additionalRetailers {
      margin-top: 32px;

      h3,
      .h3 {
        font-size: var(--font-12);
        line-height: 16px;
        letter-spacing: 0.01em;
        color: var(--tertiaryGrayColor);
        margin-bottom: 16px;

        @media (max-width:575px) {
          font-size: var(--font-16);
          line-height: 22px;
        }
      }

      .additional-retailer-wrapper{
        display: flex;
        column-gap: 30px;
        flex-wrap: wrap;
        .singleRetailer {
          display: flex;
          align-items: center;
          padding: 16px 16px;
          background: #fff;
          border-radius: var(--borderRadiusL);
          margin-bottom: 12px;
          box-shadow: $cardShadow;
          width:343px;
  
          span {
            margin-right: 15px;
  
            img {
              width: 48px;
              overflow: hidden;
              max-width: 48px;
              min-width: 48px;
  
              @media (max-width:575px) {
                width: 38px;
                max-width: 38px;
                min-width: 38px;
              }
            }
          }
  
          button {
            span {
              margin-right: 0px;
            }
          }
  
          h4,
          .h4 {
            font-size: var(--font-14);
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #2c3333;
            width: 100%;
            margin: 0px;
            margin-right: 10px;
            font-weight: 600;
          }
  
          .addRetailer {
            button {
              white-space: nowrap;
              min-width: auto;
  
              @media (max-width:992px) {
                padding: 6px 10px;
                font-size: var(--font-12);
                border-radius: var(--borderRadiusS);
              }
            }
          }
  
          &:last-child {
            margin-bottom: 0px;
          }
          @media (max-width:1024px) {
            width:330px;
          }

          @media (max-width:992px){
            width: 100%;
          }

          @media (max-width:575px) {
            padding: var(--font-13) 15px !important;
          }
        }
      }

      @media (max-width:575px) {
        margin-top: 22px;
      }
    }

    .additionalCharities {
      margin-top: 32px;
      margin-bottom: 20px;

      h3,
      .h3 {
        font-size: var(--font-12);
        line-height: 16px;
        letter-spacing: 0.01em;
        color: var(--tertiaryGrayColor);
        margin-bottom: 16px;
      }

      .additional-charities-wrapper{
        display: flex;
        column-gap: 30px;
        flex-wrap: wrap;
      
        .singleRetailer {
          display: flex;
          align-items: center;
          padding: 15px 15px;
          background: #fff;
          border-radius: var(--borderRadiusL);
          margin-bottom: 12px;
          box-shadow: 1px 1px 8px #dedede;
          width:343px;
  
          span {
            margin-right: 15px;
  
            img {
              width: 28px;
              overflow: hidden;
              max-width: 28px;
            }
          }
  
          button {
            span {
              margin-right: 0px;
            }
          }

          .content{
            h4,
            .h4 {
              font-size: var(--font-14);
              line-height: var(--font-18);
              letter-spacing: -0.02em;
              color: #2c3333;
              width: 100%;
              margin: 0px;
              font-weight:600;
              line-clamp: 1;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
    
              @media (max-width:575px) {
                font-weight: 600;
              }
            }
  
            .cityState {
                font-size: var(--font-12);
                color: var(--tertiaryGrayColor);
                letter-spacing: 0.3px;
                font-weight: 400;
                margin-bottom: 0;
              }
          }
  
          .addRetailer {
            padding-left: 15px;
            margin-left: auto;
  
            button {
              // font-size: var(--font-12);
              // line-height: 14px;
              // padding: 12px 11px;
              white-space: nowrap;
              min-width: auto;
  
              @media (max-width:480px) {
                padding: 9px 16px;
              }
            }
          }
  
          &:last-child {
            margin-bottom: 0px;
          }

          @media (max-width:1024px) {
            width:330px;
          }

          @media (max-width:992px){
            width: 100%;
          }
        }

      }
    }
  }

  .tiles-cards-group {
    .dashboard-children-card {
      .singleRetailer {
        display: flex;
        // padding: 15px 15px;
        // background: #ffffff;
        // box-shadow: 0px 4px 8px rgba(209, 222, 222, 0.41);
        // border-radius: 16px;
        // margin-bottom: 8px;
        position: relative;

        .card-inner {
          display: flex;
          padding: 16px 16px;
          width: 100%;
          box-shadow: $cardShadow;
          border-radius: var(--borderRadiusL);

          span {
            margin-right: 18px;
            width: 48px;
            min-width: 48px;
            height: 48px;
            min-height: 48px;
            line-height: 12px;
            text-align: center;
            background-size: 30px;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
            border-radius: 24px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;

              @media (max-width:575px) {
                width: 40px;
                min-width: 40px;
              }
            }

            @media (max-width:575px) {
              width: 42px;
              min-width: 42px;
              height: 42px;
              min-height: 42px;
            }
          }

          .tile-content {

            h4,
            .h4 {
              font-size: var(--font-16);
              line-height: 24px;
              color: #2c3333;
              width: 100%;
              margin: 0px;
              margin-bottom: 12px;
              overflow: hidden;
              font-weight: 700;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }

            .childListing {
              ul {
                padding: 0px;
                margin: 0px;
                white-space: nowrap;
                display: flex;

                li {
                  width: 32px;
                  height: 32px;
                  min-width: 32px;
                  min-height: 32px;
                  background-color: #f4f5f5;
                  border-radius: 16px;
                  margin-right: 8px;
                  display: inline-block;
                  list-style: none;
                  cursor: pointer;
                  overflow: hidden;

                  &:last-child {
                    margin-right: 0px;
                  }
                }

                @media (max-width: 480px) {
                  display: flex;
                }
              }
            }

            &:last-child {
              margin-bottom: 0px;
            }
          }

          @media (max-width:1200px) {
            // padding:16px 16px 25px;
          }

          @media (max-width:425px) {
            // padding:24px 16px 25px;
          }

          @media (max-width:375px) {
            // padding:21px 16px 25px;
          }
        }

        // @media (max-width:575px) {
        //   box-shadow: 0px 4px 12px #d1dedead;
        //   margin-bottom: 18px;
        //   padding: 15px 16px 24px !important;
        //   border-radius: 41px;
        //   align-items: start;
        // }
      }

      @media (max-width:480px) {
        margin-bottom: 15px !important;
      }
    }
  }

  .shop-dash-tiles {
    p.approveChartiyLbl {
      font-size: var(--font-12);
      line-height: 16px;
      letter-spacing: 0.01em;
      color: var(--tertiaryGrayColor);
      font-weight: 500;
      margin-bottom: 16px;

      @media (max-width:575px) {
        font-size: var(--font-16);
        line-height: 22px;
      }
    }
  }

  @media (max-width:575px) {
    .dashboard-family-tabs {
      .nav-tabs {
        padding-top: 19px;
        position: sticky;
        top: 0px;
        padding-left: 15px;
        margin-left: -15px;
        margin-right: -15px;
        padding-right: 15px;
        padding-bottom: 15px;
        background-color: #fff;
        z-index: 9;
      }

      .tab-content {
        margin-top: 0px !important;
        padding-top: 0px !important;
      }
    }
  }
}

.child-shop-wrapper {
  .dashboard-family-tabs {
    .nav-tabs {
      @media (max-width:575px) {
        padding-top: 20px;
        top: 41px;
        padding-bottom: 29px;
      }
    }
  }
}

.filterRetailerModal {

  h1.modal-heading {
    margin-bottom: 31px;
  }

  .filter-box {
    display: flex;
    padding: 25px 0px;
    border-bottom: 1px solid #f4f5f5;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .label {
      font-size: var(--font-16);
      line-height: 24px;
    }

    .dropdown {
      .btn {
        background-color: $grey-2;
        font-size: var(--font-15);
        color: var(--primaryGrayColor);
        border: none;
        border-radius: var(--borderRadiusM);
        padding: 10px 12px !important;
        width: 260px;
        text-align: left;
        position: relative;

        span {
          width: 220px;
          max-width: 220px;
          display: block;
          overflow: hidden;
        }

        img {
          position: absolute;
          right: 6px;
          top: calc(50% - 8px);
        }

        &:focus {
          box-shadow: none;
        }

        &:active {
          background-color: $grey-2;
          color: var(--primaryGrayColor);
          box-shadow: none !important;
        }

        &::after {
          display: none;
        }

        @media (max-width:575px) {
          width: 200px;
          font-size: 0.813rem;
          text-wrap: wrap;
        }
      }

      .dropdown-menu {
        right: 0;
        left: auto;
      }

      .dropdown-item {
        color: var(--primaryGrayColor) !important;
        position: relative;
        font-size: var(--font-14);

        svg {
          display: none;
          width: 16px;
          position: absolute;
        }

        &:active,
        &:hover {
          background-color: transparent;
        }

        &.active {
          background-color: transparent;

          svg {
            display: initial;
            position: absolute;
            right: 10px;
            top: 5px;
          }
        }
      }
    }

    select {
      background-color: $grey-2;
      border: none;
      font-size: var(--font-15);
      line-height: var(--font-19);
      font-weight: 500;
      height: auto;
      padding: 10px 12px;
      color: var(--primaryGrayColor);
      border-radius: var(--borderRadiusM);
      width: 260px;
      appearance: none;
      -webkit-appearance: none;

      &:focus {
        box-shadow: none;
      }

      @media (max-width:575px) {
        width: 200px;
      }
    }

    .down-arrow {
      position: absolute;
      width: 18px;
      right: 5px;
    }

    .multi-select {
      background-color: #f4f5f5;
      border: none;
      font-size: var(--font-16);
      line-height: var(--font-19);
      font-weight: 700;
      height: auto;
      // padding: 10px 15px;
      color: var(--primaryGrayColor);
      border-radius: var(--borderRadiusM);
      width: 240px;

      .retailerFilter__control {
        padding: 1px 5px;
        background-color: transparent;
        border: none !important;
        box-shadow: none !important;

        .retailerFilter__value-container {
          .retailerFilter__placeholder {
            color: var(--primaryGrayColor);
          }
        }

        .retailerFilter__indicators {
          .retailerFilter__indicator-separator {
            display: none;
          }

          .retailerFilter__indicator {
            padding: 0;

            svg {
              width: 17px;

              path {
                fill: #000;
              }
            }
          }
        }
      }

      .retailerFilter__menu {
        font-weight: 500;
      }

      @media (max-width:575px) {
        width: auto;
        min-width: 200px;
      }
    }

    &:nth-child(5) {
      margin-bottom: 50px;
    }

    &.chips-wrapper {
      justify-content: flex-start;
      column-gap: 15px;
      flex-wrap: wrap;
      border: none;

      .chip {
        padding: 7px 35px 7px 15px;
        background: $grey-2;
        border-radius: var(--borderRadiusM);
        min-width: 31%;
        position: relative;
        margin-bottom: 10px;
        font-size: var(--font-13);

        img {
          position: absolute;
          right: 14px;
          width: 11px;
          top: var(--font-13);
          cursor: pointer
        }
      }
    }

  }
}

.approvedRetailers-wrapper {

  h1,
  .h1 {
    font-size: var(--font-35);
    font-weight: 100;
    margin-bottom: 0px;
    text-align: center;

    .goBack {
      font-size: var(--font-15);
      line-height: 15px;
    }
  }

  .retailerTiles {
    margin-top: 30px;

    .retailer-tile {
      padding: 15px;
      border: 1px solid #f1f1f1;
      box-shadow: 0px 1px 12px #f1efef;
      border-radius: var(--borderRadiusM);
      margin-bottom: 20px;
      cursor: pointer;

      .retailer-tile-container {
        display: flex;

        .retailer-tile-icon {
          margin-right: 10px;
        }

        .retailer-tile-content {
          width: 100%;

          .tile-childName {
            font-weight: 600;
            color: var(--primaryColor);
            line-height: 20px;
            min-height: 20px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .headingCont {
              overflow: hidden;
              max-width: 150px;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}

.approved-retailer-view {
  .retailerImg {
    text-align: center;
    margin: 0px auto 32px;

    img {
      width: 128px;
    }
  }

  .retailer-desc {
    margin-top: 20px;
  }

  .buttons {
    button {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.retailerSetting,
.charitySetting {

  h1,
  .h1 {
    text-align: center;
    margin-bottom: 32px;
    -webkit-line-clamp: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 480px) {
      margin-bottom: 30px;
      font-size: var(--font-20);
    }
  }

  .retailerSelection {
    margin-bottom: 40px;
    align-items: center;

    h3,
    .h3 {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--primaryColor);
      margin-bottom: var(--font-13);
    }

    .childLimitSingleBox {
      padding-bottom: 30px;
      padding-top: 30px;
      border-bottom: 1px solid #f4f5f5;
      position: relative;

      .childOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: #fff;
        opacity: 0.5;
      }

      &:last-child {
        border: none;
      }

      &:first-child {
        padding-top: 0;
      }

      @media (max-width: 480px) {
        margin-bottom: 10px;
      }
    }

    .falseFields {
      margin-top: 30px;

      .enableDisable {
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .limitInputWrapper {
        margin-bottom: 5px;
      }
    }

    .limitInputWrapper {
      display: flex;
      margin-bottom: 33px;
      align-items: flex-end;

      label {
        font-size: var(--font-12);
        line-height: 21px;
        color: #505d5f;
        margin-right: 33px;
        min-width: 100px;
        margin-bottom: 0;

        @media (max-width:575px) {
          margin-right: 0px;
        }
      }

      .limitInputs {
        display: flex;
        width: 100%;

        .input {
          margin-right: 30px;
          width: 100%;
          max-width: 60px;

          .inputBox {
            position: relative;

            input {
              font-size: var(--font-14);
              padding-left: 11px;
              color: var(--primaryGrayColor);
              border: none;
              line-height: 21px;
              border-bottom: 1px solid var(--primaryColor);
              width: 100%;
              letter-spacing: -0.03em;
              height: auto;
              padding-top: 0px;
              padding-bottom: 0px;
              text-align: right;

              &:focus {
                box-shadow: none;
                outline: none;
                border-color: var(--primaryColor);
              }
            }

            input::placeholder {
              color: var(--tertiaryGrayColor);
              ;
              font-size: var(--font-14);
              line-height: 21px;
              font-weight: 100;
            }

            .dollar {
              font-size: var(--font-14);
              line-height: 21px;
              letter-spacing: -0.03em;
              color: var(--tertiaryGrayColor);
              position: absolute;
              top: 0;
              right: 34px;

              &.dollar-active {
                color: var(--primaryGrayColor);
              }
            }

            p {
              font-size: 14px !important;
              line-height: 21px;
              position: relative;
            }

            .remaining-dollar {
              font-size: 14px;
              line-height: 24px;
              letter-spacing: -0.03em;
              color: var(--tertiaryGrayColor);
              position: absolute;
              top: 3px;
              right: 34px;
              line-height: 15px;
            }
          }

          p {
            font-size: var(--font-12);
            line-height: 16px;
            color: var(--tertiaryGrayColor);
            margin-bottom: 5px;
            letter-spacing: -0.03em;
          }

          &:last-child {
            margin-right: 0px;
          }

          @media (max-width:575px) {
            max-width: 50px;
          }
        }
      }

      @media (max-width: 480px) {
        margin-bottom: 20px;
      }
    }

    .inline-error-msg {
      font-size: var(--font-13);
    }

    .enableDisable {
      .enableDisable-row {
        display: flex;
        align-items: center;
        // margin-bottom: 22px;

        .avtar {
          width: 48px;
          height: 48px;
          min-width: 48px;
          min-height: 48px;
          background: #f4f5f5;
          border-radius: 24px;
          text-align: center;
          margin-right: 12px;
          overflow: hidden;
        }

        h3,
        .h3 {
          font-size: var(--font-16);
          line-height: 24px;
          color: var(--primaryGrayColor);
          opacity: 0.7;
          margin: 0px;
          font-weight: normal;
          width: 100%;
        }

        @media (max-width: 480px) {
          margin-bottom: 10px;

          .avtar {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
          }

          h3,
          .h3 {
            font-size: var(--font-14);
            line-height: 20px;
          }
        }
      }
    }

    .userAvtars {
      max-width: 80px;
      min-width: 80px;
      display: flex;
      align-items: center;

      ul {
        padding: 0px;
        margin: 0px;

        li {
          list-style: none;
          display: inline-block;
          margin-right: 5px;
          width: 35px;
          height: 35px;
          text-align: center;
          line-height: 31px;
          background: #f4f5f5;
          border-radius: 25px;
        }
      }
    }

    .timeSelection {
      width: 100%;
      padding-left: 20px;

      .upper {
        ul {
          padding: 0px;
          margin: 0px;
          display: flex;

          li {
            list-style: none;
            display: inline-block;
            width: 33%;
            font-size: var(--font-13);
          }
        }
      }

      .lower {
        ul {
          padding: 0px;
          margin: 0px;
          display: flex;

          li {
            list-style: none;
            display: inline-block;
            width: 33%;
            font-size: var(--font-13);
          }
        }
      }
    }

    @media (max-width: 480px) {
      margin-bottom: 20px;
    }
  }
}

.searchCharity {
  padding: 40px 30px;

  h1,
  .h1 {
    font-size: var(--font-35);
    font-weight: 100;
    text-align: center;
    margin-bottom: 30px;
  }

  .form-group {
    position: relative;
    margin-bottom: 0px;

    span.searchIcn {
      position: absolute;
      right: 0px;
      top: 42px;
    }
  }

  .searchSuggestions {
    padding: 10px 20px;
    background-color: #ddd;

    ul {
      padding: 0px;
      margin: 0px;

      li {
        font-size: var(--font-14);
        margin-bottom: 10px;
        list-style: none;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

/*------SHOP PAGE CSS END---*/

/*----SCHEDULED TRANSFER DETAILS MODAL---*/
.schTransferDetails {

  h1,
  .h1 {
    margin-bottom: 40px;
  }

  .detailsSingle {
    display: flex;
    padding: 10px 0px;
    border-bottom: 1px solid #f4f5f5;

    .dtlLabel {
      width: 50%;
      color: var(--primaryColor);
      font-size: var(--font-16);
      line-height: 24px;
      font-weight: 500;
    }

    .dtlDesc {
      width: 50%;
      text-align: right;
    }
  }
}

/*------HELP PAGE CSS START ---*/
.help-wrapper {
  .help-single {
    margin-bottom: 20px;

    #accordion {
      .card {
        border: none;
        margin-bottom: 0px;

        .card-header {
          background: #fff;
          border: none;
          padding: 15px 0px;
          border-bottom: 1px solid #ececec;

          h5,
          .h5 {
            button {
              width: 100%;
              text-align: left;
              padding: 0px;
              color: var(--primaryColor);
              font-weight: 500;
              text-decoration: none;
              font-size: var(--font-18);
              line-height: 27.5px;
              border: none;

              span.upArrow {
                display: initial;
              }

              span.downArrow {
                display: none;
              }

              &.collapsed {
                span.upArrow {
                  display: none;
                }

                span.downArrow {
                  display: initial;
                }
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }

              &:hover {
                text-decoration: none;
              }
            }
          }
        }

        .card-body {
          color: #505d5f;
          font-size: var(--font-14);
          padding: 10px 0px;
          line-height: 22px;

          .sub-accordion {
            padding-left: 30px;

            .card {
              border: none;
              margin-bottom: 0px;

              .card-header {
                background: #fff;
                border: none !important;
                padding: 15px 0px;

                h5,
                .h5 {
                  button {
                    width: 100%;
                    text-align: left;
                    padding: 0px;
                    color: var(--primaryColor);
                    font-weight: 500;
                    text-decoration: none;
                    font-size: var(--font-18);
                    line-height: 22px;

                    span.upArrow {
                      display: initial;
                    }

                    span.downArrow {
                      display: none;
                    }

                    &.collapsed {
                      span.upArrow {
                        display: none;
                      }

                      span.downArrow {
                        display: initial;
                      }
                    }

                    &:focus {
                      outline: none;
                      box-shadow: none;
                    }

                    &:hover {
                      text-decoration: none;
                    }
                  }
                }
              }

              .card-body {
                color: #505d5f;
                font-size: var(--font-14);
                padding: 0px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
}

/*---ADD CHILD CSS START----*/
.addChildBack {
  margin-bottom: 50px;
  font-size: var(--font-18);
  line-height: 22px;
  font-weight: 500;
  color: var(--primaryGrayColor);

  img {
    margin-top: -3px;
  }
}

.addNewChild-wrapper {
  width: 500px;
  margin: auto;

  .form-group {
    margin-bottom: 51px;
  }
}

/*-----EDIT PROFILE CSS START ----- */
.user-avatars {

  h1,
  .h1 {
    margin-bottom: 30px;
  }

  .avatars-selection-box {
    background: #fff;
    text-align: center;

    ul {
      padding: 0px;

      li {
        list-style: none;
        display: inline-block;
        margin: 8px;
        width: 48px;
        height: 48px;
        text-align: center;
        background: #f4f5f5;
        border-radius: 23px;
        border: 2px solid transparent;
        overflow: hidden;
        cursor: pointer;

        &.active {
          border: 2px solid var(--primaryColor);
        }
      }
    }
  }
}

//PARENT TRUSTED CONTACT SCREEN
.page-parent-trustedContact {
  position: relative;

  .dashboard-heading {
    display: block !important;
    text-align: center;
  }

  .info-icon {
    position: absolute;
    right: 0;
    top: 0;

    svg {
      display: flex;
    }

    @media (max-width:575px) {
      top: 19px;
    }
  }
}

.trusted-contact-wrapper {
  position: relative;

  .routeBack-abs {
    top: -57px;

    @media (max-width:575px) {
      top: -81px;

      img {
        margin-top: 0 !important;
      }
    }
  }

  .form-box {
    width: 400px;
    margin: auto;

    .form-group {
      margin-bottom: 30px;
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: #fff;
        z-index: 9;
        left: 0px;
        padding: 10px 15px 30px;
      }
    }


    @media (max-width:575px) {
      width: 100%;
    }
  }
}

.page-parent-profile {
  .heading-right-icon-list {
    position: absolute;
    right: 15px;
  }
}

.editProfileWrapper {
  margin: 0px auto 0;
  padding: 0px 0px 30px;
  position: relative;
  width: 400px;

  h1,
  .h1 {
    font-size: var(--font-30);
    font-weight: 100;
    margin-bottom: 15px;
    color: var(--primaryColor);
    text-align: center;
    margin-bottom: 40px;
  }

  .routeBack-abs {
    display: none;

    img {
      margin-top: 0 !important;
    }

    &.invest-back {
      display: initial;
      top: 0;

      @media (max-width:575px) {
        top: 15px;
      }
    }

    @media (max-width:575px) {
      z-index: 9;
      position: fixed;
      top: 22px;
      margin-left: 0;
      left: 15px;
      display: block;
    }
  }

  .mobile-edit-btn {
    display: none;
    position: fixed;
    top: 17px;
    right: 15px;
    font-size: var(--font-14);
    font-weight: normal;
    margin-top: 0;
    background: transparent;
    border: none;
    z-index: 9;
    color: var(--primaryColor);

    @media (max-width:575px) {
      display: inline-block;
    }
  }

  .desktop-edit-btn {
    @media (max-width:575px) {
      display: none !important;
    }
  }

  .form-group {
    margin-bottom: 30px;

    input {
      &:disabled {
        background-color: transparent;
      }
    }

    @media (max-width:575px) {
      margin-bottom: 43px !important;
    }
  }

  .editUserAvatar {
    position: relative;

    .userIconDisplayBox {
      width: 120px;
      height: 120px;
      border-radius: 60px;
      margin: 0px auto 30px auto;
      background: #f4f5f5;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;

      .editAvatar-btn {
        position: absolute;
        bottom: 5px;
        cursor: pointer;
        right: 10px;
        width: 22px;
      }
    }

    .avatars-selection-box {
      position: absolute;
      background: #fff;
      text-align: center;
      width: 500px;
      margin-left: calc(50% - 250px);
      height: 500px;

      h3,
      .h3 {
        font-weight: 300;
        font-size: var(--font-25);
        color: var(--primaryGrayColor);
        margin-bottom: 30px;
      }

      img {
        width: 50px;
        padding: 2px;
        background: var(--gray5);
        border-radius: 25px;
        margin-right: 10px;
        cursor: pointer;

        &.active {
          border: 2px solid #f27405;
        }
      }
    }
  }

  .changePassPin {
    font-size: var(--font-14);
    color: var(--primaryColor);
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    a {
      font-size: var(--font-14);
      color: var(--primaryColor);
    }

    .right-angle {

      img {
        width: 8px;
      }
    }

    @media (max-width:575px) {}
  }

  .bottom-links {

    h3,
    .h3 {
      font-size: var(--font-14);
      line-height: 24px;
      color: var(--primaryColor);
      padding: 10px 0px;

      &:last-child {
        border-bottom: none;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  button {
    margin-top: 30px;
  }

  .copyright-text {
    font-size: var(--font-13);
  }

  .success-alert {
    color: $primary-color;
    font-size: var(--font-13);
  }

  @media (max-width:1024px) {
    padding: 0;
  }

  @media (max-width:575px) {
    width: 100%;
    margin-top: 39px;
  }
}

/*-----EDIT PROFILE CSS END------ */

// ADD GOAD  CSS
.add-goal-wrapper {

  h1,
  .h1 {
    margin-bottom: 30px;
  }

  .goal-type {
    display: flex;
    justify-content: center;
    margin: 30px auto 50px auto;

    .goal-type-box {
      margin-right: 30px;

      span {
        width: 70px;
        height: 70px;
        display: inline-block;
        background: var(--tertiaryGrayColor);
        border-radius: 70px;
        text-align: center;
        line-height: 70px;
        cursor: pointer;
      }

      p {
        text-align: center;
        font-size: var(--font-13);
        margin-top: 5px;
      }

      &.active {
        span {
          background: var(--primaryColor);
        }

        p {
          color: var(--primaryColor);
        }
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .goal-description {
    margin-top: 40px;
    padding: 0px 60px;
  }

  .child-list {
    margin-bottom: 50px;
    margin-top: 40px;

    h3,
    .h3 {
      font-size: var(--font-18);
      font-weight: 300;
      margin-bottom: 15px;
    }

    .child-listing {
      ul {
        padding: 0px;

        li {
          list-style: none;
          display: inline-block;
          margin-right: 15px;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 35px;
          background: #f4f5f5;
          border-radius: 25px;

          img {
            width: 40px;
            padding: 3px;
          }
        }
      }
    }
  }
}

//ADD GOAL CSS END

//GOAL DETAILS CSS START
.goalDetails-wrapper {
  .goalDetails-breadcrumb {
    margin-bottom: 40px;
    display: flex;

    .left-arrrow {
      span {
        line-height: 60px;
      }

      img {
        margin-left: 10px;
      }

      span.childNme {
        font-weight: 500;
        font-size: var(--font-13);
        margin-left: 10px;
      }
    }

    .center-avtar {
      font-size: var(--font-30);
      line-height: 60px;
      font-weight: 300;

      .child-bread-avtar {
        width: 60px;
        height: 60px;
        border-radius: 40px;
        background: #f4f5f5;
        line-height: 55px;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;

        img {
          margin-right: 10px;
          padding: 5px;
        }
      }
    }
  }

  .goal-detail-container-2 {
    display: flex;

    .left-image-goal {
      width: 250px;
      background: var(--gray5);
      min-width: 250px;
      border-radius: 10px;
      margin-right: 20px;
    }

    .right-goal-content {

      h5,
      .h5 {
        font-size: var(--font-14);
        margin-bottom: 20px;
      }

      p {
        font-size: var(--font-13);
        margin-bottom: 10px;
      }

      a {
        font-size: var(--font-13);
        margin-bottom: 30px;
        display: inline-block;
      }

      .merchangeName {
        li {
          list-style: none;
          font-size: var(--font-13);

          span {
            display: inline-block;
            margin-right: 10px;
            background: var(--gray5);
            width: 30px;
            height: 30px;
            vertical-align: middle;
            border-radius: 7px;
          }
        }
      }
    }
  }

  .goal-progress {
    margin-top: 40px;

    .progress-cal {
      font-size: var(--font-12);
      margin-bottom: 3px;
    }

    .progress {
      height: 7px;

      .progress-bar {
        height: 7px;
        background-color: var(--primaryColor);
      }
    }
  }

  .purchasedHistory {

    h3,
    .h3 {
      font-size: var(--font-17);
      color: var(--primaryColor);
    }

    .historyBox {
      padding: 10px;
      background: #f4f5f5;

      table {
        margin: 0px;

        tr {
          td {
            border: none;
            font-size: var(--font-13);
            padding: 4px 10px;
            color: var(--secondaryGrayColor);
          }

          th {
            font-size: var(--font-11);
            border: none;
            padding: 4px 10px;
            color: var(--secondaryGrayColor);
          }
        }
      }
    }
  }
}

//GOAL DETAILS CSS END

//ADD ALLOWANCE CSS START
.allowance-add-wrapper {

  h1,
  .h1 {
    margin-bottom: 31px;
    text-align: center;
  }

  .allowance-amtWrapper {
    h3.special {
      font-size: var(--font-16);
      margin-bottom: 0px;
      line-height: 24px;
      color: var(--primaryColor);
      display: flex;
      letter-spacing: -0.02em;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
    }

    .allowance-amount {
      font-size: var(--font-30);
      margin: 0px auto 45px auto;
      position: relative;

      input {
        font-size: var(--font-40) !important;
        padding-left: var(--font-24);
        color: var(--primaryGrayColor);
        border: none;
        line-height: var(--font-56);
        border-bottom: 1px solid #000;
        width: 100%;
        letter-spacing: -0.03em;
        //font-family: "GothamXLight";
        height: auto;

        &:focus {
          box-shadow: none;
          outline: none;
          border-color: var(--primaryColor);
          border-width: 2px;
        }
      }

      input::placeholder {
        color: var(--tertiaryGrayColor);
        font-size: var(--font-40) !important;
        line-height: var(--font-56) !important;
        font-weight: 100;
        //font-family: "GothamXLight";
      }

      .dollar {
        font-size: var(--font-40);
        line-height: 56px;
        letter-spacing: -0.03em;
        color: var(--tertiaryGrayColor);
        //font-family: "GothamXLight";
        position: absolute;
        top: 6px;

        &.dollar-ative {
          color: var(--primaryGrayColor);
        }
      }

      @media (max-width:575px) {
        margin: 0px auto 24px auto;
      }
    }
  }

  .schedule {
    margin-bottom: 45px;

    h3,
    .h3 {
      font-size: var(--font-16);
      margin-bottom: 0px;
      line-height: 24px;
      color: var(--primaryColor);
      display: flex;
      letter-spacing: -0.02em;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;

      span {
        width: 100%;
        font-weight: 700;
      }

      .recurringBtnGrp {
        display: flex;
        justify-content: space-between;

        button {
          background-color: transparent;
          color: var(--tertiaryGrayColor);
          font-size: var(--font-16);
          line-height: 24px;
          border: none;
          padding: 8px 15px;
          margin-right: 5px;
          font-weight: 400;
          border-radius: var(--borderRadiusS);

          &.active {
            background-color: var(--primaryColor);
            color: #fdfcfb;
          }

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }

    .once {
      margin-top: 16px;

      .react-datepicker {
        width: 100%;
        border: none;

        .react-datepicker__navigation--previous {
          right: 50px;
          left: auto;
        }

        .react-datepicker__month-container {
          width: 100%;

          .react-datepicker__header {
            background-color: #fff;
            border: none;

            .react-datepicker__current-month {
              margin-bottom: 20px;
            }

            .react-datepicker__day-names {
              display: flex;
              justify-content: space-around;

              .react-datepicker__day-name {
                font-weight: 300;
                font-size: var(--font-12);
                color: var(--tertiaryGrayColor);
                letter-spacing: 0.1em;
              }
            }
          }

          .react-datepicker__month {
            background-color: #fff;
            margin: 0px;

            .react-datepicker__week {
              display: flex;
              justify-content: space-around;
              font-size: var(--font-16);

              .react-datepicker__day {
                margin-bottom: 10px;
                font-family: 'GothamBook';
              }

              // .react-datepicker__day--today {
              //   background-color: var(--primaryColor);
              //   border-radius: 100%;
              //   color: #fff;
              // }

              .react-datepicker__day--keyboard-selected {
                background-color: transparent;
                color: #000;
              }

              .react-datepicker__day--selected {
                background-color: var(--primaryColor);
                border-radius: 100%;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .weeksName {
      margin-top: 45px;

      ul {
        padding: 0px;
        margin: 0px;
        display: flex;
        justify-content: space-between;

        li {
          list-style: none;
          display: inline-block;
          width: 40px;
          height: 40px;
          background: #f4f5f5;
          text-align: center;
          line-height: 40px;
          margin-right: 10px;
          color: var(--primaryColor);
          font-weight: 600;
          border-radius: var(--borderRadiusS);
          cursor: pointer;
          font-size: var(--font-16);
          color: #000;
          //font-family: "GothamXLight";

          &.active {
            background-color: var(--primaryColor);
            color: #fff;
          }

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }

    .monthlySelection {
      display: flex;
      column-gap: 20px;

      label {
        font-weight: 700;
      }

      select {
        width: 100%;
        background-color: $grey-2;
        border: none;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);

        &:last-child {
          margin-right: 0px;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .recurrence-selection {
      margin-top: 45px;
      margin-bottom: 25px;

      .form-group {
        margin-bottom: 25px;
      }

      label {
        font-weight: 700;
      }

      select {
        width: 100%;
        background-color: $grey-2;
        border: none;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);

        &:focus {
          box-shadow: none;
        }
      }
    }

    .recurrence-dayofMonth {
      .form-group {
        margin-bottom: 0;
      }

      select {
        width: 100%;
        background-color: $grey-2;
        border: none;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .completeChore {

    h3,
    .h3 {
      font-size: var(--font-16);
      margin-bottom: 0px;
      line-height: 24px;
      color: var(--primaryColor);
      font-weight: 700;
    }

    p {
      font-size: var(--font-13);
      color: var(--tertiaryGrayColor);
      margin-top: 5px;
      padding-right: 110px;
      line-height: 22px;
    }
  }

  .pauseAllowance {
    margin-bottom: 24px;

    h3,
    .h3 {
      font-size: var(--font-16);
      margin-bottom: 0px;
      line-height: 24px;
      color: var(--primaryColor);
      font-weight: 700;
    }
  }

  .saveAllowance {}
}

//ADD ALLOWANCE CSS END

.adultRelationhipModal {
  .addChildRelation {
    margin-top: 0;

    h1,
    .h1 {
      margin-bottom: 30px;
      position: relative;

      img.infocircle,
      span.infocircle {
        position: absolute;
        right: 0;
        top: calc(50% - 10px);
      }
    }

    .relationsItem {
      .childRelationSingle {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .user-name {
          min-width: 23%;

          h3,
          .h3 {
            font-size: var(--font-11);
            font-weight: 600;
            color: var(--primaryColor);
            line-height: 24px;
            margin-bottom: 0;
          }

          p {
            color: var(--gray5);
            font-size: var(--font-14);
            line-height: 22px;
            margin-bottom: 0;
          }
        }

        .childName {
          width: 48px;
          min-width: 48px;
          height: 48px;
          min-height: 48px;
          text-align: center;
          line-height: 39px;
          background: #f4f5f5;
          border-radius: 24px;
          margin-right: 10px;
          border: 2px solid transparent;
          overflow: hidden;
          box-sizing: border-box;

          &.active {
            border: 2px solid var(--primaryColor);
          }
        }

        .parentName {
          font-size: var(--font-16);
          line-height: 24px;
          font-weight: 500;
          width: 100%;
          color: var(--primaryColor);

          span {
            color: var(--tertiaryGrayColor);
            font-size: var(--font-12);
            line-height: 24px;
          }
        }

        .selection {
          // width: 180px;
          margin-left: auto;

          select {
            width: 180px;
            background-color: #f4f5f5;
            border: none;
            font-size: var(--font-16);
            line-height: var(--font-19);
            height: var(--font-36);
            color: var(--primaryGrayColor);

            &:focus {
              box-shadow: none;
            }
          }

          button {
            min-width: 135px;
          }
        }

        .ctaSection {
          // pointer-events: none;
          // min-width: 138px;
          margin-left: auto;

          .creatorSelect {
            border-radius: var(--borderRadiusS);
            font-size: var(--font-14);
            line-height: var(--font-20);
            min-width: 135px;
            background-color: #f4f5f5;

            &:disabled {
              opacity: 0.7;
              color: #000;
            }
          }
        }
      }
    }

    .bottom-buttons-group {
      margin-top: 70px;
    }
  }
}

.digital-wallet-goals {
  .goals-progress-container {
    .goals-progress-single {
      margin-bottom: 24px;
      display: flex;

      .goal-avatar {
        min-width: 64px;
        max-width: 64px;
        background: $grey-2;
        min-height: 64px;
        max-height: 64px;
        background-position: center;
        background-size: cover;
        border-radius: 32px;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 23px;

          @media (max-width:575px) {
            width: 20px;
          }
        }

        @media (max-width: 575px) {
          background-size: 25px;
        }

        @media (max-width: 480px) {
          background-size: 20px;
        }
      }

      .goal-progress-bar {
        padding-left: 16px;
        width: 100%;

        h5,
        .h5 {
          font-size: var(--font-14);
          line-height: 22px;
          color: #000;
          margin-bottom: 6px;
          font-weight: 600;
          min-height: 22px;

          span {
            font-size: var(--font-16);
            line-height: 24px;
            font-weight: normal;
            color: #000;
          }

          @media (max-width:480px) {
            min-height: 17px;
          }
        }

        .progress {
          height: 8px;
          background-color: #f6f7f7;
          margin-bottom: 5px;

          .progress-bar {
            background: var(--primaryColor);
            height: 8px;
            border-radius: 4px 0px 0px 4px;
          }
        }

        .progress-value {
          font-size: var(--font-14);
          font-weight: 500;
          line-height: 16px;
          color: #000;
          max-width: 100%;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.digital-wallet-detail-wrapper {
  .digital-wallet-breadcrumb {
    // margin-bottom: 36px;
    align-items: center;
    display: flex;

    .left-arrrow {
      font-size: var(--font-18);
      line-height: 22px;
      font-weight: 500;
      color: var(--primaryGrayColor);

      span {
        img {
          margin-top: -3px;
        }
      }

      @media (max-width: 480px) {
        font-size: var(--font-14);
        line-height: 19px;
      }
    }

    .center-avtar {
      font-size: var(--font-28);
      line-height: 40px;
      align-items: center;
      color: #000;
      font-weight: 700;
      display: flex;

      .editUserAvatar {
        .userIconDisplayBox {
          width: 32px;
          height: 32px;
          border-radius: 16px;
          background-color: #f4f5f5;
          margin: 1px 8px 0px auto;
        }
      }

      .child-bread-avtar {
        width: 60px;
        height: 60px;
        border-radius: 40px;
        background: #f4f5f5;
        line-height: 55px;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;

        img {
          margin-right: 10px;
          padding: 5px;
        }
      }
    }

    @media (max-width: 480px) {
      margin-bottom: 25px;
    }
  }
}

//TRANSFER MODAL CSS START
.transferModal-wrapper {

  h1,
  .h1 {
    margin-bottom: 31px;
  }

  .form-box {
    .form-group {
      label.special {
        font-weight: 600;
      }

      .fromToAccountGrp {
        margin-bottom: 32px;

        .fromAccount {
          margin-bottom: 32px;

          @media (max-width: 480px) {
            margin-bottom: 58px;
          }
        }

        .fromAccount,
        .toAccount {
          width: 100%;

          label {
            display: block;
          }
        }

        select {
          font-weight: 600;
          background-color: #f4f5f5;
          border: none;
          margin-right: 10px;
          font-size: var(--font-16);
          line-height: 20px;
          height: var(--font-36);
          color: var(--primaryGrayColor);
          border-radius: var(--borderRadiusM);

          &:focus {
            box-shadow: none;
          }

          &:last-child {
            margin-right: 0px;
          }

          @media (max-width: 575px) {
            font-size: var(--font-15);
            height: 62px;
            background: transparent;
            border: 1px solid $grey-3;
            color: var(--primaryGrayColor);
            border-radius: var(--borderRadiusS);

            &::placeholder {
              color: $grey-3;
            }
          }
        }
      }

      .transferMemo {
        margin-bottom: 32px;

        textarea {
          border-color: var(--primaryGrayColor);
          font-size: var(--font-13);

          &:focus {
            box-shadow: none;
          }

          @media (max-width:575px) {
            height: 48px;
            border: none;
            border-bottom: 1px solid var(--tertiaryGrayColor);
            border-radius: 0;
            resize: none;
            padding: 12px 0.75rem 12px 0;
            overflow: hidden;
            font-size: var(--font-15);
          }
        }
      }

      .recurringBtnGrp {
        display: flex;
        justify-content: space-between;

        button {
          background-color: transparent;
          color: var(--tertiaryGrayColor);
          font-size: var(--font-16);
          line-height: 24px;
          border: none;
          padding: 8px 15px;
          margin-right: 5px;
          font-weight: 400;
          border-radius: var(--borderRadiusS);

          &.active {
            background-color: var(--primaryColor);
            color: #fdfcfb;
          }

          &:last-child {
            margin-right: 0px;
          }

          @media (max-width:575px) {
            font-size: var(--font-14);
            padding: 6px 20px;
          }
        }
      }

      .once {
        margin-top: 30px;

        .react-datepicker {
          width: 100%;
          border: none;

          .react-datepicker__navigation--previous {
            right: 50px;
            left: auto;
          }

          .react-datepicker__month-container {
            width: 100%;

            .react-datepicker__header {
              background-color: #fff;
              border: none;

              .react-datepicker__current-month {
                margin-bottom: 20px;
              }

              .react-datepicker__day-names {
                display: flex;
                justify-content: space-around;

                .react-datepicker__day-name {
                  font-weight: 300;
                  font-size: var(--font-12);
                  color: var(--tertiaryGrayColor);
                  letter-spacing: 0.1em;
                }
              }
            }

            .react-datepicker__month {
              background-color: #fff;
              margin: 0px;

              .react-datepicker__week {
                display: flex;
                justify-content: space-around;
                font-size: var(--font-16);

                .react-datepicker__day {
                  margin-bottom: 10px;
                  font-family: 'GothamBook';
                }

                // .react-datepicker__day--today {
                //   background-color: var(--primaryColor);
                //   border-radius: 100%;
                //   color: #fff;
                // }

                .react-datepicker__day--keyboard-selected {
                  background-color: transparent;
                  color: #000;
                }

                .react-datepicker__day--selected {
                  background-color: var(--primaryColor);
                  border-radius: 100%;
                  color: #fff;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }

      .weeksName {
        margin-top: 30px;

        ul {
          padding: 0px;
          margin: 0px;
          display: flex;
          justify-content: space-between;

          li {
            list-style: none;
            display: inline-block;
            width: 50px;
            height: 50px;
            background: #f4f5f5;
            text-align: center;
            line-height: 50px;
            margin-right: 10px;
            color: var(--primaryColor);
            font-weight: 600;
            border-radius: var(--borderRadiusS);
            cursor: pointer;

            &.active {
              background-color: var(--primaryColor);
              color: #fff;
            }

            &:last-child {
              margin-right: 0px;
            }
          }
        }

        .skip-message {
          font-size: var(--font-13);
        }
      }

      .monthly {
        .monthlySelection {
          display: flex;
          column-gap: 20px;

          label {
            font-weight: 700;
          }

          select {
            width: 100%;
            background-color: $grey-2;
            border: none;
            font-size: var(--font-16);
            line-height: var(--font-19);
            font-weight: 600;
            height: var(--font-36);
            color: var(--primaryGrayColor);

            &:last-child {
              margin-right: 0px;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }

        .recurrence-selection {
          margin-top: 45px;
          margin-bottom: 25px;

          .form-group {
            margin-bottom: 25px;
          }

          label {
            font-weight: 700;
          }

          select {
            width: 100%;
            background-color: $grey-2;
            border: none;
            font-size: var(--font-16);
            line-height: var(--font-19);
            font-weight: 600;
            height: var(--font-36);
            color: var(--primaryGrayColor);

            &:focus {
              box-shadow: none;
            }
          }
        }

        .recurrence-dayofMonth {
          .form-group {
            margin-bottom: 0;
          }

          select {
            width: 100%;
            background-color: $grey-2;
            border: none;
            font-size: var(--font-16);
            line-height: var(--font-19);
            font-weight: 600;
            height: var(--font-36);
            color: var(--primaryGrayColor);

            &:focus {
              box-shadow: none;
            }
          }
        }
      }

      .careGiver {
        margin-bottom: 44px;

        label {
          margin-bottom: 18px;
        }

        .parentsList {
          .parentSingle {
            margin-right: 10px;
            float: left;

            li {
              width: 64px;
              height: 64px;
              background: #f4f5f5;
              list-style: none;
              text-align: center;
              border-radius: 32px;
              display: inline-block;
              margin-right: 16px;
              cursor: pointer;
              position: relative;

              .image-content {
                border-radius: 32px;
                overflow: hidden;
              }

              p {
                font-size: var(--font-12);
                margin-bottom: 0px;
                line-height: 16px;
                color: #4c5562;
                opacity: 0.7;
                letter-spacing: 0.01em;
                margin-top: 3px;
              }

              img.check {
                display: none;
              }

              &:last-child {
                margin-right: 0px;
              }

              &.active {
                img.check {
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  display: initial;
                }
              }
            }
          }

          .addNewParent {
            float: left;
            width: 64px;
            height: 64px;
            background: #f4f5f5;
            list-style: none;
            text-align: center;
            line-height: 60px;
            border-radius: 32px;
            cursor: pointer;

            img {
              width: 20px;
            }
          }
        }
      }

      &.amount {
        margin-bottom: 32px;

        .input-dollar-group {
          position: relative;

          input {
            font-size: var(--font-40) !important;
            line-height: var(--font-56);
            padding: 0px 0px 0px var(--font-24);
            color: var(--primaryGrayColor);
            height: auto;
            border-color: #97b0b036;
            //font-family: "GothamXLight";

            @media (max-width:575px) {
              font-family: 'GothamXLight';
              height: 65px;
            }
          }

          input::placeholder {
            color: var(--tertiaryGrayColor);
            font-size: var(--font-40) !important;
            line-height: var(--font-40);
            font-weight: 100;
          }

          .dollar {
            font-size: var(--font-40);
            line-height: 56px;
            //font-family: "GothamXLight";
            color: var(--tertiaryGrayColor);
            position: absolute;
            top: 9px;
            font-weight: 100;
            line-height: 37.5px;

            &.dollar-ative {
              color: var(--primaryGrayColor);
            }

            @media (max-width:575px) {
              font-family: 'GothamXLight';
              top: var(--font-13);
            }
          }
        }

        @media (max-width: 480px) {
          margin-bottom: 43px;
        }
      }

      @media (max-width:575px) {
        margin-bottom: 58px;
      }
    }
  }

  .transfer-success-wrapper {
    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      // background: #ceedea;
      border-radius: 76px;
      margin: 100px auto 0px;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: var(--primaryColor);
        line-height: 120px;
        text-align: center;

        img {
          width: 56px;

          @media (max-width: 414px) {
            width: 36px;
          }
        }

        @media (max-width: 414px) {
          width: 90px;
          height: 90px;
          border-radius: 60px;
          background: var(--primaryColor);
          line-height: 90px;
        }
      }

      @media (max-width: 414px) {
        width: 112px;
        height: 112px;
        padding: 11px;
        border-radius: 56px;
      }
    }

    .successText {
      margin-top: 24px;
      text-align: center;
      margin-bottom: 120px;

      h3,
      .h3 {
        font-weight: 600;
        font-size: var(--font-20);
        line-height: 23px;
        letter-spacing: 0.01em;
        color: var(--primaryColor);
      }
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: 100%;
        bottom: 0px;
        z-index: 9;
        left: 0px;
        padding: 10px 15px 30px;
      }
    }
  }

}

//TRANSFER MODAL CSS END

//ADD FUND MODAL CSS START
.addFundModal-wrapper {

  h1,
  .h1 {
    text-align: center;
    margin-bottom: 51px;

    @media (max-width:575px) {
      margin-bottom: 31px;
    }
  }

  .form-box {
    .form-group {
      margin-bottom: 32px;

      label.special {
        font-weight: 600;
      }

      .recurringBtnGrp {
        display: flex;
        justify-content: space-between;

        button {
          background-color: transparent;
          color: var(--tertiaryGrayColor);
          font-size: var(--font-16);
          line-height: 24px;
          border: none;
          padding: 8px 15px;
          margin-right: 5px;
          font-weight: 400;
          border-radius: var(--borderRadiusS);

          &.active {
            background-color: var(--primaryColor);
            color: #fdfcfb;
          }

          &:last-child {
            margin-right: 0px;
          }

          @media (max-width:575px) {
            padding: 6px 20px;
            font-size: var(--font-14);
          }
        }
      }

      .source {
        label {
          margin-bottom: 10px;
        }

        .sourceSingle {
          display: flex;
          padding: var(--font-13) 16px;
          border: 1px solid var(--tertiaryGrayColor);
          border-radius: var(--borderRadiusS);
          margin-bottom: 15px;
          cursor: pointer;
          align-items: center;

          .bankName {
            font-weight: 600;
            font-size: var(--font-14);
            line-height: 24px;
            margin-left: 15px;
            width: 100%;
            overflow: hidden;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            margin-right: 10px;

          }

          .accountNum {
            font-size: var(--font-16);
            line-height: 24px;
            margin-left: 15px;
            color: var(--tertiaryGrayColor);
          }

          &:last-child {
            margin-bottom: 0px;
          }

          &.active {
            border: 2px solid var(--primaryColor);
            background-color: rgba(3, 166, 150, 0.04);
          }

          @media (max-width:575px) {
            padding: 18px 15px;
          }
        }


      }

      .once {
        margin-top: 30px;

        .react-datepicker {
          width: 100%;
          border: none;

          .react-datepicker__navigation--previous {
            right: 50px;
            left: auto;
          }

          .react-datepicker__month-container {
            width: 100%;

            .react-datepicker__header {
              background-color: #fff;
              border: none;

              .react-datepicker__current-month {
                margin-bottom: 20px;
              }

              .react-datepicker__day-names {
                display: flex;
                justify-content: space-around;

                .react-datepicker__day-name {
                  font-weight: 300;
                  font-size: var(--font-12);
                  color: var(--tertiaryGrayColor);
                  letter-spacing: 0.1em;
                }
              }
            }

            .react-datepicker__month {
              background-color: #fff;
              margin: 0px;

              .react-datepicker__week {
                display: flex;
                justify-content: space-around;
                font-size: var(--font-16);

                .react-datepicker__day {
                  margin-bottom: 10px;
                  font-family: 'GothamBook';
                }

                // .react-datepicker__day--today {
                //   background-color: var(--primaryColor);
                //   border-radius: 100%;
                //   color: #fff;
                // }

                .react-datepicker__day--keyboard-selected {
                  background-color: transparent;
                  color: #000;
                }

                .react-datepicker__day--selected {
                  background-color: var(--primaryColor);
                  border-radius: 100%;
                  color: #fff;
                }
              }
            }
          }
        }
      }

      .weeksName {
        margin-top: 30px;

        ul {
          padding: 0px;
          margin: 0px;
          display: flex;
          justify-content: space-between;

          li {
            list-style: none;
            display: inline-block;
            width: 50px;
            height: 50px;
            background: #f4f5f5;
            text-align: center;
            line-height: 50px;
            margin-right: 10px;
            color: var(--primaryColor);
            font-weight: 600;
            border-radius: var(--borderRadiusS);
            cursor: pointer;

            &.active {
              background-color: var(--primaryColor);
              color: #fff;
            }

            &:last-child {
              margin-right: 0px;
            }

            @media (max-width:575px) {
              width: 40px;
              height: 40px;
              line-height: 40px;
            }

            @media (max-width:375px) {
              width: 35px;
              height: 35px;
              line-height: 35px;
            }
          }
        }
      }

      .monthly {
        .monthlySelection {
          display: flex;
          column-gap: 20px;

          label {
            font-weight: 700;
          }

          select {
            width: 100%;
            background-color: $grey-2;
            border: none;
            font-size: var(--font-16);
            line-height: var(--font-19);
            font-weight: 600;
            height: var(--font-36);
            color: var(--primaryGrayColor);

            &:last-child {
              margin-right: 0px;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }

        .recurrence-selection {
          margin-top: 45px;
          margin-bottom: 25px;

          .form-group {
            margin-bottom: 25px;
          }

          label {
            font-weight: 700;
          }

          select {
            width: 100%;
            background-color: $grey-2;
            border: none;
            font-size: var(--font-16);
            line-height: var(--font-19);
            font-weight: 600;
            height: var(--font-36);
            color: var(--primaryGrayColor);

            &:focus {
              box-shadow: none;
            }
          }
        }

        .recurrence-dayofMonth {
          .form-group {
            margin-bottom: 0;
          }

          select {
            width: 100%;
            background-color: $grey-2;
            border: none;
            font-size: var(--font-16);
            line-height: var(--font-19);
            font-weight: 600;
            height: var(--font-36);
            color: var(--primaryGrayColor);

            &:focus {
              box-shadow: none;
            }
          }
        }
      }

      &.amount {
        margin-bottom: 0;

        .input-dollar-group {
          position: relative;

          input {
            font-size: var(--font-40) !important;
            line-height: var(--font-56);
            padding: 0px 0px 0px var(--font-24);
            color: var(--primaryGrayColor);
            height: auto;
            border-color: #97b0b036;
            //font-family: "GothamXLight";

            @media (max-width:575px) {
              font-family: 'GothamXLight';
              height: 65px;
            }
          }

          input::placeholder {
            color: var(--tertiaryGrayColor);
            font-size: var(--font-40) !important;
            line-height: var(--font-56);
            font-weight: 100;
          }

          .dollar {
            font-size: var(--font-40);
            line-height: 56px;
            color: var(--tertiaryGrayColor);
            position: absolute;
            top: 9px;
            font-weight: 100;
            line-height: 37.5px;
            //font-family: "GothamXLight";

            &.dollar-ative {
              color: var(--primaryGrayColor);
            }

            @media (max-width:575px) {
              font-family: 'GothamXLight';
              top: var(--font-13);
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width:575px) {
        margin-bottom: 50px;
      }
    }

    .ach-transfer-error {
      margin-bottom: 32px;
      margin-top: 3px;
    }
  }

  .add-funds-confirmation-view {

    .modal-heading {
      position: relative;

      .back {
        position: absolute;
        left: 0;
      }
    }

    .funds-details {
      .fund-item-details {
        display: flex;
        justify-content: space-between;
        padding: 10px 0px;
        border-bottom: 1px solid $grey-2;

        .description {
          font-size: var(--font-16);
          line-height: 24px;
          color: var(--primaryColor);
          font-weight: 600;
        }

        .value {
          font-size: var(--font-16);
          line-height: 24px;
          color: #000;
        }
      }
    }

    .desclaimer-text {
      font-size: var(--font-14);
      color: $black-1;
      margin-top: 50px;
    }
  }

  .fundtransfer-success-wrapper {
    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      // background: #ceedea;
      border-radius: 76px;
      margin: 100px auto 0px;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: var(--primaryColor);
        line-height: 120px;
        text-align: center;

        img {
          width: 56px;

          @media (max-width: 414px) {
            width: 36px;
          }
        }

        @media (max-width: 414px) {
          width: 90px;
          height: 90px;
          border-radius: 60px;
          background: var(--primaryColor);
          line-height: 90px;
        }
      }

      @media (max-width: 414px) {
        width: 112px;
        height: 112px;
        padding: 11px;
        border-radius: 56px;
      }
    }

    .successText {
      margin-top: 24px;
      text-align: center;
      margin-bottom: 120px;

      h3,
      .h3 {
        font-weight: 600;
        font-size: var(--font-20);
        line-height: 23px;
        letter-spacing: 0.01em;
        color: var(--primaryColor);
      }

      p {
        font-size: var(--font-14);
      }
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: 100%;
        bottom: 0px;
        z-index: 9;
        left: 0px;
        padding: 10px 15px 30px;
      }
    }
  }
}

//ADD FUND MODAL CSS END

//RECEIPT PREVIEW MODAL
.receiptsPreviewModal {
  table {
    width: 100% !important;

    span {
      line-height: 34px;
    }
  }
}

//PRIVACY POLICY IFRAME PAGE CSS
.privacy-policy-iframe {

  h1,
  .h1 {
    font-size: var(--font-30);
    margin-bottom: 25px;
    font-weight: 700;
    color: var(--primaryColor);
    position: relative;

    span.goBack {
      font-size: var(--font-18);
      color: var(--primaryGrayColor);
      font-weight: 500;
      white-space: nowrap;
      margin-bottom: 29px;
      text-align: left;
      float: left;
      margin-top: 9px;
      align-items: center;
      display: flex;

      @media (max-width:480px) {
        position: absolute;
        top: -40px;
      }

    }

    @media (max-width:575px) {
      font-size: var(--font-20);
    }
  }

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
    min-height: calc(100vh - 210px);

    @media (max-width:575px) {
      height: 100vh;
    }
  }

  @media (max-width:768px) {
    padding: 0px 15px;
  }

  @media (max-width:1024px) {

    h1,
    .h1 {
      font-size: var(--font-25);
      margin-bottom: 15px;
    }

  }

  @media (max-width:575px) {
    margin-top: 10px;
    padding: 0;
  }

  @media (max-width:414px) {

    h1,
    .h1 {
      font-size: var(--font-20);
    }
  }
}


//EDUCATION PAGE CASS START
.page-parent-education-detail-contentId {
  .dashboard-heading {
    display: none !important;
  }
}

.page-parent-education {

  // position: relative;
  .dashboard-heading {
    .heading-right-icon-list {
      position: absolute;
      right: 50px;

      .menuHelp {
        margin-top: 0;
        margin-right: 0 !important;
      }

      @media (max-width:575px) {
        top: 20px;
      }
    }
  }

  .educationBack {
    margin-bottom: 31px;
    position: absolute;
    right: 0;
    top: 0px;

    a {
      font-size: var(--font-18);
      color: var(--primaryColor);
      line-height: 30px;
      font-weight: 500;
      padding: 0px;
    }

    @media (max-width:575px) {
      position: fixed;
      top: 17px;
      right: 15px;
      z-index: 10;
      margin-bottom: 0;

      button.btn-gradient {
        font-weight: 500;
        font-size: var(--font-15);
        background: transparent;
        padding: 0;
        color: var(--primaryColor) !important;
        min-width: auto;
      }
    }
  }

  .menuHelp {
    margin-right: 113px !important;
  }

  &.fr {
    .dashboard-heading {
      .heading-right-icon-list {
        right: 70px;
      }
    }
  }
}

.education-wrapper {
  position: relative;

  .newContent {
    position: absolute;
    right: 0;
    top: -59px;

    button {
      background: transparent;
      color: var(--primaryColor) !important;
      padding: 0 !important;
      min-width: 0 !important;
      font-weight: 600;
    }

    @media (max-width:575px) {
      z-index: 9;
      position: fixed;
      right: 15px;
      top: 17px;
    }

  }

  .education-landing {

    .education-cards-wrapper{
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
      .education-single {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        background-color: #fff;
        border-radius: var(--borderRadiusL);
        box-shadow: 1px 1px 8px #dedede;
        padding: 16px;
        width:343px;

        @media (max-width:1024px) {
          width: 330px;
        }

        @media (max-width:992px) {
          width: calc(50% - 15px);
        }

        @media (max-width:820px) {
          width: 100%;
        }
  
        .edu-thumbnail {
          width: auto;
          min-width: 98px;
          height: auto;
          min-height: 78px;
          border-radius: var(--borderRadiusS);
          margin-left: 20px;
          background-size: cover;
        }
  
        .edu-content {
          width: 100%;
  
          h4,
          .h4 {
            font-size: var(--font-14);
            line-height: 18px;
            margin-bottom: 15px;
            color: var(--primaryColor);
            font-weight: 500;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
  
            @media (max-width:575px) {
              font-size: var(--font-13);
              margin-bottom: 6px !important;
            }
          }
  
          .child-listing {
            ul {
              padding: 0px;
              margin: 0px;
  
              li {
                list-style: none;
                display: inline-block;
                margin-right: 8px;
                width: 32px;
                height: 32px;
                text-align: center;
                background: #f4f5f5;
                border-radius: 25px;
                position: relative;
                overflow: hidden;
                cursor: pointer;
  
                img.check {
                  width: 15px;
                  position: absolute;
                  bottom: -3px;
                  right: -9px;
                }
  
                &.active {
                  border: 2px solid var(--primaryColor);
                }
  
                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
  
          span {
            display: block;
            font-size: var(--font-11);
            margin-bottom: 20px;
            color: var(--tertiaryGrayColor);
          }
  
          p {
            font-size: var(--font-13);
            margin-bottom: 0px;
            color: var(--secondaryGrayColor);
          }
        }
      }
    }
  }

  @media (max-width:575px) {
    margin-top: 20px;
  }
}

.education-dtl-page {
  .header-top {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 29px;

    .page-title {
      font-size: var(--font-20);
      font-weight: 100;
      color: var(--primaryColor);
      font-weight: 700;
      margin-bottom: 0;
      line-height: 21px;

      @media (max-width:575px) {
        font-size: 18px !important;
        text-align: center;
        width: 100%;
        padding-bottom: 0px;
        line-height: var(--font-13);
      }
    }

    .right-section {
      display: flex;
      align-items: center;

      .child-listing {
        display: flex;
        align-items: center;

        ul {
          padding: 0px;
          margin-bottom: 0px;
          margin-right: 24px;
          display: flex;

          li {
            list-style: none;
            margin-right: 10px;
            display: inline-block;
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
            background: #f4f5f5;
            text-align: center;
            border-radius: 24px;
            overflow: hidden;
            box-sizing: border-box;
            // border: 2px solid var(--primaryColor);


            &.active {
              border: 2px solid var(--primaryColor);
            }

            &:last-child {
              margin-right: 0px;
            }

            @media (max-width:1024px) {
              width: 38px;
              height: 38px;
              min-width: 38px;
              min-height: 38px;
            }

            @media (max-width:575px) {
              width: 32px;
              height: 32px;
              min-width: 32px;
              min-height: 32px;
              margin-right: 5px;
            }
          }

          @media (max-width:575px) {
            margin-right: 0px;
          }
        }

        .buttons {
          float: right;
          display: flex;
          align-items: center;

          span.assignEducationBtn {
            font-size: var(--font-15);
            line-height: 20px;
            color: var(--primaryColor);
            margin-right: 40px;
            cursor: pointer;
            font-weight: 500;
            letter-spacing: 0.3px;

            @media (max-width: 1024px) {
              font-size: var(--font-14);
            }

            @media (max-width: 575px) {
              display: none;
            }
          }

          button {
            background: transparent;
            color: var(--primaryColor) !important;
            padding: 0 !important;
            min-width: 0 !important;
            font-weight: 600;
          }

          @media (max-width:575px) {
            button {
              display: none;
            }
          }


        }

        @media (max-width:575px) {
          height: auto !important;
        }
      }
    }

    @media (max-width:575px) {
      z-index: 9;
      position: sticky;
      top: 0px;
      background: #fff;
      width: 100%;
      padding-bottom: 8px;
      padding-top: 22px;
      align-items: flex-start;
    }
  }

  .assigned-child {
    margin-bottom: 36px;
    margin-top: 20px;

    h3 {
      font-size: var(--font-16);
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 16px;
      letter-spacing: -0.02em;
      color: var(--primaryColor);
    }

    .child-listing {
      ul {
        padding: 0px;
        margin: 0px;

        li {
          list-style: none;
          display: inline-block;
          margin-right: 15px;
          width: 36px;
          height: 36px;
          text-align: center;
          line-height: 20px;
          background: #f4f5f5;
          border-radius: 25px;

          &.active {
            border: 2px solid var(--primaryColor);
          }
        }
      }
    }
  }

  .mainHeading {
    color: var(--primaryColor);
    font-size: var(--font-20);
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .header-img {
    margin-bottom: 30px;
    min-height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .dtl-content {
    p {
      font-size: var(--font-13);
      color: var(--secondaryGrayColor);
    }
  }

  .linkBtn {
    @media (max-width:575px) {
      display: none;
    }
  }

  .mob-update-assign-btn {
    display: none;
    margin: 90px auto 30px;
    text-align: center;

    @media (max-width:575px) {
      display: inline-block;
      width: 100%;
      text-align: center;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 0px 15px 60px;

      .linkBtn {
        display: block;
      }
    }
  }

  .deleteEducation {
    font-size: var(--font-18);
    line-height: 22px;
    font-weight: 500;
    text-align: center;
    margin-top: 50px;
  }
}

.createAssignment-wrapper {

  h1,
  .h1 {
    text-align: center;
    margin-bottom: 56px;
  }

  .subHeading {
    font-size: var(--font-14);
    line-height: 22px;
    color: #505d5f;
    margin-bottom: 40px;
    text-align: center;
  }

  .child-selection {
    margin-bottom: 60px;

    h5,
    .h5 {
      font-size: var(--font-16);
      line-height: 24px;
      margin-bottom: 16px;
      font-weight: 700;
      color: var(--primaryColor);
    }

    ul {
      padding: 0px;
      margin: 0px;

      li {
        list-style: none;
        display: inline-block;
        text-align: center;
        margin-right: 16px;
        cursor: pointer;
        margin-bottom: 10px;

        .image-content {
          width: 48px;
          height: 48px;
          background: #f4f5f5;
          border-radius: 24px;
          overflow: hidden;
          position: relative;
          margin: auto;

          @media (max-width: 480px) {
            width: 30px;
            height: 30px;
          }
        }

        p {
          font-size: var(--font-12);
          line-height: 16px;
          margin-top: 3px;
          margin-bottom: 0px;
          color: #4c5562;
          opacity: 0.7;
        }

        &.active {
          .image-content {
            border: 2px solid var(--primaryColor);
          }
        }
      }
    }
  }

  button {}
}

.add-education-wrapper {

  h1,
  .h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 30px;

    label {
      font-weight: 600;
      color: var(--primaryColor);
    }
  }

  input:disabled {
    background-color: transparent;
  }

  .child-list {
    margin-bottom: 30px;

    h3,
    .h3 {
      font-size: var(--font-16);
      line-height: 24px;
      margin-bottom: 15px;
      color: var(--primaryColor);
      font-weight: 600;
    }

    .child-listing {
      ul {
        padding: 0px;

        li {
          list-style: none;
          display: inline-block;
          margin-right: 15px;
          text-align: center;
          cursor: pointer;

          .imgBox {
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            background-color: #f4f5f5;
            overflow: hidden;
            border-radius: 24px;
          }

          p {
            font-size: var(--font-12);
            line-height: 16px;
            color: #4c5562;
            opacity: 0.7;
            margin-top: 3px;
            letter-spacing: 0.01em;
            text-align: center;
            margin-bottom: 0px;
          }

          &.active {
            border: none;

            .imgBox {
              border: 2px solid var(--primaryColor);
            }
          }
        }
      }
    }
  }

  .mazoola-provided-content {
    font-size: var(--font-12);
    line-height: 18px;
  }
}

//WALLET PAGE CSS START
.wallet-wrapper {
  .init-connectBank {
    width: 400px;
    margin: 70px auto;

    .thumbIcon {
      width: 100px;
      height: 100px;
      background: #ddd;
      border-radius: 100px;
      margin: 0px auto 30px;
    }

    .noChild-title {
      padding: 0px 50px;
      text-align: center;
      font-size: var(--font-18);
      margin-bottom: 30px;
    }
  }

  .dropdown-transaction {
    select {
      width: 180px;
      background-color: #f4f5f5;
      border: none;
      margin-right: 10px;
      font-size: var(--font-16);
      line-height: var(--font-19);
      font-weight: 600;
      height: var(--font-36);
      color: var(--primaryGrayColor);

      &:focus {
        box-shadow: none;
      }
    }
  }

  .new-transaction {
    position: absolute;
    right: 0;
    top: 10px;
  }

  .wallet-transactionSingle {
    display: flex;
    margin-bottom: 15px;

    .transactionIcon {
      width: 50px;
      min-width: 50px;

      span {
        width: 40px;
        height: 40px;
        background: var(--gray5);
        border-radius: 40px;
        display: inline-block;
      }

      span img {
        width: 40px;
        height: 40px;
        background: var(--gray5);
        border-radius: 40px;
        display: inline-block;
      }
    }

    .transactionDtl {
      width: 100%;

      h5,
      .h5 {
        color: var(--secondaryGrayColor);
        font-size: var(--font-14);
        margin-bottom: 0px;

        span {
          color: #505d5f;
        }
      }

      p {
        color: var(--gray5);
        font-size: var(--font-12);
        margin-bottom: 0px;
      }
    }

    .transactionAmt {
      color: #505d5f;
      font-size: var(--font-15);
      margin-bottom: 0px;
      font-weight: 600;
      width: 100px;
      text-align: right;
    }
  }
}

.page-parent-wallet {
  .heading-right-icon-list {
    display: none !important;
  }
}

.wallet-page {
  position: relative;

  .topLinks {
    text-align: right;
    position: absolute;
    top: 0px;
    right: 0;

    .transferBtn {
      background: var(--primaryColor);
      border: none;
      border-radius: var(--borderRadiusM);
      color: #fff !important;
      box-shadow: none;
      padding: 16px 42px;
      font-size: var(--font-18);
      line-height: 22px;
      font-weight: 500;
      letter-spacing: 0.5px;
      cursor: pointer;

      @media (max-width: 480px) {
        padding: 9px 25px;
        font-size: var(--font-13);
      }

      @media (max-width: 320px) {
        padding: 9px 15px;
      }
    }

    .selection-filter {
      select {
        width: 180px;
        background-color: #f4f5f5;
        border: none;
        margin-right: 10px;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);

        &:focus {
          box-shadow: none;
        }
      }
    }

    span.addFundLink {
      font-size: var(--font-15);
      line-height: 20px;
      color: var(--primaryColor);
      margin-right: 40px;
      font-weight: 500;
      letter-spacing: 0.3px;

      @media (max-width:767px) {
        margin-right: 0;
        padding-left: 15px;
      }
    }

    span.transferLink {
      font-size: var(--font-15);
      line-height: 20px;
      color: var(--primaryColor);
      margin-right: 40px;
      font-weight: 500;
      letter-spacing: 0.3px;

      @media (max-width:767px) {
        margin-right: 0;
      }
    }

    @media (max-width:767px) {
      justify-content: space-between;
      position: fixed;
      display: flex;
      width: 100%;
      top: 18px;
      z-index: 9;

      button {
        background: transparent;
        color: var(--primaryColor) !important;
        padding: 0 !important;
        font-size: 15px !important;
      }
    }
  }

  .bank-connection-expired {
    margin-bottom: 20px;
    position: relative;

    .icon-title {
      padding: 10px 30px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      border-radius: var(--borderRadiusM);
      background: var(--primaryColor);
      color: #fff;

      span.closeIcon {
        position: absolute;
        right: 10px;
        top: 8px;

        svg {
          cursor: pointer;
          width: 24px;

          @media (max-width:575px) {
            width: 22px;
          }
        }

        @media (max-width:425px) {
          right: -10px;
        }
      }

      span.expandable {
        position: absolute;
        left: -20px;
        top: -2px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;

        &:not(.collapsed) {
          .collapse {
            display: none;
          }
        }

        &.collapsed {
          .collapse {
            display: initial;
          }

          .expand {
            display: none;
          }
        }

        svg {
          cursor: pointer;
          width: var(--font-13);
          height: 7px;

          @media (max-width:1024px) {
            height: 6.5px;
          }
        }

        @media (max-width:1024px) {
          left: -10px;
          width: 18px;
          height: 18px;
        }

        @media (max-width:425px) {
          left: -10px;
        }
      }

      .icon {
        padding-right: 16px;

        img {
          width: 25px;
        }
      }

      .title-desc {
        width: 100%;

        h3,
        .h3 {
          font-size: var(--font-14);
          position: relative;
          font-weight: 600;
          text-align: center;
          margin-bottom: 10px;
          padding-top: 10px;


        }

        p {
          font-size: var(--font-14);
          margin: 0;
          line-height: 19px;
          text-align: center;

          @media (max-width:575px) {
            line-height: 16px;
            font-size: var(--font-12);
          }
        }
      }

      .before {}

      @media (max-width:425px) {
        padding: 20px 20px;
      }
    }

    .connect {
      text-align: center;
      font-weight: 500;
      padding: 2px 16px 5px 16px;
      font-size: var(--font-14);

      span {
        cursor: pointer;
        color: #fff;
      }
    }

    @media (max-width:480px) {
      margin-bottom: 15px;
    }
  }


  .wallet-wrapper-new {
    margin-bottom: 64px;

    .wallet-amount {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 42px;

      .total-amount {
        font-size: var(--font-40);
        line-height: 36px;
        letter-spacing: -0.03em;
        color: #000;
        font-weight: 100;
        margin-bottom: 10px;
        //font-family: "GothamXLight";
      }

      .total-balance {
        font-size: var(--font-13);
        line-height: 16px;
        letter-spacing: 0.01em;
        cursor: pointer;
        color: var(--tertiaryGrayColor);
      }

      @media (max-width:575px) {
        margin-top: 15px;
      }
    }

    .wallet-saving-stripe {
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 15px;

      a {
        width: calc(50% - 10px);

        .wallet-saving-stripe-single {
          // display: flex;
          // width: 100%;
          // align-items: center;
          // justify-content: space-between;
          // padding: 16px;
          // background: #fff;
          // border-radius: var(--borderRadiusL);
          // margin-bottom: 15px;
          // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding: 16px 16px;
          background: #fafafa;
          border-radius: var(--borderRadiusM);

          .label {
            display: flex;
            align-items: center;

            .icn {
              width: 32px;
              height: 32px;
              border-radius: 16px;
              background: #f4f5f5;
              text-align: center;
              line-height: 30px;
              margin-right: 12px;

              svg {
                width: 15px;
              }

              @media (max-width:575px) {
                width: 28px;
                height: 28px;
                line-height: 25px;

                svg {
                  width: 16px;
                }
              }
            }

            .title {
              vertical-align: middle;
              font-size: var(--font-16);
              line-height: 24px;
              color: var(--primaryColor);
              font-weight: 600;
              letter-spacing: -0.02em;

              @media (max-width:575px) {
                font-size: var(--font-15);

              }
            }
          }

          @media (max-width:575px) {
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.27);
            // margin-bottom: 25px;
            border-radius: 8px;
            padding: 14px 15px;
            background-color: #fff;
          }
        }

        &:last-child {
          .wallet-saving-stripe-single {
            margin-bottom: 0px;
          }
        }

        @media (max-width:575px) {
          width: 100%;
        }
      }

      @media (max-width:575px) {
        row-gap: 20px;
      }
    }

    @media (max-width:575px) {
      margin-bottom: 34px;
    }
  }

  @media (max-width:575px) {
    padding-top: 5px;
  }
}

.pendingTran-wrapper {
  margin-bottom: 32px;

  h2,
  .h2 {
    font-weight: 600;
    font-size: var(--font-20);
    line-height: 22px;
    color: var(--primaryColor);
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      letter-spacing: 0.01em;
      font-size: var(--font-12);
      line-height: 16px;
      font-weight: 600;
      color: var(--primaryColor);
      margin-left: auto;
    }

    .no-pending-timeline {
      font-size: var(--font-18);

      @media (max-width:575px) {
        font-size: var(--font-16);
      }
    }
  }

  .singlePendingTransaction {
    table {
      margin: 0px;

      tr {
        // border-bottom: 1px solid #f4f5f5;

        td {
          border: none !important;
          font-size: var(--font-13);
          vertical-align: middle;
          padding: 16px 0px;

          .avtar {
            width: 32px;
            height: 32px;
            text-align: center;
            line-height: 28px;
            overflow: hidden;
          }

          input[type="checkbox"] {
            width: 25px;
            height: 25px;
            outline: 1px auto #f27405;
          }

          h5,
          .h5 {
            font-size: var(--font-16);
            margin-bottom: 0px;
            font-weight: 300;
            line-height: 24px;
          }

          p {
            font-size: var(--font-12);
            margin-bottom: 0px;
            color: var(--tertiaryGrayColor);
            line-height: 16px;
            letter-spacing: 0.01em;
          }

          &:nth-child(2) {
            padding-left: 14px !important;
          }

          &:last-child {
            font-size: var(--font-16);
            line-height: 24px;
            color: var(--primaryColor);
          }

          &.positive {
            color: var(--tertiaryColor) !important;
          }

          &.negative {
            color: var(--errorColor);
          }
        }
      }
    }

    .noChild-addNewOne {
      margin-bottom: 40px;

      .noChild-title {
        text-align: center;
        font-size: var(--font-14);
        line-height: 22px;
        margin-top: 10px;
        color: var(--secondaryGrayColor);
        font-weight: 600;
      }

      @media (max-width:575px) {
        display: none;
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.recentTran-wrapper {
  margin-bottom: 32px;

  h2,
  .h2 {
    font-weight: 600;
    font-size: var(--font-20);
    line-height: 22px;
    color: var(--primaryColor);
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      letter-spacing: 0.01em;
      font-size: var(--font-12);
      line-height: 16px;
      font-weight: 600;
      color: var(--primaryColor);
      margin-left: auto;
    }

    .no-pending-timeline {
      font-size: var(--font-18);

      @media (max-width:575px) {
        font-size: var(--font-16);
      }
    }

    @media (max-width: 480px) {
      font-size: 14px !important;
    }
  }

  .noChild-addNewOne {
    // width: 238px;
    margin: 88px auto;

    .thumbIcon {
      margin: 0px auto 10px;
    }

    .noChild-title {
      text-align: center;
      font-size: var(--font-14);
      line-height: 22px;
      margin-bottom: 5px;
      color: var(--secondaryGrayColor);
      font-weight: 600;
    }

    .noChild-subtitle {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--tertiaryGrayColor);
      text-align: center;
    }
  }

  .singlePendingTransaction {
    .noHistory {
      margin: 50px auto;
      text-align: center;

      .thumbIcon {
        text-align: center;

        img {
          width: 140px;
        }
      }

      .noHistory-title {
        margin-top: 10px;
        font-size: var(--font-14);
        line-height: 22px;
        color: var(--secondaryGrayColor);
        font-weight: 600;
      }
    }

    table {
      margin: 0px;

      thead {
        tr {
          border: none;

          th {
            padding: 16px 0px 16px 8px;
            border: none !important;

            font-size: var(--font-16);
            font-weight: 300;
            line-height: 24px;

            img {
              width: 21px;
              margin-top: -3px;

              @media (max-width: 480px) {
                width: 14px;
              }
            }

            @media (max-width: 575px) {
              display: none;
            }

            &:nth-child(2) {
              padding-left: 14px;
            }
          }
        }
      }

      tbody {

        // border-bottom: 1px solid #f4f5f5;
        td {
          border: none !important;
          padding: 16px 0px;
          font-size: var(--font-13);
          vertical-align: middle;

          .avtar {
            width: 48px;
            height: 48px;
            min-width: 48px;
            min-height: 48px;
            background-color: #f4f5f5;
            border-radius: 24px;
            text-align: center;
            overflow: hidden;

            @media (max-width:575px) {
              width: 38px;
              height: 38px;
              min-width: 38px;
              min-height: 38px;
            }
          }

          .avtar-bank {
            width: 48px;
            height: 48px;
            // background-color: #f4f5f5;
            // border-radius: 16px;
            text-align: center;
            line-height: 48px;
            overflow: hidden;
            // border-radius: 24px;

          }

          input[type="checkbox"] {
            width: 25px;
            height: 25px;
            outline: 1px auto #f27405;
          }

          h5,
          .h5 {
            font-size: var(--font-14);
            margin-bottom: 0px;
            font-weight: 300;
            line-height: 24px;

            @media (max-width: 480px) {
              font-size: var(--font-14);
              line-height: 20px;
            }
          }

          p {
            font-size: var(--font-12);
            margin-bottom: 0px;
            color: var(--tertiaryGrayColor);
            line-height: 16px;
            letter-spacing: 0.01em;

            span.dispute-flag {
              padding-left: 25px;
              position: relative;

              &.childThemeColor {
                color: var(--primaryWardColor)
              }

              &.errorColor {
                color: var(--errorColor);
              }

              &.tertiaryColor {
                color: var(--primaryColor);
              }

              &::before {
                content: "";
                position: absolute;
                width: 4px;
                height: 4px;
                background: var(--tertiaryGrayColor);
                border-radius: 4px;
                left: 10px;
                top: 6px;
              }
            }
          }

          &:nth-child(2) {
            padding-left: 14px !important;

            @media (max-width:575px) {
              padding-right: 25px !important;
            }
          }

          &:last-child {
            font-size: var(--font-16);
            line-height: 24px;
            color: var(--primaryColor);

            @media (max-width: 480px) {
              font-size: var(--font-14);
              line-height: 20px;
            }
          }

          &.positive {
            color: var(--tertiaryColor);
            white-space: nowrap;
          }

          &.negative {
            color: var(--errorColor);
            white-space: nowrap;
          }

          @media (max-width:575px) {
            vertical-align: top;
            padding: 15px 0px;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.wallet-payrequest-wrapper {
  width: 600px;
  margin: 0px auto 50px auto;

  h1,
  .h1 {
    font-size: var(--font-30);
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;

    .goBack {
      float: left;
      font-size: var(--font-15);
      line-height: 41px;
    }
  }

  .enterAmount {
    margin-bottom: 30px;

    input {
      font-size: var(--font-35);
      font-weight: 400;
      border: none;
      color: #505d5f;
    }

    input::placeholder {
      color: var(--gray5);
      font-size: var(--font-35);
      font-weight: 400;
    }
  }

  .childListing {
    margin-bottom: 30px;

    h4,
    .h4 {
      font-size: var(--font-16);
      font-weight: 400;
      margin-bottom: 15px;
    }

    ul {
      padding: 0px;

      li {
        list-style: none;
        display: inline-block;
        margin-right: 15px;
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 45px;
        background: #f4f5f5;
        border-radius: 25px;

        &.active {
          border: 2px solid var(--primaryColor);
        }

        img {
          width: 50px;
          padding: 3px;
        }
      }
    }
  }

  .whatThis {
    margin-bottom: 30px;

    input {
      font-size: var(--font-16);
      font-weight: 400;
      color: #505d5f;
      border: none;

      &::placeholder {
        font-size: var(--font-16);
        font-weight: 400;
        color: var(--gray5);
      }
    }
  }

  .schedule {
    margin-bottom: 40px;

    h3,
    .h3 {
      font-size: var(--font-22);
      font-weight: 300;
      margin-bottom: 0px;

      .type {
        span {
          font-size: var(--font-18);
          margin-right: 10px;
          position: relative;

          &:first-child {
            margin-right: 20px;

            &::after {
              content: "";
              position: absolute;
              width: 4px;
              height: 4px;
              background: #505d5f;
              border-radius: 4px;
              top: 12px;
              right: -11px;
            }
          }
        }
      }
    }

    .once {
      margin-top: 30px;

      .react-datepicker {
        width: 100%;
        border: none;

        .react-datepicker__navigation--previous {
          right: 50px;
          left: auto;
        }

        .react-datepicker__month-container {
          width: 100%;

          .react-datepicker__header {
            background-color: #fff;
            border: none;

            .react-datepicker__current-month {
              margin-bottom: 20px;
            }

            .react-datepicker__day-names {
              display: flex;
              justify-content: space-around;

              .react-datepicker__day-name {
                font-weight: 300;
                font-size: var(--font-12);
                color: var(--tertiaryGrayColor);
                letter-spacing: 0.1em;
              }
            }
          }

          .react-datepicker__month {
            background-color: #fff;
            margin: 0px;

            .react-datepicker__week {
              display: flex;
              justify-content: space-around;
              font-size: var(--font-16);

              .react-datepicker__day {
                margin-bottom: 10px;
                font-family: 'GothamBook';
              }

              // .react-datepicker__day--today {
              //   background-color: var(--primaryColor);
              //   border-radius: 100%;
              //   color: #fff;
              // }

              .react-datepicker__day--keyboard-selected {
                background-color: transparent;
                color: #000;
              }

              .react-datepicker__day--selected {
                background-color: var(--primaryColor);
                border-radius: 100%;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .bankDtl {
    margin-bottom: 50px;

    .privateLabel {
      font-size: var(--font-13);
      text-align: right;
      margin-bottom: 5px;

      span {
        color: var(--primaryColor);
      }
    }

    .bankDetails {
      width: 270px;
      margin: auto;

      .inner {
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid var(--gray5);
        justify-content: center;
        border-radius: var(--borderRadiusM);

        .logo {
          img {
            width: 60px;
            margin-right: 20px;
          }
        }

        .bankAccount {
          span {
            display: block;
            margin-right: 10px;
            font-size: var(--font-14);

            &:first-child {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .ftrBtn {
    button {
      min-width: 200px;
    }
  }
}

//STATEMENT SCREEN CSS START
.page-parent-statement {
  .dashboard-heading {
    display: block !important;
    text-align: center;
  }
}

.statementWrapper {
  position: relative;

  .routeBack-flat {
    position: absolute;
    top: -57px;
    margin-bottom: 0px;

    @media (max-width:575px) {
      position: fixed;
      top: 22px;
      z-index: 99;
      margin-bottom: 0;
    }
  }

  .goBack {
    font-size: var(--font-18);
    line-height: 22px;
    font-weight: 500;
    color: var(--primaryGrayColor);
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    cursor: pointer;
  }

  .label {
    font-size: var(--font-14);
    line-height: 22px;
    color: var(--secondaryGrayColor);
    font-weight: 500;
    margin-bottom: 15px;
  }

  .statementHeader {
    display: flex;
    align-items: center;
    padding: 16px 0px 16px 0px;
    font-size: var(--font-14);
    line-height: 22px;
    font-weight: 500;
    border: none !important;

    .sDate {
      width: 200px;
      min-width: 200px;
      margin-right: 50px;
    }

    .eDate {
      width: 200px;
      min-width: 200px;
      margin-right: 50px;
    }

    .pdf {
      text-align: right;
      width: 100%;
    }
  }

  h3.yearName {
    color: var(--tertiaryGrayColor);
    font-size: var(--font-13);
    background-color: #f4f5f5;
    padding: 7px 7px;
    margin-top: 0px;
    line-height: 1.2;

    @media (max-width:575px) {
      padding: 9px 15px;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 0;
    }
  }

  .statementCardWrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    justify-content: space-between;
    margin-bottom: 20px;

    .statementSingle {
      display: flex;
      align-items: center;
      padding: 16px;
      border-radius: var(--borderRadiusXL);
      margin-bottom: 30px;
      box-shadow: 0px 4px 8px #d1dede69;
      width: calc(33% - 8px);

      .sDate {
        width: 200px;
        min-width: 200px;
        font-size: var(--font-16);
        margin-bottom: 0px;
        color: var(--primaryColor);
        line-height: 24px;
        letter-spacing: 0.01em;
        white-space: nowrap;
        font-weight: 500;
      }

      .eDate {
        width: 200px;
        min-width: 200px;
        font-size: var(--font-12);
        margin-bottom: 0px;
        color: var(--tertiaryGrayColor);
        line-height: 16px;
        letter-spacing: 0.01em;
        margin-right: 50px;
        white-space: nowrap;
      }

      .pdf {
        width: 100%;
        text-align: right;

        img {
          margin-top: -3px;
        }
      }

      h4,
      .h4 {
        color: var(--primaryColor);
        margin: 0px;
        font-size: var(--font-14);
        line-height: 22px;
      }

      @media (max-width:1024px) {
        .sDate {
          width: auto;
          min-width: auto;
        }
      }

      @media (max-width:991px) {
        width: calc(50% - 8px);
      }

      @media (max-width:575px) {
        margin-bottom: 20px;
      }

      @media (max-width:480px) {
        width: 100%;
        box-shadow: 0px 4px 12px #d1dedead;
      }
    }

    @media (max-width:575px) {
      padding: 10px 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  @media (max-width:575px) {
    margin-top: 20px;
  }
}

//RECEIPTS SCREEN CSS START
.page-parent-receipts {
  .dashboard-heading {
    display: block !important;
    text-align: center;
  }
}


.receiptsWrapper {
  position: relative;

  .routeBack-flat {
    position: absolute;
    top: -57px;
    margin-bottom: 0px;

    @media (max-width:575px) {
      position: fixed;
      top: 22px;
      left: 15px;
      z-index: 99;
      margin-bottom: 0;
    }
  }

  .receiptHeader {
    display: flex;
    padding: 0px 0px 16px 2px;
    align-items: center;
    font-size: var(--font-16);
    line-height: 24px;

    .date {
      width: 110px;
      font-weight: 500;
    }

    .type {
      width: 100%;
      text-align: center;
      font-weight: 500;
    }

    .amount {
      width: 100px;
      font-weight: 500;
      font-size: var(--font-16);
      line-height: 22px;
      text-align: right;
    }

    @media (max-width: 480px) {
      padding: 10px 0px 10px 2px;

      .amount {
        font-size: var(--font-14);
      }
    }
  }

  .receiptSingle {
    display: flex;
    justify-content: space-between;
    padding: 15px 12px;
    align-items: center;
    font-size: var(--font-14);
    line-height: 22px;
    border-bottom: 1px solid #f4f5f5;
    cursor: pointer;
    color: #a8aeae;

    .date {
      font-weight: 500;
      color: var(--primaryColor);
    }

    .right-angle {
      img {
        width: 8px;
      }
    }

    .amount {
      width: 100px;
      font-weight: 500;
      font-size: var(--font-16);
      line-height: 22px;
      text-align: right;
      color: var(--primaryColor);
    }

    &:last-child {
      border-bottom: none;
    }

    @media (max-width: 575px) {
      padding: 10px 15px;
      font-size: var(--font-13);

      .date {
        font-weight: 400;
        white-space: nowrap;
      }

      .amount {
        font-size: var(--font-14);
      }
    }
  }

  .month-wrapper {
    .month-name {
      margin-top: 0px;
      color: var(--secondaryGrayColor);
      font-size: var(--font-13);
      background-color: #f4f5f5;
      padding: 9px 12px;
      margin-bottom: 0;

      @media (max-width:575px) {
        padding: 9px 15px;
      }
    }

    .transaction-listing {
      .table {
        margin: 0px;

        tbody {
          tr {
            border-bottom: 1px solid #f4f5f5;

            td {
              border: none;
              padding: 16px 0px;
              vertical-align: middle;
              font-size: var(--font-14);
              font-weight: 300;

              h5,
              .h5 {
                font-size: var(--font-16);
                margin-bottom: 0px;
                font-weight: 300;
                line-height: 24px;

                @media (max-width: 480px) {
                  font-size: var(--font-14);
                  line-height: 20px;
                }
              }

              p {
                font-size: var(--font-12);
                margin-bottom: 0px;
                color: var(--tertiaryGrayColor);
                line-height: 16px;
                letter-spacing: 0.01em;
              }

              .avtar {
                width: 48px;
                height: 48px;
                min-width: 48px;
                min-height: 48px;
                background-color: #f4f5f5;
                border-radius: 24px;
                overflow: hidden;
              }

              &:last-child {
                font-size: var(--font-16);
                line-height: 24px;
                color: var(--primaryColor);
                font-weight: normal;

                @media (max-width: 480px) {
                  font-size: var(--font-14);
                  line-height: 20px;
                }
              }

              &:nth-child(2) {
                font-weight: normal;
                padding-left: var(--font-14);
              }

              &.negative {
                color: var(--errorColor);
              }

              &.positive {
                color: var(--primaryColor);
              }

              @media (max-width: 480px) {
                padding: 10px 0px;
              }
            }
          }
        }
      }
    }
  }

  .noChild-addNewOne {
    width: 238px;
    margin: 88px auto;

    .thumbIcon {
      margin: 0px auto 34px;
    }

    .noChild-title {
      text-align: center;
      font-size: var(--font-14);
      line-height: 22px;
      margin-bottom: 5px;
      color: var(--secondaryGrayColor);
      font-weight: 500;
    }

    .noChild-subtitle {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--tertiaryGrayColor);
      text-align: center;
    }
  }

  @media (max-width:575px) {
    margin-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

//SENIOR STOCK DETAILS MODAL
.stock-details-modal{

  .routeBack-abs{
    top: 10px;
    left: 30px;
  }
  .investment-detail-stock-amount {
    padding: 16px;
    background-color: #f4f5f5;
    border-radius: var(--borderRadiusM);

    .stock-amount-grp {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #F4F5F5;

      .stock-name {
        display: flex;
        align-items: center;
        width: 100%;

        .portfolio-logo {
          width: 40px;
          height: 40px;
          // background: #F4F5F5;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;

          img {
            width: 40px;
            // filter: grayscale(1);
          }
        }

        .portfolio-details {
          width: 100%;

          .first-row {
            margin-bottom: 2px;

            .company {
              font-size: var(--font-14);
              line-height: 18px;
              letter-spacing: -0.02em;
              color: #243537;
              overflow: hidden;
              font-weight: 700;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }

          .bottom-row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .symbol {
              font-weight: 500;
              font-size: var(--font-12);
              line-height: 15px;
              letter-spacing: -0.02em;
              color: $black-1;
              margin-bottom: 0px;
              white-space: nowrap;
              text-transform: uppercase;

              span.watchlist-indicator {
                text-transform: initial;
              }
            }

            .amount {
              font-weight: 600;
              font-size: var(--font-14);
              line-height: 21px;
              letter-spacing: -0.02em;
              color: var(--primaryColor);
            }


          }
        }
      }
    }

    .stock-stats {

      .stock-stats-row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .stats-single {
          width: 100%;
          max-width: 140px;

          label {
            font-size: var(--font-13);
            line-height: 24px;
            letter-spacing: -0.02em;
            color: var(--tertiaryGrayColor);
            margin: 0px;
          }

          .amount {
            font-weight: 600;
            font-size: var(--font-14);
            line-height: 24px;
            letter-spacing: -0.02em;
            color: #000;

            .profitLossPercentage {
              font-size: var(--font-12);
              display: inline-block;
              margin-left: 4px;

              img {
                width: 6px;
                vertical-align: initial;

                &.rotate180 {
                  transform: rotate(180deg);
                  vertical-align: middle;
                }
              }

              &.positive {
                color: var(--tertiaryColor);
              }

              &.negative {
                color: var(--errorColor);
              }
            }


          }
        }

        &:nth-child(2) {
          margin-top: 6px;
        }
      }
    }
  }

  .graph-section{
    display:flex;
    justify-content : space-between;
    margin-top: 50px;
    .billing-summary{
      .amount{
          font-size: var(--font-20);
          font-weight: 700;
          display: flex;
          align-items: center;
          column-gap: 10px;
          font-family: 'GothamBold';
          span.growth{
              font-weight: 500;
              font-size: var(--font-12);
              img{
                  margin-left: 5px;
              }
          }
      }
      .label{
          font-size: var(--font-12);
      }
    }
    .filter-selection{
      select {
        width: 190px;
        min-width: 190px;
        background-color: #f4f5f5;
        border: none;
        font-size: var(--font-14);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);
        border-radius: var(--borderRadiusM);
    }
    }
  }

  .chart-view{
    margin-top:30px;
  }
}

//SENIOR SPENDING CATEOGORIES MODAL
.spending-categories-modal{
  .chart-view{
    margin-top: 30px;
    .chart{
      height: 220px;
      width: 220px;
      position: relative;
      margin:auto;

      canvas{
        width:220px !important;
        height:220px !important;
      }

      @media (max-width:768px){
          margin:0px auto 30px;
      }
    }
    .chart-inner-content{
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .amount{
          font-size: var(--font-16);
          // font-weight: 600;
          font-family: 'GothamBold';
      }
      .label{
          font-size: var(--font-12);
          font-weight: 600;
      }
  }
  }
  .categories-wrapper{
    margin-top: 50px;
    .stats-column {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 15px;
      .single-stat {
          display: flex;
          align-items: start;

          .dot{
              width:10px;
              height:10px;
              min-width:10px;
              min-height:10px;
              border-radius: 5px;
              margin-right: 5px;
              margin-top: 6px;
          }

          .content-box {
              .title {
                  font-size: var(--font-12);
                  line-height: 24px;
                  color: var(--black);
                  font-weight: 600;

                  @media (max-width:575px){
                      font-size: var(--font-12);
                  }
              }

              .amount {
                  font-size: var(--font-11);
                  font-weight: 600;
              }
          }

          &:nth-child(1) {
              .dot {
                background-color: #f27405;
              }
          }

          &:nth-child(2) {
              .dot {
                  background-color: var(--primaryColor);
              }
          }

          &:nth-child(3){
              .dot {
                  background-color: #f24405;
              }
          }

          &:nth-child(4){
              .dot {
                  background-color: var(--tertiaryColor);
              }
          }

          &:nth-child(5) {
              .dot {
                  background-color: var(--secondaryGrayColor);
              }
          }

          &:nth-child(6){
              .dot {
                  background-color: var(--gray5);
              }
          }
          &:nth-child(7){
            .dot {
                background-color: #7f5493;
            }
          }
          &:nth-child(8){
            .dot {
                background-color: #43547f;
            }
          }
          &:nth-child(9){
            .dot {
                background-color: #c14986;
            }
          }
          &:nth-child(10){
            .dot {
                background-color: #ffe9d1;
            }
          }
          &:nth-child(11){
            .dot {
                background-color: #895a3c;
            }
          }
          &:nth-child(12){
            .dot {
                background-color: #640000;
            }
          }
          &:nth-child(13){
            .dot {
                background-color: #ffbfc0;
            }
          }
          &:nth-child(14){
            .dot {
                background-color: #ff878a;
            }
          }
          &:nth-child(15){
            .dot {
                background-color: #2c9979;
            }
          }
          &:nth-child(16){
            .dot {
                background-color: #4c709a;
            }
          }
          &:nth-child(17){
            .dot {
                background-color: #1d627c;
            }
          }
          &:nth-child(18){
            .dot {
                background-color: #befbff;
            }
          }
          &:nth-child(19){
            .dot {
                background-color: #00a9c6;
            }
          }
          &:nth-child(20){
            .dot {
                background-color: #324b4e;
            }
          }
          &:nth-child(21){
            .dot {
                background-color: #95b0b4;
            }
          }
          &:nth-child(22){
            .dot {
                background-color: #00c8eb;
            }
          }
          &:nth-child(23){
            .dot {
                background-color: #7dbcc4;
            }
          }
          &:nth-child(24){
            .dot {
                background-color: #33737b;
            }
          }
          &:nth-child(25){
            .dot {
                background-color: #e61c5b;
            }
          }
          &:nth-child(26){
            .dot {
                background-color: #b73cc8;
            }
          }
          &:nth-child(27){
            .dot {
                background-color: #ebaa93;
            }
          }
          &:nth-child(28){
            .dot {
                background-color: #ff8597;
            }
          }
          &:nth-child(29){
            .dot {
                background-color: #ffbdcd;
            }
          }
          &:nth-child(30){
            .dot {
                background-color: #0080ff;
            }
          }

          &:last-child{
              margin-bottom: 0;
          }
      }
  }
  }
}

.seniorCommonTable{
    width: 100%;
    tr{
        th{
            padding: 16px 0px 0;
            border: none !important;
            font-size: var(--font-14);
            font-weight: 600;
            line-height: 24px;
        }
        td{
            border: none !important;
            font-size: var(--font-13);
            vertical-align: middle;
            padding: 16px 0px;
        }
    }
}

//SENIOR SPENDINGL LIMIT MODAL
.senior-spending-modal{
  .add-category{
    font-size:var(--font-12);
    color:var(--secondaryGrayColor)
  }
  .category-listing{
    margin-top: 30px;
    .single-category{
      margin-bottom: 20px;

      .first-row{
        display: flex;
        align-items: center;

        .icon{
          width: 39px;
          max-width: 39px;
          min-width: 39px;
          height: 39px;
          max-height: 39px;
          min-height: 39px;
          margin-right: 16px;
          border-radius: 100%;
          background-color: #f27405;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .title{
          font-size: var(--font-14);
          font-weight: 600;
        }
        .checkbox{
          margin-left: auto;
        }
      }

      .frequence-row{
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        margin-bottom: 8px;
        padding-left: 55px;
        select{
          border: none;
          width: auto;
          font-size: var(--font-12);
          padding: 0;
          min-width: 100px;
          box-shadow: none;
          color:var(--primaryColor);
        }
        .value-field{
          input{
            width:120px;
            font-size: var(--font-12);
            padding: 0;
            height: auto;
          }
        }
      }

      .retailers-list{
        padding-left: 55px;

        .hide-merchant-limit{
          color:var(--primaryColor);
          font-size:var(--font-12);
          margin-bottom: 12px;
          text-decoration: underline;
        }
        .single-retailer{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          .retailer-name{
            font-size: var(--font-12);
            color:var(--primaryColor);
          }
          .value-field{
            input{
              width:120px;
              font-size: var(--font-12);
              padding: 0;
              height: auto;
            }
          }
        }
      }

      .add-merchant{
        font-size:var(--font-12);
        color:var(--secondaryGrayColor);
        margin-top:20px;
        padding-left:55px;
      }

      &:nth-child(1) {
        .first-row{
            .icon {
                background-color: #f27405;
              }
        }
      }

      &:nth-child(2) {
          .first-row{
              .icon {
                  background-color: var(--primaryColor);
              }
          }
      }

      &:nth-child(3) {
          .first-row{
              .icon {
                  background-color: #f24405;
              }
          }
      }

      &:nth-child(4) {
          .first-row{
              .icon {
                  background-color: var(--tertiaryColor);
              }   
          }
      }

      &:nth-child(5) {
          .first-row{
              .icon {
                  background-color: var(--secondaryGrayColor);
              }   
          }
      }

      &:nth-child(6) {
          .first-row{
              .icon {
                  background-color: var(--gray5);
              }   
          }
      }
    }
  }
}

//SENIOR CAREGIVER EDIT MODAL
.seniorCaregiverEdit{
  .form-box {
    .form-group {
      margin-bottom: 30px;

      select{
        border-radius: 0;
        font-size: var(--font-16);
        line-height: 19px;
        border: none;
        border-radius: 0px;
        padding: 10px 12px;
        color: var(--primaryGrayColor);
        border-radius: var(--borderRadiusM);
        background-color: #f4f5f5;
        font-weight: 500;
      }
    }
  }
} 

//PORTFOLIO legends view modal
.legend-details-view-modal{
  .top-section{
    display:flex;
    justify-content : space-between;
    .billing-summary{
      .amount{
          font-size: var(--font-20);
          font-weight: 700;
          display: flex;
          align-items: center;
          column-gap: 10px;
          font-family: 'GothamBold';
          span.growth{
              font-weight: 500;
              font-size: var(--font-12);
              img{
                  margin-left: 5px;
              }
          }
      }
      .label{
          font-size: var(--font-12);
      }
    }
    .filter-selection{
      select {
        width: 190px;
        min-width: 190px;
        background-color: #f4f5f5;
        border: none;
        font-size: var(--font-14);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        color: var(--primaryGrayColor);
        border-radius: var(--borderRadiusM);
    }
    }
  }

  .chart-view{
    margin-top: 30px;
  }

  .stock-cards-wrapper{
    margin-top: 50px;
    .stock-card {
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
      border-radius: var(--borderRadiusL);
      display: flex;
      align-items: center;
      padding: 14px;
      background-color: #fff;
      margin-bottom: 12px;
      cursor: pointer;

      .portfolio-logo {
        width: 40px;
        height: 40px;
        min-height: 37px;
        min-width: 37px;
        // background: #F4F5F5;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 11px;

        img {
          // width: 15px;
          // filter: grayscale(1);
        }
      }

      .portfolio-details {
        width: 100%;

        .first-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2px;

          .company {
            font-size: var(--font-14);
            line-height: 18px;
            letter-spacing: -0.02em;
            color: #243537;
            overflow: hidden;
            font-weight: 700;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;

            @media (max-width:480px) {
              font-size: var(--font-14);
            }
          }

          .amount {
            font-weight: 600;
            font-size: var(--font-14);
            line-height: 18px;
            letter-spacing: -0.02em;
            color: var(--tertiaryColor);
            padding-left: 15px;

            @media (max-width:480px) {
              font-size: var(--font-14);
            }
          }
        }

        .bottom-row {
          display: flex;
          align-items: center;

          .symbol {
            font-weight: 500;
            font-size: var(--font-12);
            line-height: 15px;
            letter-spacing: -0.02em;
            color: $black-1;
            margin-bottom: 0px;
            white-space: nowrap;
            text-transform: uppercase;

            span.watchlist-indicator {
              text-transform: initial;
            }

            @media (max-width:480px) {
              line-height: 21px;
            }
          }

          .graph-box {
            position: relative;
            margin-left: auto;
            margin-right: 80px;

            canvas {
              width: 90px !important;
              height: 24px !important;

              @media (max-width:480px) {
                height: 21px !important;
              }
            }

            .line-stroke {
              width: 100%;
              position: absolute;
              top: calc(50% - 1px);
            }

            @media (max-width:480px) {
              margin-right: 30px;
            }
          }

          .profitLossPercent {
            font-weight: 500;
            font-size: var(--font-13);
            line-height: 15px;
            letter-spacing: -0.02em;
            margin-bottom: 0;
            text-align: right;

            &.positive {
              color: var(--tertiaryColor);
            }

            &.negative {
              color: var(--errorColor);
            }

            @media (max-width:480px) {
              line-height: 21px;
            }
          }
        }
      }

      .portfolio-name {
        margin-right: 10px;
        width: calc(100% - 257px);

        h3,
        .h3 {
          font-size: var(--font-16);
          line-height: 24px;
          letter-spacing: -0.02em;
          color: #243537;
          overflow: hidden;
          margin-bottom: 2px;
          font-weight: 700;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;

          @media (max-width:320px) {
            max-width: 70px;
          }
        }

        p {
          font-weight: 500;
          font-size: var(--font-13);
          line-height: 24px;
          letter-spacing: -0.02em;
          color: var(--tertiaryColor);
          margin-bottom: 0px;
          white-space: nowrap;
          text-transform: uppercase;
        }
      }

      .portfolio-graph {
        margin-left: auto;
        margin-right: auto;
        width: 120px;

        .d1 {
          canvas {
            width: 120px !important;
            height: 44px !important;
          }
        }
      }

      .portfolio-amount {
        margin-left: auto;
        margin-right: 10px;
        width: auto;
        text-align: right;
        max-width: 150px;
        min-width: 120px;

        h3,
        .h3 {
          font-weight: 600;
          font-size: var(--font-16);
          line-height: 24px;
          text-align: right;
          letter-spacing: -0.02em;
          color: var(--tertiaryColor);
          margin-bottom: 2px;
        }

        p {
          font-weight: 500;
          font-size: var(--font-13);
          line-height: 24px;
          letter-spacing: -0.02em;
          margin-bottom: 0;
          text-align: right;

          &.positive {
            color: var(--primaryColor);
          }

          &.negative {
            color: var(--errorColor);
          }
        }

        @media (max-width:480px) {
          margin: 0px 10px;
          margin-left: auto;
        }
      }

      .portfolio-buy {
        @media (max-width:480px) {
          margin-left: 0px;
        }
      }
    }
  }
}


//ADD ACCOUNT SENIOR MODAL
.add-account-modal{
  .card-graphic{
    margin-top: 70px;
    text-align: center;
  }
  .addaccount-content{
    font-size: var(--font-14);
    margin-top:30px;
  }
  .addaccount-richcontent{
    padding:21px 16px;
    border-radius: var(--borderRadiusM);
    background-color: #f4f5f5;
    margin-top: 30px;
    .content-row{
      font-size:var(--font-14);
      font-weight: 600;
      color:var(--primaryColor);
      margin-bottom: 10px;

      svg{
        width:21px;
        margin-right: 20px;
      }

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .bottom-text{
    font-size: var(--font-14);
    margin-top:30px;
    margin-bottom: 30px;
  }
}

//TIMELINE DISPUTE MODAL CSS
.dispute-status-modal {
  .status-rows {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0px;
    border-bottom: 1px solid $grey-2;

    .title {
      font-size: var(--font-14);
      line-height: 24px;
    }

    .value {
      font-size: var(--font-14);
      line-height: 24px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

//TIMELINE AMOUNT INFO MODAL CSS


//SCHEDULE TRANSFER SCREEN CSS
.page-parent-scheduledTransfers {
  .dashboard-heading {
    text-align: center;
    display: block !important;
  }
}

.scheduleTransfer {
  position: relative;

  .routeBack-flat {
    position: absolute;
    top: -57px;
    margin-bottom: 0;

    @media (max-width:575px) {
      position: fixed;
      top: 22px;
      z-index: 99;
      margin-bottom: 0;
    }
  }

  .scheduledTransfers {
    .singleSchedule {
      display: flex;
      padding: 16px 0px;
      align-items: center;

      .icn {
        width: 48px;
        height: 48px;
        min-width: 48px;
        min-height: 48px;
        background-color: #f4f5f5;
        border-radius: 24px;
        margin-right: var(--font-14);

        @media (max-width:575px) {
          width: 28px;
          height: 28px;
          min-width: 28px;
          min-height: 28px;
        }
      }

      .transferDtl {
        width: 100%;

        h5,
        .h5 {
          font-size: var(--font-16);
          margin-bottom: 0px;
          font-weight: 300;
          line-height: 24px;

          @media (max-width: 480px) {
            font-size: var(--font-14);
            line-height: 20px;
          }
        }

        h3,
        .h3 {
          font-size: var(--font-16);
          margin-bottom: 0px;
          font-weight: 500;
          line-height: 24px;
          min-height: 24px;
        }

        p {
          font-size: var(--font-12);
          margin-bottom: 0px;
          color: var(--tertiaryGrayColor);
          line-height: 16px;
          letter-spacing: 0.01em;

          &:nth-child(2) {
            margin-top: 1px;
          }
        }
      }

      .amount {
        font-size: var(--font-16);
        line-height: 24px;
        white-space: nowrap;

        &.positive {
          color: var(--primaryColor);
        }

        &.negative {
          color: var(--errorColor);
        }

        @media (max-width: 480px) {
          font-size: var(--font-14);
          line-height: 20px;
        }
      }

      &:first-child {
        padding-top: 0px;
      }

      @media (max-width: 575px) {
        padding: 10px 0px;
        align-items: flex-start;
        border-bottom: 1px solid #f4f5f5;
      }
    }

    .noChild-addNewOne {
      width: 238px;
      margin: 88px auto;

      .thumbIcon {
        margin: 0px auto 34px;
      }

      .noChild-title {
        text-align: center;
        font-size: var(--font-14);
        line-height: 22px;
        margin-bottom: 5px;
        color: var(--secondaryGrayColor);
        font-weight: 500;
      }

      .noChild-subtitle {
        font-size: var(--font-14);
        line-height: 22px;
        color: var(--tertiaryGrayColor);
        text-align: center;
      }
    }

    @media (max-width: 480px) {
      margin-top: 20px;
    }
  }
}

//FINICITY AGREEMENT MODAL
.finicityAgreementWrapper {
  height: auto;
  min-height: 720px;

  h1,
  .h1 {
    margin-bottom: 30px;
  }

  p {
    font-size: var(--font-14);
    line-height: 22px;
  }
}

.legendsInfo {

  h1,
  .h1 {
    margin-bottom: 40px;
  }

  .legendsBox {
    .singleLegend {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .icn {
        text-align: center;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 7px;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 3px;

        img {
          width: 12px;
        }
      }

      &:nth-child(1) {
        .icn {
          background-color: var(--tertiaryColor);
        }
      }

      &:nth-child(2) {
        .icn {
          background-color: transparent;
          border: 1px solid var(--primaryColor);
          // background-color: var(--errorColor);
        }
      }

      &:nth-child(3) {
        .icn {
          background-color: var(--errorColor);
          // background-color: #f2c94c;
        }
      }

      &:nth-child(4) {
        .icn {
          background-color: $grey-3;
        }
      }

      &:nth-child(5) {
        .icn {
          background-color: transparent;
          border: 1px solid $yellow;
        }
      }

      &:nth-child(6) {
        .icn {
          background-color: $yellow;
        }
      }


    }
  }
}

//SETTING PAGE CSS START
#childSetting {}

.addNewAddress {

  h1,
  .h1 {
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 30px;
  }

  .states-list {

    select {
      border-radius: 0;
      font-size: var(--font-16);
      line-height: 24px;
      background-color: transparent;
      padding: 6px 0px;
      height: 40px;
      border: none;
      border-bottom: 1px solid var(--primaryGrayColor);
      color: var(--primaryGrayColor);
      border-radius: 0px;

      &:disabled {
        opacity: 0.7;
      }
    }
  }

  .defaultAddress {

    h3,
    .h3 {
      font-size: var(--font-19);
      color: var(--primaryColor);
    }
  }
}

.children-setting-wrapper {
  .dashboard-family-tabs {
    .pgHeading {
      .routeBack-invest-abs {
        top: 0px;
        z-index: 9;

        @media (max-width:575px) {
          top: 22px;
          margin-left: 0;
          left: 15px;
        }
      }

      .shield-icon {
        position: absolute;
        top: 8px;
        left: 65px;

        @media (max-width:575px) {
          top: 17px;
        }
      }

      .title {
        // display: inline-block;
        // position: absolute;
        // left: 50%;
        // transform: translate(-50%, 0);
        cursor: pointer;
        line-height: 21px;
        display: flex;
        justify-content: center;

        &.dropdown {
          .dropdown-toggle {
            display: flex;
            align-items: center;
            justify-content: center;

            .dropdown-angle {
              display: flex;

              img {
                transition: all 0.5s;
                transform: rotate(90deg);
              }
            }

            &::after {
              display: none;
            }
          }

          &.show {
            .dropdown-toggle {
              .dropdown-angle {
                img {
                  transform: rotate(270deg);
                }
              }
            }
          }
        }

        ul {
          background-color: #f4f5f5;

          li {
            padding: 5px 10px;
            font-weight: 500;
            font-size: var(--font-14);
          }
        }
      }

      .child-setting-top-mobile-links {
        position: fixed;
        top: 10px;
        right: 15px;
        font-size: var(--font-14);
        font-weight: normal;
        display: none;


        @media (max-width:575px) {
          display: initial;
          top: 15px
        }
      }

      @media (max-width:575px) {
        margin-left: -15px;
        width: calc(100% + 30px);
        padding-bottom: 20px !important;
        padding-top: var(--font-14);
        position: sticky;
        top: 0px;
        background: #fff;
        z-index: 9;
        margin-bottom: 0;

        .title {
          font-size: var(--font-18);
          transform: initial;
          line-height: 30px;
          left: 50px;
          width: calc(100% - 100px);
        }
      }
    }

    @media (max-width:575px) {
      .tab-content {
        margin-top: 0px !important;
      }
    }
  }

  .goBack {
    font-size: var(--font-18);
    color: var(--primaryGrayColor);
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 31px;
  }

  h1.mainHeading {
    text-align: center;
    margin-bottom: 30px;
  }

  .tab-content {
    margin-top: 31px;
  }

  .vertical-family-tabs {
    display: flex;

    .nav-tabs {
      border: none;
      padding-bottom: 30px;
      width: 300px;
      flex-wrap: wrap;
      align-items: center;
      display: initial;

      li {
        margin-right: 30px;
        width: 100%;

        a {
          border: none;
          font-size: var(--font-15);
          line-height: 22px;
          font-weight: 600;
          padding: 12px 0px;
          color: #505d5f !important;

          &.active {
            color: var(--primaryColor) !important;
          }
        }

        &:first-child {
          a {
            padding-top: 0px;
          }
        }
      }
    }

    .tab-content {
      width: 100%;

      .noChild-addNewOne {
        width: 238px;
        margin: 88px auto;

        .thumbIcon {
          margin: 0px auto 34px;
        }

        .noChild-title {
          text-align: center;
          font-size: var(--font-14);
          line-height: 22px;
          margin-bottom: 5px;
          color: var(--secondaryGrayColor);
          font-weight: 500;
        }

        .noChild-subtitle {
          font-size: var(--font-14);
          line-height: 22px;
          color: var(--tertiaryGrayColor);
          text-align: center;
        }
      }

      .timeFrames {
        select {
          width: 180px;
          background-color: #f4f5f5;
          border: none;
          margin-right: 10px;
          font-size: var(--font-16);
          line-height: var(--font-19);
          height: var(--font-36);
          color: var(--primaryGrayColor);
          font-weight: 600;

          &:focus {
            box-shadow: none;
          }
        }

        .week-display {
          font-size: var(--font-13);
          font-weight: 600;
          height: 34px;
          line-height: 34px;
        }
      }
    }
  }

  .refer-friend-container {
    max-width: 500px;
    margin: 31px auto 0px;

    .refer-earn {
      font-size: var(--font-20);
      text-align: center;
      font-weight: 700;
      color: var(--primaryColor);
    }

    .refer-desc {
      color: #000;
      font-size: var(--font-17);
      margin-top: 25px;
      text-align: center;
      margin-bottom: 50px;

      @media (max-width:480px) {
        font-size: var(--font-13);
        margin-top: 15px;
        margin-bottom: 30px;
      }
    }

    .refer-simple {
      font-size: var(--font-26);
      text-align: center;
      font-weight: 700;
      margin-bottom: 15px;
      color: var(--primaryColor);

      @media (max-width:480px) {
        font-size: var(--font-19);
      }
    }

    ol {
      padding-left: 15px;

      li {
        font-size: var(--font-15);
        color: #000;
        line-height: 20px;
        margin-bottom: 10px;

        @media (max-width:480px) {
          font-size: var(--font-14);
          margin-bottom: 5px;
          padding-left: 7px;
        }
      }
    }

    .refer-rocks {
      font-weight: 700;
      text-align: center;
      margin-top: 50px;
      font-size: var(--font-26);
      margin-bottom: 5px;
      color: var(--primaryColor);

      @media (max-width:480px) {
        font-size: var(--font-19);
        margin-top: 30px;
      }
    }

    .refer-help {
      font-weight: 700;
      text-align: center;
      font-size: var(--font-20);
      margin-bottom: 10px;
      color: var(--primaryColor);

      @media (max-width:480px) {
        font-size: var(--font-15);
      }
    }

    .refer-btn {
      a {
        font-size: var(--font-18);
        min-width: 266px;
        padding: var(--font-13) 20px;
        display: inline-block;
      }
    }

    @media (max-width:575px) {
      margin: 11px auto 0px;
    }
  }

  .children-familytab {
    .add-invite-btn {
      @media (max-width:575px) {
        display: none;
      }
    }

    .childrenPanel {
      min-height: 370px;
      position: relative;

      .no-data-overlay-family-children {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;

        .no-data-found {
          width: 100%;
          top: auto;
          left: auto;
          transform: initial;
        }
      }

      h1,
      .h1 {
        color: var(--secondaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 9px 12px;
        margin-bottom: 0;

        @media (max-width:575px) {
          color: var(--tertiaryGrayColor);
          font-size: var(--font-13);
          background-color: #f4f5f5;
          padding: 9px 15px;
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }

      .noChild {
        text-align: center;
        margin: 50px auto;

        h3,
        .h3 {
          font-size: var(--font-14);
          line-height: 22px;
          color: var(--secondaryGrayColor);
        }

        img {
          margin-bottom: 34px;
        }

        @media (max-width:575px) {
          display: none;
        }
      }

      .child-single {
        display: flex;
        align-items: center;
        padding: 15px 12px;
        // border-bottom: 1px solid #f4f5f5;
        cursor: pointer;

        .childAvtar {
          width: 48px;
          min-width: 48px;
          height: 48px;
          min-height: 48px;
          text-align: center;
          background: #f4f5f5;
          border-radius: 24px;
          overflow: hidden;
        }

        .childName {
          margin-left: 10px;
          width: 100%;

          .child {
            font-size: var(--font-16);
            line-height: 24px;
            font-weight: 500;
            width: 100%;
            color: var(--primaryColor);
            margin-bottom: 5px;

            span {
              @media (max-width:575px) {
                font-weight: 600;
              }
            }

            @media (max-width: 1024px) {
              font-size: var(--font-14);
              line-height: 20px;
            }
          }

          .childListing {
            ul {
              padding: 0px;
              margin: 0px;

              li {
                list-style: none;
                margin-right: 15px;
                width: 24px;
                height: 24px;
                display: inline-block;
                overflow: hidden;
                border-radius: 100%;
              }
            }
          }
        }

        .invite-pending {
          color: var(--tertiaryGrayColor);
          font-size: var(--font-13);
          white-space: nowrap;
          margin-right: 60px;

          @media (max-width:575px) {
            margin-right: 30px;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        @media (max-width:575px) {
          border-bottom: 1px solid #f4f5f5;
          padding: 15px !important;
        }
      }
    }

    .adultsPanel {
      margin-top: 30px;

      h1,
      .h1 {
        color: var(--secondaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 9px 15px;
        margin-bottom: 0;

        @media (max-width:575px) {
          color: var(--tertiaryGrayColor);
          font-size: var(--font-13);
          background-color: #f4f5f5;
          padding: 9px 15px;
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }

      .noAdult {
        text-align: center;
        margin: 50px auto;

        h3,
        .h3 {
          font-size: var(--font-14);
          line-height: 22px;
          color: var(--secondaryGrayColor);
        }

        img {
          margin-bottom: 34px;
        }

        @media (max-width:575px) {
          display: none;
        }
      }

      .adult-single {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        box-shadow: none;
        padding: 15px 0px;
        // border-bottom: 1px solid #f4f5f5;
        border-radius: 0px;
        cursor: pointer;

        .childIcon {
          width: 48px;
          min-width: 48px;
          height: 48px;
          min-height: 48px;
          background: #f4f5f5;
          border-radius: 48px;
          text-align: center;
          line-height: 45px;
          overflow: hidden;
          margin-right: 15px;
        }

        .childName {
          width: 100%;

          h3,
          .h3 {
            margin-bottom: 5px;
            font-size: var(--font-16);
            color: var(--primaryColor);
            line-height: 24px;
            font-weight: 500;

            span {
              @media (max-width:575px) {
                display: block;
                font-weight: 600;
              }
            }
          }

          .child-listing {
            ul {
              padding: 0px;
              margin: 0px;

              li {
                list-style: none;
                display: inline-block;
                margin-right: 15px;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 20px;
                border-radius: 25px;

                &.active {
                  border: 2px solid var(--primaryColor);
                }

                img {}
              }
            }
          }
        }

        .invite-pending {
          color: var(--tertiaryGrayColor);
          font-size: var(--font-13);
          white-space: nowrap;
          margin-right: 60px;

          @media (max-width:575px) {
            margin-right: 30px;
          }

          @media (max-width:414px) {
            margin-right: 10px;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        @media (max-width:575px) {
          border-bottom: 1px solid #f4f5f5;
          padding: 15px !important;
        }
      }

      @media (max-width:575px) {
        margin-top: 0px;
      }
    }

    @media (max-width:575px) {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 10px;
    }
  }

  .profile-tab {
    .editUserAvatar {
      position: relative;

      .userIconDisplayBox {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        margin: 0px auto 30px auto;
        background: #f4f5f5;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;

        .editAvatar-btn {
          position: absolute;
          bottom: 5px;
          cursor: pointer;
          right: 10px;
          width: 22px;
        }
      }

      .avatars-selection-box {
        position: absolute;
        background: #fff;
        text-align: center;
        width: 500px;
        margin-left: calc(50% - 250px);
        height: 500px;

        h3,
        .h3 {
          font-weight: 300;
          font-size: var(--font-25);
          color: var(--primaryGrayColor);
          margin-bottom: 30px;
        }

        img {
          width: 50px;
          padding: 2px;
          background: var(--gray5);
          border-radius: 25px;
          margin-right: 10px;
          cursor: pointer;

          &.active {
            border: 2px solid #f27405;
          }
        }
      }
    }

    .form-box {
      width: 400px;
      margin: auto;

      .change-password {
        margin-top: 100px;

        @media (max-width:575px) {
          button {
            font-weight: 600;
          }
        }
      }

      .edit-profile-desktop {
        margin-bottom: 30px;

        @media (max-width:575px) {
          display: none;
        }
      }

      .delete-account {
        @media (max-width:575px) {
          position: absolute;
          bottom: 30px;
          width: 100%;
          left: 0;
          margin-bottom: 0 !important;
        }
      }

      .form-group {
        margin-bottom: 30px;

        input:disabled {
          background-color: transparent;
          border-color: #505D5F;
        }

        @media (max-width:575px) {
          margin-bottom: 43px;

        }
      }

      span {
        font-size: var(--font-12);
        color: #cecece;
      }

      @media (max-width:575px) {
        min-height: 450px;
      }
    }

    @media (max-width:575px) {
      margin-top: 0px;
    }
  }

  .notification-tab {
    .add-notification-btn {
      @media (max-width:575px) {
        display: none;
      }
    }

    .parentSetting-notifytab {
      .h1 {
        color: var(--tertiaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 9px 15px;
        margin-bottom: 0px;
        margin-top: 0px;
      }

      .infoicon {
        @media (max-width:575px) {
          display: none;
        }
      }

      .single-spending-tab {

        h1,
        .h1 {
          color: var(--secondaryGrayColor);
          font-size: var(--font-13);
          background-color: #f4f5f5;
          padding: 9px 12px;
          margin-bottom: 0;

          @media (max-width:575px) {
            color: var(--secondaryGrayColor);
            font-size: var(--font-13);
            background-color: #f4f5f5;
            padding: 9px 15px;
            margin-bottom: 0px;
            margin-top: 0px !important;
          }
        }

        .spending-row {
          padding: 15px 12px;
          // border-bottom: 1px solid #f4f5f5;

          h3,
          .h3 {
            font-size: var(--font-14);
            line-height: 24px;
            color: $black-1;
            margin-bottom: 0px;
            text-transform: capitalize;

            span {
              float: right;
              color: var(--tertiaryGrayColor);
              font-size: var(--font-16);
              line-height: 19px;
              font-weight: 500;
            }

            img {
              width: 18px;
              margin-left: 4px;
              vertical-align: middle;
              margin-top: -4px;
            }

            .custom-switch {
              padding-left: 1.75rem;

              label {
                &::before {
                  left: -1.75rem;
                }

                &::after {
                  left: calc(-1.75rem + 2px);
                }
              }

              .custom-control-input {
                &:disabled~.custom-control-label {
                  &::before {
                    opacity: 0.7;
                  }
                }
              }
            }
          }

          @media (max-width:575px) {
            border-bottom: 1px solid #f4f5f5;
            padding: 12px 15px !important;
          }
        }

        &:first-child {

          h1,
          .h1 {
            margin-top: 0px;
          }
        }
      }

      .category-toggles-wrapper {
        // width:500px;
        margin: 30px auto 0px;

        .feature-toggle-single {
          h4 {
            color: var(--tertiaryGrayColor);
            font-size: var(--font-13);
            background-color: $grey-2;
            padding: 7px 10px;
            margin-bottom: 0px;
            margin-top: 0px;
            line-height: 1.2;
          }

          .child-listing {
            padding: 15px 8px;

            ul {
              padding: 0;
              margin: 0;
              display: flex;
              overflow: auto;
              padding-bottom: 5px;

              li {
                list-style: none;
                margin-right: 10px;
                text-align: center;

                .child-icon {
                  list-style: none;
                  width: 46px;
                  height: 46px;
                  min-width: 46px;
                  min-height: 46px;
                  background: $grey-2;
                  text-align: center;
                  border-radius: 24px;
                  overflow: hidden;
                  box-sizing: border-box;
                  cursor: pointer;

                  &.active {
                    border: 2px solid var(--primaryColor);
                  }
                }

                .username {
                  color: var(--primaryColor);
                  font-size: var(--font-10);
                }
              }

              &::-webkit-scrollbar {
                width: 6px;
                height: 4px;
              }

              &::-webkit-scrollbar-track {
                background: transparent;
              }

              &::-webkit-scrollbar-thumb {
                background: var(--gray5);
                border-radius: 10px;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: var(--gray5);
              }
            }

            @media (max-width:575px) {
              padding: 15px 15px;
            }
          }
        }

        @media (max-width:575px) {
          width: 100%;
        }
      }

      @media (max-width:575px) {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 10px;
      }
    }
  }

  .peers-tab {
    .requests {

      h1,
      .h1 {
        color: var(--secondaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 9px 12px;
        margin-bottom: 0;

        @media (max-width:575px) {
          color: var(--tertiaryGrayColor);
          font-size: var(--font-13);
          background-color: #f4f5f5;
          padding: 9px 15px;
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }

      .request-single {
        display: flex;
        align-items: center;
        padding: 15px 12px;

        .childIcon {
          width: 48px;
          min-width: 48px;
          height: 48px;
          min-height: 48px;
          background: #f4f5f5;
          border-radius: 48px;
          text-align: center;
          line-height: 45px;
          margin-right: 15px;

          @media (max-width:575px) {
            width: 42px;
            min-width: 42px;
            height: 42px;
            min-height: 42px;
          }
        }

        .childName {
          width: 100%;

          h3,
          .h3 {
            margin: 0px;
            font-size: var(--font-16);
            color: var(--primaryColor);

            span {
              color: var(--primaryColor);

              @media (max-width:575px) {
                font-family: 'GothamBook';
                font-size: var(--font-13);
              }
            }

            @media (max-width:575px) {
              font-weight: 600;
              font-size: var(--font-17);

              span {
                font-weight: 500;
              }
            }
          }

          span {
            font-weight: 400;
            font-size: var(--font-12);
            line-height: 24px;
            margin-left: 8px;
            color: var(--tertiaryGrayColor);

            @media (max-width:575px) {
              line-height: 15px;
            }
          }

          @media (max-width:575px) {
            font-size: var(--font-14);
            font-weight: 600;
          }
        }

        .actionBtn {
          min-width: 260px;

          button {
            &:disabled {
              background-color: $grey-2 !important;
              color: $black-1;
            }

            @media (max-width:575px) {
              min-width: auto;
              padding: 9px 12px;
            }
          }

          @media (max-width:575px) {
            min-width: auto !important;
            white-space: nowrap;
          }
        }

        @media (max-width:575px) {
          margin: 0;
          padding: 18px 15px;
          border-bottom: 1px solid #f4f5f5;

          &:last-child {
            border-bottom: none;
          }
        }

      }

      @media (max-width:575px) {
        margin-bottom: 0;
      }
    }

    .peers {

      h1,
      .h1 {
        color: var(--secondaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 9px 12px;
        margin-bottom: 0;

        @media (max-width:575px) {
          color: var(--tertiaryGrayColor);
          font-size: var(--font-13);
          background-color: #f4f5f5;
          padding: 9px 15px;
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }

      .request-single {
        display: flex;
        align-items: center;
        padding: 15px 12px;

        .childIcon {
          width: 48px;
          min-width: 48px;
          height: 48px;
          min-height: 48px;
          background: #f4f5f5;
          border-radius: 48px;
          text-align: center;
          line-height: 45px;
          margin-right: 15px;
          overflow: hidden;

          @media (max-width:575px) {
            width: 42px;
            min-width: 42px;
            height: 42px;
            min-height: 42px;
          }
        }

        .childName {
          width: 100%;

          h3,
          .h3 {
            margin: 0px;
            font-size: var(--font-16);
            color: var(--primaryColor);

            span {
              font-weight: 400;
              font-size: var(--font-12);
              line-height: 24px;
              margin-left: 8px;
            }

            @media (max-width:575px) {
              font-size: var(--font-14);
              font-weight: 600;
            }
          }
        }

        .actionBtn {}

        &:last-child {
          margin-bottom: 0px;
        }

        @media (max-width:575px) {
          margin: 0;
          padding: 18px 15px;
          border-bottom: 1px solid #f4f5f5;
        }
      }
    }

    @media (max-width:575px) {
      margin-top: 0px;
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .address-tab {
    .addAddressBtn {
      position: absolute;
      right: 0;
      top: 10px;
    }

    .addNewLink {
      line-height: 22px;
      font-size: var(--font-18);
      font-weight: 500;
      color: var(--primaryColor);
      margin-bottom: 5px;
      cursor: pointer;
    }

    .address-single {
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(209, 222, 222, 0.41);
      border-radius: var(--borderRadiusS);
      padding: 20px;
      margin-bottom: 20px;
      min-height: 91px;
      // display: flex;
      // align-items: center;

      .isDefaultAddress {
        .address-wrapper {
          .step1 {
            display: flex;
            column-gap: 10px;
            margin-bottom: 3px;

            p {
              color: var(--primaryGrayColor);
              font-weight: 600;
              font-size: var(--font-14);
              padding-right: 15px;
              position: relative;

              &:nth-child(1) {
                &::after {
                  content: "";
                  right: 1px;
                  width: 4px;
                  height: 4px;
                  background: var(--primaryGrayColor);
                  border-radius: 3px;
                  top: 6px;
                  position: absolute;

                }

                @media (max-width:575px) {
                  font-size: var(--font-15);
                }
              }

              @media (max-width:575px) {
                font-weight: 600;
              }
            }
          }
        }
      }

      .notDefaultAddress {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .step1 {
          width: 100%;


          p {
            &:nth-child(1) {
              color: var(--primaryGrayColor);
              font-weight: 600;
              font-size: var(--font-14);
              margin-bottom: 3px;

              @media (max-width:575px) {
                font-size: var(--font-15);

              }
            }
          }
        }
      }

      h3,
      .h3 {
        font-size: var(--font-18);
        line-height: 22px;
        color: var(--primaryGrayColor);
        margin-bottom: 10px;

        .headingCont {
          overflow: hidden;
          max-width: 220px;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: var(--primaryGrayColor);
          display: inline-block;
        }
      }

      p {
        color: var(--tertiaryGrayColor);
        font-size: var(--font-12);
        line-height: 16px;
        margin-bottom: 0px;
      }

      @media (max-width:767px) {
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.18);
        border-radius: var(--borderRadiusS);
        min-height: auto;
        margin-bottom: 35px;
      }
    }

    @media (max-width:575px) {
      margin-top: 0px;
    }
  }

  .spending-tab {

    .add-spending-btn {
      @media (max-width:575px) {
        display: none;
      }
    }

    .single-spending-tab {

      h1,
      .h1 {
        color: var(--secondaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 9px 12px;
        margin-bottom: 0;

        @media (max-width:575px) {
          color: var(--tertiaryGrayColor);
          font-size: var(--font-13);
          background-color: #f4f5f5;
          padding: 9px 15px;
          margin-bottom: 0px;
          margin-top: 0px;
        }
      }

      .spending-row {
        padding: 15px 12px;
        // border-bottom: 1px solid #f4f5f5;

        h3,
        .h3 {
          font-size: var(--font-16);
          line-height: 24px;
          color: var(--primaryColor);

          margin-bottom: 0px;

          .custom-switch {
            padding-left: 1.75rem;

            label {
              &::before {
                left: -1.75rem;
              }

              &::after {
                left: calc(-1.75rem + 2px);
              }
            }

            // .custom-control-input{
            //   &:disabled~.custom-control-label{
            //     &::before{
            //       opacity: 0.7;
            //     }
            //   }
            // }
          }

          .settingTitle {
            line-height: 24px;
            display: inline-block;
          }

          .inputGrp {
            position: relative;
            display: inline-block;
            float: right;

            span {
              position: absolute;
              top: 0px;
            }

            span.ft {
              left: auto;
              right: 0;
            }

            input[type="text"],
            input {
              width: auto;
              height: 29px;
              text-align: right;
              padding-left: 11px;
              padding-top: 0;
              padding-bottom: 4px;
              border: none;
              color: var(--primaryColor);

              &:disabled {
                background-color: transparent;
                color: var(--tertiaryGrayColor);
                border-color: transparent;
              }

              &::placeholder {
                color: var(--tertiaryGrayColor);
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }

              @media (max-width: 575px) {
                border: none;
                padding-bottom: 0;
                height: auto;
              }
            }

            &.ft {
              input {
                padding-right: var(--font-14);
                text-align: right;
                color: $black-1;

                &:active,
                &:focus {
                  color: var(--primaryColor);
                }
              }
            }
          }

          span:not(.info-icon) {
            float: right;
            color: var(--tertiaryGrayColor);
            font-size: var(--font-16);
            line-height: 24px;
            font-weight: 500;
          }

          svg {
            width: 21px;
            margin-left: 10px;
            vertical-align: middle;
            margin-top: -1px;
            cursor: pointer;
          }

          @media (max-width:575px) {
            font-size: var(--font-16);
          }
        }

        @media (max-width:575px) {
          padding: 17px 15px;

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      &:first-child {

        h1,
        .h1 {
          margin-top: 0px;
        }
      }
    }

    &.ft {
      .footer-buttons {
        @media (max-width:575px) {
          display: none;
        }
      }

      @media (max-width:575px) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @media (max-width:575px) {
      margin-top: 0px;
      margin-left: -15px;
      margin-right: -15px;
    }

  }

  .addBank-wrapper {
    margin-top: 30px;
  }

  .relation-tab {

    .add-relation-btn {
      @media (max-width:575px) {
        display: none;
      }
    }

    .addRelationBtn {
      position: absolute;
      right: 0;
      top: 10px;
    }

    .addNewLink {
      line-height: 22px;
      font-size: var(--font-18);
      font-weight: 500;
      color: var(--primaryColor);
      margin-bottom: 5px;
      cursor: pointer;
    }

    .peers {
      margin-top: 10px;

      h1,
      .h1 {
        font-size: var(--font-18);
        margin-bottom: 15px;
        font-weight: 400;
      }

      .request-single {
        display: flex;
        align-items: center;
        padding: 15px 0px;
        // border-bottom: 1px solid #f4f5f5;

        .childIcon {
          width: 48px;
          min-width: 48px;
          height: 48px;
          min-height: 48px;
          background: #f4f5f5;
          border-radius: 48px;
          text-align: center;
          line-height: 44px;
          margin-right: 15px;
          overflow: hidden;
        }

        .childName {
          width: 100%;

          h3,
          .h3 {
            margin: 0px;
            font-size: var(--font-16);
            line-height: 24px;
            color: var(--primaryColor);

            span {
              color: var(--tertiaryGrayColor);
              font-weight: 400;
              margin-left: 8px;
              font-size: var(--font-12);
              line-height: 24px;
            }

            @media (max-width:575px) {
              font-size: var(--font-14);
              font-weight: 600;
            }
          }
        }

        .actionBtn {}

        &:first-child {
          padding-top: 0;
        }

        @media (max-width:575px) {
          border-bottom: 1px solid #f4f5f5;
        }
      }
    }

    @media (max-width:575px) {
      margin-top: 0px;
    }
  }
}

.rpn-notification-holder {
  .rpn-notification-card {
    overflow: hidden;
    border: 1px solid #d4d5d5;
    border-radius: 10px;

    .rpn-notification-card-top {
      border-radius: 10px 10px 0px 0px;
      background-color: $grey-2;
      border-bottom: 1px solid var(--tertiaryGrayColor);
      color: var(--primaryColor);
      overflow: hidden;
      border-bottom: 1px solid #103d44;

      span {
        font-size: var(--font-13);
      }

      span.rpn-notification-card-close {
        font-size: var(--font-12);
      }
    }

    .rpn-notification-card-bottom {
      box-shadow: none;
      background-color: $grey-2;

      span.subtitle {
        color: var(--primaryColor);
        font-size: var(--font-15);
      }

      span.message {
        color: var(--primaryColor);
        font-size: var(--font-13);
      }
    }
  }
}

//ABOUT BANK IFRAME SCREEN
.about-bank-wrapper {
  padding: 30px 0px;

  @media (max-width:575px) {
    padding: 5px 0px;
  }
}

//PARENT SETTING PAGE CSS
.parent-setting-wrapper {
  .top-buttons-group {
    padding-bottom: 31px;

    img {
      cursor: pointer;
    }

    @media (max-width:575px) {
      padding-top: 0px;
      position: fixed;
      z-index: 99;
      background-color: #fff;
      top: 20px;
      left: 65px;
      padding-bottom: 0;
    }
  }

  .pgHeading {
    font-size: var(--font-20);
    padding-bottom: 31px;
    color: var(--primaryColor);
    font-weight: 700;
    margin-bottom: 0 !important;
    line-height: 21px;
    text-align: center;

    .routeBack-invest-abs {
      position: absolute;
      top: 11px;
      left: 0;
      display: none;

      &.invest-back {
        display: inline-block;
        top: 15px;

        @media (max-width:575px) {
          top: 20px;
        }
      }

      @media (max-width:575px) {
        top: 19px;
        left: 15px;
        display: inline-block;
      }
    }

    @media (max-width:575px) {
      margin-top: 0px;
      font-size: var(--font-18);
      margin-left: 0;
      width: calc(100% + 30px);
      padding: 14px 15px 20px !important;
      position: sticky;
      top: 0px;
      background: #fff;
      z-index: 9;
      margin-left: -15px;
      margin-right: -15px;
      text-align: center;
      line-height: 30px;
    }

    .title {
      &.desktop {
        @media (max-width:575px) {
          display: none;
        }
      }

      &.mobile {
        display: none;

        @media (max-width:575px) {
          display: inline-block;
        }
      }
    }
  }

  .child-setting-top-mobile-links {
    position: fixed;
    top: 15px;
    right: 15px;
    font-size: var(--font-14);
    font-weight: normal;
    display: none;

    img.infocircle {
      width: 21px;
      margin-top: -1px;
    }


    @media (max-width:575px) {
      display: initial;
      top: 14px;
    }
  }

  .address-tab {
    @media (max-width:575px) {
      button.btn-gradient {
        display: none;
      }
    }
  }

  .banking-tab {
    .nobank-flow {
      max-width: 600px;
      margin: auto;

      .screen-1 {

        h3,
        .h3 {
          font-size: var(--font-18);
          font-weight: 700;
          padding-bottom: 20px;
          color: var(--primaryColor);
          margin-bottom: 0;
        }

        p {
          font-size: var(--font-15);
        }

        ul {
          padding: 0;

          li {
            list-style: none;
            font-size: var(--font-15);
          }
        }

        .btn-next {
          margin-top: 50px;

          button.next {
            // font-size: var(--font-18);
            // min-width: 266px;
            // padding: var(--font-13) 20px;
          }
        }

        @media (max-width:575px) {
          padding-bottom: 50px;
        }
      }

      .screen-2 {

        h3,
        .h3 {
          font-size: var(--font-18);
          font-weight: 700;
          padding-bottom: 20px;
          color: var(--primaryColor);
          margin-bottom: 0;

          img {
            margin-right: 15px;
            cursor: pointer;

            @media (max-width:575px) {
              display: none;
            }
          }
        }

        p {
          font-size: var(--font-15);
        }

        .popular-banks-list {
          margin-top: 20px;
          margin-bottom: 25px;

          li {
            list-style: none;
            padding: 9px 0px 7px;
            border-bottom: 1px solid #f4f5f5;
            cursor: pointer;

            a {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .bank-name {
                font-size: var(--font-15);
                color: var(--primaryColor);
              }

              .angle-right {
                img {
                  width: 8px;
                }
              }
            }
          }
        }

        .btn-next {
          margin-top: 50px;

          // button.next {
          //   font-size: var(--font-18);
          //   min-width: 266px;
          //   padding: var(--font-13) 20px;
          // }
        }

        @media (max-width:575px) {
          padding-bottom: 50px;
        }
      }
    }

    .bottom-fixed-buttons {

      @media (max-width:575px) {
        position: fixed;
        width: 100%;
        bottom: 0px;
        background: #fff;
        z-index: 9;
        left: 0px;
        padding: 10px 15px 30px;
        margin-top: 0 !important;
      }
    }

    .bank-available-flow {
      .banks-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        flex-wrap: wrap;

        .bank-detail {
          display: flex;
          align-items: center;
          margin-bottom: 45px;
          width: 43%;
          cursor: pointer;
          box-shadow: 0px 4px 8px rgba(209, 222, 222, 0.41);
          padding: 20px;
          border-radius: var(--borderRadiusS);
          margin-bottom: 20px;

          .bank-icon {
            width: 40px;
            min-width: 40px;
            margin-right: 10px;
          }

          .bank-name {
            padding-right: 15px;
            width: 100%;

            h3,
            .h3 {
              font-size: var(--font-16);
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              line-height: 20px;
              min-height: 20px;
              color: var(--primaryColor);
              margin-bottom: 0;
            }

            p {
              color: var(--tertiaryGrayColor);
              margin-bottom: 0;
              font-size: var(--font-13);

              &.mobile {
                display: none;

                @media (max-width:575px) {
                  display: inline-block;
                }
              }

              &.desktop {
                @media (max-width:575px) {
                  display: none;
                }
              }
            }
          }

          .status {
            margin-left: auto;
            text-align: right;

            label {
              margin-bottom: 0;
              font-size: var(--font-12);
              white-space: nowrap;

              &.verified {
                color: var(--secondaryGrayColor);
              }

              &.unverified {
                color: var(--errorColor);
              }
            }

            span {
              display: block;
              font-size: var(--font-12);
              color: var(--secondaryGrayColor);
              white-space: nowrap;
            }
          }

          @media (max-width:767px) {
            margin-bottom: 25px;
          }

          @media (max-width:575px) {
            width: 100% !important;
            padding: 0;
            box-shadow: none;
          }
        }
      }
    }

    .connecting-bank-msg {
      position: fixed;
      top: 276px;
      max-width: 800px;
      width: calc(100% - 506px);
      height: calc(100vh - 336px);
      display: flex;
      align-items: center;
      justify-content: center;

      .wrapper {

        h1,
        .h1 {
          font-weight: 600;
          font-size: var(--font-22);
          line-height: 23px;
          letter-spacing: 0.01em;
          color: var(--primaryColor);
        }

        h4,
        .h4 {
          font-size: var(--font-16);
        }
      }

      @media (max-width:991px) {
        width: calc(100% - 320px);
      }

      @media (max-width:768px) {
        width: calc(100% - 230px);
      }

      @media (max-width:767px) {
        width: 100%;
        left: 50%;
        top: 50%;
        height: auto;
        transform: translate(-50%, -50%);
      }

      @media (max-width:575px) {
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        transform: none;
        background-color: #fff;
        z-index: 9;
      }
    }

    .add-bank-emptyState-wrapper {
      .provider-message {
        font-size: var(--font-14);
        line-height: 22px;
        color: var(--tertiaryGrayColor);
        position: fixed;
        bottom: 0;
        max-width: 800px;
        width: calc(100% - 506px);
        padding: 0px 120px 10px;
        text-align: center;

        @media (max-width:991px) {
          width: calc(100% - 320px);
        }

        @media (max-width:768px) {
          width: calc(100% - 230px);
        }

        @media (max-width:575px) {
          padding: 0px 20px 10px;
          left: 0;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  .bankingNoteBottom {
    font-size: var(--font-14);
    line-height: 22px;
    text-align: center;
    margin-top: 175px;
    padding: 0px 100px;
    color: var(--tertiaryGrayColor);

    @media (max-width:575px) {
      font-size: var(--font-13);
      line-height: 19px;
      padding: 0px 30px;
      position: fixed;
      bottom: 10px;
      left: 0;
      right: 0;
    }
  }

  .parentSetting-peerstab {
    .peers {
      .peers-single {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        box-shadow: none;
        padding: 15px 0px;
        // border-bottom: 1px solid #f4f5f5;
        border-radius: 0px;

        .childIcon {
          width: 48px;
          min-width: 48px;
          height: 48px;
          min-height: 48px;
          background: #f4f5f5;
          border-radius: 48px;
          text-align: center;
          line-height: 45px;
          margin-right: 15px;
          overflow: hidden;
        }

        .childName {
          width: 100%;

          h3,
          .h3 {
            margin-bottom: 5px;
            font-size: var(--font-16);
            line-height: 24px;
          }

          .child-listing {
            ul {
              padding: 0px;
              margin: 0px;

              li {
                list-style: none;
                display: inline-block;
                margin-right: 15px;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 20px;
                background: #f4f5f5;
                border-radius: 25px;

                &.active {
                  border: 2px solid var(--primaryColor);
                }

                img {
                  width: 25px;
                  padding: 3px;
                }
              }
            }
          }
        }

        .actionBtn {
          min-width: 260px;

          &.remove {
            min-width: auto;
          }
        }

        &:first-child {
          padding-top: 0px;
        }
      }
    }
  }

  .parentSetting-legaltab {
    #accordion {
      .card {
        border: none;
        margin-bottom: 0px;

        .card-header {
          background: #fff;
          border: none;
          padding: 12px 12px;
          // border-bottom: 1px solid #ececec;

          h5,
          .h5 {
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
              width: 100%;
              text-align: left;
              padding: 0px;
              color: var(--primaryColor);
              font-weight: 500;
              text-decoration: none;
              font-size: var(--font-14);
              line-height: 24px;
              border: none;

              @media (max-width: 575px) {
                font-size: var(--font-14);
              }
            }

            .right-angle {
              display: none;

              @media (max-width:575px) {
                display: inline-block;

                img {
                  width: 8px;
                }
              }
            }
          }

          @media (max-width: 575px) {
            padding: 15px;
            border-bottom: 1px solid #f4f5f5;
          }
        }

        .card-body {
          color: #505d5f;
          font-size: var(--font-14);
          padding: 10px 0px;
          line-height: 22px;
        }

        &:last-child {
          .card-header {
            border-bottom: none;
          }
        }
      }
    }

    .childrenPanel {
      min-height: auto;

      @media (max-width:575px) {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .parentSetting-accessibilityTab {
    .h1 {
      color: var(--tertiaryGrayColor);
      font-size: var(--font-13);
      background-color: #f4f5f5;
      padding: 9px 12px;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .single-spending-tab {

      h1,
      .h1 {

        color: var(--secondaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 9px 15px;
        margin-bottom: 0;

        @media (max-width:575px) {
          color: var(--secondaryGrayColor);
          font-size: var(--font-13);
          background-color: #f4f5f5;
          padding: 9px 15px;
          margin-bottom: 0px;
          margin-top: 0px !important;
        }
      }

      .spending-row {
        padding: 15px 12px;
        // border-bottom: 1px solid #f4f5f5;

        h3,
        .h3 {
          font-size: var(--font-14);
          line-height: 24px;
          color: $black-1;
          margin-bottom: 0px;
          text-transform: capitalize;

          span {
            float: right;
            color: var(--tertiaryGrayColor);
            font-size: var(--font-16);
            line-height: 19px;
            font-weight: 500;
          }

          img {
            width: 18px;
            margin-left: 4px;
            vertical-align: middle;
            margin-top: -4px;
          }

          .custom-switch {
            padding-left: 1.75rem;

            label {
              &::before {
                left: -1.75rem;
              }

              &::after {
                left: calc(-1.75rem + 2px);
              }
            }
          }
        }

        @media (max-width:575px) {
          border-bottom: 1px solid #f4f5f5;
          padding: 12px 15px !important;
        }
      }

      &:first-child {

        h1,
        .h1 {
          margin-top: 0px;
        }
      }
    }

    .languages-list {
      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          padding: 13px 12px;
          border-bottom: 1px solid #f4f5f5;

          a {
            padding: 0;
            display: flex;
            align-items: center;
            color: #424242 !important;

            img {
              margin-right: 10px;
              margin-top: -3px;
              width: 19px;
            }

            &:hover {
              background-color: transparent;
            }

            .checkmark {
              margin-left: auto;

              svg {
                width: 23px;
              }
            }
          }

        }
      }
    }

    @media (max-width:575px) {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 10px;
    }
  }

  .parentSetting-notifytab {
    .h1 {
      color: var(--tertiaryGrayColor);
      font-size: var(--font-13);
      background-color: #f4f5f5;
      padding: 9px 15px;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .infoicon {
      @media (max-width:575px) {
        display: none;
      }
    }

    .single-spending-tab {

      h1,
      .h1 {
        // font-size: var(--font-18);
        // margin-bottom: 10px;
        // font-weight: 400;
        // margin-top: 35px;

        color: var(--secondaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 9px 15px;
        margin-bottom: 0;

        @media (max-width:575px) {
          color: var(--secondaryGrayColor);
          font-size: var(--font-13);
          background-color: #f4f5f5;
          padding: 9px 15px;
          margin-bottom: 0px;
          margin-top: 0px !important;
        }
      }

      .spending-row {
        padding: 15px 0px;
        // border-bottom: 1px solid #f4f5f5;

        h3,
        .h3 {
          font-size: var(--font-14);
          line-height: 24px;
          color: $black-1;
          margin-bottom: 0px;
          text-transform: capitalize;

          span {
            float: right;
            color: var(--tertiaryGrayColor);
            font-size: var(--font-16);
            line-height: 19px;
            font-weight: 500;
          }

          img {
            width: 18px;
            margin-left: 4px;
            vertical-align: middle;
            margin-top: -4px;
          }

          .custom-switch {
            padding-left: 1.75rem;

            label {
              &::before {
                left: -1.75rem;
              }

              &::after {
                left: calc(-1.75rem + 2px);
              }
            }
          }
        }

        @media (max-width:575px) {
          border-bottom: 1px solid #f4f5f5;
          padding: 12px 15px !important;
        }
      }

      &:first-child {

        h1,
        .h1 {
          margin-top: 0px;
        }
      }
    }

    .category-toggles-wrapper {
      // width:500px;
      margin: 30px auto 0px;

      .feature-toggle-single {
        h4 {
          color: var(--tertiaryGrayColor);
          font-size: var(--font-13);
          background-color: $grey-2;
          padding: 7px 10px;
          margin-bottom: 0px;
          margin-top: 0px;
          line-height: 1.2;
        }

        .child-listing {
          padding: 15px 8px;

          ul {
            padding: 0;
            margin: 0;
            display: flex;
            overflow: auto;
            padding-bottom: 5px;

            li {
              list-style: none;
              margin-right: 10px;
              text-align: center;

              .child-icon {
                list-style: none;
                width: 46px;
                height: 46px;
                min-width: 46px;
                min-height: 46px;
                background: $grey-2;
                text-align: center;
                border-radius: 24px;
                overflow: hidden;
                box-sizing: border-box;
                cursor: pointer;

                &.active {
                  border: 2px solid var(--primaryColor);
                }
              }

              .username {
                color: var(--primaryColor);
                font-size: var(--font-10);
              }
            }

            &::-webkit-scrollbar {
              width: 6px;
              height: 4px;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
            }

            &::-webkit-scrollbar-thumb {
              background: var(--gray5);
              border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: var(--gray5);
            }
          }

          @media (max-width:575px) {
            padding: 15px 15px;
          }
        }
      }

      @media (max-width:575px) {
        width: 100%;
      }
    }

    @media (max-width:575px) {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 10px;
    }
  }

  .parentSetting-investmentTab {
    position: fixed;
    top: 197px;
    max-width: 800px;
    width: calc(100% - 506px);
    height: calc(100vh - 197px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width:991px) {
      width: calc(100% - 320px);
    }

    @media (max-width:768px) {
      width: calc(100% - 230px);
    }

    @media (max-width:767px) {
      width: 100%;
      left: 0;
      // top: 29px;
      // height: calc(100vh - 88px);
      top: 0;
      height: 100vh;
    }

    .redirection-message {
      font-weight: 600;
      font-size: var(--font-22);
      line-height: 23px;
      letter-spacing: 0.01em;
      color: var(--primaryColor);
      max-width: 400px;
    }
  }

  .privacy-policy-iframe {
    @media (max-width:575px) {
      margin-top: 0px;
      padding: 0;
    }
  }

  .physicalcard-tab {

    .infoicon {
      text-align: right;


      @media (max-width:575px) {
        display: none;
      }
    }

    .request-single {
      display: flex;
      align-items: center;
      padding: 16px 0px;

      &:nth-child(2) {
        padding-top: 0px;
      }

      .childIcon {
        width: 48px;
        min-width: 48px;
        height: 48px;
        min-height: 48px;
        background: #f4f5f5;
        border-radius: 48px;
        text-align: center;
        line-height: 44px;
        margin-right: 15px;
        overflow: hidden;

        @media (max-width:1024px) {
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          line-height: 38px;
        }
      }

      .childName {
        width: 100%;

        label {
          font-size: var(--font-12);
          color: var(--tertiaryGrayColor);
          margin-bottom: 0;
          display: block;

          @media (max-width:575px) {
            // font-weight: 600;
          }
        }

        h3,
        .h3 {
          margin: 0px;
          font-size: var(--font-16);
          line-height: 24px;
          color: var(--primaryColor);

          span {
            color: var(--tertiaryGrayColor);
            font-weight: 400;
            margin-left: 8px;
            font-size: var(--font-12);
            line-height: 24px;
          }

          @media (max-width:1024px) {
            font-size: var(--font-14);
            line-height: 20px;
            font-weight: 600;
          }
        }
      }

      .actionBtn {
        white-space: nowrap;
        display: flex;
        align-items: center;
        column-gap: 10px;

        button {
          min-width: 112px;
        }

        .statusInd {
          box-shadow: none;
          padding: 11px 20px;
          font-size: var(--font-14);
          line-height: 20px;
          font-weight: 500;
          letter-spacing: 0.3px;
          border: none;
          background-color: transparent;
          color: var(--tertiaryGrayColor);
        }
      }

      @media (max-width:575px) {
        border-bottom: 1px solid #f4f5f5;
        padding: 15px 0px;
      }
    }

    .order-section {
      table {
        color: var(--primaryColor);

        thead {
          tr {
            th {
              border: none;
              color: var(--primaryColor);
              font-size: var(--font-20);
              padding: 5px 0.75rem;
            }
          }
        }

        tbody {
          tr {
            td {
              border: none;
              padding: 5px 0.75rem;
            }
          }
        }

        tfoot {
          tr {
            th {
              border: none;
              font-size: var(--font-20);
              padding: 5px 0.75rem;
            }
          }
        }

        @media (max-width:480px) {

          thead,
          tfoot {
            tr {
              th {
                font-size: var(--font-16);
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: var(--font-14);
                line-height: 17px;
              }
            }
          }
        }
      }
    }

    .confirm-card {
      font-size: var(--font-14);
      line-height: 22px;
      text-align: center;
      margin-top: 25px;
      padding: 0px 100px;
      color: var(--tertiaryGrayColor);

      @media (max-width:575px) {
        padding: 0px 10px;
        font-size: var(--font-13);
        line-height: 19px;
      }
    }

    .confirm-card-button {
      button {
        width: 120px;

        @media (max-width: 1024px) {
          padding: 10px 20px;
          font-size: var(--font-14);
          border-radius: var(--borderRadiusS);
        }

        @media (max-width: 480px) {
          font-size: var(--font-13);
          padding: 9px 10px !important;
          justify-content: center;
          width: 100px;
        }
      }
    }
  }

  .activate-card {
    margin-top: 50px;

    h3,
    .h3 {
      font-size: var(--font-24);
      text-align: center;
      margin-bottom: var(--font-13);
      color: var(--primaryColor);
    }

    p {
      font-size: var(--font-14);
      line-height: 22px;
      text-align: center;
      margin-bottom: 25px;
      padding: 0px 100px;
      color: var(--tertiaryGrayColor);
    }

    .inputsGrp {
      display: flex;
      justify-content: center;
      margin-bottom: 60px;

      input {
        margin-right: 10px;
        text-align: center;
        font-weight: 400;
        font-size: var(--font-35);
        width: 72px;
        height: 72px;
        border-radius: var(--borderRadiusM);
        background: #f4f5f5;
        border: 2px solid transparent;

        &:focus,
        &:active {
          border: 2px solid var(--primaryColor);
        }

        &:last-child {
          margin-right: 0px;
        }

        @media (max-width: 375px) {
          width: 52px;
          height: 52px;
          font-size: var(--font-30);
          border-radius: var(--borderRadiusM);
        }
      }
    }

    h4,
    .h4 {
      font-size: var(--font-14);
      line-height: 22px;
      text-align: center;
      padding: 0px 100px;
      color: var(--tertiaryGrayColor);
      font-weight: 400;
    }

    @media (max-width:480px) {

      p,
      h4,
      .h4 {
        padding: 0px;
      }
    }
  }
}

//PARENT SETTING PAGE CSS END

//PHYSICAL CARD ACTIVATION MODAL CSS
.activate-physical-card {
  .info-content1 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .info-content2 {
    text-align: center;
    margin-top: 40px;
  }

  .otp-wrapper {
    display: flex;
    justify-content: center;

    input {
      margin-right: 10px;
      text-align: center;
      font-weight: 400;
      font-size: var(--font-35);
      width: 72px !important;
      height: 72px !important;
      border-radius: var(--borderRadiusL);
      background: #f4f5f5;
      border: 2px solid transparent;
      color: var(--primaryColor);

      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
        border: 2px solid var(--primaryColor);
      }

      &:last-child {
        margin-right: 0px;
      }

      @media (max-width: 575px) {
        width: 48px !important;
        height: 48px !important;
        min-width: 48px !important;
        min-height: 48px !important;
        margin-right: 10px !important;
        font-size: var(--font-24);
      }
    }
  }
}


//BANK ADD SUCCESS MODAL CSS
.bank-add-success-modal {
  padding-top: 100px !important;

  .bank-success-wrapper {
    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      // background: #ceedea;
      border-radius: 76px;
      margin: 0px auto 30px;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: var(--primaryColor);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 56px;

          @media (max-width: 414px) {
            width: 36px;
          }
        }

        @media (max-width: 414px) {
          width: 90px;
          height: 90px;
          border-radius: 60px;
        }
      }

      @media (max-width: 414px) {
        width: 112px;
        height: 112px;
        padding: 11px;
        border-radius: 56px;
      }
    }

    .successText {
      margin-top: 24px;
      text-align: center;

      h3,
      .h3 {
        font-weight: 600;
        font-size: var(--font-20);
        line-height: 23px;
        letter-spacing: 0.01em;
        color: var(--primaryColor);
      }
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: calc(100% - 30px);
        bottom: 0px;
        padding-top: 10px;
        z-index: 9;
        padding-bottom: 10px;
        left: 15px;
      }
    }
  }

  .banks-list {
    margin-bottom: 60px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    column-gap: 40px;
    flex-wrap: wrap;

    .bank-detail {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      width: 100%;

      .bank-icon {
        width: 38px;
        min-width: 38px;
        margin-right: 10px;
      }

      .bank-name {
        color: $black;
        line-height: 20px;
        font-size: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .verified {
        margin-left: auto;
        font-size: var(--font-14);
        color: var(--tertiaryGrayColor);
      }

      @media (max-width:767px) {
        margin-bottom: 25px;
      }

      @media (max-width:575px) {
        width: 100%;
      }
    }
  }
}

//COMPLETE INVITE MODAL
.pending-child-invitation-modal {
  .modal-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
  }
}

.completeInvite-modal {
  p {
    font-size: var(--font-14);
    line-height: 22px;
    color: var(--secondaryGrayColor);
    margin-bottom: 32px;
    text-align: center;
    text-align: center;
  }

  .qr-code {
    text-align: center;
    margin-bottom: 30px;
  }

  .pin-code {
    text-align: center;
    margin-bottom: 43px;
    margin-top: 34px;

    label {
      font-size: var(--font-16);
      line-height: 24px;
      color: var(--tertiaryGrayColor);
      text-align: center;
      cursor: pointer;
      margin-bottom: 0px;
    }

    h3,
    .h3 {
      font-size: var(--font-44);
      line-height: 54px;
      color: var(--primaryColor);
      font-weight: 300;
      letter-spacing: 0.4px;
      margin-bottom: 10px;
    }
  }

  .invite-other-child {
    margin-bottom: 20px;
    text-align: center;

    span {
      color: var(--primaryColor);
      cursor: pointer;
    }
  }
}

//PROFILE DELETION CONFIRMATION MODAL
.profileDeletionConfirm {
  .modal-header {
    justify-content: center;
    color: #fff;
    background-color: var(--primaryColor);

    span {
      vertical-align: middle;
      line-height: 27px;
      margin-left: 6px;
    }
  }

  .modal-body {
    input {
      width: 200px;
      margin: auto;
    }
  }

  .modal-footer {
    display: inherit;
  }
}


//SUPER POWER PAGE CSS

.parental-superpower-screen {
  .routeBack-flat {
    display: none;

    @media (max-width:575px) {
      display: inline-block;
      position: fixed;
      top: 22px;
      z-index: 99;
      margin-bottom: 0;
    }
  }

  .dashboard-family-tabs {
    .nav-tabs {
      border: none;

      li {
        margin-right: 24px;

        a {
          border: none;
          font-size: var(--font-15);
          line-height: 26px;
          padding: 0px 0px 3px 0px;
          color: var(--tertiaryGrayColor) !important;
          position: relative;

          &.active {
            color: var(--primaryColor) !important;
          }

          span {
            font-weight: 200;
          }

          &.active::after {
            content: "";
            position: absolute;
            width: 48px;
            background: var(--primaryColor);
            height: 2px;
            bottom: -2px;
            left: 0;
            border-radius: 2px;
          }
        }

        &.shopping-inTabs {
          a {
            font-size: var(--font-18);
            color: var(--primaryColor) !important;
            line-height: 30px;
            font-weight: 500;
            padding: 0px;
          }
        }
      }
    }

    .tab-content {
      margin-top: 21px;
    }
  }

  .superpower-links {

    .superpower-link-single {
      font-size: var(--font-16);
      line-height: 20px;
      color: var(--primaryColor);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0px;
      // border-bottom: 1px solid #f4f5f5;
      cursor: pointer;
      font-weight: 500;

      &.active {
        color: var(--tertiaryGrayColor);
      }

      span {
        svg {
          width: 20px;
          margin-right: 10px;
          margin-top: -4px;
        }
      }

      img {
        width: 20px;
      }

      @media(max-width:575px) {
        font-size: var(--font-15);
        border-bottom: 1px solid #f4f5f5;

        span {
          svg {
            width: 16px;
          }
        }
      }
    }
  }

  @media (max-width:575px) {
    padding-top: 27px;
  }
}


//FAMILY MEMBER INVITE PAGE CSS
.familyInvitation-tile {

  h1,
  .h1 {
    font-size: var(--font-25);
    font-weight: 100;
    margin-bottom: 25px;
    color: var(--primaryColor);
    font-weight: 700;
    line-height: 21px;

    .goBack {
      font-size: var(--font-18);
      color: var(--primaryGrayColor);
      font-weight: 500;
      white-space: nowrap;
      float: left;
      line-height: 35px;

      span {
        margin-left: -10px;
      }

      img {
        margin-top: -3px;
      }
    }

    @media (max-width:575px) {
      padding: 15px 0px 10px;
      font-size: 18px !important;
    }
  }

  .routeBack-abs {
    top: -1px;

    @media (max-width:575px) {
      top: 22px;

      img {
        margin-top: 0 !important;
      }
    }
  }

  .invitationTile-wrapper {
    display: flex;
    margin: 50px auto 0px;
    justify-content: center;
    column-gap: 20px;

    .invitationTile-box {
      width: 100%;
      padding: 21px 20px 32px 20px;
      border-radius: var(--borderRadiusXL);
      box-shadow: $cardShadow;
      cursor: pointer;
      position: relative;

      .create-account {
        height: 121px;
        margin: 0px auto;

        img {
          max-height: 121px;
          width:100%;
        }

        @media (max-width:991px) {
          width: 100%;
        }
      }

      h3,
      .h3 {
        font-size: var(--font-22);
        line-height: 32px;
        color: var(--primaryColor);
        font-weight: 600;
        margin-bottom: 0px;
        font-family: 'GothamXLight';
      }

      p {
        font-size: var(--font-14);
        line-height: var(--font-20);
        color: var(--tertiaryGrayColor);
        font-weight: 400;
        margin-bottom: 0px;
        margin-top: 4px;
      }

      .tile-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background-repeat: no-repeat;
      }

      @media (max-width:768px) {
        margin-bottom: 30px;
      }

      @media (max-width:575px) {
        // width: 100%;
        // height:308px;
      }
    }

    @media (max-width:768px) {
      flex-wrap: wrap;
    }

    @media (max-width:575px) {
      margin-top: 0;
    }
  }

  .doLater {
    margin-top: 150px;

    @media (max-width:767px) {
      margin-top: 40px;
    }
  }
}

.inviteChild {
  position: relative;

  h1,
  .h1 {
    font-size: var(--font-25);
    color: var(--primaryColor);
    font-weight: 700;
    // display: flex;
    // justify-content: space-between;
    margin-bottom: 0;
    padding-bottom: 25px;
    line-height: 21px;
    text-align: center;

    @media (max-width:1024px) {
      font-size: var(--font-25);
      margin-bottom: 15px;
    }

    @media (max-width:575px) {
      font-size: 18px !important;
      text-align: center;
      padding: 15px 0px 10px;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 9;
    }
  }

  .invite_child-title {
    font-size: var(--font-25);
    color: var(--primaryColor);
    font-weight: 700;
    text-align: center;
    margin-top: 40px;

    @media (max-width:575px) {
      font-size: var(--font-27);
      text-align: left;
    }
  }

  .routeBack-abs {
    position: absolute;
    top: 0px;

    @media (max-width:575px) {
      position: fixed;
      top: 20px;
      left: 15px;
      margin-bottom: 0;
      z-index: 99;
    }
  }

  .goBack {
    font-size: var(--font-18);
    color: var(--primaryGrayColor);
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 31px;

    img {
      margin-top: -3px;
    }
  }

  .form-box {
    width: 400px;
    margin: 31px auto 0px;

    .form-group {
      margin-bottom: 50px;

      .genderGrp {
        button {
          background-color: #f4f5f5;
          color: var(--secondaryGrayColor);
          font-size: var(--font-12);
          line-height: 16px;
          border: none;
          padding: 16px 30px;
          margin-right: 15px;
          font-weight: 500;
          border-radius: var(--borderRadiusS);

          &.active {
            background-color: var(--primaryColor);
            color: #fff;
          }

          @media (max-width:575px) {
            padding: 10px 30px;
          }
        }
      }

      .careGiver {
        label {
          margin-bottom: 10px;
        }

        .parentsList {
          .parentSingle {
            margin-right: 10px;
            float: left;

            li {
              width: 64px;
              height: 64px;
              background: #f4f5f5;
              list-style: none;
              text-align: center;
              border-radius: 32px;
              display: inline-block;
              margin-right: 16px;
              cursor: pointer;
              overflow: hidden;

              &:last-child {
                margin-right: 0px;
              }

              &.active {
                border: 2px solid var(--primaryColor);
              }
            }
          }

          .addNewParent {
            float: left;
            width: 64px;
            height: 64px;
            background: #f4f5f5;
            list-style: none;
            text-align: center;
            line-height: 60px;
            border-radius: 32px;
            cursor: pointer;

            img {
              width: 20px;
            }
          }
        }
      }

      @media (max-width:575px) {
        margin-bottom: 43px;
      }
    }

    @media (max-width:575px) {
      margin-top: 30px;
    }
  }

  .react-datepicker-popper {
    .react-datepicker {
      .react-datepicker__month-container {
        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day--selected {
              background-color: var(--primaryColor);
            }

            .react-datepicker__day--keyboard-selected {
              background-color: transparent;
              color: #000;
            }
          }
        }
      }
    }
  }

  .bottom-fixed-buttons {
    @media (max-width:575px) {
      position: fixed;
      width: 100%;
      bottom: 0px;
      background: #fff;
      z-index: 9;
      padding: 10px 15px 30px;
      left: 0px;
    }
  }

  @media (max-width:575px) {
    margin-top: 0;
  }
}

.child-invite-spending {
  h1.heading {
    font-size: var(--font-20);
    color: var(--primaryColor);
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    line-height: 21px;
    padding-bottom: 20px;

    @media (max-width:575px) {
      padding-top: 14px;
      position: sticky;
      top: 0px;
      background-color: #fff;
      display: block;
      text-align: center;
      width: 100%;
      padding-bottom: 0px;
      font-size: var(--font-18);
      line-height: 30px;
    }
  }

  .add-spending-btn {
    .add-relation-btn {
      @media (max-width:575px) {
        position: fixed;
        top: 19px;
        right: 15px;
        z-index: 9;
        background: transparent;
        color: var(--primaryColor) !important;
        padding: 0 !important;
        width: auto;
        min-width: auto;
      }
    }

    @media (max-width:575px) {
      position: sticky;
      top: 0;
      z-index: 99;
    }
  }



  .single-spending-tab {

    h1,
    .h1 {
      background-color: #f4f5f5;
      color: var(--secondaryGrayColor);
      font-size: var(--font-13);
      margin-bottom: 0;
      margin-top: 0;
      padding: 9px 12px;

      @media (max-width:575px) {
        color: var(--tertiaryGrayColor);
        font-size: var(--font-13);
        background-color: #f4f5f5;
        padding: 9px 15px;
        margin-bottom: 0px;
        margin-top: 0px;
      }
    }

    .spending-row {
      padding: 15px 12px;
      // border-bottom: 1px solid #f4f5f5;

      h3,
      .h3 {
        font-size: var(--font-14);
        line-height: 24px;
        color: var(--primaryColor);

        margin-bottom: 0px;

        .custom-switch {
          padding-left: 1.75rem;

          label {
            &::before {
              left: -1.75rem;
            }

            &::after {
              left: calc(-1.75rem + 2px);
            }
          }
        }

        .settingTitle {
          line-height: 24px;
          display: inline-block;
        }

        .inputGrp {
          position: relative;
          display: inline-block;
          float: right;

          span {
            position: absolute;
            top: 0px;
          }

          span.ft {
            left: 48px;
          }

          input[type="text"],
          input {
            width: auto;
            height: 29px;
            text-align: right;
            padding-left: 11px;
            padding-top: 0;
            padding-bottom: 4px;
            border: none;
            color: var(--primaryColor);

            &:disabled {
              background-color: transparent;
              color: var(--tertiaryGrayColor);
              border-color: transparent;
            }

            &::placeholder {
              color: var(--tertiaryGrayColor);
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }

            @media (max-width: 575px) {
              border: none;
              padding-bottom: 0;
              height: auto;
            }
          }

          &.ft {
            input {
              padding-right: 14px;
              text-align: right;
            }
          }
        }

        span.info-icon{
          svg{
            cursor: pointer;
            margin-left: 10px;
            margin-top: -1px;
            vertical-align: middle;
            width: 21px;
          }
        }

        span:not(.info-icon) {
          float: right;
          color: var(--tertiaryGrayColor);
          font-size: var(--font-16);
          line-height: 24px;
          font-weight: 500;
        }

        img {
          width: 21px;
          margin-left: 10px;
          vertical-align: middle;
          margin-top: -1px;
          cursor: pointer;
        }

        @media (max-width:575px) {
          font-size: var(--font-16);
        }
      }

      @media (max-width:575px) {
        padding: 17px 15px;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &:first-child {

      h1,
      .h1 {
        margin-top: 0px;
      }
    }
  }

  .mobile-fixed-btns {
    @media (max-width:575px) {
      position: fixed;
      width: 100%;
      background: #fff;
      z-index: 9;
      left: 0;
      margin-bottom: 0 !important;
      padding: 10px 15px 30px;
      bottom: 0;
      margin-top: 0 !important;
    }
  }

  @media (max-width:575px) {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 50px;
  }
}

.inviteSenior {
  position: relative;

  h1,
  .h1 {
    font-size: var(--font-25);
    color: var(--primaryColor);
    font-weight: 700;
    // display: flex;
    // justify-content: space-between;
    margin-bottom: 0;
    padding-bottom: 25px;
    line-height: 21px;
    text-align: center;

    @media (max-width:575px) {
      font-size: 18px !important;
      text-align: center;
      padding: 15px 0px 10px;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 9;
    }
  }

  .invite_child-title {
    font-size: var(--font-25);
    color: var(--primaryColor);
    font-weight: 700;
    text-align: center;
    margin-top: 40px;

    @media (max-width:575px) {
      font-size: var(--font-27);
      text-align: left;
    }
  }

  .routeBack-abs {
    position: absolute;
    top: 0px;

    @media (max-width:575px) {
      position: fixed;
      top: 20px;
      left: 15px;
      margin-bottom: 0;
      z-index: 99;
    }
  }

  .goBack {
    font-size: var(--font-18);
    color: var(--primaryGrayColor);
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 31px;

    img {
      margin-top: -3px;
    }
  }

  &.step1{
    .sub-title{
      font-size:var(--font-16);
      font-weight: 600;
      margin-top:30px;
      text-align: center;
    }
    .tiles-selection{
      display: flex;
      margin-top: 30px;
      column-gap: 20px;
      justify-content: center;

      .invitationTile-box {
        width: 100%;
        padding: 21px 20px 32px 20px;
        border-radius: var(--borderRadiusXL);
        box-shadow: $cardShadow;
        cursor: pointer;
        position: relative;
        width:343px;

        @media (max-width:575px){
          width:100%;
        }
  
        .create-account {
          height: 121px;
          margin: 0px auto;
  
          img {
            max-height: 121px;
            width:100%;
          }
  
          @media (max-width:991px) {
            width: 100%;
          }
        }
  
        h3,
        .h3 {
          font-size: var(--font-22);
          line-height: 32px;
          color: var(--primaryColor);
          font-weight: 600;
          margin-bottom: 0px;
          font-family: 'GothamXLight';
        }
  
        p {
          font-size: var(--font-14);
          line-height: var(--font-20);
          color: var(--tertiaryGrayColor);
          font-weight: 400;
          margin-bottom: 0px;
          margin-top: 4px;
        }
      }

      @media (max-width:575px){
        flex-wrap: wrap;
      }
    }
  }

  .form-box {
    width: 400px;
    margin: 31px auto 0px;

    .inviteAdultDropdown {
      select {
        border: none;
        font-size: var(--font-16);
        font-weight: 400;
        border-bottom: 1px solid var(--primaryGrayColor);

        &:focus {
          box-shadow: none;
        }
      }
    }

    .form-group {
      margin-bottom: 50px;

      select {
        border-radius: var(--borderRadiusM);
        padding-left: 0px;
        text-align: center;
        margin-left: 10%;
      }

      .careGiver {
        .parentsList {
          .parentSingle {
            margin-right: 10px;
            float: left;

            li {
              width: 64px;
              height: 64px;
              background: #f4f5f5;
              list-style: none;
              text-align: center;
              border-radius: 32px;
              display: inline-block;
              margin-right: 16px;
              cursor: pointer;
              overflow: hidden;
              border: 2px solid transparent;

              &:last-child {
                margin-right: 0px;
              }

              &.active {
                border: 2px solid var(--primaryColor);
              }
            }
          }

          .addNewParent {
            float: left;
            width: 64px;
            height: 64px;
            background: #f4f5f5;
            list-style: none;
            text-align: center;
            line-height: 60px;
            border-radius: 32px;
            cursor: pointer;

            img {
              width: 20px;
            }
          }
        }
      }

      @media (max-width:480px) {
        margin-bottom: 32px;
      }
    }
  }

  .kids-listing {
    margin-bottom: 80px;

    .kid-single {
      display: flex;
      align-items: center;
      padding: 15px 0px;
      border-bottom: 1px solid $grey-2;

      .user {
        margin-right: 12px;

        img {
          width: 46px;
        }
      }

      .user-name {

        h3,
        .h3 {
          font-size: var(--font-14);
          font-weight: 600;
          color: var(--primaryColor);
          line-height: 24px;
          margin-bottom: 0;
        }

        p {
          color: var(--gray5);
          font-size: var(--font-14);
          line-height: 22px;
          margin-bottom: 0;
        }
      }

      select {
        min-width: 120px;
        background-color: #f4f5f5;
        border: none;
        margin-left: auto;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        text-align: left;
        color: var(--primaryGrayColor);
        padding: 10px 12px;
        width: auto;

        &:focus {
          box-shadow: none;
        }

        @media (max-width: 480px) {
          width: 120px;
          font-size: var(--font-12);
        }
      }

      .show-pin {
        margin-left: auto;
        min-width: 120px;

        button{
          width:100%;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  .bottom-fixed-buttons {
    @media (max-width:575px) {
      position: fixed;
      width: 100%;
      background: #fff;
      z-index: 9;
      left: 0;
      margin-bottom: 0 !important;
      padding: 10px 15px 30px;
      bottom: 0;
    }
  }

  .adultInvite-success-wrapper {
    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      // background: #ceedea;
      border-radius: 76px;
      margin: 100px auto 0px;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: var(--primaryColor);
        line-height: 120px;
        text-align: center;

        img {
          width: 56px;
        }
      }

      @media (max-width: 414px) {
        margin-top: 0;
      }
    }

    .successText {
      margin-top: 24px;
      text-align: center;
      margin-bottom: 150px;

      h3,
      .h3 {
        font-weight: 600;
        font-size: var(--font-20);
        line-height: 23px;
        letter-spacing: 0.01em;
        color: var(--primaryColor);
      }
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: calc(100% - 30px);
        bottom: 0px;
        padding-top: 10px;
        z-index: 9;
        padding-bottom: 10px;
        left: 15px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media (max-width:575px) {
      width: 100%;
      margin: auto;
      position: fixed;
      left: 0;
      padding: 0px 35px;
      margin-top: 50%;
    }
  }

  @media (max-width:575px) {
    height: auto;
    // min-height: 100vh;
    padding-bottom: 30px;
  }

}

.inviteAdult {
  position: relative;

  h1,
  .h1 {
    font-size: var(--font-25);
    color: var(--primaryColor);
    font-weight: 700;
    // display: flex;
    // justify-content: space-between;
    margin-bottom: 0;
    padding-bottom: 25px;
    line-height: 21px;
    text-align: center;

    @media (max-width:575px) {
      font-size: 18px !important;
      text-align: center;
      padding: 15px 0px 10px;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 9;
    }
  }

  .invite_child-title {
    font-size: var(--font-25);
    color: var(--primaryColor);
    font-weight: 700;
    text-align: center;
    margin-top: 40px;

    @media (max-width:575px) {
      font-size: var(--font-27);
      text-align: left;
    }
  }

  .routeBack-abs {
    position: absolute;
    top: 0px;

    @media (max-width:575px) {
      position: fixed;
      top: 20px;
      left: 15px;
      margin-bottom: 0;
      z-index: 99;
    }
  }

  .goBack {
    font-size: var(--font-18);
    color: var(--primaryGrayColor);
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 31px;

    img {
      margin-top: -3px;
    }
  }

  .form-box {
    width: 400px;
    margin: 31px auto 0px;

    .inviteAdultDropdown {
      select {
        border: none;
        font-size: var(--font-16);
        font-weight: 400;
        border-bottom: 1px solid var(--primaryGrayColor);

        &:focus {
          box-shadow: none;
        }
      }
    }

    .form-group {
      margin-bottom: 50px;

      select {
        border-radius: var(--borderRadiusM);
        padding-left: 0px;
        text-align: center;
        margin-left: 10%;
      }

      .careGiver {
        .parentsList {
          .parentSingle {
            margin-right: 10px;
            float: left;

            li {
              width: 64px;
              height: 64px;
              background: #f4f5f5;
              list-style: none;
              text-align: center;
              border-radius: 32px;
              display: inline-block;
              margin-right: 16px;
              cursor: pointer;
              overflow: hidden;
              border: 2px solid transparent;

              &:last-child {
                margin-right: 0px;
              }

              &.active {
                border: 2px solid var(--primaryColor);
              }
            }
          }

          .addNewParent {
            float: left;
            width: 64px;
            height: 64px;
            background: #f4f5f5;
            list-style: none;
            text-align: center;
            line-height: 60px;
            border-radius: 32px;
            cursor: pointer;

            img {
              width: 20px;
            }
          }
        }
      }

      @media (max-width:480px) {
        margin-bottom: 32px;
      }
    }
  }

  .kids-listing {
    margin-bottom: 80px;

    .kid-single {
      display: flex;
      align-items: center;
      padding: 15px 0px;
      border-bottom: 1px solid $grey-2;

      .user {
        margin-right: 12px;

        img {
          width: 46px;
        }
      }

      .user-name {

        h3,
        .h3 {
          font-size: var(--font-14);
          font-weight: 600;
          color: var(--primaryColor);
          line-height: 24px;
          margin-bottom: 0;
        }

        p {
          color: var(--gray5);
          font-size: var(--font-14);
          line-height: 22px;
          margin-bottom: 0;
        }
      }

      select {
        min-width: 120px;
        background-color: #f4f5f5;
        border: none;
        margin-left: auto;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 600;
        height: var(--font-36);
        text-align: left;
        color: var(--primaryGrayColor);
        padding: 10px 12px;
        width: auto;

        &:focus {
          box-shadow: none;
        }

        @media (max-width: 480px) {
          width: 120px;
          font-size: var(--font-12);
        }
      }

      .show-pin {
        margin-left: auto;
        min-width: 120px;

        button{
          width:100%;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  .bottom-fixed-buttons {
    @media (max-width:575px) {
      position: fixed;
      width: 100%;
      background: #fff;
      z-index: 9;
      left: 0;
      margin-bottom: 0 !important;
      padding: 10px 15px 30px;
      bottom: 0;
    }
  }

  .adultInvite-success-wrapper {
    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      // background: #ceedea;
      border-radius: 76px;
      margin: 100px auto 0px;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: var(--primaryColor);
        line-height: 120px;
        text-align: center;

        img {
          width: 56px;
        }
      }

      @media (max-width: 414px) {
        margin-top: 0;
      }
    }

    .successText {
      margin-top: 24px;
      text-align: center;
      margin-bottom: 150px;

      h3,
      .h3 {
        font-weight: 600;
        font-size: var(--font-20);
        line-height: 23px;
        letter-spacing: 0.01em;
        color: var(--primaryColor);
      }
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: calc(100% - 30px);
        bottom: 0px;
        padding-top: 10px;
        z-index: 9;
        padding-bottom: 10px;
        left: 15px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media (max-width:575px) {
      width: 100%;
      margin: auto;
      position: fixed;
      left: 0;
      padding: 0px 35px;
      margin-top: 50%;
    }
  }

  @media (max-width:575px) {
    height: auto;
    // min-height: 100vh;
    padding-bottom: 30px;
  }

}

.scanQr-inviteChild {
  // margin-top: -62px;

  h1,
  .h1 {
    font-size: var(--font-30);
    margin-bottom: 25px;
    color: var(--primaryColor);
    font-weight: 700;
    position: relative;

    .user-avatar {

      @media (max-width:575px) {
        width: 32px;
        height: 32px;
      }
    }

    .routeBack-abs {
      display: none;

      @media (max-width:575px) {
        display: inline-block;
        top: 5px;
      }
    }


  }

  .goBack {
    font-size: var(--font-18);
    color: var(--primaryGrayColor);
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 31px;

    img {
      margin-top: -3px;
    }
  }

  .done-qr {
    @media (max-width:575px) {
      display: none;
    }
  }

  .form-box {
    width: 600px;
    margin: 0px auto;

    .scanText {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--secondaryGrayColor);
      margin-bottom: 32px;
      text-align: center;
    }

    .qr-code {
      text-align: center;

      img {
        width: 240px;
      }
    }

    .showPin {
      font-size: var(--font-16);
      line-height: 24px;
      color: var(--tertiaryGrayColor);
      text-align: center;
      margin-bottom: 43px;
      margin-top: 34px;
      cursor: pointer;

      p {
        margin-bottom: 0px;
      }

      span.generatedPin {
        font-size: var(--font-44);
        line-height: 54px;
        color: var(--primaryColor);
        font-weight: 300;
        letter-spacing: 0.4px;
        margin-bottom: 20px;
        display: inline-block;
      }
    }
  }

  @media (max-width:575px) {
    padding-top: 15px;
  }
}

.changePin-wrapper {
  .goBack {
    span {
      font-size: var(--font-18);
      line-height: 22px;
      font-weight: 500;
      color: var(--primaryColor);

      img {
        margin-top: -3px;
      }
    }
  }

  .subHeading {
    color: var(--secondaryGrayColor);
    font-size: var(--font-22);
    line-height: 24px;
    text-align: center;
    margin-top: 60px;
    font-weight: 100;
  }

  .pinBoxes {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .pinBox {
      margin-right: 16px;

      input {
        background: #f4f5f5;

        box-sizing: border-box;
        width: 72px;
        height: 72px;
        border-radius: var(--borderRadiusM);
        border: 2px solid transparent;
        text-align: center;

        &:focus {
          border: 2px solid var(--primaryColor);
          outline: none;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.addBank-init {
  width: 400px;
  margin: 55px auto;
  text-align: center;

  button {
    display: block;
    margin: 0px auto 20px auto;

    &:last-child {
      margin: 0px auto 0px auto;
    }
  }

  button.mobileAddBankBtn {
    display: none;

    @media (max-width: 575px) {
      display: initial;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    margin: 40px auto !important;
  }
}

.addBank-min {
  position: absolute;
  right: 0;
  top: 0px;
}

//TIMELINE CHILD SELECTION MODAL CSS
.timeline-child-selection-modal {
  .child-listing {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;

    ul {
      padding: 0px;
      margin-bottom: 0px;

      li {
        list-style: none;
        margin-right: 10px;
        display: inline-block;
        text-align: center;
        overflow: hidden;
        cursor: pointer;

        img {
          width: 48px;
          height: 48px;
          min-width: 48px;
          min-height: 48px;
          background: #f4f5f5;
          box-sizing: border-box;
          border-radius: 24px;
        }

        &:last-child {
          margin: 0px;
        }

        &.active {
          img {
            border: 2px solid var(--primaryColor);
          }

        }

        p {
          margin-bottom: 0;
          font-size: var(--font-12);
        }
      }
    }
  }

  .desktop-filters-dropdown {
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    label {
      width: 50%;
      min-width: 50%;
      margin-bottom: 0;
      font-size: var(--font-14);
    }

    select {
      width: 100%;
      background-color: #f4f5f5;
      border: none;
      margin-right: 10px;
      font-size: var(--font-14);
      line-height: var(--font-19);
      font-weight: 600;
      height: var(--font-36);
      color: var(--primaryGrayColor);
      border-radius: 12px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

//PARENT TIMELINE SCREEN CSS
.page-parent-timeline {

  .heading-right-icon-list {
    display: none !important;
  }
}

.timelines-listing-wrapper {
  position: relative;

  .timeline-dispute {
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 600;
    font-size: 1rem;

    @media (max-width:575px) {
      top: 12px;
      font-size: var(--font-13);
      position: fixed;
      left: 15px
    }
  }

  .timeline-info-icon {
    position: absolute;
    right: 0;
    top: -49px;

    @media (max-width:1024px) {
      top: -28px;
    }

    @media (max-width:575px) {
      top: 12px;
      position: fixed;
      right: 15px;
    }
  }

  .search-timeline {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-bottom: 15px;
    background: #fff;

    .searchBox {
      border-radius: 12px;
      position: relative;
      width: 343px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;

      input {
        border: none;
        padding: 16px 20px 16px 48px;
        height: auto;
        border-radius: 12px;
        background-color: #f4f5f5;
        border: 1px solid transparent;
        transition: all 0.7s;

        &:focus {
          border: 1px solid var(--primaryColor);
        }
      }

      img.searchIcn {
        position: absolute;
        top: 21px;
        left: 16px;
      }

      .filterIcon {
        position: absolute;
        top: 16px;
        cursor: pointer;
        right: 20px;
        width: 21px;
      }

      @media (max-width:1024px) {
        width: 330px;
      }

      @media (max-width:992px) {
        width: calc(50% - 15px);
      }

      @media(max-width:575px) {
        border-radius: 6px;

        input {
          height: 60px;
          border-radius: 8px;
        }

        img.searchIcn {
          top: 23px;
        }

        img.closeFilter {
          top: 17px;
          width: 12px;
        }
      }
    }

    .closeFilter {
      cursor: pointer;
      font-weight: 500;
      font-size: var(--font-14);
      color: var(--primaryColor);
      transition: all 0.5s;
    }

    .child-listing {
      display: flex;
      align-items: center;
      column-gap: 20px;

      .mobile-activity-label {
        font-size: var(--font-14);
        font-weight: 600;
        color: var(--primaryColor);
        display: none;

        select {
          width: 130px;
          background-color: #f4f5f5;
          border: none;
          margin-right: 10px;
          font-size: var(--font-14);
          line-height: var(--font-19);
          font-weight: 600;
          height: var(--font-36);
          color: var(--primaryGrayColor);
          border-radius: 12px;

          &:focus {
            box-shadow: none;
          }
        }

        @media (max-width:575px) {
          display: initial;
        }
      }

      ul {
        padding: 0px;
        margin-bottom: 0px;
        text-align: right;
        display: flex;

        li {
          list-style: none;
          margin-right: 10px;
          width: 36px;
          height: 36px;
          min-width: 36px;
          min-height: 36px;
          background: #f4f5f5;
          text-align: center;
          border-radius: 24px;
          overflow: hidden;
          box-sizing: border-box;
          cursor: pointer;


          &:last-child {
            margin: 0px;
          }

          &.active {
            border: 2px solid var(--primaryColor);
          }

          @media (max-width:575px) {
            margin-right: 5px;
          }
        }
      }
    }

    @media (max-width:575px) {
      position: sticky;
      top: 44px;
      padding-top: 35px;
      padding-bottom: 0px;
      margin-bottom: 15px;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      z-index: 9;

      .searchBox {
        width: 100%;
        // margin-bottom: 20px;
      }

      .child-listing {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .desktop-filters-dropdown {
    margin-bottom: 20px;

    select {
      width: 150px;
      background-color: #f4f5f5;
      border: none;
      margin-right: 10px;
      font-size: var(--font-14);
      line-height: var(--font-19);
      font-weight: 600;
      height: var(--font-36);
      color: var(--primaryGrayColor);
      border-radius: 12px;

      &:focus {
        box-shadow: none;
      }
    }

    @media (max-width:575px) {
      display: none;
    }
  }

  .selection-filter {
    select {
      width: 180px;
      background-color: #f4f5f5;
      border: none;
      margin-right: 10px;
      font-size: var(--font-16);
      line-height: var(--font-19);
      font-weight: 600;
      height: var(--font-36);
      color: var(--primaryGrayColor);

      &:focus {
        box-shadow: none;
      }

      @media (max-width: 480px) {
        width: 120px;
        height: 32px;
        font-size: var(--font-12);
      }
    }



  }

  .searchTimelineData {
    position: relative;

    input {
      padding-left: 33px;
    }

    span {
      position: absolute;
      top: 5px;
    }
  }

  .recentEvents {
    font-size: var(--font-13);
    line-height: 16px;
    color: #bcc0c5;
    margin-top: 24px;
    font-weight: 500;
  }

  .timeline-table {
    margin-top: 8px;

    .timeline-single {
      display: flex;
      align-items: center;
      padding: 16px 0px;
      // border-bottom: 1px solid #f4f5f5;

      .avatar {
        margin-right: 8px;

        .noAvtar {
          width: 32px;
          height: 32px;
          background-color: #f4f5f5;
          border-radius: 16px;
          text-align: center;
          line-height: 28px;
          overflow: hidden;

          img {}
        }
      }

      .timelineDesc {
        width: 100%;

        span.dateTime {
          display: block;
          color: var(--tertiaryGrayColor);
          font-weight: normal;
          font-size: var(--font-12);
          line-height: 16px;
          letter-spacing: 0.01em;
        }

        span:nth-child(1) {
          font-size: var(--font-16);
          line-height: 24px;
          display: inline-block;
          color: #000;
        }

        span:nth-child(2) {
          font-size: var(--font-16);
          line-height: 24px;
          font-weight: 600;
          display: inline-block;
        }
      }

      .amount {
        font-size: var(--font-16);
        line-height: 24px;
        width: 100px;
        text-align: right;
        color: var(--primaryColor);

        &.negative {
          color: var(--errorColor);
        }
      }
    }
  }

  .month-name {
    margin-top: 0px;
    color: var(--secondaryGrayColor);
    font-size: var(--font-13);
    background-color: #f4f5f5;
    padding: 9px 12px;
    margin-bottom: 0;

    @media (max-width:575px) {
      padding: 9px 15px;
    }
  }
}

.timeLineCustomize {

  h1,
  .h1 {
    margin-bottom: 30px;
  }

  .form-group {
    position: relative;
    margin-bottom: 30px;

    span.calIcon {
      position: absolute;
      right: 0px;
      top: 42px;
    }
  }

  button.btn-gradient {}
}

@media (min-width: 320px) and (max-width: 980px) {
  .navbar {
    padding: 0.5rem 1rem !important;
  }
}

/*404*/

//TIMELINE CREATE DISPUTE CSS
.page-parent-createDispute {
  position: relative;

  .heading-right-icon-list {
    display: none !important;
  }

  .routeBack-abs {
    top: 0
  }
}

.timeline-create-dispute {
  .create-fields {
    .field-single {
      display: flex;
      padding: 17px 0px;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .label {
        font-size: var(--font-16);
        line-height: 24px;
      }

      select {
        background-color: #f4f5f5;
        border: none;
        font-size: var(--font-16);
        line-height: var(--font-19);
        font-weight: 700;
        height: auto;
        padding: 10px 12px;
        color: var(--primaryGrayColor);
        border-radius: 12px;
        width: 240px;
        appearance: none;
        -webkit-appearance: none;

        &:focus {
          box-shadow: none;
        }

        @media (max-width:575px) {
          width: auto;
          min-width: 200px;
          max-width: 200px;
        }
      }
    }
  }

  .reason-attributes {
    margin-top: 50px;

    .form-group {

      h3,
      .h3 {
        font-size: var(--font-14);
        margin-bottom: 5px;
        color: var(--secondaryGrayColor);
        font-weight: 500;
      }
    }
  }

  .bottom-fixed-buttons {
    @media (max-width:575px) {
      position: fixed;
      width: 100%;
      bottom: 0px;
      background: #fff;
      z-index: 9;
      left: 0px;
      padding: 10px 15px 30px;
    }
  }

  .dispute-success-wrapper {
    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      // background: var(--primaryColor);
      border-radius: 76px;
      margin: 100px auto 0px;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: var(--primaryColor);
        line-height: 120px;
        text-align: center;

        img {
          width: 56px;

          @media (max-width: 414px) {
            width: 36px;
          }
        }

        @media (max-width: 414px) {
          width: 90px;
          height: 90px;
          border-radius: 60px;
          background: #f27405;
          line-height: 90px;
        }
      }

      @media (max-width: 414px) {
        width: 112px;
        height: 112px;
        padding: 11px;
        border-radius: 56px;
      }
    }

    .successText {
      margin-top: 24px;
      text-align: center;

      h3,
      .h3 {
        font-weight: 600;
        font-size: var(--font-20);
        line-height: 23px;
        letter-spacing: 0.01em;
        color: var(--primaryColor);
      }
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: calc(100% - 30px);
        bottom: 0px;
        padding-top: 10px;
        z-index: 9;
        padding-bottom: 10px;
        left: 15px;
      }
    }
  }

  .dispute-error-wrapper {
    .successMarkBox {
      width: 152px;
      height: 152px;
      padding: 16px;
      // background: var(--primaryColor);
      border-radius: 76px;
      margin: 100px auto 0px;

      .checkcover {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        background: var(--errorColor);
        line-height: 120px;
        text-align: center;

        img {
          width: 56px;

          @media (max-width: 414px) {
            width: 36px;
          }
        }

        @media (max-width: 414px) {
          width: 90px;
          height: 90px;
          border-radius: 60px;
          background: #f27405;
          line-height: 90px;
        }
      }

      @media (max-width: 414px) {
        width: 112px;
        height: 112px;
        padding: 11px;
        border-radius: 56px;
      }
    }

    .successText {
      margin-top: 24px;
      text-align: center;

      h3,
      .h3 {
        font-weight: 600;
        font-size: var(--font-20);
        line-height: 23px;
        letter-spacing: 0.01em;
        color: var(--primaryColor);
      }
    }

    .bottom-fixed-buttons {
      @media (max-width:575px) {
        position: fixed;
        width: calc(100% - 30px);
        bottom: 0px;
        padding-top: 10px;
        z-index: 9;
        padding-bottom: 10px;
        left: 15px;
      }
    }
  }
}

* {
  // transition: all 0.6s;
}

html {
  height: 100%;
}

body {
  // font-family: "Lato", sans-serif;
  color: #888;
  margin: 0;
}

#main {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: var(--font-50);
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }

  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

/*Dashboard Box Design*/

.box {
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 10px 25px;
  text-align: right;
  display: block;
  background-color: #fff;
  margin-top: 60px;
}

.box-icon {
  background-color: #fff;
  display: table;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 0 auto 12px auto;
  margin-top: -61px;
  padding: 3px;
  border: 1px solid #cecece;
}

.box-icon img {
  color: #fff;
  display: table-cell;
  text-align: center;
  padding: 10px;
  vertical-align: middle;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.info h4,
.h4 {
  font-size: var(--font-26);
  text-transform: capitalize;
}

.info>p {
  color: #717171;
  font-size: var(--font-16);
  padding-top: 10px;
  text-align: justify;
}

.info>a {
  background-color: #03a9f4;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  transition: all 0.5s ease 0s;
}

.info>a:hover {
  background-color: #0288d1;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  transition: all 0.5s ease 0s;
}

.wallet {
  text-align: left;
  width: 48%;
  float: left;
}

.walletTxt {
  font-size: var(--font-14);
  color: #999999;
}

.walletAmount {
  font-size: var(--font-18);
  font-weight: bold;
  color: #263a46;
  padding: 5px 0px 20px 0px;
}

.setting {
  padding: 7px 10px;
  background: #fff;
  border: 1px dotted #999;
  text-align: center;
  width: 100px;
  margin: 0px auto;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.setting:hover {
  background: #efefef;
}

.table {
  margin: 24px 0px;
}

.add-new-peer {
  justify-content: flex-end;
  padding: 8px 0px;

  button {
    padding: 8px 12px;
    font-size: var(--font-12);
  }
}

.peer-list {
  margin-top: 5px;

  .success {
    color: #6ba56c;
    font-size: var(--font-12);
  }

  .peer-row:not(:last-child) {
    margin-bottom: 16px;
  }

  .peer-row:last-of-type {
    border-bottom: none !important;
  }

  p {
    margin: 0px;
  }

  label {
    font-size: var(--font-12);
    color: #7d7d7d;
  }

  .btn-remove {
    font-size: var(--font-12);
    vertical-align: text-top;
    padding: 0px;
    margin: 0px;
    color: red;
    cursor: pointer;
  }
}

.paginate {
  display: flex;
  flex-direction: row-reverse;

  .pagination {
    li {
      padding: 2px 8px;
      border: 1px solid #ececec;
      border-radius: 3px;
      color: #337ab7;

      &.active {
        background: #337ab7;
        color: #fff !important;

        a {
          color: #fff !important;
        }
      }
    }
  }
}

.userIconDisplayBox {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  margin: 0px auto 20px auto;
  background: #f4f5f5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.authorizePhone {

  h1,
  .h1 {
    margin-bottom: 40px;
  }

  .authorizeBtns {
    display: flex;
    justify-content: space-around;

    .authorizeBtn {
      cursor: pointer;

      span {
        color: var(--primaryColor);
        font-weight: 500;
      }
    }

    .declineBtn {
      cursor: pointer;

      span {
        color: var(--errorColor);
        font-weight: 500;
      }
    }
  }

  .cancelBtn {
    margin-top: 70px;
  }
}

.commonModal {
  padding: 40px 30px;
  position: relative;

  span.close {
    background: #fff;
    position: absolute;
    right: 10px;
    top: 10px;
    text-align: center;
    font-size: var(--font-32);
    font-weight: 700;
    border-radius: 30px;
    line-height: 40px;
    cursor: pointer;
    opacity: 1;
    float: none;
    display: flex;
    align-items: center;

    img {
      width: 24px;

      @media (max-width:575px) {
        width: 22px;
      }
    }

    &:hover {
      opacity: 1 !important;
    }

    @media (max-width:575px) {
      right: 7px;
      top: 7px;
    }
  }

  @media (max-width:575px) {
    padding: 30px 20px 20px;
    font-size: var(--font-14);
    line-height: 23px;
  }
}

.page-parent-child-changePassword {
  .dashboard-heading {
    text-align: center;

    .heading-right-icon-list {
      display: none !important;
    }
  }

  .changePassword-wrapper {
    .routeBack-abs {
      top: 0px;
      position: absolute;

      img {
        margin-top: 0px;
      }
    }
  }
}


.page-parent-changePassword {
  .dashboard-heading {
    text-align: center;

    .heading-right-icon-list {
      display: none !important;
    }
  }

  .changePassword-wrapper {
    position: relative;

    .routeBack-abs {
      top: -57px;
      position: absolute;

      img {
        margin-top: 0px;
      }

      @media (max-width:575px) {
        top: 15px !important;
      }
    }
  }
}

.changePassword-wrapper {

  .password-info {
    font-size: var(--font-14);
    margin-bottom: 31px;
  }

  .routeBack-abs {
    @media (max-width:575px) {
      position: fixed !important;
      top: 20px !important;
      left: 15px;
      z-index: 9;
    }
  }

  .form-box {
    width: 400px;
    margin: auto;

    .form-group {
      margin-bottom: 30px;
      position: relative;

      .passwordEye {
        position: absolute;
        right: 0;
        bottom: 9px;
      }

    }

    .desktop-update {
      @media (max-width:575px) {
        display: none !important;
      }
    }

    .mobile-top-btn {
      display: none !important;

      @media (max-width:575px) {
        display: inline-block !important;
        position: fixed;
        top: 20px;
        right: 15px;
        font-size: var(--font-14) !important;
        font-weight: normal;
        background: transparent !important;
        color: var(--primaryColor) !important;
        padding: 0 !important;
        width: auto;
        min-width: auto !important;
        margin-bottom: 0;
        z-index: 9;

      }
    }

    .passwordRegMatch {
      ul {
        padding: 0px;

        li {
          list-style: none;
          font-size: var(--font-13);
          display: flex;
          align-items: center;
          margin-bottom: 4px;

          span {
            width: 14px;
            height: 14px;
            background: transparent;
            display: inline-block;
            margin-right: 10px;
            border-radius: 14px;
            vertical-align: middle;
            border: 2px solid var(--secondaryGrayColor);
          }

          img.MatchImg {
            margin-right: 10px;
          }
        }
      }
    }
  }

  @media (max-width:575px) {
    margin-top: 30px;
  }
}

//RESPONSIVE MEDIA QUERY START
@media screen and (max-width: 10000px) and (min-width: 1200px) {
  .main-container {
    width: 1100px;
    margin: auto;
    // margin:0px auto 0px 40px;
    padding: 0px;
  }

  .loader-div {
    max-width: 800px;
  }
}



@media (max-width: 1024px) {
  .goBackInner {
    height: 30px;
    margin-top: 0;
  }

  .familyInvitation-tile {

    h1,
    .h1 {
      font-size: var(--font-25);
    }
  }

  .topLanguageChange {
    a.dropdown-toggle {
      padding: 0px;
      line-height: 23px;
      font-size: var(--font-14);
      height: 30px;
      vertical-align: top;
      color: var(--tertiaryGrayColor);
    }
  }

  .dashboard-notification-wrapper {
    .openNotification {
      a {
        line-height: 30px !important;
        font-size: 17px !important;
        height: 30px !important;

        // span.fa {
        //   font-size: var(--font-14) !important;
        // }

        span.notification-unred {
          width: 6px !important;
          height: 6px !important;
          border-radius: 6px !important;
          top: 16px !important;
          right: -1px !important;
        }
      }
    }
  }

  .menuHelp {
    a {
      // line-height: 30px !important;
      // font-size: 17px !important;
      // height: 30px !important;
    }
  }

  .addBank-min {
    top: 0;

    button {
      padding: 6px 20px !important;
    }
  }

  .shop-wrapper {
    .shop-dash-merchants {
      .approvedRetailers {
        .singleRetailer {
          // padding: 10px 15px;

          h4,
          .h4 {
            font-size: var(--font-14);
            line-height: 20px;
          }
        }
      }

      .additionalRetailers {
        .singleRetailer {
          padding: 10px 15px;

          h4,
          .h4 {
            font-size: var(--font-14);
            line-height: 20px;
          }

          .addRetailer {
            button {
              // padding: 9px 8px;
            }
          }
        }
      }
    }
  }

  .dashboard-wrapper {
    .dashboard-right-article {
      .inviteAdult {

        h1,
        .h1 {
          font-size: var(--font-25);
          margin-bottom: 15px;
        }

        .goBack {
          font-size: var(--font-14);
        }
      }

      .addChildRelation {

        h1,
        .h1 {
          font-size: var(--font-25);
          margin-bottom: 15px;
        }

        .goBack {
          font-size: var(--font-14);
        }
      }

      .scanQr-inviteChild {

        h1,
        .h1 {
          font-size: var(--font-25);
          margin-bottom: 15px;
        }

        .goBack {
          font-size: var(--font-14);
        }
      }

      .addBank-init {
        margin: 80px auto;
      }

      .children-setting-wrapper {
        .profile-tab {

          .form-box {
            .form-group {
              button.btn-gradient {
                min-width: auto;
              }
            }
          }
        }

        .children-familytab {

          .childrenPanel {
            .child-single {
              padding: 10px 0px;

              .childAvtar {
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                line-height: 38px;
              }

              .childName {
                font-size: var(--font-14);
                line-height: 20px;
              }
            }
          }

          .adultsPanel {
            .adult-single {
              padding: 10px 0px;

              .childIcon {
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                line-height: 38px;
              }

              .childName {

                h3,
                .h3 {
                  font-size: var(--font-14);
                  line-height: 20px;
                }
              }
            }
          }
        }
      }

      .btn-gradient-outline,
      .btn-gray,
      .btn-gradient,
      .btn-white,
      .btn-secondary {
        padding: 12px 30px;
        font-size: var(--font-13);
        border-radius: 8px;
      }

      .education-wrapper {
        .education-single {

          .edu-thumbnail {
            min-width: 115px;
            min-height: 65px;
            background-size: cover;
          }
        }
      }

      .education-dtl-page {
        .header-top {
          margin-bottom: 30px;
        }
      }

      .child-detail-wrapper {
        .child-detail-breadcrumb {
          .left-arrrow {
            font-size: var(--font-14);
            line-height: 40px;
          }
        }

        .digital-wallet-childDtl {
          padding: 15px;

          h3,
          .h3 {
            font-size: var(--font-20);
            line-height: 30px;
          }

          .wallet-saving-stripe {
            a {
              .wallet-saving-stripe-single {
                padding: 15px 16px;
              }
            }
          }
        }

        .chores-wrapper {
          padding: 15px;

          .chores-header-container {

            h3,
            .h3 {
              font-size: var(--font-20);
              line-height: 30px;

              a.addChoreBtn {
                font-size: var(--font-14);
              }
            }
          }
        }

        .goals-wrapper {
          padding: 15px;

          h3,
          .h3 {
            font-size: var(--font-20);
            line-height: 30px;
          }
        }
      }

      .recentTran-wrapper {

        h2,
        .h2 {
          font-size: var(--font-20);
          line-height: 30px;
        }
      }

      .pendingTran-wrapper {

        h2,
        .h2 {
          font-size: var(--font-20);
          line-height: 30px;
        }

        .singlePendingTransaction {
          table {
            tr {
              td {

                h5,
                .h5 {
                  font-size: var(--font-14);

                  span {
                    font-weight: 600;
                  }
                }

                &:last-child {
                  font-size: var(--font-16);
                }
              }
            }
          }
        }
      }

      .bankingNoteBottom {
        margin-top: 14px;
      }
    }
  }
}

@media (max-width: 991px) {
  .education-dtl-page embed {
    width: 100%;
    height: 400px !important;
  }

  .signUpStepForm-wrapper {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0px 20px 30px;

    .stepNumbers-list {
      .progressWrapper {
        width: 100%;
      }
    }
  }

  .dashboard-wrapper {
    .dashboard-right-article {
      width: calc(100% - 242px);
    }
  }
}

@media (max-width:820px) {
  .dashboard-wrapper {
    .dashboard-right-article {
      margin-left: 200px;
      width: calc(100% - 200px);
    }
  }
}

@media (max-width: 768px) {
  .education-dtl-page embed {
    width: 100%;
    height: 100% !important;
    min-height: 300px;
  }

  .education-dtl-page {
    .mainHeading {
      font-size: 20px !important;
    }
  }

  .main-container {
    padding: 0px 15px;
  }

  .dashboard-wrapper {
    .toggleSidebar {
      position: fixed;
    }

    .dashboard-right-article {
      margin-left: 200px;
      width: calc(100% - 200px);
    }
  }

  .dashboard-wrapper {
    .dashboard-right-article {
      .dashboard-tile {
        // padding: 16px 10px;

        .dashboard-tile-container {
          .dashboard-tile-content {
            .tile-spent.children {}

            .tile-spent.chore {
              // max-width: 110px;
            }

            .tile-childName.chore {
              font-size: var(--font-16);

              span {
                font-size: var(--font-16);
              }
            }

            .tile-bottom {
              .tile-chart {
                .pieChart-customLegends {
                  .singleLegend {
                    .prgBar {
                      width: 40px;
                    }
                  }
                }

                canvas {
                  margin-right: 6px;
                  width: 30px !important;
                  height: 30px !important;
                }
              }

              .choresList {
                img {
                  width: 30px;
                }
              }
            }
          }
        }
      }

      .child-detail-wrapper {
        .displayAllowance {
          padding: 30px 0px;
        }

        .displayChart {
          .chartFrame select {
            font-size: var(--font-15);
          }
        }

        .chores-wrapper {
          .chores-header-container {

            h3,
            .h3 {
              font-size: var(--font-18);
            }

            select {
              // font-size: var(--font-13);
            }
          }
        }

        .goals-wrapper {

          h3,
          .h3 {
            font-size: var(--font-18);
          }

          .goals-progress-container {
            .goals-progress-single {
              .goal-avatar {
                min-width: 50px;
                max-width: 50px;
                min-height: 50px;
                max-height: 50px;
              }
            }
          }
        }
      }

      .digital-wallet-childDtl {

        h3,
        .h3 {
          font-size: 18px !important;
        }

        // .wallet-amount {
        //   .total-amount {
        //     font-size: var(--font-35);
        //     line-height: 44px;
        //   }
        // }

        .wallet-saving-stripe {
          a {
            .wallet-saving-stripe-single {
              padding: 10px 16px !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .dashboard-wrapper {
    // .toggleSidebar {
    //   display: initial;
    //   top: 15px;
    //   right: 10px;
    //   z-index: 999;
    // }

    .dashboard-right-article {
      margin-left: 0px;
      width: 100%;
    }
  }
}

@media (max-width: 640px) {

  .inviteChild {
    .form-box {
      width: 100%;
    }
  }

  .scanQr-inviteChild {
    .form-box {
      width: 100%;
    }
  }

  .inviteAdult {
    .form-box {
      width: 100%;
    }
  }
}

@media (max-width: 575px) {

  .myCal .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
    font-size: var(--font-20);
  }

  .myCal .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
    font-size: var(--font-18);
  }

  .myCal .rbc-calendar .rbc-month-view .rbc-month-header .rbc-header {
    font-size: var(--font-16);
    padding: 6px;
    line-height: 24px;
  }

  .myCal .rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content {
    min-height: 57px;
  }

  .myCal .rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell {
    font-size: var(--font-16);
  }

  .education-dtl-page {
    .mainHeading {}
  }

  .education-wrapper {

    .education-single {
      margin-bottom: 25px;

      .edu-thumbnail {
        min-width: 95px !important;
        margin-left: 20px;
        min-height: 55px !important;

        img {}
      }
    }
  }

  .dashboard-wrapper {
    .dashboard-right-article {
      .child-detail-wrapper {
        .displayChart {
          .pieChart {
            canvas {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {

  .loginLangSelect {
    margin-right: 0px !important;

    .dropdown-menu {
      padding: 0px;
      min-width: auto;
      transform: translate3d(-54px, 14px, 0px);

      a {
        font-size: var(--font-13);
        padding: 3px 10px;
        text-align: left;
      }
    }
  }

  .regoLogin {
    width: 100%;
    margin: 0px auto;
  }

  .education-dtl-page {
    .header-top {
      .right-section {
        .child-listing {
          height: 39px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .regoSignup {

    .confirmPhoneNumWrapper {
      width: 100% !important;

      .confirmNum-InputsGroups {
        input {
          font-size: var(--font-20);
        }
      }
    }
  }

  .landing-selection-wrapper {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  .statementWrapper {
    .goBack {
      font-size: var(--font-14);
    }
  }

  .receiptsWrapper {
    .goBack {
      font-size: var(--font-14);
      margin-bottom: 10px;
    }
  }

  .scheduleTransfer {
    .goBack {
      span {
        font-size: var(--font-14);
      }
    }
  }

  .myCal .rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content {
    min-height: 50px;
  }

  .changePin-wrapper {
    .subHeading {
      font-size: var(--font-18);
    }

    .pinBoxes {
      margin-top: 20px;

      .pinBox {
        input {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  .changePassword-wrapper {
    .form-box {
      width: 100%;

      .form-group {
        margin-bottom: 20px;
      }
    }

    .goBack {
      span {
        font-size: var(--font-14);
      }
    }
  }

  .retailerSetting {

    h1,
    .h1 {
      margin-bottom: 40px;
    }

    .retailerSelection {
      margin-bottom: 20px;

      .limitInputWrapper {
        margin-bottom: 20px;
      }

      .childLimitSingleBox {
        margin-bottom: 10px;
      }

      .enableDisable {
        .enableDisable-row {
          margin-bottom: 10px;

          h3,
          .h3 {
            font-size: var(--font-14);
          }

          .avtar {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
          }
        }
      }
    }
  }

  .add-education-wrapper {
    .child-list {
      margin-bottom: 20px;

      h3,
      .h3 {
        margin-bottom: 5px;
      }
    }

    .form-group {
      margin-bottom: 20px;

      input {
        height: auto;
        padding: 0px;
      }
    }
  }

  .addFundModal-wrapper {
    .form-box {
      .form-group {

        &.amount {
          input {
            line-height: 40px;
          }

          .dollar {
            line-height: 40px;
            top: 2px;
          }
        }
      }
    }
  }

  .transferModal-wrapper {
    .form-box {
      .form-group {

        .careGiver {
          .parentsList {
            padding-bottom: 0px;

            .parentSingle {
              li {
                width: 40px;
                height: 40px;
              }
            }
          }
        }

        &.amount {
          input {
            line-height: 40px;
          }

          .dollar {
            line-height: 40px;
            top: 2px;
          }
        }
      }
    }
  }

  .timeLineCustomize {
    .form-group {
      margin-bottom: 20px;

      input {
        height: auto;
        padding: 0px;
        font-size: var(--font-14);
      }

      span.calIcon {
        top: 32px;
      }
    }

    button {
      margin-top: 20px;
    }
  }

  .allowance-add-wrapper {

    h1,
    .h1 {
      margin-bottom: 25px;
    }

    .allowance-amount {
      input {
        line-height: 40px;
      }

      .dollar {
        line-height: 42px;
      }
    }

    .schedule {
      margin-bottom: 30px;

      h3,
      .h3 {
        .recurringBtnGrp {
          button {
            font-size: var(--font-14);
          }
        }
      }
    }
  }

  .addNewAddress {
    .newAddressForm {
      .form-group {

        input {
          height: auto;
          padding: 0px;
        }
      }

      .defaultAddress {

        h3,
        .h3 {
          font-size: var(--font-16);
        }
      }
    }
  }



  .legendsInfo {
    .legendsBox {
      .singleLegend {
        font-size: var(--font-14);
      }
    }
  }

  .authorizePhone {
    .authorizeBtns {

      .authorizeBtn,
      .declineBtn {
        span {
          font-size: var(--font-14);
        }
      }
    }
  }

  .parent-setting-wrapper,
  .help-wrapper {

    .parentSetting-legaltab,
    .help-single {
      #accordion {
        .card {
          margin-bottom: 0px;

          .card-header {

            h5,
            .h5 {
              line-height: 20px;

              button {
                font-size: var(--font-14);
                line-height: 20px;
                border: none;
                vertical-align: top;
                position: relative;
                padding-right: 20px;

                &.collapsed {
                  span.downArrow {
                    margin-top: 0px !important;
                    position: absolute;
                    right: 0;
                    top: 4px;
                  }
                }

                span.upArrow {
                  margin-top: 0px !important;
                  position: absolute;
                  right: 0;
                  top: 4px;
                }
              }
            }
          }

          .card-body {
            font-size: var(--font-13);
            line-height: 21px;

            .sub-accordion {
              padding-left: 10px;

              .card {
                margin-bottom: 0px;

                .card-header {

                  h5,
                  .h5 {
                    line-height: 20px;

                    button {
                      font-size: var(--font-14);
                      line-height: 20px;
                      border: none;
                      vertical-align: top;
                      position: relative;
                      padding-right: 20px;

                      &.collapsed {
                        span.downArrow {
                          margin-top: 0px !important;
                          position: absolute;
                          right: 0;
                          top: 4px;
                        }
                      }

                      span.upArrow {
                        margin-top: 0px !important;
                        position: absolute;
                        right: 0;
                        top: 4px;
                      }
                    }
                  }
                }

                .card-body {
                  font-size: var(--font-13);
                  line-height: 21px;
                }
              }
            }
          }
        }
      }
    }

    .parentSetting-notifytab {
      .single-spending-tab:first-child {

        h1,
        .h1 {
          font-size: var(--font-14);
        }
      }

      .single-spending-tab {
        .spending-row {
          padding: 9px 0px;
        }
      }
    }
  }

  #connect-container {
    width: 100% !important;
  }

  .dashboard-family-tabs {
    .nav-tabs {
      li.shopping-inTabs {
        a {
          font-size: 15px !important;
        }
      }
    }
  }

  .shop-wrapper {
    .shop-dash-merchants {

      .additionalRetailers {
        .singleRetailer {
          margin-bottom: 10px;
        }
      }

      .approvedRetailers {
        .singleRetailer {

          .childListing {
            ul {
              li {
                width: 25px;
                height: 25px;
                min-width: 25px;
                min-height: 25px;
                line-height: inherit;
              }
            }
          }
        }
      }
    }
  }

  .education-dtl-page {
    .mainHeading {
      font-size: 16px !important;
      line-height: 25px;
    }

    .header-top {
      margin-bottom: 15px !important;

      .left-redirect {
        font-size: var(--font-14);
      }
    }
  }

  .education-wrapper {
    .educationBack {

      span {
        font-size: var(--font-14);
      }

      a {
        font-size: var(--font-16);
      }
    }

    .education-single {
      .edu-thumbnail {
        min-width: 75px !important;
        margin-left: 20px;
        min-height: 45px !important;
      }
    }
  }

  .wallet-page {
    .wallet-wrapper-new {
      .wallet-amount {
        .total-amount {
          font-size: var(--font-35);
        }
      }
    }

    .pendingTran-wrapper {

      h2,
      .h2 {
        font-size: var(--font-16) !important;
        margin-bottom: 0px;
        font-weight: 600;

        span {
          font-family: 'GothamBook';
        }
      }

      .singlePendingTransaction {
        table {
          tr {
            td {
              padding: 15px 0px;

              h5,
              .h5 {
                font-weight: 400;
                font-size: 15px !important;
              }

              &:last-child {
                font-size: var(--font-14);
              }
            }
          }
        }
      }
    }

    .recentTran-wrapper {

      h2,
      .h2 {
        font-size: var(--font-16) !important;
        margin-bottom: 0px;
        font-weight: 600;

        span {
          font-family: 'GothamBook';
        }
      }

      .singlePendingTransaction {
        table {
          tr {
            td {
              padding: var(--font-13) 0px;

              h5,
              .h5 {
                font-size: var(--font-16);
                line-height: 20px;

                span {
                  font-weight: 500;
                }
              }

              &:last-child {
                font-size: var(--font-14);
              }
            }
          }
        }
      }
    }
  }

  .timelines-listing-wrapper {

    .recentTran-wrapper {
      .singlePendingTransaction {
        table {
          tr {
            td {
              padding: 15px 0px;

              h5,
              .h5 {
                font-size: var(--font-16);
                line-height: 20px;

                span {
                  font-weight: 500;
                }
              }

              &:last-child {
                font-size: var(--font-16);
              }
            }
          }
        }
      }
    }
  }

  .inviteChild {
    .form-box {
      .form-group {
        .genderGrp {
          button {
            padding: 12px 24px;
          }
        }

        .careGiver {
          .parentsList {
            .parentSingle {
              li {
                width: 38px;
                height: 38px;
                line-height: 33px;
              }
            }

            .addNewParent {
              width: 38px;
              height: 38px;
              line-height: 33px;

              img {
                width: 10px;
              }
            }
          }
        }
      }
    }
  }

  .inviteAdult {
    .form-box {
      .form-group {
        .careGiver {
          .parentsList {
            .parentSingle {
              li {
                width: 38px;
                height: 38px;
                line-height: 33px;
              }
            }

            .addNewParent {
              width: 38px;
              height: 38px;
              line-height: 33px;

              img {
                width: 10px;
              }
            }
          }
        }
      }
    }
  }

  .children-setting-wrapper {
    .address-tab {

      .addNewLink {
        font-size: var(--font-16);
      }
    }

    .relation-tab {

      .addNewLink {
        font-size: var(--font-16);
      }

      .peers {
        .request-single {
          .childIcon {
            width: 38px;
            min-width: 38px;
            height: 38px;
            min-height: 38px;
            line-height: 34px;
          }
        }
      }
    }

    .profile-tab {
      .form-box {
        width: 100%;
      }
    }
  }

  .mobDeskBtn {
    span.mobileIcn {
      display: initial;
    }

    span.desktopText {
      display: none;
    }
  }

  .dashboard-wrapper {
    .dashboard-right-article {

      .btn-gradient-outline,
      .btn-gray,
      .btn-gradient,
      .btn-white,
      .btn-secondary {
        padding: 9px 25px;
        font-size: var(--font-13);
      }

      .dashboard-tile {
        .dashboard-tile-container {
          .dashboard-tile-content {
            .tile-bottom {
              .tile-chart {
                canvas {
                  width: 51px !important;
                  height: 51px !important;
                }
              }
            }
          }
        }
      }

      .dashboard-family-tabs {
        .nav-tabs {
          li {
            margin-right: 35px;
            white-space: nowrap;
            margin-bottom: 11px;

            a.nav-link {
              // font-size: var(--font-15);
              // line-height: 19px;
            }
          }
        }
      }
    }
  }

  .child-detail-wrapper {
    .chores-wrapper {
      .chores-single-data {
        margin-bottom: 15px;

        table {
          tr {
            td:nth-child(1) {
              // width: 15px;

              img {
                width: 24px;
                height: auto;
              }
            }

            td {

              h5,
              .h5 {}

              p {}
            }

            td:nth-child(4) {
              width: 20px;
            }

            td:nth-child(5) {
              width: 20px;
            }
          }
        }
      }
    }

    .goals-wrapper {
      .goals-progress-container {
        .goals-progress-single {
          margin-bottom: 18px;

          .goal-progress-bar {

            h5,
            .h5 {
              font-size: var(--font-13);
              line-height: 17px;

              span {
                font-size: var(--font-12);
                line-height: 17px;
              }
            }
          }

          .goal-avatar {
            min-width: 40px !important;
            max-width: 40px !important;
            min-height: 40px !important;
            max-height: 40px !important;
          }
        }
      }
    }
  }

  .add-chore-wrapper1 {
    .form-group {
      margin-bottom: 30px;
    }

    .description {
      margin: 30px auto 30px auto;
    }

    .child-list {
      .child-listing {
        ul {
          li {
            .child-content {
              // width: 38px;
              // height: 38px;
              // line-height: 30px;
            }
          }
        }
      }
    }

    .time {

      h3,
      .h3 {
        ul {
          li {
            font-size: var(--font-13);
            line-height: 19px;
            padding: 6px var(--font-13);
          }
        }
      }
    }

    .choreSuggestions {
      .suggestionsList {
        .singleSuggestion {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 414px) {

  .goBackInner {
    height: 24px;
    font-size: var(--font-12);
  }

  // .topLanguageChange {
  //   a.dropdown-toggle {
  //     padding: 0px;
  //     line-height: 24px;
  //     font-size: var(--font-11);
  //     height: 24px;
  //     vertical-align: top;

  //     &::after {
  //       margin-top: -2px;
  //     }
  //   }
  // }

  .dashboard-notification-wrapper {

    .openNotification {
      a {
        line-height: 24px !important;
        font-size: 17px !important;
        height: 24px !important;

        // span.fa {
        //   font-size: 12px !important;
        // }

        span.notification-unred {
          top: 11px !important;
          right: -2px !important;
        }
      }
    }
  }

  .shop-wrapper {
    .shop-dash-merchants {
      .approvedRetailers {
        .singleRetailer {
          span {
            margin-right: 9px;
          }

          h4,
          .h4 {
            -webkit-line-clamp: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 10px;
          }
        }
      }

      .additionalRetailers {
        .singleRetailer {
          span {
            margin-right: 9px;
          }

          h4,
          .h4 {
            -webkit-line-clamp: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 10px;
          }
        }
      }
    }
  }

  .add-chore-wrapper1 {
    // margin-top: -39px !important;

    h1,
    .h1 {
      // font-size: 20px !important;
    }

    .goBack {
      font-size: var(--font-12);
      margin-bottom: 20px;
    }
  }

  .dashboard-wrapper {
    .dashboard-right-article {
      .dashboard-heading {
        // font-size: var(--font-20);
      }

      .addChildRelation {
        .goBack {
          margin-bottom: 20px;
        }

        .relationsItem {
          .childRelationSingle {
            margin-bottom: 20px;

            .childName {
              width: 38px;
              min-width: 38px;
              height: 38px;
              min-height: 38px;
              line-height: 29px;
            }

            .parentName {
              font-size: var(--font-14);
            }

            .selection {
              select {
                width: 110px;
                font-size: var(--font-13);
              }
            }
          }
        }
      }

      .inviteChild,
      .inviteAdult {
        .form-box {
          .form-group {
            margin-bottom: 30px;

            label {
              margin-bottom: 0px;
            }
          }
        }

        h1,
        .h1 {
          font-size: var(--font-20);
          margin-bottom: 15px;
        }

        .goBack {
          font-size: var(--font-12);
        }
      }

      .addChildRelation {

        h1,
        .h1 {
          font-size: var(--font-20);
          margin-bottom: 15px;
        }

        .goBack {
          font-size: var(--font-12);
        }
      }

      .scanQr-inviteChild {

        h1,
        .h1 {
          font-size: var(--font-20);
          margin-bottom: 15px;
        }

        .goBack {
          font-size: var(--font-12);
          margin-bottom: 20px;
        }

        .form-box {
          .scanText {
            margin-bottom: 20px;
          }

          .showPin {
            font-size: var(--font-14);
            margin-bottom: 40px;
          }

          .qr-code {
            img {
              width: 150px;
            }
          }
        }
      }
    }
  }

  .dashboard-wrapper {
    .dashboard-right-article {
      .inviteChild {
        // margin-top: -44px;
      }

      // .child-detail-wrapper {
      //   .displayChart {
      //     .pieChart {
      //       width: 230px;
      //       height: 230px;
      //       min-width: 230px;
      //       min-height: 230px;
      //     }
      //   }
      // }
    }
  }
}

@media (max-width: 375px),
(max-width: 376px) {
  .createAssignment-wrapper {

    h1,
    .h1 {
      margin-bottom: 26px;
    }

    button {
      min-width: auto;
    }
  }

  .signUpStepForm-wrapper {

    .stepNumbers-list {
      img {
        // margin-bottom: 4px;
        // width: 30px;
      }
    }
  }

  .regoSignup {
    .largeBtn {
      width: 90%;
    }

    .signup-createAccount,
    .myProfileWrapper {
      width: 100%;
    }

    .verifyIdentity {
      width: 100%;
    }

    .confirmationCode {

      h1,
      .h1 {
        margin-bottom: 25px;
      }

      .resend-code {
        font-weight: 500;
      }

      .verificationCodeInputs {
        input {
          width: 52px;
          height: 52px;
          font-size: var(--font-30);
          border-radius: var(--borderRadiusM);
        }
      }
    }

    .myProfileWrapper {
      .error {
        font-size: var(--font-13);
      }

      .userIconDisplayBox {
        margin: 0px auto 26px auto;
      }
    }

    .user-agreement {
      .acceptTerms {
        label {
          font-size: var(--font-13);
        }
      }
    }
  }

  .landing-selection-wrapper {

    h3,
    p,
    .btns-group {}
  }

  .myCal .rbc-calendar .rbc-month-view .rbc-month-header .rbc-header {
    font-size: var(--font-13);
  }

  .myCal .rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell {
    font-size: var(--font-14);
  }

  .myCal .rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content {
    min-height: 43px;
  }

  .transferModal-wrapper {
    .form-box {
      .form-group {
        .weeksName {
          ul {
            li {
              line-height: 40px;
              height: 40px;
              font-size: var(--font-13);
            }
          }
        }
      }
    }
  }

  .shop-wrapper {
    .shop-dash-merchants {
      .approvedRetailers {
        .singleRetailer {
          span {
            margin-right: 9px;
          }

          h4,
          .h4 {
            -webkit-line-clamp: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 10px;
          }
        }
      }

      .additionalRetailers {
        .singleRetailer {
          span {
            margin-right: 9px;
          }

          h4,
          .h4 {
            -webkit-line-clamp: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 10px;
          }

          button {
            span {
              margin: 0px;
            }
          }
        }
      }
    }
  }

  .education-wrapper {
    .education-single {
      .edu-thumbnail {
        margin-left: 20px !important;
      }
    }
  }

  .inviteChild,
  .inviteAdult {
    .form-box {
      .form-group {
        .careGiver {
          .parentsList {
            .parentSingle {
              li {
                width: 35px;
                height: 35px;
                line-height: 30px;
                margin-right: 10px;
              }
            }

            .addNewParent {
              width: 35px;
              height: 35px;
              line-height: 32px;
            }
          }
        }
      }
    }
  }

  .dashboard-wrapper {
    .dashboard-right-article {
      .digital-wallet-childDtl {

        h3,
        .h3 {
          font-size: 17px !important;
        }
      }

      .dashboard-family-tabs {
        .nav-tabs {

          &.myFamily-navtabs {
            height: auto;
          }
        }
      }

      .addChildRelation {
        .relationsItem {
          .childRelationSingle {
            .childName {
              width: 35px;
              min-width: 35px;
              height: 35px;
              min-height: 35px;
              line-height: 27px;
            }
          }
        }
      }
    }
  }

  .child-detail-wrapper {
    .displayChart {
      .pieChart {

        .chartCenter {

          h3,
          .h3 {
            // font-size: var(--font-16);
            margin-bottom: 0px;
          }
        }
      }

      .chartLegends {}
    }
  }
}

@media (max-width: 320px) {
  .inviteChild {
    .form-box {
      .form-group {
        .genderGrp {
          button {
            padding: 8px 21px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .child-detail-wrapper {
    .chores-wrapper {
      .chores-single-data {
        table {
          tr {
            td {

              h5,
              .h5 {}

              p {}
            }

            td:nth-child(3) {
              font-size: var(--font-12);
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.form-group.amount input,
.allowance-amount input {
  text-align: left !important;
}

.transferModal-wrapper .react-datepicker__day--today {
  background-color: transparent !important;
  border-radius: 100%;
  font-weight: normal;
  color: #000 !important;
}

.transferModal-wrapper .react-datepicker__day--selected {
  background-color: var(--primaryColor) !important;
  border-radius: 100% !important;
  color: #fff !important;
}

.dashboard-tile .tile-chart canvas {
  height: 50px;
  width: 46px;
}

.negative {
  color: var(--errorColor);
}

.positive {
  color: var(--primaryColor);
}

.pac-container {
  z-index: 999999999 !important;
}

// CHORECHECK CSS START

.header-chorecheck {
  background-color: var(--primaryColor);
  padding: 8px 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;

  .navbar-container {
    padding: 0px 40px;

    .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        max-width: 190px;
        max-height: 50px;

        @media (max-width: 480px) {
          width: 150px;
        }
      }

      button {
        border: 2px solid #fff;
        border-radius: 30px;
        padding: 10px 42px;

        @media (max-width: 991px) {
          display: none;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0px 15px;
    }

    @media (max-width: 10000px) and (min-width: 1200px) {
      max-width: 1100px;
      margin: auto;
      padding: 0px;
    }
  }

  @media (max-width:767px) {
    display: none;
  }
}

.choreCheck-wrapper {
  width: 477px;
  margin: 30px auto;
  padding: 0px 15px 50px;

  // .btn-90{
  //   width: 40%;
  //   margin: auto;
  // }

  .choreCheckError {
    font-size: var(--font-12);
  }

  .pg__tagLine {
    color: #2eb5b5;
    font-size: var(--font-14);
    margin-bottom: 0px;
    font-weight: 500;
  }

  .pg__title {
    color: var(--primaryColor);
    font-size: var(--font-25);
    line-height: 35px;

    @media (max-width: 480px) {
      font-size: var(--font-21);
      line-height: 30px;
    }
  }

  .choreCheck-landing {
    text-align: center;

    .choreCheckLogo {
      img {
        width: 100px;
        display: block;
        margin: auto;

        &.chorecheckText {
          margin-top: 10px;
          width: 120px;
        }
      }

      h3,
      .h3 {
        color: var(--primaryColor);
        margin-top: 15px;
        font-size: var(--font-18);
        margin-bottom: 15px;
      }

      p {
        font-size: var(--font-18);
        color: var(--primaryColor);
        font-weight: 500;
        margin: 35px auto;
        line-height: 20px;
      }
    }

    .mazoolaLogo {
      img {
        width: 80px;
        border-radius: 14px;
      }

      p {
        margin-top: 5px;
        margin-bottom: 40px;
        font-weight: 700;
        color: #2eb5b5;
        margin-top: 15px;

        img {
          width: 160px;
          border-radius: 0px;
        }
      }
    }

    .existingUser {
      padding-bottom: 25px;
      position: relative;

      h4,
      .h4 {
        font-size: var(--font-14);
        margin-bottom: 50px;
        padding: 0px 70px;

        @media (max-width: 320px) {
          padding: 0px 20px;
        }
      }
    }

    .moreInfo {
      color: var(--tertiaryGrayColor);
      font-size: var(--font-15);
      padding-bottom: 30px;
      font-weight: 500;

      span {
        cursor: pointer;
      }
    }
  }

  .howItWorks-landing,
  .userAgreement-landing {
    &__description {
      font-size: var(--font-14);
      line-height: 22px;
      color: #000;
      font-weight: 400;
      margin-bottom: 50px;

      p {
        margin-bottom: 15px;

        &.special {
          font-weight: 500;
          position: relative;
          padding: 15px 0px;
          margin-bottom: 20px;

          &::before {
            content: "";
            width: 70px;
            background-color: var(--tertiaryGrayColor);
            height: 2px;
            left: calc(50% - 35px);
            position: absolute;
            top: 0;
          }

          &::after {
            content: "";
            width: 70px;
            background-color: var(--tertiaryGrayColor);
            height: 2px;
            left: calc(50% - 35px);
            position: absolute;
            bottom: 0;
          }
        }
      }

      ul {
        li {
          margin-bottom: 6px;
        }
      }

      .whyMazoola {
        ul {
          li {
            margin-bottom: 6px;
          }
        }
      }

      label {
        margin: 15px auto;
        // text-align: center;
        display: block;

        input {
          height: 19px;
          vertical-align: sub;
          margin-right: 10px;
        }
      }

      .custom-checkbox {
        label {
          padding-left: 10px;

          &::before {
            width: 20px;
            height: 20px;
            top: 0.05rem;
            left: -1.5rem;
            cursor: pointer;
          }

          &::after {
            width: 20px;
            height: 20px;
            background-size: 12px;
            top: 0.05rem;
            left: -1.5rem;
            cursor: pointer;
          }
        }
      }

      @media (max-width: 480px) {
        font-size: var(--font-13);
      }
    }
  }

  .signin {
    &__conversion {
      font-size: var(--font-14);
      line-height: 22px;
      color: #000;
      font-weight: 400;
      margin-bottom: 50px;
      margin-top: -30px;
    }

    &__description {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--tertiaryGrayColor);
      font-weight: 400;
      margin-bottom: 50px;

      .noChild-addNewOne {
        .thumbIcon {
          text-align: center;
          margin-bottom: 34px;
        }

        .noChild-title {
          text-align: center;
          font-size: var(--font-14);
          line-height: 22px;
          margin-bottom: 5px;
          color: var(--secondaryGrayColor);
          font-weight: 500;
        }
      }

      &__inviteChildren {
        p {
          color: #000;
        }

        ul,
        ol {
          padding-left: 25px;

          li {
            color: #000;
            margin-bottom: 6px;
          }
        }

        .childs-list-pin {
          font-weight: 500;
          font-size: var(--font-16);

          ul {
            padding: 0px;
            padding-left: 26px;

            li {
              list-style: none;
              margin-bottom: 6px;
            }
          }
        }

        .findChildPin {
          margin-top: 80px;
        }

        button {
          margin-top: 0px !important;
        }
      }

      .mazoolaLogo {
        margin-top: 50px;

        img {
          margin-bottom: 20px;
        }

        button {
          margin-top: 20px;
        }
      }

      .moreInfo {
        color: var(--tertiaryGrayColor);
        font-size: 16x;
        padding-bottom: 30px;
        text-align: center;
        margin-top: 60px;
        font-weight: 500;

        span {
          cursor: pointer;
        }
      }

      .form-box {
        margin-top: 40px;

        .form-group {
          margin-bottom: 40px;

          .phone-input-groups {
            display: flex;
            padding: 0px 30px;

            input {
              margin-right: 32px;
              text-align: center;
              font-size: var(--font-24);
              line-height: 36px;
              height: auto;
              font-weight: 500;
              color: #000;

              &::placeholder {
                font-size: var(--font-24);
                line-height: 36px;
              }

              &:focus {
                border-color: var(--primaryColor);
              }

              &:last-child {
                margin-right: 0px;
              }
            }
          }

          .inputGrp {
            position: relative;

            span {
              position: absolute;
              right: 30px;
              top: 8px;

              img {
                width: 16px;
                cursor: pointer;
              }
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }

        .verificationCodeInputs {
          display: flex;
          justify-content: center;
          margin-bottom: 40px;

          input {
            margin-right: 10px;
            text-align: center;
            font-weight: 400;
            width: 72px;
            height: 72px;
            border-radius: var(--borderRadiusM);
            background: #f4f5f5;
            font-size: var(--font-35);
            border: 2px solid transparent;

            &:focus,
            &:active {
              border: 2px solid var(--primaryColor);
            }

            &:last-child {
              margin-right: 0px;
            }

            @media (max-width: 375px) {
              width: 52px;
              height: 52px;
              border-radius: var(--borderRadiusM);
              font-size: var(--font-30);
            }
          }
        }

        .resend {
          font-weight: 500;
          font-size: var(--font-14);
          line-height: 24px;
          color: var(--tertiaryGrayColor);
          text-align: center;
          margin-bottom: 10px;
          text-decoration: underline;
        }

        .reEnter {
          font-weight: 500;
          font-size: var(--font-14);
          line-height: 24px;
          color: var(--tertiaryGrayColor);
          text-align: center;
          margin-bottom: 10px;
          text-decoration: underline;
        }
      }

      @media (max-width: 480px) {
        margin-bottom: 20px;
      }
    }

    button {
      margin-top: 80px;

      @media (max-width: 480px) {
        margin-top: 30px;
      }
    }
  }

  .conversion {
    &__conversion {
      font-size: var(--font-14);
      line-height: 22px;
      color: #000;
      font-weight: 400;
      margin-bottom: 50px;
      margin-top: -30px;
    }

    &__description {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--tertiaryGrayColor);
      font-weight: 400;
      margin-bottom: 50px;
    }

    &__moreInfo {
      position: relative;
      text-align: center;
      padding: 15px 0px;
      margin-bottom: 20px;

      &::before {
        content: "";
        width: 70px;
        background-color: var(--tertiaryGrayColor);
        height: 2px;
        left: calc(50% - 35px);
        position: absolute;
        top: 0;
      }

      &::after {
        content: "";
        width: 70px;
        background-color: var(--tertiaryGrayColor);
        height: 2px;
        left: calc(50% - 35px);
        position: absolute;
        bottom: 0;
      }

      p {
        &:first-child {
          font-size: var(--font-14);
          font-weight: 500;
          color: #2eb5b5;
          margin-top: 0px;
        }

        &:last-child {
          font-size: var(--font-15);
          color: #a8aeae;
          margin-bottom: 0px;
        }
      }
    }

    &__btnGrp {
      .custom-control {
        margin-bottom: 10px;
        text-align: left;

        label {
          font-size: var(--font-13);
          font-weight: 500;
          line-height: 23px;
        }
      }
    }
  }

  .createPass {
    &__description {
      font-size: var(--font-14);
      line-height: 22px;
      color: var(--tertiaryGrayColor);
      font-weight: 400;
      margin-bottom: 50px;

      .form-box {
        margin-top: 40px;

        .form-group {
          margin-bottom: 40px;

          .inputGrp {
            position: relative;

            span {
              position: absolute;
              right: 30px;
              top: var(--font-13);

              img {
                width: 16px;
                cursor: pointer;
              }
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }

        .passwordRegMatch {
          ul {
            padding: 0px;

            li {
              list-style: none;
              font-size: var(--font-12);
              color: var(--primaryGrayColor);
              line-height: 16px;
              letter-spacing: 0.02em;
              margin-bottom: 4px;

              &:last-child {
                margin-bottom: 0px;
              }

              span {
                width: 14px;
                height: 14px;
                background: transparent;
                display: inline-block;
                margin-right: 10px;
                border-radius: 14px;
                vertical-align: middle;
                border: 2px solid var(--gray5);
                margin-top: -3px;
              }

              img.MatchImg {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }

    button {
      margin-top: 80px;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
  }
}

.reset-success {
  margin-top: 200px;

  img {
    width: 100px;
  }
}

.delete-chore {
  margin-top: 60px !important;
  margin-right: -26px !important;
}

.version {
  text-align: right;
  color: grey;
  opacity: 0.5;
}

#editChore {
  .add-chore-wrapper1 {
    margin-top: 0px !important;
  }

  .add-chore-wrapper1 .description label {
    margin-bottom: 0px;
  }

  .add-chore-wrapper1 .rewards .dollarInput input::placeholder {
    color: var(--tertiaryGrayColor);
    font-size: var(--font-20);
    line-height: 24px;
    //font-family: "GothamXLight";
  }

  .add-chore-wrapper1 .viewChildUpdation .singleChildAuth .weeksName ul li {
    margin-right: 8px;
  }

  .add-education-wrapper .child-list {
    margin-bottom: 0px;
  }

  .add-chore-wrapper1 .description {
    margin: 16px auto 16px auto;
  }

  .add-chore-wrapper1 .rewards {

    margin-bottom: 16px;

  }

  .add-chore-wrapper1 .time {
    margin-bottom: 56px;
  }
}


.investment-wrapper {
  label {
    &.active {
      color: var(--primaryColor);
      font-size: var(--font-16);
    }
  }
}

/**  temp css by saurabh **/
.small {
  .pointer {
    color: var(--tertiaryGrayColor) !important;
  }
}

.invest-affiliationQA {
  .custom-control-label {
    position: relative;
  }

  .img-fluid {
    width: 18px;
    margin-top: 1px;
    position: absolute;
  }
}

.chore-createdby {
  font-size: var(--font-12);
  line-height: 16px;
  color: #4c5562;
  opacity: 0.7;
  margin-top: 3px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-bottom: 0px;
}

.back-text {
  display: none;

  @media (max-width: 767px) {
    display: none;
  }
}

.getting_started .myProfileWrapper {
  position: relative;
  width: 475px;
  margin: auto;
}

.external-connecting-bank-msg {
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {

    h1,
    .h1 {
      font-weight: 600;
      font-size: var(--font-22);
      line-height: 23px;
      letter-spacing: 0.01em;
      color: var(--primaryColor);
    }

    h4,
    .h4 {
      font-size: var(--font-16);
    }
  }

  @media (max-width:991px) {
    width: calc(100% - 320px);
  }

  @media (max-width:768px) {
    width: calc(100% - 230px);
  }

  @media (max-width:767px) {
    width: 100%;
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
  }
}

.complete-profile-wrapper {
  width: 500px;
  max-width: 500px;
  margin: auto;

  .complete-profile-content {

    h3,
    .h3 {
      font-weight: 700;
      font-size: var(--font-24);
      line-height: 27px;
      margin-bottom: 16px;
      color: var(--primaryColor);

      @media (max-width:1024px) {
        font-size: var(--font-16);
        margin-bottom: 8px;
      }
    }

    p {
      font-weight: 400;
      font-size: var(--font-16);
      line-height: 24px;
      color: var(--secondaryGrayColor);
      letter-spacing: 0.01em;

      @media (max-width:1024px) {
        font-size: var(--font-13);
        line-height: 20px;
      }
    }
  }

  .bottom-fixed-buttons {
    @media (max-width:575px) {
      position: fixed;
      width: 100%;
      bottom: 0px;
      background: #fff;
      z-index: 9;
      left: 0px;
      padding: 10px 15px 30px;
    }
  }

  @media (max-width:575px) {
    width: 100%;
    padding-top: 10px;
  }
}

.reg_pending {
  color: var(--secondaryGrayColor);
  font-size: var(--font-13);
}

.modal-dialog {
  min-height: 100%;

  &.modal-dialog-end {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    width: 500px;
    min-height: 100vh;
    background: #fff;
    align-items: start;

    @media (max-width:575px){
      width:100%;
    }

    .modal-content{
      border:none;
      min-height: 100vh;
    }
  }
}